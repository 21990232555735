import React from 'react';
import styled from 'styled-components';

import { color } from '@helpers/const';
import isMobile from '@helpers/hoc/isMobile';
import withLocation from '@helpers/hoc/withLocation';

import {
  constTotalCodeDigit,
  constGameBallStartNumber,
  constGameBallNumber,
} from '@const/game';
import { constTrendBallList } from '@const/playRule';

import { SummarizeTable } from '@components/common/table';

const styledWithFilter = (value, filter, apply) => {
  let addClassName;
  if (apply) {
    for (let index = 0; index < filter.length; index++) {
      if (value >= filter[0][0] && value <= filter[0][1]) {
        addClassName = 'red-text';
      } else if (filter[1] && value >= filter[1][0] && value <= filter[1][1]) {
        addClassName = 'blue-text';
      } else if (filter[2] && value >= filter[2][0] && value <= filter[2][1]) {
        addClassName = 'green-text';
      }
    }
  }

  return <span className={addClassName}>{value}</span>;
};

const getTableHeader = (
  group,
  filter,
  apply,
  isMobile,
  mobileActiveBall,
  headerW,
) => {
  let columns = [
    {
      title: '号码',
      dataIndex: '号码',
      width: isMobile ? 30 : 80,
    },
  ];

  for (let index = 0; index < constTotalCodeDigit[group]; index++) {
    let column;
    if (isMobile) {
      if (index === mobileActiveBall) {
        columns.push(
          {
            title: '总开',
            dataIndex: '总开' + index,
            width: 30,
            render: (value) => {
              return styledWithFilter(value, filter, apply);
            },
          },
          {
            title: '最多连开',
            dataIndex: '最多连开' + index,
            width: 30,
            render: (value) => {
              return styledWithFilter(value, filter, apply);
            },
          },
          {
            title: '当前遗漏',
            dataIndex: '当前遗漏' + index,
            width: 30,
            render: (value) => {
              return styledWithFilter(value, filter, apply);
            },
          },
          {
            title: '最大遗漏',
            dataIndex: '最大遗漏' + index,
            width: 30,
            render: (value) => {
              return styledWithFilter(value, filter, apply);
            },
          },
        );
      }
    } else {
      column = {
        title: constTrendBallList[index].text,
        children: [
          {
            title: '总开',
            dataIndex: '总开' + index,
            width: headerW,
            render: (value) => {
              return styledWithFilter(value, filter, apply);
            },
          },
          {
            title: '最多连开',
            dataIndex: '最多连开' + index,
            width: headerW,
            render: (value) => {
              return styledWithFilter(value, filter, apply);
            },
          },
          {
            title: '当前遗漏',
            dataIndex: '当前遗漏' + index,
            width: headerW,
            render: (value) => {
              return styledWithFilter(value, filter, apply);
            },
          },
          {
            title: '最大遗漏',
            dataIndex: '最大遗漏' + index,
            width: headerW,
            render: (value) => {
              return styledWithFilter(value, filter, apply);
            },
          },
        ],
      };
      columns.push(column);
    }
  }
  return columns;
};

const findHaoMaTongJi = (openCodeArray, group) => {
  let result = [];

  for (
    let indexNumber = constGameBallStartNumber[group];
    indexNumber <= constGameBallNumber[group];
    indexNumber++
  ) {
    let objFound = {};
    for (
      let indexDigit = 0;
      indexDigit < constTotalCodeDigit[group];
      indexDigit++
    ) {
      let countTotal = 0;
      let countStraight = 0;
      let countStraightTemp = 0;
      let countStraightMissing = 0;
      let isStraightMissing = true;
      let mostMissing = 0;
      let mostMissingTemp = 0;
      for (let indexRow = 0; indexRow < openCodeArray.length; indexRow++) {
        if (openCodeArray[indexRow][indexDigit] === indexNumber) {
          countTotal++;
          countStraightTemp++;

          if (isStraightMissing) {
            isStraightMissing = false;
          }

          if (countStraight < countStraightTemp) {
            countStraight = countStraightTemp;
          }

          if (mostMissing < mostMissingTemp) {
            mostMissing = mostMissingTemp;
          }
          mostMissingTemp = 0;
        } else {
          countStraightTemp = 0;
          mostMissingTemp++;
          if (isStraightMissing) {
            countStraightMissing++;
          }
        }
      }
      if (mostMissing < mostMissingTemp) {
        mostMissing = mostMissingTemp;
      }
      objFound['总开' + indexDigit] = countTotal;
      objFound['最多连开' + indexDigit] = countStraight;
      objFound['当前遗漏' + indexDigit] = countStraightMissing;
      objFound['最大遗漏' + indexDigit] = mostMissing;
    }
    result.push(objFound);
  }

  return result;
};

const getTableData = (openCodeArray, group) => {
  let dataSourceArray = [];

  const tableResult = findHaoMaTongJi(openCodeArray, group);

  const maxRow = constGameBallNumber[group] - constGameBallStartNumber[group];

  for (let indexRow = 0; indexRow <= maxRow; indexRow++) {
    let resultObj = {};
    resultObj['key'] = indexRow;
    resultObj['号码'] = indexRow + constGameBallStartNumber[group];

    for (
      let indexDigit = 0;
      indexDigit < constTotalCodeDigit[group];
      indexDigit++
    ) {
      for (let indexResult = 0; indexResult < 4; indexResult++) {
        const total = '总开' + indexDigit;
        const straight = '最多连开' + indexDigit;
        const straightMissing = '当前遗漏' + indexDigit;
        const mostMissing = '最大遗漏' + indexDigit;
        resultObj[total] = tableResult[indexRow][total];
        resultObj[straight] = tableResult[indexRow][straight];
        resultObj[straightMissing] = tableResult[indexRow][straightMissing];
        resultObj[mostMissing] = tableResult[indexRow][mostMissing];
      }
    }
    dataSourceArray.push(resultObj);
  }

  return dataSourceArray;
};

const MainTable = ({
  isMobile,
  openCodeArray,
  group,
  filter,
  apply,
  mobileActiveBall,
  search,
}) => {
  const { language } = search;
  let headerW = 53;
  if (language === 'en-US' || language === 'vi-VN') {
    headerW = 80;
  }
  const columns = getTableHeader(
    group,
    filter,
    apply,
    isMobile,
    mobileActiveBall,
    headerW,
  );
  let dataSourceArray = [];
  dataSourceArray = openCodeArray.length && getTableData(openCodeArray, group);

  return (
    <TrendsResultWrapper>
      <TrendsTableWrapper>
        <SummarizeTable
          tableData={dataSourceArray}
          tableHeader={columns}
          fixHeader={true}
          headerHight={36}
        />
      </TrendsTableWrapper>
    </TrendsResultWrapper>
  );
};

export default withLocation(isMobile(MainTable));

const TrendsResultWrapper = styled.div`
  background-color: ${color.white};

  .ant-table table {
    th {
      padding: 15px 5px;
      font-size: 15px;
      background-color: ${color.backgroundGrey};
    }

    td {
      font-size: 12px;
      line-height: 10px;
      padding: 8px 0 9px;
      color: ${color.lightFontGrey};
    }

    @media only screen and (max-width: 576px) {
      th {
        font-size: 13px;
        line-height: normal;
        padding: 0;
        background-color: ${color.orange};
        height: 35px;
      }

      td {
        font-size: 13px;
        padding: 0;
        line-height: 34px;
        color: ${color.mobileBlack};
      }

      .ant-table-row {
        &:nth-child(2n) {
          background-color: ${color.backgroundGrey};

          > td {
            background-color: ${color.backgroundGrey};
          }
        }
      }
    }
  }
`;

const TrendsTableWrapper = styled.div`
  padding: 0 25px 48px;

  @media only screen and (max-width: 576px) {
    padding: 0;
  }
`;

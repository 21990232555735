import React from 'react';
import styled from 'styled-components';

import LuzhuTable from '@components/common/luzhuTable';

import { constLuZhuName } from '@const/playRule';

import { color } from '@helpers/const';
import { generateLuzhuResult } from '@helpers/utils';

const { bigSmall, oddEven } = constLuZhuName;

const FilterResult = ({
  openCodeArray,
  filterBall,
  filterType,
  optionBall,
  group,
}) => {
  return (
    <TrendsResultWrapper>
      <GenerateLuzhuTable
        openCodeArray={openCodeArray}
        filterBall={filterBall}
        filterType={filterType}
        optionBall={optionBall}
        group={group}
      />
    </TrendsResultWrapper>
  );
};

export default FilterResult;

const GenerateLuzhuTable = ({
  openCodeArray,
  filterBall,
  filterType,
  optionBall,
  group,
}) => {
  let table = [];
  let groupDuplicateArray;

  if (openCodeArray && filterBall.length && filterType.length) {
    for (let indexBall = 0; indexBall < filterBall.length; indexBall++) {
      for (let indexType = 0; indexType < filterType.length; indexType++) {
        groupDuplicateArray = generateLuzhuResult(
          openCodeArray,
          filterBall[indexBall],
          filterType[indexType],
          group,
        );

        let defaultFilter;
        switch (filterType[indexType]) {
          case bigSmall.value:
            defaultFilter = ['big', 'small'];
            break;
          case oddEven.value:
            defaultFilter = ['odd', 'even'];
            break;
          default:
            break;
        }

        table.push(
          <LuzhuTable
            key={filterBall[indexBall] + '' + filterType[indexType]}
            groupDuplicateArray={groupDuplicateArray}
            defaultFilter={defaultFilter}
            filterType={filterType[indexType]}
            filterBall={filterBall[indexBall]}
            optionBallName={optionBall[filterBall[indexBall]].label}
            group={group}
          />,
        );
      }
    }
  }
  return table;
};

const TrendsResultWrapper = styled.div`
  background-color: ${color.white};

  @media only screen and (max-width: 576px) {
  }
`;

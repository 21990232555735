import React from 'react';
import styled from 'styled-components';
import { Row, Col } from 'antd';
import { find } from 'lodash';

import withPaddingWidth from '@helpers/hoc/withPaddingWidth';
import withLayout from '@helpers/hoc/withLayout';
import withLocation from '@helpers/hoc/withLocation';

import { color } from '@helpers/const';
import { URLS } from '@const/config';
import SEO from '@components/common/seo';
import BreadCrumb from '@components/common/breadCrumb';
import SideMenu from '@components/common/sideMenu';

const contents = [
  {
    id: 1,
    text: '关于我们',
    url: `${URLS.aboutUs}`,
    des: (
      <div>
        <p>
          乐彩网
          开奖网是一家多年完全致力于中国及世界各地公益彩票事业，提供全方位彩票开奖及彩票相关服务的提供商。
        </p>
        <p>
          乐彩网 以中国市场为技术研发基地,
          以互联网技术、电信技术、无线应用技术为依托，将创新的技术与传统的彩票产业相结合，面向全球彩票服务市场，提供最快最全的彩票开奖相关服务。
        </p>
        <p>
          乐彩网
          彩票开奖网拥有一流的技术研发创新实力以及国内外多年的彩票网站运营及管理经验，
          为中国以及全球彩票玩家提供一流的彩票创新产品及优质的服务。乐彩网
          彩票开奖网本着专业、诚信、服务至上的经营理念， 充分发挥机动灵活的运营
          及管理机制，伴随着中国互联网及手机互联网时代的腾飞、彩票市场的不断繁荣，和各方客户的忠诚支持，将全面促进新型彩票服务市场的全盛发展。
        </p>
      </div>
    ),
  },
  {
    id: 2,
    text: '免责声明',
    url: `${URLS.aboutUs}?tab=2`,
    des: (
      <div>
        <p>
          在使用乐彩网
          彩票开奖网之前，请您务必仔细阅读并透彻理解本声明。您可以选择不使用乐彩网彩票开奖网，但如果您使用乐彩网
          彩票开奖网，您的使用行为将被视为对本声明全部内容的认可
        </p>
        <p>
          除乐彩网彩票开奖网注明之服务条款外，其他一切因使用乐彩网彩票开奖网而可能遭致的意外、疏忽、侵权及其造成的损失（包括因下载被搜索链接到的第三方网站内容而感染电脑病毒），乐彩网彩票开奖网对其概不负责，亦不承担任何法律责任。
        </p>
        <p>
          任何通过使用乐彩网彩票开奖网而链接到的第三方网页均系他人制作或提供，您可能从该第三方网页上获得资讯及享用服务，乐彩网彩票开奖网对其合法性概不负责，亦不承担任何法律责任。
        </p>
        <p>
          任何单位或个人认为通过乐彩网彩票开奖网链接到的第三方网页内容可能涉嫌侵犯其信息网络传播权，应该及时向乐彩网彩票开奖网提出书面权利通知，并提供身份证明、权属证明及详细侵权情况证明。乐彩网彩票开奖网在收到上述法律文件后，将会依法尽快断开相关链接内容。详情参见特定频道的著作权保护声明。
        </p>
        <p>
          用户明确同意其使用本网站网络服务所存在的风险将完全由其自己承担；因其使用本网站网络服务而产生的一切后果也由其自己承担，乐彩网彩票开奖网对用户不承担任何责任
        </p>
        <p>下列情况时本网站亦毋需承担任何责任：</p>
        <ul>
          <li>
            任何由于黑客攻击、计算机病毒侵入或发作、因政府管理而造成的暂时性关闭等影响网络正常经营之不可抗力而造成的个人资料泄露、丢失、被盗用或被窜改等。
          </li>
          <li>
            由于与本网站链接的其它网站所造成之个人资料泄露及由此而导致的任何法律争议和后果。
          </li>
          <li>
            本站各频道内容除标明“乐彩网彩票开奖网出品”外，其余内容皆由签约内容服务提供商（机构）、网友提供，本公司不承担由于内容的合法性及健康性所引起的一切争议和法律责任。如有疑异，请与本公司客服中心联系，我们将尽快处理。
          </li>
          <li>
            本网站网页上有与其他网站网页的链接，包括旗帜广告和网页上的其他广告。本网对其他任何网站的内容、隐私政策或运营，或经营这些网站的公司的行为概不负责。在向这些与本网链接的网站提供个人信息之前，请查阅它们的隐私政策。
          </li>
          <li>本站所有帖子、评论仅代表作者个人观点，不代表本站。</li>
        </ul>
        <p>欢迎大家对侵犯版权等不合法和不健康的内容进行监督和举报。</p>
      </div>
    ),
  },
  {
    id: 3,
    text: '商务合作',
    url: `${URLS.aboutUs}?tab=3`,
    des: (
      <div>
        <p>
          乐彩网彩票开奖网是最全彩种的彩票开奖网站和免费彩票开奖数据提供商，为广大彩民提供全国各大联销型彩票及各地地方彩票的最快开奖结果、彩票资讯、彩票分析、彩票软件、彩票社区等全方位、一体化的综合彩票服务。
        </p>
        <p>乐彩网彩票开奖网可以根据合作网站的需求，提供各种合作模式。</p>
        <p>诚招各大网站携手合作，互利共赢、共创美好生活。</p>
      </div>
    ),
  },
];

const AboutUsPage = ({ search }) => {
  const contentData = {
    title: find(contents, { id: search.tab ? Number(search.tab) : 1 }).text,
    des: find(contents, { id: search.tab ? Number(search.tab) : 1 }).des,
  };
  return (
    <>
      <SEO title="About Us" />
      <BreadCrumb
        data={[
          { text: '首页', url: URLS.home },
          {
            text: '关于我们',
          },
        ]}
      />

      <Row gutter={[20, { xs: 20, md: 60 }]}>
        <Col xs={24} md={4}>
          <SideMenu data={contents} search={search} />
        </Col>
        <Col xs={24} md={20}>
          <WordWrapper>
            <h2>{contentData.title}</h2>
            {contentData.des}
          </WordWrapper>
        </Col>
      </Row>
    </>
  );
};

export default withLocation(withLayout(withPaddingWidth(AboutUsPage)));

const WordWrapper = styled.div`
  background-color: ${color.white};
  padding: 60px;

  > h2 {
    font-weight: bold;
    font-size: 16px;
    letter-spacing: 0;
    margin-bottom: 16px;
  }

  p {
    margin-bottom: 10px;
    line-height: 32px;
  }

  ul {
    list-style-type: decimal;

    li {
      margin-bottom: 10px;
    }
  }
`;

import React from 'react';

const isMobile = (ComponentToWrap) => (props) => (
  <ComponentToWrap
    {...props}
    isMobile={
      Math.max(
        document.documentElement.clientWidth || 0,
        window.innerWidth || 0,
      ) < 576
        ? true
        : false
    }
  />
);

export default isMobile;

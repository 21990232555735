import React from 'react';
import styled from 'styled-components';
import { Row, Col, Checkbox } from 'antd';

import LcwButton from '@components/common/button';

import { color } from '@helpers/const';

import isMobile from '@helpers/hoc/isMobile';
import filterSticky from '@helpers/hoc/filterSticky';

import { transl } from '@helpers/utils';

import { constLuZhuMobileBall } from '@const/playRule';

const FilterList = ({
  isMobile,
  optionBall,
  filterBall,
  setFilterBall,
  optionType,
  filterType,
  setFilterType,
  group,
}) => {
  return (
    <Col>
      {!isMobile ? (
        <>
          <Row gutter={[10, { md: 10 }]}>
            <Col>{transl('筛选名次：')}&nbsp;</Col>
            <Col>
              <Checkbox.Group
                value={filterType}
                onChange={(props) => setFilterType(props)}>
                <Row gutter={12}>
                  {optionType.map(({ text, value }) => {
                    return (
                      <Col key={text}>
                        <Checkbox value={value}>{transl(text)}</Checkbox>
                      </Col>
                    );
                  })}
                </Row>
              </Checkbox.Group>
            </Col>
          </Row>
          <Row gutter={[10, 0]}>
            <Col style={{ lineHeight: '2' }}>{transl('筛选名次：')}&nbsp;</Col>
            <Col>
              <Row gutter={[12.5, 10]}>
                {optionBall.map(({ label, value }) => {
                  return (
                    <Col key={label}>
                      <LcwButton
                        text={label}
                        active={filterBall.includes(value) ? true : false}
                        activeOnClick={() => setFilterBall([value])}
                      />
                    </Col>
                  );
                })}
              </Row>
            </Col>
          </Row>
        </>
      ) : (
        <Row className="ball-filter-wrapper hide-scrollbar">
          {constLuZhuMobileBall[group].map(({ label, value }) => {
            return (
              <Col key={label} className={'button-wrapper ' + group}>
                <LcwButton
                  text={label}
                  active={value === filterBall}
                  activeOnClick={() => setFilterBall(value)}
                  btnStyle={isMobile ? 10 : 1}
                  translShort={true}
                />
              </Col>
            );
          })}
        </Row>
      )}
    </Col>
  );
};

const Filter = ({
  isMobile,
  optionBall,
  filterBall,
  setFilterBall,
  optionType,
  filterType,
  setFilterType,
  group,
}) => {
  return (
    <FilterWrapper>
      <Row justify="space-between" align="middle" className="filter-list">
        <FilterList
          isMobile={isMobile}
          optionBall={optionBall}
          filterBall={filterBall}
          setFilterBall={setFilterBall}
          optionType={optionType}
          filterType={filterType}
          setFilterType={setFilterType}
          group={group}
        />
      </Row>
    </FilterWrapper>
  );
};

export default filterSticky(isMobile(Filter));

const FilterWrapper = styled.div`
  padding: 0 25px 19px;
  background-color: ${color.white};

  @media only screen and (max-width: 576px) {
    padding: 0;
    border-bottom: 1px solid ${color.borderGrey};

    .filter-list {
      flex: 1;

      .ant-col {
        flex: 1;

        .ball-filter-wrapper {
          width: 100%;
          flex: 1;
          flex-flow: unset;
          overflow: auto;

          .button-wrapper {
            flex: 1 0 auto;
            padding: 0 20px;

            &.SSC {
              flex: 1 1 auto;
              padding: 0;
              margin: 0 5px;
              min-width: 33%;

              // &:last-child {
              //     width: 20%;
              //     flex: 1 1 20%;
              // }
            }
          }
        }
      }
    }
  }
`;

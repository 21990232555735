import React from 'react';
import styled from 'styled-components';
import { inject, observer } from 'mobx-react';
import { toJS } from 'mobx';
import { Row, Col } from 'antd';

import {
  arrayStringToArrayInt,
  groupDuplicateArrayNumber,
  findLengRe,
  transl,
} from '@helpers/utils';
import { color } from '@helpers/const';
import withLocation from '@helpers/hoc/withLocation';
import isMobile from '@helpers/hoc/isMobile';

import { constPlayGroup } from '@const/game';

import { SummarizeTable } from '@components/common/table';
import { CircleNumber, BoxNumber, DiceNumber } from '@components/common/number';

const { SSC, PK10, XY10, D11, KL10F, K3 } = constPlayGroup;

const numberStyle = {
  height: '30px',
  width: '30px',
  fontSize: '16px',
  lineHeight: 1.9,
};

const mobileNumberStyle = {
  height: '22px',
  width: '22px',
  fontSize: '13px',
  lineHeight: '22px',
  margin: '2px 0',
};

const getNumberCode = (
  group,
  value,
  duplicateTime = 0,
  showNumber,
  isMobile = false,
) => {
  return (
    <div style={{ margin: '0 10px' }}>
      <Row gutter={{ xs: 15, sm: 12 }}>
        {value.map((num, index) => {
          return (
            <Col key={index}>
              {group === SSC || group === D11 || group === KL10F ? (
                <CircleNumber
                  number={num}
                  cssStyle={isMobile ? mobileNumberStyle : numberStyle}
                />
              ) : group === K3 ? (
                <DiceNumber
                  number={num}
                  cssStyle={isMobile ? mobileNumberStyle : numberStyle}
                />
              ) : (
                <BoxNumber
                  number={num}
                  cssStyle={isMobile ? mobileNumberStyle : numberStyle}
                />
              )}
              {duplicateTime && showNumber ? (
                <DuplicateNumber>{duplicateTime[index]}</DuplicateNumber>
              ) : (
                ''
              )}
            </Col>
          );
        })}
      </Row>
    </div>
  );
};

const getTableHeader = (showNumber, group, isMobile) => {
  let columns;
  if (isMobile) {
    columns = [
      [
        {
          title: '名次',
          dataIndex: '名次',
          width: 80,
          render: (value) => {
            return transl(value, [], true, false, 'en-US');
          },
        },
        {
          title: '热',
          dataIndex: '热',
          render: (value, row, index) => {
            const obj = {
              children: getNumberCode(
                group,
                value[0],
                value[1],
                showNumber,
                isMobile,
              ),
            };
            return obj;
          },
        },
      ],
      [
        {
          title: '名次',
          dataIndex: '名次',
          width: 80,
          render: (value) => {
            return transl(value, [], true, false, 'en-US');
          },
        },
        {
          title: '温',
          dataIndex: '温',
          render: (value, row, index) => {
            const obj = {
              children: getNumberCode(group, value, 0, showNumber, isMobile),
            };
            return obj;
          },
        },
      ],
      [
        {
          title: '名次',
          dataIndex: '名次',
          width: 80,
          render: (value) => {
            return transl(value, [], true, false, 'en-US');
          },
        },
        {
          title: '冷',
          dataIndex: '冷',
          render: (value, row, index) => {
            const obj = {
              children: getNumberCode(group, value, 0, showNumber, isMobile),
            };
            return obj;
          },
        },
      ],
    ];
  } else {
    columns = [
      {
        title: '名次',
        dataIndex: '名次',
        width: 103,
        render: (value) => {
          return transl(value, [], true, false, 'en-US');
        },
      },
      {
        title: '热',
        dataIndex: '热',
        width: 305,
        render: (value, row, index) => {
          const obj = {
            children: getNumberCode(
              group,
              value[0],
              value[1],
              showNumber,
              isMobile,
            ),
          };
          return obj;
        },
      },
      {
        title: '温',
        dataIndex: '温',
        width: 305,
        render: (value, row, index) => {
          const obj = {
            children: getNumberCode(group, value, 0, showNumber, isMobile),
          };
          return obj;
        },
      },
      {
        title: '冷',
        dataIndex: '冷',
        width: 305,
        render: (value, row, index) => {
          const obj = {
            children: getNumberCode(group, value, 0, showNumber, isMobile),
          };
          return obj;
        },
      },
    ];
  }

  return columns;
};

const getTableData = (singleGame, group, isMobile, resultDisplay) => {
  let arrayNumberOpenCode = [];

  singleGame.map(({ openCode }) => {
    return arrayNumberOpenCode.push(arrayStringToArrayInt(openCode));
  });

  const resultObj = groupDuplicateArrayNumber(arrayNumberOpenCode);
  const result = findLengRe(group, resultObj, resultDisplay);

  let dataSourceArray;

  switch (group) {
    case SSC:
    case D11:
      if (isMobile) {
        dataSourceArray = [
          [
            {
              key: 1,
              名次: '第一球',
              热: [result[0].hot, result[0].countHot],
            },
            {
              key: 2,
              名次: '第二球',
              热: [result[1].hot, result[1].countHot],
            },
            {
              key: 3,
              名次: '第三球',
              热: [result[2].hot, result[2].countHot],
            },
            {
              key: 4,
              名次: '第四球',
              热: [result[3].hot, result[3].countHot],
            },
            {
              key: 5,
              名次: '第五球',
              热: [result[4].hot, result[4].countHot],
            },
          ],
          [
            {
              key: 1,
              名次: '第一球',
              温: result[0].warm,
            },
            {
              key: 2,
              名次: '第二球',
              温: result[1].warm,
            },
            {
              key: 3,
              名次: '第三球',
              温: result[2].warm,
            },
            {
              key: 4,
              名次: '第四球',
              温: result[3].warm,
            },
            {
              key: 5,
              名次: '第五球',
              温: result[4].warm,
            },
          ],
          [
            {
              key: 1,
              名次: '第一球',
              冷: result[0].cold,
            },
            {
              key: 2,
              名次: '第二球',
              冷: result[1].cold,
            },
            {
              key: 3,
              名次: '第三球',
              冷: result[2].cold,
            },
            {
              key: 4,
              名次: '第四球',
              冷: result[3].cold,
            },
            {
              key: 5,
              名次: '第五球',
              冷: result[4].cold,
            },
          ],
        ];
      } else {
        dataSourceArray = [
          {
            key: 1,
            名次: '第一球',
            热: [result[0].hot, result[0].countHot],
            温: result[0].warm,
            冷: result[0].cold,
          },
          {
            key: 2,
            名次: '第二球',
            热: [result[1].hot, result[1].countHot],
            温: result[1].warm,
            冷: result[1].cold,
          },
          {
            key: 3,
            名次: '第三球',
            热: [result[2].hot, result[2].countHot],
            温: result[2].warm,
            冷: result[2].cold,
          },
          {
            key: 4,
            名次: '第四球',
            热: [result[3].hot, result[3].countHot],
            温: result[3].warm,
            冷: result[3].cold,
          },
          {
            key: 5,
            名次: '第五球',
            热: [result[4].hot, result[4].countHot],
            温: result[4].warm,
            冷: result[4].cold,
          },
        ];
      }
      break;
    case K3:
      if (isMobile) {
        dataSourceArray = [
          [
            {
              key: 1,
              名次: '第一球',
              热: [result[0].hot, result[0].countHot],
            },
            {
              key: 2,
              名次: '第二球',
              热: [result[1].hot, result[1].countHot],
            },
            {
              key: 3,
              名次: '第三球',
              热: [result[2].hot, result[2].countHot],
            },
          ],
          [
            {
              key: 1,
              名次: '第一球',
              温: result[0].warm,
            },
            {
              key: 2,
              名次: '第二球',
              温: result[1].warm,
            },
            {
              key: 3,
              名次: '第三球',
              温: result[2].warm,
            },
          ],
          [
            {
              key: 1,
              名次: '第一球',
              冷: result[0].cold,
            },
            {
              key: 2,
              名次: '第二球',
              冷: result[1].cold,
            },
            {
              key: 3,
              名次: '第三球',
              冷: result[2].cold,
            },
          ],
        ];
      } else {
        dataSourceArray = [
          {
            key: 1,
            名次: '第一球',
            热: [result[0].hot, result[0].countHot],
            温: result[0].warm,
            冷: result[0].cold,
          },
          {
            key: 2,
            名次: '第二球',
            热: [result[1].hot, result[1].countHot],
            温: result[1].warm,
            冷: result[1].cold,
          },
          {
            key: 3,
            名次: '第三球',
            热: [result[2].hot, result[2].countHot],
            温: result[2].warm,
            冷: result[2].cold,
          },
        ];
      }
      break;
    case PK10:
    case XY10:
      if (isMobile) {
        const name = [
          '冠军',
          '亚军',
          '第三名',
          '第四名',
          '第五名',
          '第六名',
          '第七名',
          '第八名',
          '第九名',
          '第十名',
        ];
        dataSourceArray = [];
        for (let index = 0; index < 3; index++) {
          let obj = [];
          if (index === 0) {
            for (let indexValue = 0; indexValue < name.length; indexValue++) {
              obj.push({
                key: indexValue,
                名次: name[indexValue],
                热: [result[indexValue].hot, result[indexValue].countHot],
              });
            }
          } else if (index === 1) {
            for (let indexValue = 0; indexValue < name.length; indexValue++) {
              obj.push({
                key: indexValue,
                名次: name[indexValue],
                温: result[indexValue].warm,
              });
            }
          } else if (index === 2) {
            for (let indexValue = 0; indexValue < name.length; indexValue++) {
              obj.push({
                key: indexValue,
                名次: name[indexValue],
                冷: result[indexValue].cold,
              });
            }
          }
          dataSourceArray.push(obj);
        }
      } else {
        dataSourceArray = [
          {
            key: 1,
            名次: '冠军',
            热: [result[0].hot, result[0].countHot],
            温: result[0].warm,
            冷: result[0].cold,
          },
          {
            key: 2,
            名次: '亚军',
            热: [result[1].hot, result[1].countHot],
            温: result[1].warm,
            冷: result[1].cold,
          },
          {
            key: 3,
            名次: '第三名',
            热: [result[2].hot, result[2].countHot],
            温: result[2].warm,
            冷: result[2].cold,
          },
          {
            key: 4,
            名次: '第四名',
            热: [result[3].hot, result[3].countHot],
            温: result[3].warm,
            冷: result[3].cold,
          },
          {
            key: 5,
            名次: '第五名',
            热: [result[4].hot, result[4].countHot],
            温: result[4].warm,
            冷: result[4].cold,
          },
          {
            key: 6,
            名次: '第六名',
            热: [result[5].hot, result[5].countHot],
            温: result[5].warm,
            冷: result[5].cold,
          },
          {
            key: 7,
            名次: '第七名',
            热: [result[6].hot, result[6].countHot],
            温: result[6].warm,
            冷: result[6].cold,
          },
          {
            key: 8,
            名次: '第八名',
            热: [result[7].hot, result[7].countHot],
            温: result[7].warm,
            冷: result[7].cold,
          },
          {
            key: 9,
            名次: '第九名',
            热: [result[8].hot, result[8].countHot],
            温: result[8].warm,
            冷: result[8].cold,
          },
          {
            key: 10,
            名次: '第十名',
            热: [result[9].hot, result[9].countHot],
            温: result[9].warm,
            冷: result[9].cold,
          },
        ];
      }
      break;
    case KL10F:
      if (isMobile) {
        dataSourceArray = [
          [
            {
              key: 1,
              名次: '第一球',
              热: [result[0].hot, result[0].countHot],
            },
            {
              key: 2,
              名次: '第二球',
              热: [result[1].hot, result[1].countHot],
            },
            {
              key: 3,
              名次: '第三球',
              热: [result[2].hot, result[2].countHot],
            },
            {
              key: 4,
              名次: '第四球',
              热: [result[3].hot, result[3].countHot],
            },
            {
              key: 5,
              名次: '第五球',
              热: [result[4].hot, result[4].countHot],
            },
            {
              key: 6,
              名次: '第六球',
              热: [result[5].hot, result[5].countHot],
            },
            {
              key: 7,
              名次: '第七球',
              热: [result[6].hot, result[6].countHot],
            },
            {
              key: 8,
              名次: '第八球',
              热: [result[7].hot, result[7].countHot],
            },
          ],
          [
            {
              key: 1,
              名次: '第一球',
              温: result[0].warm,
            },
            {
              key: 2,
              名次: '第二球',
              温: result[1].warm,
            },
            {
              key: 3,
              名次: '第三球',
              温: result[2].warm,
            },
            {
              key: 4,
              名次: '第四球',
              温: result[3].warm,
            },
            {
              key: 5,
              名次: '第五球',
              温: result[4].warm,
            },
            {
              key: 6,
              名次: '第六球',
              温: result[5].warm,
            },
            {
              key: 7,
              名次: '第七球',
              温: result[6].warm,
            },
            {
              key: 8,
              名次: '第八球',
              温: result[7].warm,
            },
          ],
          [
            {
              key: 1,
              名次: '第一球',
              冷: result[0].cold,
            },
            {
              key: 2,
              名次: '第二球',
              冷: result[1].cold,
            },
            {
              key: 3,
              名次: '第三球',
              冷: result[2].cold,
            },
            {
              key: 4,
              名次: '第四球',
              冷: result[3].cold,
            },
            {
              key: 5,
              名次: '第五球',
              冷: result[4].cold,
            },
            {
              key: 6,
              名次: '第六球',
              冷: result[5].cold,
            },
            {
              key: 7,
              名次: '第七球',
              冷: result[6].cold,
            },
            {
              key: 8,
              名次: '第八球',
              冷: result[7].cold,
            },
          ],
        ];
      } else {
        dataSourceArray = [
          {
            key: 1,
            名次: '第一球',
            热: [result[0].hot, result[0].countHot],
            温: result[0].warm,
            冷: result[0].cold,
          },
          {
            key: 2,
            名次: '第二球',
            热: [result[1].hot, result[1].countHot],
            温: result[1].warm,
            冷: result[1].cold,
          },
          {
            key: 3,
            名次: '第三球',
            热: [result[2].hot, result[2].countHot],
            温: result[2].warm,
            冷: result[2].cold,
          },
          {
            key: 4,
            名次: '第四球',
            热: [result[3].hot, result[3].countHot],
            温: result[3].warm,
            冷: result[3].cold,
          },
          {
            key: 5,
            名次: '第五球',
            热: [result[4].hot, result[4].countHot],
            温: result[4].warm,
            冷: result[4].cold,
          },
          {
            key: 6,
            名次: '第六球',
            热: [result[5].hot, result[5].countHot],
            温: result[5].warm,
            冷: result[5].cold,
          },
          {
            key: 7,
            名次: '第七球',
            热: [result[6].hot, result[6].countHot],
            温: result[6].warm,
            冷: result[6].cold,
          },
          {
            key: 8,
            名次: '第八球',
            热: [result[7].hot, result[7].countHot],
            温: result[7].warm,
            冷: result[7].cold,
          },
        ];
      }
      break;
    default:
      break;
  }

  return dataSourceArray;
};

const MainTable = ({ trendsStore, search, isMobile, showNumber }) => {
  const { singleGame, resultDisplay } = trendsStore;

  const { group } = search;

  let columns;
  let dataSourceArray;
  if (isMobile) {
    columns = getTableHeader(showNumber, group, isMobile);
    dataSourceArray = singleGame
      ? getTableData(toJS(singleGame), group, isMobile, resultDisplay)
      : [];
  } else {
    columns = getTableHeader(showNumber, group);
    dataSourceArray = singleGame
      ? getTableData(toJS(singleGame), group, isMobile, resultDisplay)
      : [];
  }

  return (
    <TrendsResultWrapper>
      <TrendsTableWrapper className={'border-top ' + group}>
        {isMobile ? (
          columns.map((column, index) => {
            return (
              <SummarizeTable
                key={index}
                tableData={dataSourceArray[index]}
                tableHeader={column}
              />
            );
          })
        ) : (
          <SummarizeTable tableData={dataSourceArray} tableHeader={columns} />
        )}
      </TrendsTableWrapper>
    </TrendsResultWrapper>
  );
};

export default isMobile(
  withLocation(inject('trendsStore')(observer(MainTable))),
);

const TrendsResultWrapper = styled.div`
  background-color: ${color.white};

  .ant-table table {
    th {
      padding: 15px 0;
      font-size: 15px;

      @media only screen and (max-width: 576px) {
        padding: 0;
        font-size: 13px;
        line-height: 29px;
        background-color: ${color.orange};
      }
    }

    @media only screen and (max-width: 576px) {
      .ant-table-tbody {
        tr {
          &:nth-child(2n) {
            background-color: ${color.backgroundGrey};
          }

          td {
            font-size: 13px;
            line-height: 29px;

            .ant-col {
              > div {
                margin-right: 0;
              }
            }
          }
        }
      }
    }
  }
`;

const TrendsTableWrapper = styled.div`
  padding: 0 25px 48px;

  @media only screen and (max-width: 576px) {
    padding: 0;

    .ant-table-wrapper {
      margin-bottom: 10px;
    }
  }
`;

const DuplicateNumber = styled.div`
  position: absolute;
  right: -5px;
  top: -5px;
  font-size: 12px;
  color: ${color.white};
  background-color: ${color.red};
  padding: 2px 0;
  width: 24px;
  border-radius: 5px;

  @media only screen and (max-width: 576px) {
    right: -4px;
    top: 0;
    font-size: 10px;
    font-weight: 600;
    width: 18px;
    line-height: 10px;
    padding: 1px 0;
    color: ${color.red};
    border: 1px solid ${color.red};
    background-color: ${color.white};
  }
`;

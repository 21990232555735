import React, { useState } from 'react';
import styled from 'styled-components';
import { Row, Col } from 'antd';
import LeftTable from './leftTable';
import RightTable from './rightTable';
import isMobile from '@helpers/hoc/isMobile';

const TrendsChartTable = ({ tableData, tableDataAnalysis, ...props }) => {
  const [sorting, setSorting] = useState(true);

  const sortedTableData = tableData.slice();
  const rightTableData = tableDataAnalysis.table.slice();

  if (sorting) {
    sortedTableData.reverse();

    for (let index = 0; index < rightTableData.length; index++) {
      const temp = rightTableData[index].slice();
      rightTableData[index] = temp.reverse();
    }
  }

  return (
    <TrendsChartTableWrapper>
      <Col className="left">
        <LeftTable
          sorting={sorting}
          setSorting={setSorting}
          tableData={sortedTableData}
          {...props}
        />
      </Col>
      <Col className="result">
        <RightTable
          tableData={rightTableData}
          tableDataAnalysis={tableDataAnalysis}
          {...props}
        />
      </Col>
    </TrendsChartTableWrapper>
  );
};

export default isMobile(TrendsChartTable);

const TrendsChartTableWrapper = styled(Row)`
  position: relative;
  padding: 0 25px 48px;
  text-align: center;
  flex-flow: unset;

  .ant-row {
    flex-flow: unset;
  }

  .ant-col {
    max-width: unset;

    &.result {
      &:nth-child(2) {
        overflow-x: auto;

        @media only screen and (max-width: 576px) {
          overflow-x: unset;
        }

        &::-webkit-scrollbar {
          width: 8px;
          height: 8px;
        }

        /* Track */
        &::-webkit-scrollbar-track {
          background: #f1f1f1;
        }

        /* Handle */
        &::-webkit-scrollbar-thumb {
          background: #cdcdcd;
        }

        /* Handle on hover */
        &::-webkit-scrollbar-thumb:hover {
          background: #797979;
        }

        &::-webkit-scrollbar-button {
          width: 0;
          height: 0;
          display: none;
        }

        scrollbar-width: thin;
      }
    }
  }

  @media only screen and (max-width: 576px) {
    padding: 0;

    .left {
      flex: 1;
    }
  }
`;

import React from 'react';
import styled from 'styled-components';
import { Row, Col } from 'antd';

import { color } from '@helpers/const';
import isMobile from '@helpers/hoc/isMobile';
import withLocation from '@helpers/hoc/withLocation';
import { URLS } from '@const/config';

import { generateQueryString, parseQueryString, transl } from '@helpers/utils';

import { TitleText } from './text';

import LcwButton from './button';

import { Images } from '@components/common/images';
import NumberSelection from '@components/common/numberSelection';

export const FilterButton = ({ data, location, activeBall }) => {
  return (
    <FilterButtonWrapper>
      <Row gutter={[15, 10]}>
        {data.map(({ text, ballIndex }, index) => {
          return (
            <Col key={index}>
              <LcwButton
                text={transl(text)}
                url={`${URLS.trendChart}?${generateQueryString({
                  ...parseQueryString(location.search),
                  ball: ballIndex,
                })}`}
                btnStyle={1}
                active={ballIndex === activeBall ? true : false}
              />
            </Col>
          );
        })}
      </Row>
    </FilterButtonWrapper>
  );
};

const FilterButtonWrapper = styled(Row)`
  margin: 0 22px;

  &::-webkit-scrollbar {
    width: 0px;
    height: 0px;
  }

  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
  overflow-scrolling: touch;
  scrollbar-width: none;

  .ant-row {
    flex-wrap: nowrap;
  }

  .ant-col {
    min-width: fit-content;
  }

  .ant-btn {
    margin-right: 10px;
  }
`;

// UnderLineFilter start here
const UnderLineFilterComponent = ({
  isMobile,
  filterList,
  onClickAction,
  activeFilter,
  openFilter,
  styleSet,
  selection = true,
  search,
}) => {
  let styleSetName;
  switch (styleSet) {
    case 1:
      styleSetName = 'set-1';
      break;

    default:
      break;
  }

  const { language } = search;

  const filterListComp = (filterList) =>
    filterList &&
    filterList.map((item, index) => {
      return (
        <UnderLineCategory key={index} xs={{ span: 8 }}>
          <UnderLineLink
            className={
              openFilter && item.filter === activeFilter
                ? 'open active ' + styleSetName
                : item.filter === activeFilter
                ? 'active ' + styleSetName
                : { styleSetName }
            }
            onClick={() => onClickAction(item)}>
            <TitleText
              text={transl(item.nameInChinese)}
              fontSize={isMobile ? 15 : 18}
            />
          </UnderLineLink>
        </UnderLineCategory>
      );
    });

  return (
    <UnderLineFilterWrapper
      className={styleSetName + ' hide-scrollbar ' + language}>
      {isMobile ? (
        <Row style={{ flexWrap: 'nowrap' }}>{filterListComp(filterList)}</Row>
      ) : (
        <Row justify={!isMobile ? 'space-between' : ''}>
          <Col>{filterListComp(filterList)}</Col>
          {selection && (
            <Col>
              <NumberSelection />
            </Col>
          )}
        </Row>
      )}
    </UnderLineFilterWrapper>
  );
};

export const UnderLineFilter = withLocation(isMobile(UnderLineFilterComponent));

const UnderLineFilterWrapper = styled.div`
  position: relative;
  padding-bottom: 15px;
  margin-bottom: 16px;
  overflow-y: hidden;
  -webkit-overflow-scrolling: touch;
  overflow-scrolling: touch;
  white-space: nowrap;
  display: inline-block;
  width: 100%;

  ::-webkit-scrollbar {
    height: 0px;
  }

  @media only screen and (max-width: 576px) {
    font-size: 13px;
    text-align: center;
    padding: 0;
    margin: 0;

    p {
      white-space: break-spaces;
      color: ${color.mobileBlack};
      line-height: 16px;
      height: 44px;
      display: flex;
      align-items: center;
    }

    &.en-US,
    &.vi-VN {
      p {
        font-size: 13px;
      }
    }

    &.set-1 {
      overflow: auto;
      width: 100%;
      flex-flow: nowrap;

      .ant-col {
        flex: 0 0 28%;
        max-width: 28%;

        > div {
          padding: 0 10px;

          &:after {
            content: none;
          }

          p {
            white-space: pre-wrap;
          }
        }
      }
    }
  }
`;

const UnderLineCategory = styled(Col)`
  display: inline-block;
  margin: 0 20px;
  max-width: unset;
  flex: unset;

  @media only screen and (max-width: 576px) {
    display: flex;
    margin: 0;
    flex: 0 0 33.33333333%;
    max-width: 33.33333333%;
  }
`;

const UnderLineLink = styled.div`
  cursor: pointer;

  p {
    font-weight: 600;
  }

  &:hover {
    color: ${color.orange};
  }

  &.active {
    &:after {
      content: '';
      position: absolute;
      width: 33px;
      height: 5px;
      bottom: -19px;
      left: 50%;
      transform: translateX(-50%);
      background-color: ${color.orange};
      z-index: 1;
    }
    p {
      color: ${color.orange};
    }
  }

  @media only screen and (max-width: 576px) {
    position: relative;
    width: fit-content;
    padding-right: 16px;
    margin: auto;

    p {
      font-weight: 400;
    }

    &:after {
      content: '';
      position: absolute;
      background-image: url(${Images.icn_dropdown_grey});
      width: 11px;
      height: 7px;
      top: 50%;
      left: unset;
      right: 0;
      transform: translateY(-50%);
      background-color: transparent;
    }

    &.active {
      &:after {
        width: 11px;
        height: 7px;
        left: unset;
        right: 0;
        transform: translateY(-50%);
        background-color: transparent;
      }

      &.open {
        &:after {
          background-image: url(${Images.icn_dropdown_up_orange});
        }
      }
    }
  }
`;

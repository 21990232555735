import React, { useEffect, useState, useRef } from 'react';
import { color } from '@helpers/const';
import { transl } from '@helpers/utils';
import { Row, Col } from 'antd';
import styled from 'styled-components';

import {
  constPlayGroup,
  constGameBallNumber,
  constGameBallStartNumber,
} from '@const/game';
import { basicColumnBackgroundColor } from '@const/playRule';

import withLocation from '@helpers/hoc/withLocation';

const { KL10F, K3, PK10, XY10 } = constPlayGroup;

const ConclusionResultTable = ({ valueBall, group }) => {
  return (
    <Col className="ball-column">
      <Row>
        {valueBall.map((value, numberIndex) => {
          return (
            <div key={numberIndex} className={'counter ' + group}>
              <span>{transl(value, [], false, false, 'en-US')}</span>
            </div>
          );
        })}
      </Row>
    </Col>
  );
};

const Header = ({ headerAnalysis, group, isMobile, ball, search }) => {
  const { language } = search;

  return (
    <HeaderWrapper className={'ball-' + ball + ' ' + language}>
      {headerAnalysis.map(({ dataIndex, title, children }, index) => {
        if (isMobile) {
          if (index === ball) {
            return (
              <Col key={dataIndex}>
                <Row className="row-number">
                  {children.map(({ dataIndex, title }) => {
                    return (
                      <Col key={dataIndex} className={'number ' + group}>
                        <b>{transl(title, [], false, false, 'en-US')}</b>
                      </Col>
                    );
                  })}
                </Row>
              </Col>
            );
          } else {
            return '';
          }
        } else {
          return (
            <Col key={dataIndex}>
              <div className="ball-number">
                <b>{transl(title, [], false, false, 'en-US')}</b>
              </div>
              <Row className="row-number">
                {children.map(({ dataIndex, title }) => {
                  return (
                    <Col key={dataIndex} className={'number ' + group}>
                      <b>{transl(title, [], false, false, 'en-US')}</b>
                    </Col>
                  );
                })}
              </Row>
            </Col>
          );
        }
      })}
    </HeaderWrapper>
  );
};

function drawCanvas(
  tableDataAnalysis,
  context,
  group,
  section,
  ball,
  isMobile,
) {
  let sectionCount = 0;
  let condX;
  let condY;
  switch (group) {
    case K3:
      condX = 50;
      condY = 29;

      if (isMobile) {
        condX = 40;
      }

      break;
    default:
      condX = 23;
      condY = 29;
      if ((group === PK10 || group === XY10) && isMobile) {
        condX = 21;
      }
      break;
  }
  if (isMobile) {
    context.beginPath();
    for (
      let indexRow = 0;
      indexRow < tableDataAnalysis[ball].length;
      indexRow++
    ) {
      if (section) {
        sectionCount = Math.floor(indexRow / 5);
      }
      for (
        let indexNumber = 0;
        indexNumber < tableDataAnalysis[ball][indexRow].length;
        indexNumber++
      ) {
        if (tableDataAnalysis[ball][indexRow][indexNumber][1]) {
          const numberPos = indexNumber + 1;

          if (indexRow === 0) {
            context.moveTo(
              numberPos * condX - condX / 2,
              1 * condY - condY / 2 + sectionCount,
            );
          } else {
            context.lineTo(
              numberPos * condX - condX / 2,
              (indexRow + 1) * condY - condY / 2 + sectionCount,
            );
          }
        }
      }
    }
    context.strokeStyle = basicColumnBackgroundColor[ball];
    context.stroke();
  } else {
    for (let indexBall = 0; indexBall < tableDataAnalysis.length; indexBall++) {
      context.beginPath();
      for (
        let indexRow = 0;
        indexRow < tableDataAnalysis[indexBall].length;
        indexRow++
      ) {
        if (section) {
          sectionCount = Math.floor(indexRow / 5);
        }
        for (
          let indexNumber = 0;
          indexNumber < tableDataAnalysis[indexBall][indexRow].length;
          indexNumber++
        ) {
          if (tableDataAnalysis[indexBall][indexRow][indexNumber][1]) {
            const numberPos = indexNumber + 1;
            const ballPos =
              indexBall *
              (constGameBallNumber[group] -
                constGameBallStartNumber[group] +
                1);

            if (indexRow === 0) {
              context.moveTo(
                (numberPos + ballPos) * condX - condX / 2,
                1 * condY - condY / 2 + sectionCount,
              );
            } else {
              context.lineTo(
                (numberPos + ballPos) * condX - condX / 2,
                (indexRow + 1) * condY - condY / 2 + sectionCount,
              );
            }
          }
        }
      }
      context.strokeStyle = basicColumnBackgroundColor[indexBall];
      context.stroke();
    }
  }
}

const Body = ({ tableData, group, filter, ball, isMobile }) => {
  const canvasRef = useRef(null);
  const mainTable = useRef(null);
  const [context, setContext] = useState(null);
  const [tableWidth, setTableWidth] = useState(0);
  const [tableHeight, setTableHeight] = useState(0);

  useEffect(() => {
    if (canvasRef.current) {
      const renderCtx = canvasRef.current.getContext('2d');

      if (renderCtx) {
        setContext(renderCtx);
      }
    }

    if (mainTable.current) {
      setTableWidth(mainTable.current.clientWidth);
      setTableHeight(mainTable.current.clientHeight);
    }
  }, [canvasRef, mainTable, context, tableData]);

  setTimeout(() => {
    if (mainTable.current && context && tableData.length && group !== KL10F) {
      context.clearRect(0, 0, tableWidth, tableHeight);
      if (filter.includes('line')) {
        drawCanvas(
          tableData,
          context,
          group,
          filter.includes('section'),
          ball,
          isMobile,
        );
      }
    }
  }, 100);

  return (
    <BodyWrapper>
      <div className="result-wrapper" ref={mainTable}>
        <canvas
          id="canvas"
          ref={canvasRef}
          width={tableWidth}
          height={tableHeight}></canvas>
        <Row>
          {tableData.map((valueRow, index) => {
            if (isMobile) {
              if (index === ball || group === KL10F) {
                return (
                  <Col key={index} className={'ball-column ball-' + ball}>
                    {valueRow.map((valueBall, indexRow) => {
                      return (
                        <Row
                          key={indexRow}
                          className={
                            indexRow % 5 === 4 && filter.includes('section')
                              ? 'separate'
                              : ''
                          }>
                          {valueBall.map((value, numberIndex) => {
                            const classStyle =
                              filter.includes('hightLightCount') && value[2]
                                ? 'non-appear counter ' + group
                                : 'counter ' + group;
                            return (
                              <Col
                                key={indexRow + '' + numberIndex}
                                className={classStyle}>
                                <span className={value[1] ? 'circle' : ''}>
                                  {filter.includes('count') || value[1]
                                    ? value[0]
                                    : ''}
                                </span>
                              </Col>
                            );
                          })}
                        </Row>
                      );
                    })}
                  </Col>
                );
              } else {
                return '';
              }
            } else {
              return (
                <Col key={index} className="ball-column">
                  {valueRow.map((valueBall, indexRow) => {
                    return (
                      <Row
                        key={indexRow}
                        className={
                          indexRow % 5 === 4 && filter.includes('section')
                            ? 'separate'
                            : ''
                        }>
                        {valueBall.map((value, numberIndex) => {
                          const classStyle =
                            filter.includes('hightLightCount') && value[2]
                              ? 'non-appear counter ' + group
                              : 'counter ' + group;
                          return (
                            <Col
                              key={indexRow + '' + numberIndex}
                              className={classStyle}>
                              <span className={value[1] ? 'circle' : ''}>
                                {filter.includes('count') || value[1]
                                  ? value[0]
                                  : ''}
                              </span>
                            </Col>
                          );
                        })}
                      </Row>
                    );
                  })}
                </Col>
              );
            }
          })}
        </Row>
      </div>
    </BodyWrapper>
  );
};

const Bottom = ({ tableDataAnalysis, group, isMobile, ball }) => {
  const { appear, average, biggest, connect } = tableDataAnalysis;

  return (
    <BottomWrapper className={'ball-' + ball}>
      <Row>
        {appear &&
          appear.map((valueBall, index) => {
            if (isMobile) {
              if (ball === index || group === KL10F) {
                return (
                  <ConclusionResultTable
                    key={index}
                    valueBall={valueBall}
                    group={group}
                  />
                );
              }
            } else {
              return (
                <ConclusionResultTable
                  key={index}
                  valueBall={valueBall}
                  group={group}
                />
              );
            }
            return '';
          })}
      </Row>
      <Row>
        {average &&
          average.map((valueBall, index) => {
            if (isMobile) {
              if (ball === index || group === KL10F) {
                return (
                  <ConclusionResultTable
                    key={index}
                    valueBall={valueBall}
                    group={group}
                  />
                );
              }
            } else {
              return (
                <ConclusionResultTable
                  key={index}
                  valueBall={valueBall}
                  group={group}
                />
              );
            }
            return '';
          })}
      </Row>
      <Row>
        {biggest &&
          biggest.map((valueBall, index) => {
            if (isMobile) {
              if (ball === index || group === KL10F) {
                return (
                  <ConclusionResultTable
                    key={index}
                    valueBall={valueBall}
                    group={group}
                  />
                );
              }
            } else {
              return (
                <ConclusionResultTable
                  key={index}
                  valueBall={valueBall}
                  group={group}
                />
              );
            }
            return '';
          })}
      </Row>
      <Row>
        {connect &&
          connect.map((valueBall, index) => {
            if (isMobile) {
              if (ball === index || group === KL10F) {
                return (
                  <ConclusionResultTable
                    key={index}
                    valueBall={valueBall}
                    group={group}
                  />
                );
              }
            } else {
              return (
                <ConclusionResultTable
                  key={index}
                  valueBall={valueBall}
                  group={group}
                />
              );
            }
            return '';
          })}
      </Row>
    </BottomWrapper>
  );
};

const RightTable = ({ ...props }) => {
  return (
    <>
      <Header {...props} />
      <Body {...props} />
      {!props.isMobile ? <Header {...props} /> : ''}
      <Bottom {...props} />
    </>
  );
};

export default withLocation(RightTable);

const HeaderWrapper = styled(Row)`
  font-size: 15px;
  line-height: 30px;
  background-color: ${color.backgroundGrey};

  .ball-number {
    border: 1px solid ${color.borderGrey};
    border-left: 0;
    height: 30px;
  }

  .row-number {
    .number {
      width: 23px;
      border: 1px solid ${color.borderGrey};
      border-left: 0;
      border-top: 0;

      &.K3 {
        width: 50px;
      }

      &.KL10F {
        width: 40px;
      }

      @media only screen and (max-width: 576px) {
        &.PK10,
        &.XY10 {
          width: 21px;
        }

        &.K3 {
          width: 40px;
        }

        &.KL10F {
          width: 23px;
        }
      }
    }
  }

  @media only screen and (max-width: 576px) {
    position: sticky;
    top: 88px;
    font-size: 13px;
    color: ${color.white};
    background-color: ${color.lightYellow};
    z-index: 2;

    &.en-US,
    &.vi-VN {
      top: 135px;
    }

    b {
      font-weight: 400;
    }

    .ball-number {
      b {
        font-weight: 400;
      }
    }

    .row-number {
      .number {
        height: 30px;
        border-top: 1px solid ${color.borderGrey};
      }
    }

    &.ball-0,
    &.ball-5 {
      .ant-col {
        background-color: ${color.lightYellow};
      }
    }

    &.ball-1,
    &.ball-6 {
      .ant-col {
        background-color: ${color.lightRed};
      }
    }

    &.ball-2,
    &.ball-7 {
      .ant-col {
        background-color: ${color.lightBlue};
      }
    }

    &.ball-3,
    &.ball-8 {
      .ant-col {
        background-color: ${color.lightGreen};
      }
    }

    &.ball-4,
    &.ball-9 {
      .ant-col {
        background-color: ${color.lightPurple};
      }
    }
  }
`;

const BodyWrapper = styled(Row)`
  .result-wrapper {
    position: relative;

    #canvas {
      position: absolute;
      top: 0;
      left: 0;
      z-index: 1;
    }

    .ball-column {
      &:first-child,
      &:nth-child(6) {
        background-color: ${color.opacityYellow};

        .circle {
          background-color: ${color.lightYellow};
        }
      }

      &:nth-child(2),
      &:nth-child(7) {
        background-color: ${color.opacityRed};

        .circle {
          background-color: ${color.lightRed};
        }
      }

      &:nth-child(3),
      &:nth-child(8) {
        background-color: ${color.opacityBlue};

        .circle {
          background-color: ${color.lightBlue};
        }
      }

      &:nth-child(4),
      &:nth-child(9) {
        background-color: ${color.opacityGreen};

        .circle {
          background-color: ${color.lightGreen};
        }
      }

      &:nth-child(5),
      &:nth-child(10) {
        background-color: ${color.opacityPurple};

        .circle {
          background-color: ${color.lightPurple};
        }
      }

      .ant-row {
        .counter {
          flex: 1 1;
          justify-content: center;
          width: 23px;
          padding: 4px 0;
          font-size: 12px;
          color: ${color.lightFontGrey};
          border: 1px solid ${color.tableBorderGrey};
          border-top: 0;
          border-left: 0;

          span {
            display: block;
            min-width: 20px;
            width: 20px;
            margin: auto;
            height: 20px;
            padding: 0;
            line-height: 20px;
            text-align: center;

            &.circle {
              position: relative;
              border-radius: 100%;
              color: ${color.white};
              z-index: 1;
            }
          }

          &.non-appear {
            background-color: ${color.darkGrey};
            color: ${color.white};
          }

          &.K3 {
            width: 50px;
          }

          &.KL10F {
            width: 40px;

            &:nth-child(-n + 10) {
              background-color: ${color.opacityYellow};

              .circle {
                background-color: ${color.lightYellow};
              }

              &.non-appear {
                background-color: ${color.darkGrey};
                color: ${color.white};
              }
            }

            &:nth-last-child(-n + 10) {
              background-color: ${color.opacityRed};

              .circle {
                background-color: ${color.lightRed};
              }

              &.non-appear {
                background-color: ${color.darkGrey};
                color: ${color.white};
              }
            }
          }

          @media only screen and (max-width: 576px) {
            &.PK10,
            &.XY10 {
              width: 21px;
            }

            &.K3 {
              width: 40px;
            }

            &.KL10F {
              display: flex;
              align-items: center;
              width: 23px;
              height: 35px;

              &:nth-child(-n + 10) {
                background-color: transparent;

                .circle {
                  background-color: unset;
                }
              }

              &:nth-last-child(-n + 10) {
                background-color: transparent;

                .circle {
                  background-color: unset;
                }
              }
            }
          }
        }

        &:last-child {
          .counter {
            border-bottom: 0;

            @media only screen and (max-width: 576px) {
              border-bottom: 1px solid ${color.tableBorderGrey};
            }
          }
        }

        &.separate {
          .counter {
            border-bottom: 2px solid ${color.tableBorderGrey};
          }

          &:last-child {
            .counter {
              border-bottom: 1px solid ${color.tableBorderGrey};
            }
          }
        }

        @media only screen and (max-width: 576px) {
          &:nth-child(2n + 1) {
            background-color: white;
          }
        }
      }

      @media only screen and (max-width: 576px) {
        &.ball-0,
        &.ball-5 {
          background-color: ${color.opacityYellow};

          .circle {
            background-color: ${color.lightYellow};
          }

          .ant-row {
            .counter.KL10F {
              .circle {
                background-color: ${color.lightYellow};
              }
            }
          }
        }

        &.ball-1,
        &.ball-6 {
          background-color: ${color.opacityRed};

          .circle {
            background-color: ${color.lightRed};
          }

          .ant-row {
            .counter.KL10F {
              .circle {
                background-color: ${color.lightRed};
              }
            }
          }
        }

        &.ball-2,
        &.ball-7 {
          background-color: ${color.opacityBlue};

          .circle {
            background-color: ${color.lightBlue};
          }
        }

        &.ball-3,
        &.ball-8 {
          background-color: ${color.opacityGreen};

          .circle {
            background-color: ${color.lightGreen};
          }
        }

        &.ball-4,
        &.ball-9 {
          background-color: ${color.opacityPurple};

          .circle {
            background-color: ${color.lightPurple};
          }
        }
      }
    }
  }
`;

const BottomWrapper = styled.div`
  .ball-column {
    .ant-row {
      .counter {
        flex: 1 1;
        justify-content: center;
        width: 23px;
        padding: 4px 0;
        font-size: 12px;
        color: ${color.lightFontGrey};
        border: 1px solid ${color.tableBorderGrey};
        border-top: 0;
        border-left: 0;

        &.K3 {
          width: 50px;
        }

        &.KL10F {
          width: 40px;

          &:nth-child(-n + 10) {
            background-color: ${color.opacityYellow};

            .circle {
              background-color: ${color.lightYellow};
            }
          }

          &:nth-last-child(-n + 10) {
            background-color: ${color.opacityRed};

            .circle {
              background-color: ${color.lightRed};
            }
          }
        }

        span {
          display: block;
          min-width: 20px;
          width: 20px;
          margin: auto;
          height: 20px;
          padding: 0;
          line-height: 20px;
          text-align: center;
        }

        @media only screen and (max-width: 576px) {
          &.PK10,
          &.XY10 {
            width: 21px;
          }

          &.K3 {
            width: 40px;
          }

          &.KL10F {
            width: 23px;

            &:nth-child(-n + 10) {
              background-color: unset;
            }

            &:nth-last-child(-n + 10) {
              background-color: unset;
            }
          }
        }
      }
    }
  }

  @media only screen and (max-width: 576px) {
    span {
      color: ${color.white};
    }

    &.ball-0,
    &.ball-5 {
      .ant-col {
        background-color: ${color.lightYellow};
      }
    }

    &.ball-1,
    &.ball-6 {
      .ant-col {
        background-color: ${color.lightRed};
      }
    }

    &.ball-2,
    &.ball-7 {
      .ant-col {
        background-color: ${color.lightBlue};
      }
    }

    &.ball-3,
    &.ball-8 {
      .ant-col {
        background-color: ${color.lightGreen};
      }
    }

    &.ball-4,
    &.ball-9 {
      .ant-col {
        background-color: ${color.lightPurple};
      }
    }
  }
`;

import { arrayStringToArrayInt } from '@helpers/utils';

import { styleOddEven, styleBigSmall, getNumberCode } from './style';

const k3Header = (
  singleGame,
  group,
  activeTab,
  setTab,
  subSubOption,
  isMobile,
  openDisplayType,
  setOpenDisplayType,
  activePattern,
  setPattern,
  openPattern,
  setOpenPattern,
) => {
  let columns = [];
  let temp = [];

  if (!isMobile) {
    columns = [
      {
        title: '时间',
        dataIndex: '时间',
        width: 200,
      },
    ];
  }
  columns.push(
    {
      title: '期数',
      dataIndex: '期数',
      width: isMobile ? 45 : 156,
      sorter: (a, b) => a.期数 - b.期数,
      defaultSortOrder: 'ascend',
      sortDirections: ['ascend', 'descend', 'ascend'],
    },
    {
      title: '显示号码',
      dataIndex: '显示号码',
      width: isMobile ? '' : 200,
      render: (value, row, index) => {
        let pairStyle = [];

        let nextOpenCode = {};
        if (index < singleGame.length - 1) {
          nextOpenCode = singleGame[index + 1];
        } else {
          nextOpenCode = singleGame[index - 1];
        }

        nextOpenCode =
          nextOpenCode && arrayStringToArrayInt(nextOpenCode.openCode);
        if (nextOpenCode) {
          for (
            let indexDigit = 0;
            indexDigit < nextOpenCode.length;
            indexDigit++
          ) {
            if (value[indexDigit] === nextOpenCode[indexDigit]) {
              pairStyle.push(true);
              temp[indexDigit] = nextOpenCode[indexDigit];
            } else if (value[indexDigit] === temp[indexDigit]) {
              pairStyle.push(true);
              temp[indexDigit] = false;
            } else {
              pairStyle.push(false);
              temp[indexDigit] = false;
            }
          }
        }

        const obj = getNumberCode(value, subSubOption, pairStyle, group);

        return obj;
      },
    },
    {
      title: '总和',
      children: [
        {
          title: '总数',
          dataIndex: '总和',
          width: isMobile ? 40 : 80,
        },
        {
          title: '单双',
          dataIndex: '总和oddEven',
          width: isMobile ? 40 : 80,
          render: (value) => {
            return styleOddEven(value);
          },
        },
        {
          title: '大小',
          dataIndex: '总和BigSmall',
          width: isMobile ? 40 : 80,
          render: (value) => {
            return styleBigSmall(value);
          },
        },
      ],
    },
    {
      title: '形态',
      dataIndex: '形态',
      className: 'fullTransl',
      width: isMobile ? '' : 80,
    },
    {
      title: '跨',
      dataIndex: '跨',
      width: isMobile ? 30 : 80,
    },
  );

  return columns;
};

export default k3Header;

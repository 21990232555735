import React from 'react';
import styled from 'styled-components';
import { Row, Col } from 'antd';
import { Link } from 'gatsby';

import { color } from '@helpers/const';
import { TitleText } from './text';
// import LcwButton from "./button";

const Title = () => {
  return (
    <TitleWrapper>
      <TitleText text={'分析工具'} />
    </TitleWrapper>
  );
};

const Lotto = (props) => {
  const { icon, name, yilou, zoushi } = props.data;
  // const {icon, name, yilou, zoushi, moreUrl} = props.data;
  return (
    <LottoWrapper>
      <DetailWrapper align="middle">
        <IconWrapper>
          <img src={icon} alt={name} />
        </IconWrapper>
        <NameWrapper>{name}</NameWrapper>
        {/* <MoreButton>
                    <LcwButton text="更多" url={moreUrl} btnStyle={3} />
                </MoreButton> */}
      </DetailWrapper>
      <Tool marginBottom="19px">
        <ToolTitle bg={1}>走势</ToolTitle>
        <ToolLinkList>
          {zoushi.map((item, index) => {
            return (
              <ToolLink key={index}>
                <Link to={item.url}>{item.name}</Link>
              </ToolLink>
            );
          })}
        </ToolLinkList>
      </Tool>
      <Tool>
        {yilou ? <ToolTitle bg={2}>遗漏</ToolTitle> : ''}
        <ToolLinkList>
          {yilou &&
            yilou.map((item, index) => {
              return (
                <ToolLink key={index}>
                  <Link to={item.url}>{item.name}</Link>
                </ToolLink>
              );
            })}
        </ToolLinkList>
      </Tool>
    </LottoWrapper>
  );
};

const SideBarTool = ({ toolList, allGames }) => {
  return (
    <SideBarToolWrapper>
      <Title />
      {toolList.map((item, index) => {
        return (
          allGames &&
          allGames.map(({ gameNameInChinese }) => {
            return gameNameInChinese === item.name ? (
              <Lotto data={item} key={index} />
            ) : (
              ''
            );
          })
        );
      })}
    </SideBarToolWrapper>
  );
};
export default SideBarTool;

const SideBarToolWrapper = styled.div`
  margin-bottom: 11px;
`;

const TitleWrapper = styled.div`
  padding: 9px 15px;
  background-color: ${color.white};
  border-bottom: 1px solid #eeeeee;
`;

const LottoWrapper = styled.div`
  padding: 15px;
  background-color: ${color.white};
  border-bottom: 1px solid #eeeeee;
`;

const DetailWrapper = styled(Row)`
  margin-bottom: 15px;
`;

const IconWrapper = styled.div`
  width: 48px;
  margin-right: 10px;
`;

const NameWrapper = styled.p`
  font-size: 14px;
  font-weight: bold;
  margin-bottom: 0;
`;

// const MoreButton = styled.div`
//     margin-left: auto;
// `;

const Tool = styled.div`
  margin-bottom: ${(props) => (props.marginBottom ? props.marginBottom : 0)};
`;

const ToolTitle = styled.p`
  font-weight: bold;
  width: 48px;
  font-size: 14px;
  line-height: 14px;
  padding: 4px 0;
  text-align: center;
  border-radius: 4px;
  margin-bottom: 9px;
  background-color: ${(props) =>
    props.bg === 1 ? color.softBlue : color.mint};
`;

const ToolLinkList = styled(Row)`
  padding: 0;
  margin-bottom: 0;
  margin-bottom: -10px;
`;

const ToolLink = styled(Col)`
  margin-right: 13px;
  margin-bottom: 10px;

  a {
    color: ${color.fontGrey};

    &:hover {
      color: ${color.orange};
    }
  }

  &:last-child {
    margin-right: 0;
  }
`;

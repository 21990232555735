import React from 'react';

import { Row, Col } from 'antd';

import { bigSmall, oddEven, transl } from '@helpers/utils';
import { constBigSmallThreshold } from '@const/playRule';
import { constPlayGroup } from '@const/game';

import { CircleNumber, BoxNumber, DiceNumber } from '@components/common/number';

const { SSC, D11, K3, KL10F, PCDD, QXC, THREED, MAX3D } = constPlayGroup;

export const styleOddEven = (value) => {
  return (
    <span className={value === '单' ? 'blue-text' : 'red-text'}>
      {transl(value, [], true, false, 'en-US')}
    </span>
  );
};

export const styleBigSmall = (value) => {
  return (
    <span
      className={
        value === '大'
          ? 'red-text'
          : value === '和'
          ? 'green-text'
          : 'blue-text'
      }>
      {transl(value, [], true, false, 'en-US')}
    </span>
  );
};

export const styleDragonTiger = (value, fullHead = true) => {
  return (
    <span
      className={
        value === '龙'
          ? 'red-text'
          : value === '虎'
          ? 'blue-text'
          : 'green-text'
      }>
      {transl(value, [], fullHead, false, 'en-US')}
    </span>
  );
};

export const styleShunZhi = (value) => {
  return (
    <span
      className={
        value === '顺子' || value === '对子' ? 'red-text' : 'blue-text'
      }>
      {transl(value, [], true, false, 'en-US')}
    </span>
  );
};

export const headerClickedHandler = (type, column, setTab) => {
  if (type === 'JiShiKaiJiang') {
    setTab(column.key);
  }
};

export const selecteCssStyle = (subSubOption, num, pairStyle, group) => {
  const threshold = constBigSmallThreshold[group];

  if (subSubOption.includes('对子号') && pairStyle) {
    return { opacity: 1 };
  } else if (subSubOption.includes('大') && num > threshold) {
    if (subSubOption.includes('单') || subSubOption.includes('双')) {
      if (subSubOption.includes('单') && num % 2 !== 0) {
        return { opacity: 1 };
      } else if (subSubOption.includes('双') && num % 2 === 0) {
        return { opacity: 1 };
      } else {
        return { opacity: 0.3 };
      }
    } else {
      return { opacity: 1 };
    }
  } else if (subSubOption.includes('小') && num <= threshold) {
    if (subSubOption.includes('单') || subSubOption.includes('双')) {
      if (subSubOption.includes('单') && num % 2 !== 0) {
        return { opacity: 1 };
      } else if (subSubOption.includes('双') && num % 2 === 0) {
        return { opacity: 1 };
      } else {
        return { opacity: 0.3 };
      }
    } else {
      return { opacity: 1 };
    }
  } else if (
    !(subSubOption.includes('大') || subSubOption.includes('小')) &&
    subSubOption.includes('单') &&
    num % 2 !== 0
  ) {
    return { opacity: 1 };
  } else if (
    !(subSubOption.includes('大') || subSubOption.includes('小')) &&
    subSubOption.includes('双') &&
    num % 2 === 0
  ) {
    return { opacity: 1 };
  } else if (subSubOption.includes(num)) {
    return { opacity: 1 };
  } else if (subSubOption.length === 0) {
    return { opacity: 1 };
  } else {
    return { opacity: 0.3 };
  }
};

export const getNumberCode = (value, subSubOption, pairStyle, addClass) => {
  return (
    <Row justify="center" className={'result-row-' + addClass}>
      {value.map((num, index) => {
        return (
          <Col key={index} className={'table-number'}>
            {addClass === SSC ||
            addClass === D11 ||
            addClass === PCDD ||
            addClass === QXC ||
            addClass === THREED ||
            addClass === MAX3D ||
            addClass === KL10F ? (
              <CircleNumber
                number={num}
                cssStyle={selecteCssStyle(
                  subSubOption,
                  num,
                  pairStyle[index],
                  addClass,
                )}
              />
            ) : addClass === K3 ? (
              <DiceNumber
                number={num}
                cssStyle={selecteCssStyle(
                  subSubOption,
                  num,
                  pairStyle[index],
                  addClass,
                )}
              />
            ) : (
              <BoxNumber
                number={num}
                cssStyle={selecteCssStyle(
                  subSubOption,
                  num,
                  pairStyle[index],
                  addClass,
                )}
              />
            )}
          </Col>
        );
      })}
    </Row>
  );
};

export const getNumberBigSmall = (value, subSubOption, pairStyle, addClass) => {
  let threshold = constBigSmallThreshold[addClass];

  return (
    <Row justify="center" className={'result-row-' + addClass}>
      {value.map((num, index) => {
        const resultBigSmall = bigSmall(num, threshold);
        return (
          <Col
            key={index}
            style={selecteCssStyle(
              subSubOption,
              num,
              pairStyle[index],
              addClass,
            )}>
            <div className={(resultBigSmall ? 'big ' : 'small ') + addClass}>
              {transl(resultBigSmall ? '大' : '小', [], true, false, 'en-US')}
            </div>
          </Col>
        );
      })}
    </Row>
  );
};

export const getNumberOddEven = (value, subSubOption, pairStyle, addClass) => {
  return (
    <Row justify="center" className={'result-row-' + addClass}>
      {value.map((num, index) => {
        const resultBigSmall = oddEven(num);
        return (
          <Col
            key={index}
            style={selecteCssStyle(
              subSubOption,
              num,
              pairStyle[index],
              addClass,
            )}>
            <div className={(resultBigSmall ? 'odd ' : 'even ') + addClass}>
              {transl(resultBigSmall ? '单' : '双', [], true, false, 'en-US')}
            </div>
          </Col>
        );
      })}
    </Row>
  );
};

export const styleJiZhi = (value) => {
  let result;
  let classStyle;
  if (value < 6) {
    result = '极小';
    classStyle = 'blue-text';
  } else if (value > 21) {
    result = '极大';
    classStyle = 'red-text';
  } else {
    result = '-';
  }
  return (
    <span className={classStyle}>
      {transl(result, [], true, false, 'en-US')}
    </span>
  );
};

export const styleLunPan = (value) => {
  let classStyle;
  const redText = [
    1,
    3,
    5,
    7,
    9,
    12,
    14,
    16,
    18,
    19,
    21,
    23,
    25,
    27,
    30,
    32,
    34,
    36,
  ];
  if (redText.includes(value)) {
    classStyle = 'red-text';
  }
  return (
    <span className={classStyle}>
      {transl(value, [], true, false, 'en-US')}
    </span>
  );
};

export const styleBoSe = (value) => {
  let result;
  let classStyle;
  const redValue = [3, 6, 9, 12, 15, 18, 21, 24];
  const greenValue = [1, 4, 7, 10, 16, 19, 22, 25];
  const blueValue = [2, 5, 8, 11, 17, 20, 23, 26];

  if (redValue.includes(value)) {
    result = '红波';
    classStyle = 'red-text';
  } else if (greenValue.includes(value)) {
    result = '绿波';
    classStyle = 'green-text';
  } else if (blueValue.includes(value)) {
    result = '蓝波';
    classStyle = 'blue-text';
  } else {
    result = '特例';
  }
  return (
    <span className={classStyle}>
      {transl(result, [], true, false, 'en-US')}
    </span>
  );
};

import { constPlayGroup } from '@const/game';
import { constTrendBallList } from '@const/playRule';

import { transl } from '@helpers/utils';

const dataIndexKeyK3 = ['一号', '二号', '三号', '四号', '五号', '六号'];

const { SSC, D11, PK10, XY10, K3, KL10F, PCDD, THREED, QXC } = constPlayGroup;

export const ballHeader = (group) => {
  let columns = [];
  switch (group) {
    case SSC:
    case PCDD:
    case THREED:
    case QXC:
      columns.push({
        title: '号码',
        dataIndex: '号码',
      });

      for (let col = 0; col < 10; col++) {
        columns.push({
          title: col.toString(),
          dataIndex: 'col' + col.toString(),
          render: (value, row, index) => {
            const childObj = {
              children: value ? value : 0,
            };

            return childObj;
          },
        });
      }
      break;
    case D11:
      columns.push({
        title: '号码',
        dataIndex: '号码',
        width: 90,
      });

      for (let col = 1; col < 12; col++) {
        columns.push({
          title: col.toString(),
          dataIndex: 'col' + col.toString(),
          width: 50,
          render: (value, row, index) => {
            const childObj = {
              children: value ? value : 0,
            };

            return childObj;
          },
        });
      }
      break;
    case PK10:
    case XY10:
      columns = [
        {
          title: '号码',
          dataIndex: '号码',
          width: 90,
        },
      ];

      for (let col = 0; col < 10; col++) {
        columns.push({
          title: col.toString(),
          dataIndex: 'col' + col.toString(),
          width: 90,
        });
      }
      break;
    case K3:
      columns.push({
        title: '号码',
        dataIndex: '号码',
        width: 80,
      });

      for (let col = 0; col < 6; col++) {
        columns.push({
          title: dataIndexKeyK3[col],
          dataIndex: dataIndexKeyK3[col],
        });
      }
      break;
    case KL10F:
      columns.push({
        title: '号码',
        dataIndex: '号码',
        width: 90,
      });

      for (let col = 0; col < 8; col++) {
        columns.push({
          title: constTrendBallList[col].text,
          dataIndex: 'col' + col.toString(),
          width: 50,
          render: (value, row, index) => {
            const childObj = {
              children: value ? value : 0,
            };

            return childObj;
          },
        });
      }
      break;
    default:
      break;
  }

  return columns;
};

export const ballData = (group, data) => {
  let dataSource;
  let obj;

  if (data) {
    switch (group) {
      case SSC:
      case PCDD:
      case THREED:
      case QXC:
        obj = {
          key: '1',
          号码: transl('出现次数', [], true, false, 'en-US'),
        };

        for (let col = 0; col < 11; col++) {
          const colName = 'col' + col;
          obj[colName] = data[col];
        }

        dataSource = [obj];
        break;
      case D11:
        obj = {
          key: '1',
          号码: transl('出现次数', [], true, false, 'en-US'),
        };

        for (let col = 1; col < 12; col++) {
          const colName = 'col' + col;
          obj[colName] = data[col];
        }

        dataSource = [obj];
        break;
      case K3:
        obj = {
          key: '1',
          号码: transl('出现次数', [], true, false, 'en-US'),
        };
        for (let col = 1; col < 7; col++) {
          const colName = dataIndexKeyK3[col - 1];
          obj[colName] = data[col];
        }
        dataSource = [obj];
        break;
      case KL10F:
        obj = {
          key: '1',
          号码: transl('出现次数', [], true, false, 'en-US'),
        };

        for (let col = 0; col < 8; col++) {
          const colName = 'col' + col.toString();
          obj[colName] = data[col];
        }

        dataSource = [obj];
        break;
      default:
        break;
    }
  }

  return dataSource;
};

import React from 'react';
import styled from 'styled-components';

import { color } from '@helpers/const';
import {
  sumOfNumber,
  oddEven,
  bigSmall,
  calcDuplicateCode,
  findDragonTigerThousandDigit,
} from '@helpers/utils';
import isMobile from '@helpers/hoc/isMobile';
import withLocation from '@helpers/hoc/withLocation';

import { constPlayGroup } from '@const/game';
import {
  constBigSmallThreshold,
  constTotalNumberBigSmallThreshold,
} from '@const/playRule';

import { SummarizeTable } from '@components/common/table';

import { openCodeToArray, transl } from '@helpers/utils';

const { PK10, XY10 } = constPlayGroup;

const getTableHeader = (result, mode, isMobile, isTransl) => {
  let columns = [
    {
      title: '长龙',
      className: 'long-dragon',
      children: [
        {
          title: '日期',
          dataIndex: '日期',
          className: 'date',
          width: isMobile ? 100 : 198,
        },
      ],
    },
    {
      title: isTransl
        ? transl('开“/x/i”长龙出现条数', [mode.word])
        : transl('开') + '“' + transl(mode.word) + '”' + transl('长龙出现条数'),
      children: [],
    },
  ];

  let biggestKey = Math.max(
    ...result.openCode.map((x) => Math.max(...Object.keys(x))),
  );

  for (let index = 2; index <= biggestKey; index++) {
    columns[1].children.push({
      title: `${index} ${transl('期', [], true, false, 'en-US')}`,
      dataIndex: `${index}期`,
      width: isMobile ? 42 : '',
    });
  }

  return columns;
};

const getTableData = (result) => {
  let dataSourceArray = [];

  for (let index = 0; index < result.openCode.length; index++) {
    let resultObj = {};
    resultObj['key'] = index;
    resultObj['日期'] = result.openTime[index];

    Object.keys(result.openCode[index]).map(function (keyName, keyIndex) {
      // use keyName to get current key's name
      // and a[keyName] to get its value

      const name = keyName + '期';
      resultObj[name] = result.openCode[index][keyName];
      return resultObj;
    });
    dataSourceArray.push(resultObj);
  }

  return dataSourceArray;
};

const findLongResult = (mode, filterBall, openCodeArray, group) => {
  let result = {
    openTime: [],
    openCode: [],
  };

  for (let indexRow = 0; indexRow < openCodeArray.length; indexRow++) {
    const rowArray = openCodeToArray(openCodeArray[indexRow]);
    result.openTime.push(openCodeArray[indexRow][0].stopOrderTime.slice(0, 10));
    let changLongArray = [];
    let count = 0;
    for (let index = 0; index < rowArray.length; index++) {
      let value;

      switch (mode.filter) {
        case 'oddLongMode':
        case 'evenLongMode':
          if (filterBall[1]) {
            switch (group) {
              case PK10:
              case XY10:
                value = oddEven(
                  sumOfNumber([rowArray[index][0], rowArray[index][1]]),
                );
                break;
              default:
                value = oddEven(sumOfNumber(rowArray[index]));
                break;
            }
          } else {
            value = oddEven(rowArray[index][filterBall[0]]);
          }

          if (
            (value && mode.filter === 'oddLongMode') ||
            (!value && mode.filter === 'evenLongMode')
          ) {
            count++;
          } else {
            if (count > 1) {
              changLongArray.push(count);
            }
            count = 0;
          }

          break;
        case 'bigLongMode':
        case 'smallLongMode':
          if (filterBall[1]) {
            let threshold = constTotalNumberBigSmallThreshold[group];
            if (typeof threshold === 'object') {
              threshold = threshold.threshold;
            }
            switch (group) {
              case PK10:
              case XY10:
                value = bigSmall(
                  sumOfNumber([rowArray[index][0], rowArray[index][1]]),
                  threshold,
                );
                break;
              default:
                value = bigSmall(sumOfNumber(rowArray[index]), threshold);
                break;
            }
          } else {
            value = bigSmall(
              rowArray[index][filterBall[0]],
              constBigSmallThreshold[group],
            );
          }

          if (
            (value && mode.filter === 'bigLongMode') ||
            (!value && mode.filter === 'smallLongMode')
          ) {
            count++;
          } else {
            if (count > 1) {
              changLongArray.push(count);
            }
            count = 0;
          }
          break;
        case 'dragonLongMode':
        case 'tigerLongMode':
          value = findDragonTigerThousandDigit(
            rowArray[index][filterBall[0][0]],
            rowArray[index][filterBall[0][1]],
          );

          if (
            (value === '龙' && mode.filter === 'dragonLongMode') ||
            (value === '虎' && mode.filter === 'tigerLongMode')
          ) {
            count++;
          } else {
            if (count > 1) {
              changLongArray.push(count);
            }
            count = 0;
          }
          break;
        default:
          break;
      }
    }

    changLongArray = calcDuplicateCode(changLongArray);
    result.openCode.push(changLongArray);
  }

  return result;
};

const MainTable = ({
  isMobile,
  mode,
  filterBall,
  openCodeArray,
  group,
  search,
}) => {
  const result = findLongResult(mode, filterBall, openCodeArray, group);

  const { language } = search;

  let isTransl = false;

  if (language === 'en-US' || language === 'vi-VN') {
    isTransl = true;
  }

  const columns = getTableHeader(result, mode, isMobile, isTransl);
  const dataSourceArray = getTableData(result);

  return (
    <TrendsResultWrapper>
      <TrendsTableWrapper>
        <SummarizeTable
          tableData={dataSourceArray}
          tableHeader={columns}
          scrollAble={true}
          fixHeader={true}
        />
      </TrendsTableWrapper>
    </TrendsResultWrapper>
  );
};

export default withLocation(isMobile(MainTable));

const TrendsResultWrapper = styled.div`
  background-color: ${color.white};

  .ant-table-wrapper {
    .ant-table {
      .ant-table-container {
        > .ant-table-content {
          > table {
            > thead {
              th {
                background-color: ${color.backgroundGrey};

                @media only screen and (max-width: 576px) {
                  background-color: ${color.orange};
                }

                &.long-dragon {
                  border-bottom: none;
                  text-align: right;
                  padding-right: 29px;

                  &:after {
                    content: '';
                    position: absolute;
                    width: 107%;
                    height: 1px;
                    background-color: #f0f0f0;
                    bottom: 0px;
                    right: 50%;
                    transform: translateX(50%) rotate(19deg);
                    z-index: 1;
                  }
                }

                &.date {
                  text-align: left;
                  padding-left: 29px;

                  @media only screen and (max-width: 576px) {
                    text-align: center;
                    padding-left: 0;
                  }
                }
              }
            }
          }
        }
      }

      td {
        color: ${color.black};
      }
    }

    @media only screen and (max-width: 576px) {
      .ant-table {
        .ant-table-container {
          table {
            white-space: nowrap;

            thead {
              tr {
                &:first-child {
                  display: none;
                }
              }

              th {
                line-height: initial;
                padding: 3px 1px;

                &.date {
                  height: 30px;
                  min-width: 100px;
                }
              }
            }

            td {
              color: ${color.mobileBlack};
              padding: 0;
              line-height: 29px;
              min-width: 42px;
            }

            tr {
              &:nth-child(2n) {
                background-color: ${color.backgroundGrey};
              }
            }
          }
        }
      }
    }
  }
`;

const TrendsTableWrapper = styled.div`
  padding: 0 25px 48px;

  @media only screen and (max-width: 576px) {
    padding: 0;
  }
`;

import React from 'react';
import styled from 'styled-components';

import LuzhuTable from '@components/common/luzhuTable';

import { constPlayGroup } from '@const/game';
import { constFilterRankSubOptions } from '@const/playRule';

import { color } from '@helpers/const';
import {
  generateLuzhuResult,
  sumOfArrayList,
  sumOfArrayListByPosition,
} from '@helpers/utils';

const { PK10, XY10, D11, KL10F } = constPlayGroup;

const FilterResult = ({
  openCodeArray,
  filterBall,
  filterType,
  optionBall,
  group,
}) => {
  return (
    <TrendsResultWrapper>
      <GenerateLuzhuTable
        openCodeArray={openCodeArray}
        filterBall={filterBall}
        filterType={filterType}
        optionBall={optionBall}
        group={group}
      />
    </TrendsResultWrapper>
  );
};

export default FilterResult;

const GenerateLuzhuTable = ({
  openCodeArray,
  filterBall,
  filterType,
  optionBall,
  group,
}) => {
  let table = [];
  let groupDuplicateArray;

  if (openCodeArray && filterBall.length && filterType.length) {
    for (let indexBall = 0; indexBall < filterBall.length; indexBall++) {
      for (let indexType = 0; indexType < filterType.length; indexType++) {
        let totalOfOpenCode;
        if (group !== PK10 && group !== XY10) {
          totalOfOpenCode = sumOfArrayList(openCodeArray);
          groupDuplicateArray = generateLuzhuResult(
            totalOfOpenCode,
            filterBall[indexBall],
            filterType[indexType],
            group,
          );
        } else if (group === PK10 || group === XY10) {
          totalOfOpenCode = sumOfArrayListByPosition(openCodeArray, 0, 1);
          groupDuplicateArray = generateLuzhuResult(
            totalOfOpenCode,
            filterBall[indexBall],
            filterType[indexType],
            group,
          );
        }

        let defaultFilter;
        if (filterType[indexType] === 'bigSmall') {
          if (group === D11 || group === KL10F) {
            defaultFilter = [
              constFilterRankSubOptions.bigSmall.option[0].name,
              constFilterRankSubOptions.bigSmall.option[1].name,
              constFilterRankSubOptions.bigSmall.option[2].name,
            ];
          } else {
            defaultFilter = [
              constFilterRankSubOptions.bigSmall.option[0].name,
              constFilterRankSubOptions.bigSmall.option[1].name,
            ];
          }
        } else if (filterType[indexType] === 'oddEven') {
          defaultFilter = [
            constFilterRankSubOptions.oddEven.option[0].name,
            constFilterRankSubOptions.oddEven.option[1].name,
          ];
        }

        table.push(
          <LuzhuTable
            key={filterBall[indexBall] + '' + filterType[indexType]}
            groupDuplicateArray={groupDuplicateArray}
            defaultFilter={defaultFilter}
            filterType={filterType[indexType]}
            filterBall={filterBall[indexBall]}
            optionBallName={optionBall[filterBall[indexBall]].label}
            group={group}
          />,
        );
      }
    }
  }
  return table;
};

const TrendsResultWrapper = styled.div`
  background-color: ${color.white};

  @media only screen and (max-width: 576px) {
  }
`;

import React, { useState } from 'react';
import { toJS } from 'mobx';
import { inject, observer } from 'mobx-react';
import Filter from './Filter';
import MainTable from './MainTable';
import ModeFilter from './ModeFilter';

import withLocation from '@helpers/hoc/withLocation';

import { constPlayGroup } from '@const/game';
import { constLuZhuOptionBall } from '@const/playRule';

import Reference from '@components/common/reference';

const { K3, KL10F } = constPlayGroup;

const constModeFilter = [
  {
    filter: 'oddLongMode',
    nameInChinese: '单长龙',
    word: '单',
  },
  {
    filter: 'evenLongMode',
    nameInChinese: '双长龙',
    word: '双',
  },
  {
    filter: 'bigLongMode',
    nameInChinese: '大长龙',
    word: '大',
  },
  {
    filter: 'smallLongMode',
    nameInChinese: '小长龙',
    word: '小',
  },
  {
    filter: 'dragonLongMode',
    nameInChinese: '龙长龙',
    word: '龙',
  },
  {
    filter: 'tigerLongMode',
    nameInChinese: '虎长龙',
    word: '虎',
  },
];

const constModeFilterK3 = [
  {
    filter: 'oddLongMode',
    nameInChinese: '单长龙',
    word: '单',
  },
  {
    filter: 'evenLongMode',
    nameInChinese: '双长龙',
    word: '双',
  },
  {
    filter: 'bigLongMode',
    nameInChinese: '大长龙',
    word: '大',
  },
  {
    filter: 'smallLongMode',
    nameInChinese: '小长龙',
    word: '小',
  },
];

const ChangLongTongJi = ({ trendsStore, search }) => {
  const { group } = search;

  const { histByDate, histByDateBy19Days } = trendsStore;

  const [mode, setMode] = useState(constModeFilter[0]);
  const [filterBall, setFilterBall] = useState([
    constLuZhuOptionBall[group][0].value,
    constLuZhuOptionBall[group][0].total,
  ]);
  const [openBallFilter, setOpenBallFilter] = useState(false);

  let openCodeArray = [];
  if (histByDate && histByDateBy19Days) {
    openCodeArray = toJS(histByDateBy19Days);
    openCodeArray.unshift(toJS(histByDate));
  }

  let modeFilerList;
  switch (group) {
    case K3:
    case KL10F:
      modeFilerList = constModeFilterK3;
      break;

    default:
      modeFilerList = constModeFilter;
      break;
  }

  const content = ['Dr. - Draw number'];

  return (
    <>
      <ModeFilter
        mode={mode}
        setMode={setMode}
        modeFilter={modeFilerList}
        setFilterBall={setFilterBall}
        group={group}
        openBallFilter={openBallFilter}
        setOpenBallFilter={setOpenBallFilter}
      />
      <Filter
        filterBall={filterBall}
        setFilterBall={setFilterBall}
        group={group}
        mode={mode}
        openBallFilter={openBallFilter}
        setOpenBallFilter={setOpenBallFilter}
      />
      <Reference content={content} />
      {openCodeArray.length && openCodeArray[0].length ? (
        <MainTable
          mode={mode}
          filterBall={filterBall}
          openCodeArray={openCodeArray}
          constModeFilter={modeFilerList}
          group={group}
        />
      ) : (
        ''
      )}
    </>
  );
};

export default withLocation(inject('trendsStore')(observer(ChangLongTongJi)));

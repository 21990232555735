import React from 'react';
import { Breadcrumb, Icon } from 'antd';
import styled from 'styled-components';
import { Link } from 'gatsby';

import { color } from '@helpers/const';

class BreadCrumb extends React.Component {
  render() {
    const { data } = this.props;

    return (
      <BreadCrumbWrapper>
        <Breadcrumb separator=">">
          {data.map((res, index) => {
            return (
              <Breadcrumb.Item key={index}>
                {res.icon && (
                  <Icon type={res.icon} style={{ marginRight: '5px' }} />
                )}
                {res.url ? (
                  <Link to={res.url}>
                    <span
                      style={{
                        textTransform: 'capitalize',
                      }}>
                      {res.text}
                    </span>
                  </Link>
                ) : (
                  <span style={{ textTransform: 'capitalize' }}>
                    {res.text}
                  </span>
                )}
              </Breadcrumb.Item>
            );
          })}
        </Breadcrumb>
      </BreadCrumbWrapper>
    );
  }
}

export default BreadCrumb;

const BreadCrumbWrapper = styled.div`
  padding: 15px 0;

  span {
    color: ${color.black};
  }

  .ant-breadcrumb-separator {
    margin: 0 5px;
    font-size: 16px;
  }
`;

import React, { useState } from 'react';
import styled from 'styled-components';
import { inject, observer } from 'mobx-react';
import { toJS } from 'mobx';

import { color } from '@helpers/const';
import withLocation from '@helpers/hoc/withLocation';
import isMobile from '@helpers/hoc/isMobile';

import { SummarizeTable } from '@components/common/table';

import { Images } from '@components/common/images';

import getTableHeader from './TableHeader';
import getTableData from './TableData';

const MainTable = ({
  trendsStore,
  search,
  isMobile,
  filterBall,
  filterType,
}) => {
  const [activeTab, setTab] = useState('显示号码');
  const [openDisplayType, setOpenDisplayType] = useState();

  const [activePattern, setPattern] = useState(1);
  const [openPattern, setOpenPattern] = useState();

  const { singleGame } = trendsStore;

  const { group, language } = search;

  let switcher;
  let switcherPattern;
  if (isMobile) {
    switcher = document.getElementsByClassName('ant-table-cell tab1 number');
    switcher = switcher[0] && switcher[0].clientWidth;

    switcherPattern = document.getElementsByClassName('tab1 pattern');
    switcherPattern = switcherPattern[0] && switcherPattern[0].clientWidth;
  }

  const columns = getTableHeader(
    toJS(singleGame),
    group,
    activeTab,
    setTab,
    filterBall.concat(filterType),
    isMobile,
    openDisplayType,
    setOpenDisplayType,
    activePattern,
    setPattern,
    openPattern,
    setOpenPattern,
  );
  const dataSourceArray = singleGame
    ? getTableData(toJS(singleGame), group, isMobile, activeTab)
    : [];

  let headerHightGroup = '';
  switch (group) {
    case 'MAX3D':
    case 'PCDD':
    case 'K3':
    case 'SSC':
    case 'PK10':
    case 'KL10F':
      headerHightGroup = 61;
      break;
    case 'THREED':
    case 'QXC':
      headerHightGroup = 78;
      break;

    default:
      break;
  }

  return (
    <TrendsResultWrapper
      className="trends-ref"
      switcherWidth={switcher}
      switcherPatternWidth={switcherPattern}>
      <TrendsTableWrapper className={'border-top ' + group}>
        <SummarizeTable
          className={'haveTabs ' + language}
          tableData={dataSourceArray}
          tableHeader={columns}
          fixHeader={true}
          headerHight={headerHightGroup}
        />
      </TrendsTableWrapper>
    </TrendsResultWrapper>
  );
};

export default isMobile(
  withLocation(inject('trendsStore')(observer(MainTable))),
);

const TrendsResultWrapper = styled.div`
  background-color: ${color.white};

  @media only screen and (max-width: 576px) {
    padding: 0;
  }

  .ant-table table {
    tr {
      :nth-child(even) {
        background-color: ${color.backgroundGrey};
      }
    }

    th {
      padding: 12px;

      &.no-line {
        border-bottom: 1px solid transparent !important;
      }

      &.vertical {
        line-height: 12px;
        padding: 5px;
      }

      &.ant-table-cell.tab {
        cursor: pointer;
        background-color: ${color.backgroundGrey};

        &.active {
          color: ${color.orange};
          border-color: ${color.orange};
          background-color: ${color.white};

          &:before {
            content: '';
            position: absolute;
            width: 1px;
            height: calc(100% + 2px);
            background-color: ${color.orange};
            left: -1px;
            top: -1px;
          }

          &:after {
            content: '';
            position: absolute;
            width: calc(100% + 2px);
            height: 1px;
            background-color: ${color.orange};
            left: -1px;
            top: 0px;
          }
        }

        @media only screen and (max-width: 576px) {
          &.tab2 {
            display: none;
            position: absolute;
            color: ${color.white};
            line-height: 28px;
            border-top: 1px solid ${color.borderGrey};
            background-color: ${color.orangeTransparent};
            z-index: 2;
            width: ${(props) => props.switcherWidth + 1}px;
            top: 30px;

            &.row2 {
              top: 60px;
            }

            &.row2-extent {
              top: 65px;
            }

            &.pattern {
              right: 0;
              width: ${(props) => props.switcherPatternWidth + 1}px;
            }
          }

          &.tab3 {
            display: none;
            position: absolute;
            color: ${color.white};
            line-height: 28px;
            border-top: 1px solid ${color.borderGrey};
            background-color: ${color.orangeTransparent};
            z-index: 2;
            width: ${(props) => props.switcherWidth + 1}px;
            top: 58px;

            &.row2 {
              top: 88px;
            }

            &.row2-extent {
              top: 93px;
            }

            &.pattern {
              right: 0;
              width: ${(props) => props.switcherPatternWidth + 1}px;
            }
          }

          &.tab4 {
            display: none;
            position: absolute;
            color: ${color.white};
            line-height: 28px;
            border-top: 1px solid ${color.borderGrey};
            background-color: ${color.orangeTransparent};
            z-index: 2;
            width: ${(props) => props.switcherWidth + 1}px;
            top: 78px;

            &.row2 {
              top: 117px;
            }

            &.pattern {
              right: 0;
              width: ${(props) => props.switcherPatternWidth + 1}px;
            }
          }

          &.open {
            display: table-cell;
          }

          &.active {
            position: relative;
            color: ${color.white};
            background-color: ${color.orange};
            border-color: ${color.borderGrey};

            &:before {
              content: none;
            }

            &:after {
              content: '';
              background-image: url(${Images.icn_dropdown_white});
              position: absolute;
              width: 11px;
              height: 7px;
              top: 50%;
              left: calc(50% + 40px);
              transform: translate(-50%, -50%);
            }

            &.d3 {
              &:after {
                left: calc(50% + 40px);
              }
            }

            &.qxc {
              &:after {
                left: calc(50% + 38px);
              }
            }

            &.PK10 {
              &:after {
                left: unset;
                right: 10px;
                transform: translateY(-50%);
              }
            }

            &.D11 {
              &:after {
                left: calc(50% + 40px);
              }
            }

            &.open {
              &:after {
                background-image: url(${Images.icn_dropdown_up_white});
              }
            }
          }

          &.hidden {
            display: none;
          }
        }
      }

      @media only screen and (max-width: 576px) {
        padding: 0;
        font-size: 13px;
        line-height: 29px;

        &.qxc {
          line-height: 13px;
          padding: 3px;
        }
      }
    }

    tr {
      td {
        padding: 6px 0;

        @media only screen and (max-width: 576px) {
          padding: 0;
          line-height: 29px;

          .ant-row {
            flex-flow: nowrap;
          }

          &.pk10 {
            .ant-row {
              flex-flow: nowrap;
            }
          }

          &.kl10f {
            .ant-row {
              flex-flow: nowrap;

              .ant-col {
                div {
                  font-size: 12px;
                  width: 19px;
                  height: 19px;
                  line-height: 19px;
                  margin: 0 2px;
                }
              }
            }
          }
        }
      }
    }
  }
`;

const TrendsTableWrapper = styled.div`
  padding: 15px 25px;

  @media only screen and (max-width: 576px) {
    padding: 0;
  }

  // &.D11 {
  //     .ant-table table {
  //         th {
  //             padding: 12px 0;
  //         }
  //     }
  // }

  .en-US,
  .vi-VN {
    @media only screen and (max-width: 576px) {
      .ant-table table th.ant-table-cell.tab {
        &.tab2 {
          &.row2-extent {
            top: 77px;
          }
        }

        &.tab3 {
          &.row2-extent {
            top: 105px;
          }
        }
      }
    }
  }
`;

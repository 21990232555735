import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { Row, Col } from 'antd';

import { Images } from '@components/common/images';

import { color } from '@helpers/const';
import { formatTimeStamp } from '@helpers/utils';

const Renderer = ({ minutes, seconds }) => {
  if (minutes === '00' && seconds === '00') {
    return (
      <Col>
        <div className="loading" style={{ color: color.orange }}>
          开奖中
        </div>
      </Col>
    );
  } else {
    return (
      <>
        <Col>
          <Time className="time">{minutes}</Time>
        </Col>
        <Col>分</Col>
        <Col>
          <Time className="time">{seconds}</Time>
        </Col>
        <Col>秒</Col>
      </>
    );
  }
};

const Timer = ({ timer, size, setRolling, rolling, noIcon }) => {
  const [count, setCount] = useState(timer);

  useEffect(() => {
    // if( localTimer !== timer){
    //     setCount(timer);
    //     setLocalTimer(timer);
    // }else{
    // const interval = setInterval(() => {
    //     let newTimer = count;
    //     newTimer = newTimer - 1;
    //     newTimer = newTimer >= 0 ? newTimer : 0;
    //     setCount(newTimer);
    //     console.log("newTimer", newTimer);
    // }, 1000);
    // return () => {
    //   clearInterval(interval);
    // }
    const timerToNextDraw = setTimeout(() => {
      let newTimer = count;
      newTimer = newTimer - 1;
      newTimer = newTimer >= 0 ? newTimer : 0;
      setCount(newTimer);
      if (newTimer === 0) {
        setRolling(true);
      } else if (newTimer !== 0 && rolling === true) {
        setRolling(false);
      }
    }, 1000);

    return function cleanup() {
      clearTimeout(timerToNextDraw);
    };
    // }
  }, [count, setRolling, rolling]);

  return (
    <TimerWrapper gutter={10} align="middle" className={size ? size : ''}>
      {!noIcon && <TimerIcon className={size ? size : ''}></TimerIcon>}
      <Renderer
        minutes={formatTimeStamp(count)[1]}
        seconds={formatTimeStamp(count)[2]}
      />
    </TimerWrapper>
  );
};

export default Timer;

const TimerWrapper = styled(Row)`
  line-height: 2;
  font-size: 16px;
  color: ${color.fontGrey};

  &.big {
    font-size: 23px;

    .time {
      font-size: 32px;
      width: 61px;
      height: 60px;
      line-height: 58px;
    }

    @media only screen and (max-width: 576px) {
      font-size: 14px;

      .time {
        font-size: 14px;
        width: 31px;
        height: 30px;
        line-height: 32px;
      }
    }
  }

  &.medium {
    font-size: 20px;

    .time {
      font-size: 26px;
      width: 50px;
      height: 50px;
      line-height: 50px;
    }
  }

  .loading {
    &:after {
      content: ' .';
      font-size: 1.5em;
      line-height: 0;
      animation: dots 1.5s steps(5, end) infinite;

      @keyframes dots {
        0%,
        20% {
          color: rgba(0, 0, 0, 0);
          text-shadow: 0.25em 0 0 rgba(0, 0, 0, 0), 0.5em 0 0 rgba(0, 0, 0, 0);
        }
        40% {
          color: ${color.orange};
          text-shadow: 0.25em 0 0 rgba(0, 0, 0, 0), 0.5em 0 0 rgba(0, 0, 0, 0);
        }
        60% {
          text-shadow: 0.25em 0 0 ${color.orange}, 0.5em 0 0 rgba(0, 0, 0, 0);
        }
        80%,
        100% {
          text-shadow: 0.25em 0 0 ${color.orange}, 0.5em 0 0 ${color.orange};
        }
      }
    }
  }
`;

const TimerIcon = styled(Col)`
  background-image: url(${Images.timerIcon});
  background-size: cover;
  width: 23px;
  height: 26px;
  display: block;
  margin-right: 16.3px;

  &.big {
    width: 34px;
    height: 39px;
  }

  @media only screen and (max-width: 576px) {
    margin-right: 5px;

    &.big {
      width: 20px;
      height: 23px;
    }
  }
`;

const Time = styled.div`
  text-align: center;
  width: 40.7px;
  height: 40px;
  font-size: 22px;
  line-height: 41px;
  color: ${color.white};
  background-color: ${color.charcoalGrey};
  border-radius: 8px;

  &:nth-child(2) {
    margin-left: 0;
  }
`;

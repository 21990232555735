import React from 'react';
import styled from 'styled-components';

import {
  sumOfNumber,
  getTotalOddEven,
  getTotalBigSmall,
  findDragonTigerThousandDigit,
  findShunZi,
  arrayStringToArrayInt,
  oddEven,
  findK3Pattern,
  getNumberSpan,
  someOfDigit,
  bigSmall,
} from '@helpers/utils';

import { shunZhi } from '@const/playRule';
import { constPlayGroup } from '@const/game';

import { SummarizeTable } from '@components/common/table';

const { SSC, D11, PK10, XY10, K3, KL10F, SIX } = constPlayGroup;

const { firstThree, middleThree, lastThree } = shunZhi;

const getTableHeader = (group) => {
  let columns = [];

  switch (group) {
    case SSC:
    case D11:
      columns = [
        {
          title: '总和',
          dataIndex: '总和',
          colSpan: 3,
        },
        {
          title: '总和oddEven',
          dataIndex: '总和oddEven',
          colSpan: 0,
        },
        {
          title: '总和BigSmall',
          dataIndex: '总和BigSmall',
          colSpan: 0,
        },
        {
          title: '龙虎',
          dataIndex: '龙虎',
        },
        {
          title: '前三',
          dataIndex: '前三',
        },
        {
          title: '中三',
          dataIndex: '中三',
        },
        {
          title: '后三',
          dataIndex: '后三',
        },
      ];
      break;
    case PK10:
    case XY10:
      columns = [
        {
          title: '龙虎',
          dataIndex: '龙虎',
          colSpan: 5,
        },
        {
          title: '龙虎2',
          dataIndex: '龙虎2',
          colSpan: 0,
        },
        {
          title: '龙虎3',
          dataIndex: '龙虎3',
          colSpan: 0,
        },
        {
          title: '龙虎4',
          dataIndex: '龙虎4',
          colSpan: 0,
        },
        {
          title: '龙虎5',
          dataIndex: '龙虎5',
          colSpan: 0,
        },
        {
          title: '冠亚和',
          dataIndex: '冠亚和',
          colSpan: 3,
        },
        {
          title: '冠亚和EvenOdd',
          dataIndex: '冠亚和EvenOdd',
          colSpan: 0,
        },
        {
          title: '冠亚和BigSmall',
          dataIndex: '冠亚和BigSmall',
          colSpan: 0,
        },
      ];
      break;
    case K3:
      columns = [
        {
          title: '总和',
          dataIndex: '总和',
          colSpan: 3,
        },
        {
          title: '总和oddEven',
          dataIndex: '总和oddEven',
          colSpan: 0,
        },
        {
          title: '总和BigSmall',
          dataIndex: '总和BigSmall',
          colSpan: 0,
        },
        {
          title: '形态',
          dataIndex: '形态',
        },
        {
          title: '跨',
          dataIndex: '跨',
        },
      ];
      break;
    case KL10F:
      columns = [
        {
          title: '总和',
          dataIndex: '总和',
          colSpan: 3,
        },
        {
          title: '总和oddEven',
          dataIndex: '总和oddEven',
          colSpan: 0,
        },
        {
          title: '总和BigSmall',
          dataIndex: '总和BigSmall',
          colSpan: 0,
        },
        {
          title: '跨',
          dataIndex: '跨',
        },
      ];
      break;
    case SIX:
      columns = [
        {
          title: '总和',
          dataIndex: '总和总数',
          width: 50,
          colSpan: 3,
        },
        {
          title: '单双',
          dataIndex: '总和单双',
          width: 50,
          colSpan: 0,
        },
        {
          title: '大小',
          dataIndex: '总和大小',
          width: 50,
          colSpan: 0,
        },
        {
          title: '特码',
          dataIndex: '特码单双',
          width: 50,
          colSpan: 4,
        },
        {
          title: '大小',
          dataIndex: '特码大小',
          width: 50,
          colSpan: 0,
        },
        {
          title: '合单双',
          dataIndex: '特码合单双',
          width: 53,
          colSpan: 0,
        },
        {
          title: '合大小',
          dataIndex: '特码合大小',
          width: 53,
          colSpan: 0,
        },
      ];
      break;
    default:
      break;
  }

  return columns;
};

const getTableData = (group, dataSource) => {
  let dataSourceArray = [];
  let arrayNumbers;
  if (dataSource) {
    switch (group) {
      case SSC:
      case D11:
        arrayNumbers = arrayStringToArrayInt(dataSource);
        dataSourceArray = [
          {
            key: '1',
            总和: sumOfNumber(arrayNumbers),
            总和oddEven: getTotalOddEven(arrayNumbers),
            总和BigSmall: getTotalBigSmall(arrayNumbers, group),
            龙虎: findDragonTigerThousandDigit(
              arrayNumbers[0],
              arrayNumbers[4],
            ),
            前三: findShunZi(firstThree, arrayNumbers, group),
            中三: findShunZi(middleThree, arrayNumbers, group),
            后三: findShunZi(lastThree, arrayNumbers, group),
          },
        ];
        break;
      case XY10:
      case PK10:
        arrayNumbers = arrayStringToArrayInt(dataSource);
        dataSourceArray = [
          {
            key: '1',
            龙虎: findDragonTigerThousandDigit(
              arrayNumbers[0],
              arrayNumbers[9],
            ),
            龙虎2: findDragonTigerThousandDigit(
              arrayNumbers[1],
              arrayNumbers[8],
            ),
            龙虎3: findDragonTigerThousandDigit(
              arrayNumbers[2],
              arrayNumbers[7],
            ),
            龙虎4: findDragonTigerThousandDigit(
              arrayNumbers[3],
              arrayNumbers[6],
            ),
            龙虎5: findDragonTigerThousandDigit(
              arrayNumbers[4],
              arrayNumbers[5],
            ),
            冠亚和: arrayNumbers[0] + arrayNumbers[1],
            冠亚和EvenOdd: oddEven(arrayNumbers[0] + arrayNumbers[1])
              ? '单'
              : '双',
            冠亚和BigSmall: getTotalBigSmall(arrayNumbers, group),
          },
        ];
        break;
      case K3:
        arrayNumbers = arrayStringToArrayInt(dataSource);
        dataSourceArray = [
          {
            key: '1',
            总和: sumOfNumber(arrayNumbers),
            总和oddEven: getTotalOddEven(arrayNumbers),
            总和BigSmall: getTotalBigSmall(arrayNumbers, group),
            形态: findK3Pattern(arrayNumbers, group),
            跨: getNumberSpan(arrayNumbers[2], arrayNumbers[0]),
          },
        ];
        break;
      case KL10F:
        arrayNumbers = arrayStringToArrayInt(dataSource);
        const sortArrayNumbers = arrayNumbers.slice().sort((a, b) => a - b);
        dataSourceArray = [
          {
            key: '1',
            总和: sumOfNumber(arrayNumbers),
            总和oddEven: getTotalOddEven(arrayNumbers),
            总和BigSmall: getTotalBigSmall(arrayNumbers, group),
            跨: getNumberSpan(sortArrayNumbers[7], sortArrayNumbers[0]),
          },
        ];
        break;
      case SIX:
        arrayNumbers = arrayStringToArrayInt(dataSource);
        const specialCode = arrayNumbers.slice(6, 7);
        const specialSumOfDigit = someOfDigit(specialCode);
        const specialOddEven = oddEven(specialSumOfDigit) ? '合单' : '合双';
        const specialBigSmall = bigSmall(specialSumOfDigit, 6)
          ? '合大'
          : '合小';
        dataSourceArray = [
          {
            key: '1',
            总和总数: sumOfNumber(arrayNumbers),
            总和单双: getTotalOddEven(arrayNumbers),
            总和大小: getTotalBigSmall(arrayNumbers, 175),
            特码单双: oddEven(specialCode) ? '单' : '双',
            特码大小: bigSmall(specialCode, 24) ? '大' : '小',
            特码合单双: specialOddEven,
            特码合大小: specialBigSmall,
          },
        ];
        break;
      default:
        break;
    }
  }

  return dataSourceArray;
};

const CardTable = ({ group, dataSource }) => {
  const tableHeader = getTableHeader(group);
  const tableData =
    dataSource !== 'undefined' ? getTableData(group, dataSource) : [];

  return (
    <CardResultWrapper>
      <SummarizeTable tableData={tableData} tableHeader={tableHeader} />
    </CardResultWrapper>
  );
};

export default CardTable;

const CardResultWrapper = styled.div`
  .ant-table-wrapper {
    margin-bottom: 0;

    th {
      font-weight: normal;
    }
  }
`;

import React, { useEffect, useState, useRef } from 'react';
import styled from 'styled-components';
import { inject, observer } from 'mobx-react';
import { toJS } from 'mobx';

import LeftTable from '@components/common/trendsChartTable/leftTable';

import {
  arrayStringToArrayInt,
  bigSmall,
  oddEven,
  isPrime,
} from '@helpers/utils';
import { color } from '@helpers/const';
import withLocation from '@helpers/hoc/withLocation';
import isMobile from '@helpers/hoc/isMobile';
import {
  getDataAnalysis,
  getDataAnalysisPattern,
  transl,
} from '@helpers/utils';

import {
  constPlayGroup,
  constGameBallNumber,
  constGameBallStartNumber,
} from '@const/game';
import {
  constTrendBallList,
  basicColumnBackgroundColor,
  constBigSmallThreshold,
} from '@const/playRule';

import { Row, Col } from 'antd';

const { KL10F, K3 } = constPlayGroup;

const getTableHeaderData = () => {
  let columns = [
    {
      title: '期数',
      dataIndex: '期数',
      width: 120,
      sorter: true,
    },
    {
      title: '开奖号码',
      dataIndex: '开奖号码',
      width: 120,
      className: 'open-code',
    },
  ];

  return columns;
};

const getTableData = (singleGame, isMobile) => {
  let arrayNumberOpenCode = [];

  singleGame.map((game) => {
    return arrayNumberOpenCode.push(arrayStringToArrayInt(game.openCode));
  });

  let dataSourceArray = [];

  for (let index = 0; index < singleGame.length; index++) {
    const stringIssueNumber = singleGame[index].uniqueIssueNumber.toString();
    const shortenIssueNumber =
      stringIssueNumber.length > 4
        ? stringIssueNumber.slice(stringIssueNumber.length - 4)
        : stringIssueNumber;
    let data = {
      key: index,
      uniqueIssueNumber: isMobile ? shortenIssueNumber : stringIssueNumber,
      openCode: arrayNumberOpenCode[index],
    };
    dataSourceArray.push(data);
  }

  return dataSourceArray;
};

const getTableHeaderAnalysis = (group, ballFilter) => {
  let result = [];
  let header;
  switch (group) {
    case KL10F:
      header = [
        {
          title: '小区',
          dataIndex: '小区',
        },
        {
          title: '大区',
          dataIndex: '大区',
        },
      ];

      let startIndex = [1, 11];

      let maxIndex = [10, 20];

      for (let index = 0; index < header.length; index++) {
        let ball = [];

        let header = {
          title: constTrendBallList[index].text,
          dataIndex: constTrendBallList[index].text,
        };

        for (
          let indexChild = startIndex[index];
          indexChild <= maxIndex[index];
          indexChild++
        ) {
          const ballCol = {
            title: indexChild.toString(),
            dataIndex: indexChild,
          };
          ball.push(ballCol);
        }

        header.children = ball;
        result.push(header);
      }
      break;
    default:
      header = {
        title: constTrendBallList[ballFilter].text,
        dataIndex: constTrendBallList[ballFilter].text,
      };

      let ball = [];
      for (
        let indexChild = constGameBallStartNumber[group];
        indexChild <= constGameBallNumber[group];
        indexChild++
      ) {
        const ballCol = {
          title: indexChild.toString(),
          dataIndex: constTrendBallList[ballFilter].text + indexChild,
        };
        ball.push(ballCol);
      }

      header.children = ball;
      result.push(header);

      break;
  }

  const objPatternHeader = [
    {
      title: '形态特征',
      children: ['大', '小', '单', '双', '合', '质'],
    },
    {
      title: '012路',
      children: ['0', '1', '2'],
    },
    {
      title: '升平降',
      children: ['升', '平', '降'],
    },
  ];

  for (let index = 0; index < objPatternHeader.length; index++) {
    header = {
      title: objPatternHeader[index].title,
      dataIndex: objPatternHeader[index].title,
    };

    let pattern = [];
    for (
      let indexChild = 0;
      indexChild < objPatternHeader[index].children.length;
      indexChild++
    ) {
      const patternCol = {
        title: objPatternHeader[index].children[indexChild],
        dataIndex: objPatternHeader[index].children[indexChild],
      };
      pattern.push(patternCol);
    }

    header.children = pattern;
    result.push(header);
  }

  return result;
};

const getTableDataAnalysis = (singleGame, group, ballFilter) => {
  let arrayNumberOpenCode = [];

  singleGame.map((game) => {
    return arrayNumberOpenCode.push(arrayStringToArrayInt(game.openCode));
  });

  const resultDataAnalysis = getDataAnalysis(
    group,
    arrayNumberOpenCode,
    ballFilter,
  );

  const {
    appear,
    average,
    biggest,
    connect,
    openCodeByBall,
    table,
  } = resultDataAnalysis;

  const result = {
    table: [table[ballFilter]],
    appear: appear[ballFilter],
    average: average[ballFilter],
    biggest: biggest[ballFilter],
    connect: connect[ballFilter],
    openCodeByBall: openCodeByBall[ballFilter],
  };

  return result;
};

const getPatternAnalysis = (openCode, group, allSingleGame, ballFilter) => {
  const openCodeNum30Array = arrayStringToArrayInt(
    allSingleGame[openCode.length].openCode,
  );

  const openCodeNum30 = openCodeNum30Array[ballFilter];

  const bigSmallThreshold = constBigSmallThreshold[group];

  let result;
  let resultTable = [];

  let countBigSmall = 0;
  let isBig;

  let countOddEven = 0;
  let isOdd;

  let countPrime = 0;
  let isPrimeNumber;

  let count012 = [0, 0, 0];
  let is012;

  let countUpDown = [0, 0, 0];
  let isUpDown;

  let countAppear = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
  let countNonAppear = [
    true,
    true,
    true,
    true,
    true,
    true,
    true,
    true,
    true,
    true,
    true,
    true,
  ];
  for (let index = 0; index < openCode.length; index++) {
    let bigSmallArray;
    if (bigSmall(openCode[index], bigSmallThreshold)) {
      if (isBig) {
        countBigSmall++;
      } else {
        countBigSmall = 1;
      }
      isBig = true;
      countNonAppear[0] = false;
      bigSmallArray = [
        ['大', countNonAppear[0]],
        [countBigSmall, countNonAppear[1]],
      ];
      countAppear[0]++;
    } else {
      if (isBig) {
        countBigSmall = 1;
      } else {
        countBigSmall++;
      }
      isBig = false;
      countNonAppear[1] = false;
      bigSmallArray = [
        [countBigSmall, countNonAppear[0]],
        ['小', countNonAppear[1]],
      ];
      countAppear[1]++;
    }

    let oddEvenArray;
    if (oddEven(openCode[index])) {
      if (isOdd) {
        countOddEven++;
      } else {
        countOddEven = 1;
      }
      isOdd = true;
      countNonAppear[2] = false;
      oddEvenArray = [
        ['单', countNonAppear[2]],
        [countOddEven, countNonAppear[3]],
      ];
      countAppear[2]++;
    } else {
      if (isOdd) {
        countOddEven = 1;
      } else {
        countOddEven++;
      }
      isOdd = false;
      countNonAppear[3] = false;
      oddEvenArray = [
        [countOddEven, countNonAppear[2]],
        ['双', countNonAppear[3]],
      ];
      countAppear[3]++;
    }

    let primeArray;
    if (isPrime(openCode[index])) {
      if (isPrimeNumber) {
        countPrime = 1;
      } else {
        countPrime++;
      }
      isPrimeNumber = false;
      countNonAppear[5] = false;
      primeArray = [
        [countPrime, countNonAppear[4]],
        ['质', countNonAppear[5]],
      ];
      countAppear[5]++;
    } else {
      if (isPrimeNumber) {
        countPrime++;
      } else {
        countPrime = 1;
      }
      isPrimeNumber = true;
      countNonAppear[4] = false;
      primeArray = [
        ['合', countNonAppear[4]],
        [countPrime, countNonAppear[5]],
      ];
      countAppear[4]++;
    }

    let array012;
    if (openCode[index] % 3 === 0) {
      if (is012 === 0) {
        count012[1]++;
        count012[2]++;
      } else {
        count012[0] = 0;
        count012[1]++;
        count012[2]++;
      }
      is012 = 0;
      countNonAppear[6] = false;
      array012 = [
        ['0', countNonAppear[6]],
        [count012[1], countNonAppear[7]],
        [count012[2], countNonAppear[8]],
      ];
      countAppear[6]++;
    } else if (openCode[index] % 3 === 1) {
      if (is012 === 1) {
        count012[0]++;
        count012[2]++;
      } else {
        count012[1] = 0;
        count012[0]++;
        count012[2]++;
      }
      is012 = 1;
      countNonAppear[7] = false;
      array012 = [
        [count012[0], countNonAppear[6]],
        ['1', countNonAppear[7]],
        [count012[2], countNonAppear[8]],
      ];
      countAppear[7]++;
    } else {
      if (is012 === 2) {
        count012[0]++;
        count012[1]++;
      } else {
        count012[2] = 0;
        count012[0]++;
        count012[1]++;
      }
      is012 = 2;
      countNonAppear[8] = false;
      array012 = [
        [count012[0], countNonAppear[6]],
        [count012[1], countNonAppear[7]],
        ['2', countNonAppear[8]],
      ];
      countAppear[8]++;
    }

    let arrayUpDown;

    const nextOpen =
      index === openCode.length - 1 ? openCodeNum30 : openCode[index + 1];

    if (openCode[index] > nextOpen) {
      if (isUpDown === 0) {
        countUpDown[1]++;
        countUpDown[2]++;
      } else {
        countUpDown[0] = 0;
        countUpDown[1]++;
        countUpDown[2]++;
      }
      isUpDown = 0;
      countNonAppear[9] = false;
      arrayUpDown = [
        ['升', countNonAppear[9]],
        [countUpDown[1], countNonAppear[10]],
        [countUpDown[2], countNonAppear[11]],
      ];
      countAppear[9]++;
    } else if (openCode[index] === nextOpen) {
      if (isUpDown === 1) {
        countUpDown[0]++;
        countUpDown[2]++;
      } else {
        countUpDown[1] = 0;
        countUpDown[0]++;
        countUpDown[2]++;
      }
      isUpDown = 1;
      countNonAppear[10] = false;
      arrayUpDown = [
        [countUpDown[0], countNonAppear[9]],
        ['平', countNonAppear[10]],
        [countUpDown[2], countNonAppear[11]],
      ];
      countAppear[10]++;
    } else if (openCode[index] < nextOpen) {
      if (isUpDown === 2) {
        countUpDown[0]++;
        countUpDown[1]++;
      } else {
        countUpDown[2] = 0;
        countUpDown[0]++;
        countUpDown[1]++;
      }
      isUpDown = 2;
      countNonAppear[11] = false;
      arrayUpDown = [
        [countUpDown[0], countNonAppear[9]],
        [countUpDown[1], countNonAppear[10]],
        ['降', countNonAppear[11]],
      ];
      countAppear[11]++;
    }

    let resultArray = bigSmallArray.concat(oddEvenArray);
    resultArray = resultArray.concat(primeArray);
    resultArray = resultArray.concat(array012);
    resultArray = resultArray.concat(arrayUpDown);
    resultTable.push(resultArray);
  }

  const calDataAnalysisPattern = getDataAnalysisPattern(resultTable, 12);

  const { countAverage, countBiggest, countConnect } = calDataAnalysisPattern;

  result = {
    table: resultTable,
    appear: countAppear,
    average: countAverage,
    biggest: countBiggest,
    connect: countConnect,
  };

  return result;
};

function drawCanvas(tableDataAnalysis, context, group, section) {
  let sectionCount = 0;
  let condX;
  const condY = 36;
  switch (group) {
    case K3:
      condX = 50;
      break;
    default:
      condX = 37;
      break;
  }
  for (let indexBall = 0; indexBall < tableDataAnalysis.length; indexBall++) {
    context.beginPath();
    for (
      let indexRow = 0;
      indexRow < tableDataAnalysis[indexBall].length;
      indexRow++
    ) {
      if (section) {
        sectionCount = Math.floor(indexRow / 5);
      }
      for (
        let indexNumber = 0;
        indexNumber < tableDataAnalysis[indexBall][indexRow].length;
        indexNumber++
      ) {
        if (tableDataAnalysis[indexBall][indexRow][indexNumber][1]) {
          const numberPos = indexNumber + 1;
          const ballPos =
            indexBall *
            (constGameBallNumber[group] - constGameBallStartNumber[group] + 1);

          if (indexRow === 0) {
            context.moveTo(
              (numberPos + ballPos) * condX - condX / 2,
              1 * condY - condY / 2 + sectionCount,
            );
          } else {
            context.lineTo(
              (numberPos + ballPos) * condX - condX / 2,
              (indexRow + 1) * condY - condY / 2 + sectionCount,
            );
          }
        }
      }
    }
    context.strokeStyle = basicColumnBackgroundColor[indexBall];
    context.stroke();
  }
}

const ConclusionResultTable = ({ valueBall, group }) => {
  return (
    <div className={'conclusion counter ' + group}>
      <span>{valueBall}</span>
    </div>
  );
};

const MainTable = ({ trendsStore, search, isMobile, filter, ballFilter }) => {
  const [sorting, setSorting] = useState(true);

  const canvasRef = useRef(null);
  const mainTable = useRef(null);
  const [context, setContext] = useState(null);
  const [tableWidth, setTableWidth] = useState(0);
  const [tableHeight, setTableHeight] = useState(0);

  const { singleGame, allSingleGame } = trendsStore;

  const { group, language } = search;

  useEffect(() => {
    if (canvasRef.current) {
      const renderCtx = canvasRef.current.getContext('2d');

      if (renderCtx) {
        setContext(renderCtx);
      }
    }

    if (mainTable.current) {
      setTableWidth(mainTable.current.clientWidth);
      setTableHeight(mainTable.current.clientHeight);
    }
  }, [canvasRef, mainTable, context, singleGame]);

  const headerData = getTableHeaderData();
  const headerAnalysis = getTableHeaderAnalysis(group, ballFilter);
  const tableData = singleGame ? getTableData(toJS(singleGame), isMobile) : [];
  const tableDataAnalysis = singleGame
    ? getTableDataAnalysis(toJS(singleGame), group, ballFilter)
    : [];
  const tableDataPattern =
    singleGame && allSingleGame
      ? getPatternAnalysis(
          tableDataAnalysis.openCodeByBall,
          group,
          toJS(allSingleGame),
          ballFilter,
        )
      : '';

  const sortedTableData = tableData.slice();

  const rightTableData = tableDataAnalysis.table.slice();

  const sortedTableDataPattern = tableDataPattern.table.slice();

  if (sorting) {
    sortedTableData.reverse();
    sortedTableDataPattern.reverse();

    for (let index = 0; index < rightTableData.length; index++) {
      const temp = rightTableData[index].slice();
      rightTableData[index] = temp.reverse();
    }
  }

  setTimeout(() => {
    if (
      mainTable.current &&
      context &&
      rightTableData.length &&
      group !== KL10F
    ) {
      context.clearRect(0, 0, tableWidth, tableHeight);
      if (filter.includes('line')) {
        drawCanvas(rightTableData, context, group, filter.includes('section'));
      }
    }
  }, 100);

  return (
    <TrendsResultWrapper>
      <TrendsTableWrapper>
        <div>
          <Row className="table-wrapper">
            <Col className="table-left">
              <LeftTable
                isMobile={isMobile}
                headerData={headerData}
                tableData={sortedTableData}
                filter={filter}
                group={group}
                ball={ballFilter}
                row={2}
                normal
                sorting={sorting}
                setSorting={setSorting}
              />
            </Col>
            <Col className="table-right">
              <Row style={{ width: '100%' }} className={language}>
                {headerAnalysis.map(({ dataIndex, title, children }, index) => {
                  if (isMobile) {
                    if (index !== 0 && index !== 3) {
                      return (
                        <Col key={dataIndex}>
                          <div className="ball-number">
                            <b>{transl(title, [], false, true, 'en-US')}</b>
                          </div>
                          <Row className="row-number">
                            {children.map(({ dataIndex, title }) => {
                              return (
                                <Col
                                  key={dataIndex}
                                  className={'number ' + group}>
                                  <b>
                                    {transl(title, [], false, true, 'en-US')}
                                  </b>
                                </Col>
                              );
                            })}
                          </Row>
                        </Col>
                      );
                    }
                    return '';
                  } else {
                    return (
                      <Col key={dataIndex}>
                        <div className="ball-number">
                          <b>{transl(title, [], false, true, 'en-US')}</b>
                        </div>
                        <Row className="row-number">
                          {children.map(({ dataIndex, title }) => {
                            return (
                              <Col
                                key={dataIndex}
                                className={'number ' + group}>
                                <b>{transl(title, [], false, true, 'en-US')}</b>
                              </Col>
                            );
                          })}
                        </Row>
                      </Col>
                    );
                  }
                })}
              </Row>
              {
                <div className="result-wrapper">
                  {!isMobile ? (
                    <canvas
                      id="canvas"
                      ref={canvasRef}
                      width={tableWidth}
                      height={tableHeight}></canvas>
                  ) : (
                    ''
                  )}
                  <Row>
                    {!isMobile
                      ? rightTableData.map((valueRow, index) => {
                          return (
                            <Col
                              key={index}
                              className="ball-column"
                              ref={mainTable}>
                              {valueRow.map((valueBall, indexRow) => {
                                return (
                                  <Row
                                    key={indexRow}
                                    className={
                                      indexRow % 5 === 4 &&
                                      filter.includes('section')
                                        ? 'separate'
                                        : ''
                                    }>
                                    {valueBall.map((value, numberIndex) => {
                                      const classStyle =
                                        filter.includes('hightLightCount') &&
                                        value[2]
                                          ? 'non-appear counter ' + group
                                          : 'counter ' + group;
                                      return (
                                        <div
                                          key={indexRow + '' + numberIndex}
                                          className={classStyle}>
                                          <span
                                            className={
                                              value[1] ? 'circle' : ''
                                            }>
                                            {filter.includes('count') ||
                                            value[1]
                                              ? value[0]
                                              : ''}
                                          </span>
                                        </div>
                                      );
                                    })}
                                  </Row>
                                );
                              })}
                            </Col>
                          );
                        })
                      : ''}
                    <Col className="ball-column">
                      {tableDataPattern &&
                        sortedTableDataPattern.map((row, indexRow) => {
                          return (
                            <Row
                              key={indexRow}
                              className={
                                indexRow % 5 === 4 && filter.includes('section')
                                  ? 'separate'
                                  : ''
                              }>
                              {row.map((value, index) => {
                                let classStyle =
                                  typeof value[0] === 'string'
                                    ? 'counter pattern ' + group
                                    : 'counter ' + group;
                                classStyle =
                                  filter.includes('hightLightCount') && value[1]
                                    ? classStyle + ' non-appear'
                                    : classStyle;
                                if (isMobile) {
                                  if (index < 9) {
                                    return (
                                      <div key={index} className={classStyle}>
                                        <span>
                                          {transl(
                                            filter.includes('count') ||
                                              typeof value[0] === 'string'
                                              ? value[0]
                                              : '',
                                            [],
                                            false,
                                            true,
                                            'en-US',
                                          )}
                                        </span>
                                      </div>
                                    );
                                  } else {
                                    return '';
                                  }
                                } else {
                                  return (
                                    <div key={index} className={classStyle}>
                                      <span>
                                        {transl(
                                          filter.includes('count') ||
                                            typeof value[0] === 'string'
                                            ? value[0]
                                            : '',
                                          [],
                                          false,
                                          true,
                                          'en-US',
                                        )}
                                      </span>
                                    </div>
                                  );
                                }
                              })}
                            </Row>
                          );
                        })}
                    </Col>
                  </Row>
                </div>
              }
              {!isMobile ? (
                <Row style={{ width: '100%' }}>
                  {headerAnalysis.map(
                    ({ dataIndex, title, children }, index) => {
                      return (
                        <Col key={dataIndex}>
                          <div className="ball-number btm-part">
                            <b>{transl(title, [], true, false, 'en-US')}</b>
                          </div>
                          <Row className="row-number">
                            {children.map(({ dataIndex, title }) => {
                              return (
                                <Col
                                  key={dataIndex}
                                  className={'number ' + group}>
                                  <b>{transl(title, [], false, true, false)}</b>
                                </Col>
                              );
                            })}
                          </Row>
                        </Col>
                      );
                    },
                  )}
                </Row>
              ) : (
                ''
              )}
              <div className="result-wrapper">
                <Row>
                  {!isMobile
                    ? tableDataAnalysis.appear.map((valueBall, index) => {
                        return (
                          <Col key={index}>
                            <ConclusionResultTable
                              valueBall={valueBall}
                              group={group}
                            />
                          </Col>
                        );
                      })
                    : ''}
                  {tableDataPattern &&
                    tableDataPattern.appear.map((valueBall, index) => {
                      if (isMobile) {
                        if (index < 9) {
                          return (
                            <Col key={index}>
                              <ConclusionResultTable
                                valueBall={valueBall}
                                group={group}
                              />
                            </Col>
                          );
                        } else {
                          return '';
                        }
                      } else {
                        return (
                          <Col key={index}>
                            <ConclusionResultTable
                              valueBall={valueBall}
                              group={group}
                            />
                          </Col>
                        );
                      }
                    })}
                </Row>
              </div>
              <div className="result-wrapper">
                <Row>
                  {!isMobile
                    ? tableDataAnalysis.average.map((valueBall, index) => {
                        return (
                          <Col key={index}>
                            <ConclusionResultTable
                              valueBall={valueBall}
                              group={group}
                            />
                          </Col>
                        );
                      })
                    : ''}
                  {tableDataPattern &&
                    tableDataPattern.average.map((valueBall, index) => {
                      if (isMobile) {
                        if (index < 9) {
                          return (
                            <Col key={index}>
                              <ConclusionResultTable
                                valueBall={valueBall}
                                group={group}
                              />
                            </Col>
                          );
                        } else {
                          return '';
                        }
                      } else {
                        return (
                          <Col key={index}>
                            <ConclusionResultTable
                              valueBall={valueBall}
                              group={group}
                            />
                          </Col>
                        );
                      }
                    })}
                </Row>
              </div>
              <div className="result-wrapper">
                <Row>
                  {!isMobile
                    ? tableDataAnalysis.biggest.map((valueBall, index) => {
                        return (
                          <Col key={index}>
                            <ConclusionResultTable
                              valueBall={valueBall}
                              group={group}
                            />
                          </Col>
                        );
                      })
                    : ''}
                  {tableDataPattern &&
                    tableDataPattern.biggest.map((valueBall, index) => {
                      if (isMobile) {
                        if (index < 9) {
                          return (
                            <Col key={index}>
                              <ConclusionResultTable
                                valueBall={valueBall}
                                group={group}
                              />
                            </Col>
                          );
                        } else {
                          return '';
                        }
                      } else {
                        return (
                          <Col key={index}>
                            <ConclusionResultTable
                              valueBall={valueBall}
                              group={group}
                            />
                          </Col>
                        );
                      }
                    })}
                </Row>
              </div>
              <div className="result-wrapper">
                <Row>
                  {!isMobile
                    ? tableDataAnalysis.connect.map((valueBall, index) => {
                        return (
                          <Col key={index}>
                            <ConclusionResultTable
                              valueBall={valueBall}
                              group={group}
                            />
                          </Col>
                        );
                      })
                    : ''}
                  {tableDataPattern &&
                    tableDataPattern.connect.map((valueBall, index) => {
                      if (isMobile) {
                        if (index < 9) {
                          return (
                            <Col key={index}>
                              <ConclusionResultTable
                                valueBall={valueBall}
                                group={group}
                              />
                            </Col>
                          );
                        } else {
                          return '';
                        }
                      } else {
                        return (
                          <Col key={index}>
                            <ConclusionResultTable
                              valueBall={valueBall}
                              group={group}
                            />
                          </Col>
                        );
                      }
                    })}
                </Row>
              </div>
            </Col>
          </Row>
        </div>
      </TrendsTableWrapper>
    </TrendsResultWrapper>
  );
};

export default isMobile(
  withLocation(inject('trendsStore')(observer(MainTable))),
);

const TrendsResultWrapper = styled.div`
  background-color: ${color.white};

  .ant-table table {
    th {
      padding: 7px 0;
      font-size: 15px;
      background-color: ${color.white};

      &.number {
        padding: 5px 0;
      }
    }
  }
`;

const TrendsTableWrapper = styled.div`
  position: relative;
  padding: 0 25px 48px;

  @media only screen and (max-width: 576px) {
    padding: 0;
    font-size: 13px;
    font-weight: 400;

    b {
      font-weight: 400;
    }
  }

  #canvas {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
  }

  .table-wrapper {
    flex-flow: unset;

    .result-wrapper {
      position: relative;

      .ball-column {
        &:first-child,
        &:nth-child(6) {
          background-color: ${color.opacityYellow};

          .circle {
            background-color: ${color.lightYellow};
          }

          @media only screen and (max-width: 576px) {
            background-color: ${color.white};
          }
        }

        .separate {
          .counter {
            border-bottom: 2px solid ${color.tableBorderGrey};
            height: 37px;
          }
        }

        @media only screen and (max-width: 576px) {
          > .ant-row {
            &:nth-child(2n) {
              background-color: ${color.backgroundGrey};
            }
          }
        }
      }

      .counter {
        display: flex;
        flex: 1 1;
        justify-content: center;
        width: 37px;
        height: 36px;
        align-items: center;
        color: ${color.lightFontGrey};
        border: 1px solid ${color.tableBorderGrey};
        border-top: 0;
        border-left: 0;

        &.K3 {
          width: 50px;
        }

        @media only screen and (max-width: 576px) {
          width: 23px;
          padding: 0;
          flex: 1;
          height: 29px;

          &.K3 {
            width: 30px;
          }

          &.conclusion {
            color: ${color.white};
            background-color: ${color.orange};
          }
        }

        &.KL10F {
          width: 40px;

          &:nth-child(-n + 10) {
            background-color: ${color.opacityYellow};

            .circle {
              background-color: ${color.lightYellow};
            }
          }

          &:nth-last-child(-n + 10) {
            background-color: ${color.opacityRed};

            .circle {
              background-color: ${color.lightRed};
            }
          }

          &.non-appear {
            background-color: ${color.darkGrey};
            color: ${color.white};
          }
        }

        &.non-appear {
          background-color: ${color.darkGrey};
          color: ${color.white};
        }

        &.pattern {
          color: ${color.white};
          background-color: ${color.lightYellow};

          &:nth-child(2n) {
            background-color: ${color.lightBlue};
          }
        }

        span {
          display: block;
          min-width: 20px;
          height: 20px;
          padding: 0;
          line-height: 19px;
          text-align: center;

          @media only screen and (max-width: 576px) {
            height: auto;
            line-height: 28px;
          }

          &.circle {
            position: relative;
            border-radius: 100%;
            color: ${color.white};
            z-index: 1;
          }
        }
      }
    }

    .ant-row {
      flex-flow: unset;
    }

    .ant-col {
      max-width: unset;
    }

    .table-left {
      flex: 1;
    }

    .table-right {
      overflow-x: auto;

      @media only screen and (max-width: 576px) {
        overflow-x: unset;

        > .ant-row {
          position: sticky;
          top: 88px;
          z-index: 2;

          &.en-US,
          &.vi-VN {
            top: 135px;
          }
        }
      }

      &::-webkit-scrollbar {
        width: 8px;
        height: 8px;
      }

      /* Track */
      &::-webkit-scrollbar-track {
        background: #f1f1f1;
      }

      /* Handle */
      &::-webkit-scrollbar-thumb {
        background: #cdcdcd;
      }

      /* Handle on hover */
      &::-webkit-scrollbar-thumb:hover {
        background: #797979;
      }

      &::-webkit-scrollbar-button {
        width: 0;
        height: 0;
        display: none;
      }

      scrollbar-width: thin;

      .ball-number {
        background-color: ${color.backgroundGrey};
        text-align: center;
        font-size: 15px;
        padding: 3px 0;
        border: 1px solid ${color.tableBorderGrey};
        border-left: 0;

        @media only screen and (max-width: 576px) {
          font-size: 13px;
          font-weight: 400;
          padding: 0;
          line-height: 21px;
          color: ${color.white};
          background-color: ${color.orange};

          b {
            font-weight: 400;
          }
        }

        &.btm-part {
          border-top: 0;
          height: 31px;
        }
      }

      .row-number {
        text-align: center;
        font-size: 15px;
        background-color: ${color.backgroundGrey};

        @media only screen and (max-width: 576px) {
          font-size: 13px;
          font-weight: 400;
        }

        .number {
          width: 37px;
          padding: 3px 0;
          border: 1px solid ${color.tableBorderGrey};
          border-left: 0;
          border-top: 0;

          &.K3 {
            width: 50px;
          }

          &.KL10F {
            width: 40px;
          }

          @media only screen and (max-width: 576px) {
            width: 23px;
            line-height: 29px;
            padding: 0;
            color: ${color.white};
            background-color: ${color.orange};

            &.K3 {
              width: 30px;
            }

            b {
              font-weight: 400;
            }
          }
        }
      }
    }
  }
`;

import { get, isNil, isUndefined, omitBy, find } from 'lodash';

import {
  constPlayGroup,
  totalOpenCodePerDay,
  constGameBallNumber,
  constGameBallStartNumber,
  constTotalCodeDigit,
} from '@const/game';

import {
  constSixGameNumberColor,
  constSixGameColor,
  constLuZhuName,
  constHotWarmCold,
  constSixElements,
} from '@const/playRule';

import {
  shunZhi,
  constTotalNumberBigSmallThreshold,
  constBigSmallThreshold,
  constLuZhuOptionBall,
  constCurrentYearZodiac,
} from '@const/playRule';

import { URLS } from '@const/config';

import translationFile from '@const/translation';
import translationFileShort from '@const/translationShort';
import translationFileSpecial from '@const/translationSpecial';

import { Images } from '@components/common/images';

const { SSC, PK10, XY10, D11, K3, KL10F, PCDD, XPK } = constPlayGroup;

const QUERY_STRING_PREFIX = '?';

export const formatGMTDate = (gmtDate) => {
  // e.g. 27/12/2018, 10:00:00am
  const date = new Date(gmtDate);
  return `${date.toLocaleDateString('en-GB')}, ${date.toLocaleTimeString(
    'en-US',
  )}`;
};

export const formatISODate = (isoDate) => {
  // e.g. 27/12/2018, 10:00:00am
  const date = new Date(isoDate);
  const day = date.getDate();
  const month = date.getMonth() + 1;
  const year = date.getFullYear();
  let hours = date.getHours();
  let minutes = date.getMinutes();
  const ampm = hours >= 12 ? 'pm' : 'am';
  hours %= 12;
  hours = hours || 12;
  minutes = minutes < 10 ? 0 + minutes : minutes;

  return `${day}/${month}/${year}, ${hours}:${minutes}${ampm}`;
};

const isInvalidParams = (value) => {
  const valueType = typeof value;
  return (
    valueType === 'function' ||
    valueType === 'object' ||
    isNil(value) ||
    value === ''
  );
};

export const generateQueryString = (queryParams) => {
  const params = omitBy(queryParams, isInvalidParams);
  const paramsArray = Object.keys(params).map(
    (key) => `${key}=${get(params, key)}`,
  );
  return encodeURI(paramsArray.join('&'));
};

export const parseQueryString = (query) => {
  const validQuery = decodeURI(query).trim();
  if (!validQuery) {
    return {};
  }

  const queryPrefix = validQuery.slice(0, 1);
  if (queryPrefix !== QUERY_STRING_PREFIX) {
    console.error('Query string format is not correct');
    return {};
  }
  const queryStringParams = validQuery
    .slice(1)
    .split('&')
    .map((param) => {
      const paramObj = {};
      const result = param.split('=');
      const key = result[0];
      const value = result[1];
      if (!key || !value) {
        return {};
      }
      paramObj[result[0]] = result[1];
      return paramObj;
    })
    .reduce((params, currentParams) => ({ ...currentParams, ...params }));
  return omitBy(queryStringParams, isUndefined);
};

export const _isFollowed = (userDetails, id) => {
  return userDetails && find(userDetails.followers, { id }) ? true : false;
};

export const _isSelf = (userDetails, thisUserID) => {
  return userDetails && !(userDetails.id === thisUserID);
};

export const sumOfNumber = (numbers) => {
  return numbers.length ? numbers.reduce((a, b) => a + b, 0) : numbers;
};

export function oddEven(value) {
  return value % 2 !== 0;
}

export function bigSmall(value, threshold) {
  return value > threshold;
}

export function getTotalOddEven(value) {
  const number = sumOfNumber(value);
  return oddEven(number) ? '单' : '双';
}

export function getTotalBigSmall(values, group) {
  let sum;
  switch (group) {
    case PK10:
    case XY10:
      sum = sumOfNumber([values[0], values[1]]);
      break;
    default:
      sum = sumOfNumber(values);
      break;
  }

  let threshold = constTotalNumberBigSmallThreshold[group];

  let result;

  if (typeof threshold === 'object') {
    if (sum === threshold.draw) {
      result = '和';
    } else {
      result = bigSmall(sum, threshold.threshold) ? '大' : '小';
    }
  } else {
    result = bigSmall(sum, threshold) ? '大' : '小';
  }

  return result;
}

function baoZi(nums) {
  const num0 = nums[0];
  const num1 = nums[1];
  const num2 = nums[2];
  const numn4 = num0 === num1 && num1 === num2 && num0 === num2;
  if (numn4) {
    return '豹子';
  } else {
    return '';
  }
}

function sunZi(nums, group) {
  const num0 = '0' + nums[0].toString();
  const num1 = '0' + nums[1].toString();
  const num2 = '0' + nums[2].toString();
  const Num0 = '0' + nums[0].toString();
  const Num1 = '0' + nums[1].toString();
  const Num2 = '0' + nums[2].toString();
  const numn3 = num0 + num1 + num2;
  const numn4 = Num0 === Num1 && Num1 === Num2;

  let constSunZi = [];

  switch (group) {
    case SSC:
    case PCDD:
      constSunZi = [
        '000102', // 0, 1, 2
        '010203', // 1, 2, 3
        '020304', // 2, 3, 4
        '030405', // 3, 4, 5
        '040506', // 4, 5, 6
        '050607', // 5, 6, 7
        '060708', // 6, 7, 8
        '070809', // 7, 8, 9
        '000809', // 8, 9, 0
        '000109', // 9, 0, 1
      ];
      break;
    case D11:
      constSunZi = [
        '010203', // 1, 2, 3
        '020304', // 2, 3, 4
        '030405', // 3, 4, 5
        '040506', // 4, 5, 6
        '050607', // 5, 6, 7
        '060708', // 6, 7, 8
        '070809', // 7, 8, 9
        '0809010', // 8, 9, 10
        '09010011', // 9, 10, 11
        '01010011', // 10, 11, 1
        '0102011', // 11, 1, 2
      ];
      break;
    case XPK:
      constSunZi = [
        '010203', // A, 2, 3
        '020304',
        '030405',
        '040506',
        '050607',
        '060708',
        '070809',
        '0809010',
        '09010011', // 9, 10, J
        '010011012', // 10, J, Q
        '011012013', // J, Q, K
        '01012013', // Q, K, A
      ];
      break;
    default:
      break;
  }

  if (constSunZi.includes(numn3) && !numn4) {
    return '顺子';
  } else {
    return '';
  }
}

function duiZi(nums) {
  const num0 = nums[0];
  const num1 = nums[1];
  const num2 = nums[2];
  const numn4 = num0 === num1 || num1 === num2;
  const numn5 = num0 === num1 && num1 === num2;
  if (numn4 && !numn5) {
    return '对子';
  } else {
    return '';
  }
}

function banSun(nums, group) {
  const strNum0 = '0' + nums[0].toString();
  const strNum1 = '0' + nums[1].toString();
  const strNum2 = '0' + nums[2].toString();
  const strNum3 = strNum0 + strNum1;
  const strNum4 = strNum0 + strNum2;
  const strNum5 = strNum1 + strNum2;

  let constBanSun = [];
  switch (group) {
    case SSC:
    case PCDD:
      constBanSun = [
        '0001', // 0, 1
        '0102', // 1, 2
        '0203',
        '0304',
        '0405',
        '0506',
        '0607',
        '0708',
        '0809', // 8, 9
        '0009', // 9, 0
      ];
      break;
    case D11:
      constBanSun = [
        '0102',
        '0203',
        '0304',
        '0405',
        '0506',
        '0607',
        '0708',
        '0809',
        '09010',
        '010011', // 10, 11
        '01011', // 11, 1
      ];
      break;
    case XPK:
      constBanSun = [
        '0102',
        '0203',
        '0304',
        '0405',
        '0506',
        '0607',
        '0708',
        '0809',
        '09010',
        '010011', // 10, 11
        '011012', // 11, 12
        '012013', // 12, 13
        '01013', // 13, 1
      ];
      break;

    default:
      break;
  }

  if (
    constBanSun.includes(strNum3) ||
    constBanSun.includes(strNum4) ||
    constBanSun.includes(strNum5)
  ) {
    return '半顺';
  } else {
    return '';
  }
}

const { firstThree, middleThree, lastThree } = shunZhi;

export function findShunZi(position, nums, group) {
  let threeArray = [];
  let num0;
  let num1;
  let num2;
  switch (position) {
    case firstThree:
      num0 = nums[0];
      num1 = nums[1];
      num2 = nums[2];
      break;
    case middleThree:
      num0 = nums[1];
      num1 = nums[2];
      num2 = nums[3];
      break;
    case lastThree:
      num0 = nums[2];
      num1 = nums[3];
      num2 = nums[4];
      break;
    default:
      console.log('Error findShunZhi Position Not Found');
      return;
  }
  threeArray = [num0, num1, num2];
  threeArray.sort(function (a, b) {
    return a - b;
  });

  let result = baoZi(threeArray);
  if (!result) {
    result = sunZi(threeArray, group);
    if (!result) {
      result = duiZi(threeArray);
      if (!result) {
        result = banSun(threeArray, group);
        if (!result) {
          result = '杂六';
        }
      }
    }
  }
  return result;
}

export function findDragonTigerThousandDigit(num1, num2) {
  let result = '和';

  if (num1 > num2) {
    result = '龙';
  } else if (num1 < num2) {
    result = '虎';
  }
  return result;
}

export function findAndReplaceObject(object, value, replaceValue) {
  const { current, lastOpen } = replaceValue;
  const {
    currentTimeEpoch,
    nextOfficialOpenTime,
    nextOfficialOpenTimeEpoch,
    nextStopOrderTime,
    nextStopOrderTimeEpoch,
    officialOpenTime,
    officialOpenTimeEpoch,
    openStatus,
    stopOrderTime,
    stopOrderTimeEpoch,
  } = current;

  const { openCode, uniqueIssueNumber, planNo, openTime } = lastOpen;

  for (var x in object) {
    if (object.hasOwnProperty(x)) {
      if (typeof object[x] == 'object') {
        findAndReplaceObject(object[x], value, replaceValue);
      }
      if (object[x] === value) {
        object['currentTimeEpoch'] = currentTimeEpoch;
        object['nextOfficialOpenTime'] = nextOfficialOpenTime;
        object['nextOfficialOpenTimeEpoch'] = nextOfficialOpenTimeEpoch;
        object['nextStopOrderTime'] = nextStopOrderTime;
        object['nextStopOrderTimeEpoch'] = nextStopOrderTimeEpoch;
        object['officialOpenTime'] = officialOpenTime;
        object['officialOpenTimeEpoch'] = officialOpenTimeEpoch;
        object['openStatus'] = openStatus;
        object['lastIssueNumber'] = uniqueIssueNumber;
        object['lastOpenCode'] = openCode;
        object['lastPlanNo'] = planNo;
        object['lastOpenTime'] = openTime;
        object['stopOrderTime'] = stopOrderTime;
        object['stopOrderTimeEpoch'] = stopOrderTimeEpoch;
        object['uniqueIssueNumber'] = uniqueIssueNumber;
        break; // uncomment to stop after first replacement
      }
    }
  }

  return object;
}

export function findAndReplaceValue(object, gameUniqueId, value) {
  for (var x in object) {
    if (object.hasOwnProperty(x)) {
      if (typeof object[x] == 'object') {
        findAndReplaceValue(object[x], gameUniqueId, value);
      }
      if (object[x] === gameUniqueId) {
        object['lastOpenCode'] = value;
        break; // uncomment to stop after first replacement
      }
    }
  }

  return object;
}

export function calcLeftPlanNo(gameUniqueId, lastPlanNo) {
  const leftPlanNo = totalOpenCodePerDay[gameUniqueId] - lastPlanNo;

  return leftPlanNo;
}

export function splitDigits(Digits) {
  return ('' + Digits).split('');
}

export function formatOpenTime(openTime) {
  let formatTime = openTime;
  formatTime = formatTime.replace('T', ' ');
  formatTime = formatTime.slice(0, formatTime.length - 6);

  return formatTime;
}

export function getOpenTimeDate(openTime) {
  let formatTime = openTime;
  formatTime = formatTime.slice(0, 10);

  return formatTime;
}

export const convertStringArrayToIntArray = (digits) => {
  return digits.map((strNumber) => parseInt(strNumber));
};

export function arrayStringToArrayInt(strNumbers) {
  let intNumbers = strNumbers && strNumbers.length ? strNumbers.split(',') : '';
  intNumbers = intNumbers ? convertStringArrayToIntArray(intNumbers) : '';

  return intNumbers;
}

export function formatTimeStamp(time) {
  let timer;
  if (time) {
    let seconds = Math.floor((time % 3600) % 60);
    let minutes = Math.floor((time % 3600) / 60);
    let horus = Math.floor(time / 3600);
    horus = horus.toString().length === 1 ? '0' + horus : horus;
    minutes = minutes.toString().length === 1 ? '0' + minutes : minutes;
    seconds = seconds.toString().length === 1 ? '0' + seconds : seconds;
    timer = [horus, minutes, seconds];
  } else {
    timer = ['00', '00', '00'];
  }

  return timer;
}

export function searchArrayObjectByKey(nameKey, myArray) {
  for (var i = 0; i < myArray.length; i++) {
    if (myArray[i].gameUniqueId === nameKey) {
      return myArray[i];
    }
  }
}

export function calcDuplicateCode(array) {
  let objResult = {};

  if (array.length) {
    let flatArray = array.flat();
    flatArray.forEach(function (x) {
      objResult[x] = (objResult[x] || 0) + 1;
    });
  }

  return objResult;
}

export function openCodeToArray(obj) {
  let result = [];
  if (Array.isArray(obj) && obj.length) {
    result.push(
      obj.map((item) => {
        const codes = arrayStringToArrayInt(item.openCode);
        return codes;
      }),
    );

    result = result.flat();
  }

  return result;
}

function generateLuzhuBigSmall(array, selectedIndex, group) {
  let newArray = [];
  let convert;
  for (let index = 0; index < array.length; index++) {
    if (constLuZhuOptionBall[group][selectedIndex].total) {
      if (typeof constTotalNumberBigSmallThreshold[group] === 'object') {
        if (array[index] === constTotalNumberBigSmallThreshold[group].draw) {
          convert = '和';
        } else {
          convert = bigSmall(
            array[index],
            constTotalNumberBigSmallThreshold[group].threshold,
          )
            ? '大'
            : '小';
        }
      } else {
        convert = bigSmall(
          array[index],
          constTotalNumberBigSmallThreshold[group],
        )
          ? '大'
          : '小';
      }
    } else {
      convert = bigSmall(
        array[index][selectedIndex],
        constBigSmallThreshold[group],
      )
        ? '大'
        : '小';
    }
    newArray.push(convert);
  }
  return newArray;
}

function generateLuzhuOddEven(array, selectedIndex, group) {
  let newArray = [];
  let convert;
  for (let index = 0; index < array.length; index++) {
    if (constLuZhuOptionBall[group][selectedIndex].total) {
      if (oddEven(array[index])) {
        convert = '单';
      } else {
        convert = '双';
      }
    } else {
      if (oddEven(array[index][selectedIndex])) {
        convert = '单';
      } else {
        convert = '双';
      }
    }
    newArray.push(convert);
  }
  return newArray;
}

function generateLuzhuDragonTiger(array, selectedIndex, group) {
  let newArray = [];
  let convert;
  let index1, index2;
  if (group === 'SSC') {
    index1 = selectedIndex[0];
    index2 = selectedIndex[1];
  } else if (group !== 'PK10' && group !== 'XY10') {
    index1 = 0;
    index2 = 4;
  } else if (group === 'PK10' || group === 'XY10') {
    switch (selectedIndex) {
      case 0:
        index1 = 0;
        index2 = 9;
        break;
      case 1:
        index1 = 1;
        index2 = 8;
        break;
      case 2:
        index1 = 2;
        index2 = 7;
        break;
      case 3:
        index1 = 3;
        index2 = 6;
        break;
      case 4:
        index1 = 4;
        index2 = 5;
        break;
      default:
        break;
    }
  }

  for (let index = 0; index < array.length; index++) {
    convert = findDragonTigerThousandDigit(
      array[index][index1],
      array[index][index2],
    );
    newArray.push(convert);
  }
  return newArray;
}

function generateLuzhuHaoMa(array, selectedIndex, group) {
  let result = [];
  switch (group) {
    case PK10:
    case XY10:
      for (let index = 0; index < array.length; index++) {
        if (array[index].indexOf(selectedIndex) < 5) {
          result.push('前');
        } else {
          result.push('后');
        }
      }
      break;
    default:
      for (let index = 0; index < array.length; index++) {
        if (array[index].includes(selectedIndex)) {
          result.push('总来');
        } else {
          result.push('没来');
        }
      }
      break;
  }

  return result;
}

export function generateLuzhuResult(array, selectedIndex, selectedType, group) {
  let newArray;

  if (selectedType === constLuZhuName.bigSmall.value) {
    newArray = generateLuzhuBigSmall(array, selectedIndex, group);
  } else if (selectedType === constLuZhuName.oddEven.value) {
    newArray = generateLuzhuOddEven(array, selectedIndex, group);
  } else if (selectedType === constLuZhuName.dragonTiger.value) {
    newArray = generateLuzhuDragonTiger(array, selectedIndex, group);
  } else if (selectedType === constLuZhuName.haoMa.value) {
    newArray = generateLuzhuHaoMa(array, selectedIndex, group);
  }

  let grouped;
  if (newArray) {
    grouped = newArray.reduce((r, v, i, a) => {
      if (v === a[i - 1]) {
        r[r.length - 1].push(v);
      } else {
        r.push(v === a[i + 1] ? [v] : v);
      }
      return r;
    }, []);
  }

  return grouped;
}

export function groupDuplicateArrayNumber(array) {
  let filterArray = [];
  let colArray = [];

  for (let col = 0; col < array[0].length; col++) {
    for (let row = 0; row < array.length; row++) {
      colArray.push(array[row][col]);
    }
    filterArray.push(colArray);
    colArray = [];
  }

  let result = [];
  for (let index = 0; index < filterArray.length; index++) {
    result.push(calcDuplicateCode(filterArray[index]));
  }

  return result;
}

export const sumOfArrayList = (arrayList) => {
  let newArray = [];

  for (let index = 0; index < arrayList.length; index++) {
    newArray.push(sumOfNumber(arrayList[index]));
  }

  return newArray;
};

export const sumOfArrayListByPosition = (arrayList, pos1, pos2) => {
  let newArray = [];

  for (let index = 0; index < arrayList.length; index++) {
    const number1 = arrayList[index][pos1];
    const number2 = arrayList[index][pos2];
    newArray.push(sumOfNumber([number1, number2]));
  }

  return newArray;
};

function indexOfMax(arr) {
  if (arr.length === 0) {
    return -1;
  }

  var max = arr[0];
  var maxIndex = 0;

  for (var i = 1; i < arr.length; i++) {
    if (arr[i] > max) {
      maxIndex = i;
      max = arr[i];
    }
  }

  return maxIndex;
}

export const getMostColor = (array) => {
  const { red, blue, green } = constSixGameColor;
  const colorArray = [red, blue, green];
  let countRed = 0;
  let countBlue = 0;
  let countGreen = 0;

  for (let index = 0; index < array.length; index++) {
    let point = 1;
    if (index === array.length - 1) {
      point = 1.5;
    }

    if (constSixGameNumberColor.red.includes(array[index])) {
      countRed = countRed + point;
    } else if (constSixGameNumberColor.blue.includes(array[index])) {
      countBlue = countBlue + point;
    } else if (constSixGameNumberColor.green.includes(array[index])) {
      countGreen = countGreen + point;
    }
  }

  let result;
  if (
    (countRed === 1.5 && countBlue === 3 && countGreen === 3) ||
    (countRed === 3 && countBlue === 1.5 && countGreen === 3) ||
    (countRed === 3 && countBlue === 3 && countGreen === 1.5)
  ) {
    result = '和局';
  } else {
    const maxIndex = indexOfMax([countRed, countBlue, countGreen]);
    result = colorArray[maxIndex];
  }

  return result;
};

export const someOfDigit = (value) => {
  const digits = splitDigits(value);
  const intDigits = convertStringArrayToIntArray(digits);
  return sumOfNumber(intDigits);
};

export const getNumberOfDigit = (value) => {
  const digits = splitDigits(value);
  const intDigits = convertStringArrayToIntArray(digits);
  return intDigits.length < 2 ? intDigits[0] : intDigits[1];
};

export const findK3Pattern = (array, group) => {
  const pattern = findShunZi(firstThree, array, group);
  let result;
  switch (pattern) {
    case '顺子':
      result = '三连号';
      break;
    case '豹子':
      result = '三同号';
      break;
    case '对子':
      result = '二同号';
      break;
    case '半顺':
      result = '二连号';
      break;
    default:
      result = '杂三';
      break;
  }

  return transl(result, [], true, false, 'en-US');
};

export const getNumberSpan = (number1, number2) => {
  const k3Span = number1 - number2;
  return k3Span;
};

export function findLengRe(group, array, size) {
  let result = [];
  let hot = [];
  let warm = [];
  let cold = [];
  let countHot = [];

  let startIndex = 0;
  let maxIndex = 0;
  switch (group) {
    case SSC:
      maxIndex = 10;
      break;
    case PK10:
    case XY10:
      startIndex = 1;
      maxIndex = 11;
      break;
    case D11:
      startIndex = 1;
      maxIndex = 12;
      break;
    case K3:
      startIndex = 1;
      maxIndex = 7;
      break;
    case KL10F:
      startIndex = 1;
      maxIndex = 21;
      break;
    default:
      break;
  }

  const { hot: hotRule, warm: warmRule } = constHotWarmCold[size];

  array.map((value) => {
    for (let index = startIndex; index < maxIndex; index++) {
      if (value[index] >= hotRule) {
        hot.push(index);
        countHot.push(value[index]);
      } else if (value[index] >= warmRule[0]) {
        warm.push(index);
      } else if (value[index] >= 0 || value[index] === undefined) {
        cold.push(index);
      }
    }

    result.push({
      hot,
      warm,
      cold,
      countHot,
    });

    hot = [];
    warm = [];
    cold = [];
    countHot = [];

    return result;
  });

  return result;
}

export function getTrendsURL(gameUniqueId, playGroup, playRule = '') {
  return (
    URLS.trends +
    '?searchGame=' +
    gameUniqueId +
    '&group=' +
    playGroup +
    '&rule=' +
    playRule
  );
}

export function getZouShiTuBiao(constGameUniqueId, playGroup) {
  return (
    URLS.trendChart +
    '?game=' +
    constGameUniqueId +
    '&group=' +
    playGroup +
    '&ball=0'
  );
}

export function getChangLongURL(gameUniqueId, gameNameInChinese) {
  return (
    URLS.changLong +
    '?gameUniqueId=' +
    gameUniqueId +
    '&gameNameInChinese=' +
    gameNameInChinese
  );
}

export function getSelectAllOption(options) {
  let array = [];

  if (options && options.length) {
    options.map(({ value }) => {
      return array.push(value);
    });
  }

  return array;
}

export function isPrime(num) {
  for (var i = 2; i < num; i++) if (num % i === 0) return false;
  return num > 1;
}

export function convertNumberToPK(value) {
  let result;
  switch (value) {
    case 11:
      result = 'J';
      break;
    case 12:
      result = 'Q';
      break;
    case 13:
      result = 'K';
      break;
    default:
      result = value;
      break;
  }

  return result;
}

export function getDataAnalysis(group, arrayNumberOpenCode) {
  let dataSourceArray = [];
  let count = [];
  let countAppear = [];
  let countAverage = [];
  let countBiggest = [];
  let countConnect = [];
  let connectResult = [];

  for (
    let indexDigit = 0;
    indexDigit < constTotalCodeDigit[group];
    indexDigit++
  ) {
    let number = [];
    let number2 = [];
    let appear = [];
    let average = [];

    for (
      let indexNumber = constGameBallStartNumber[group];
      indexNumber <= constGameBallNumber[group];
      indexNumber++
    ) {
      number[indexNumber] = 1;
      number2[indexNumber] = 1;
      appear[indexNumber] = 0;
      average[indexNumber] = [];
    }
    count.push(number);
    countAppear.push(appear);
    countAverage.push(average);
    countBiggest.push(number2);
  }

  for (let indexBall = 0; indexBall < constTotalCodeDigit[group]; indexBall++) {
    let data = [];
    let rowConnectTemp = [];
    let checkConnectNonAppear = [];
    for (let indexRow = 0; indexRow < arrayNumberOpenCode.length; indexRow++) {
      let row = [];
      for (
        let indexBallNumber = constGameBallStartNumber[group];
        indexBallNumber <= constGameBallNumber[group];
        indexBallNumber++
      ) {
        let col;
        if (arrayNumberOpenCode[indexRow][indexBall] === indexBallNumber) {
          checkConnectNonAppear[indexBallNumber] = false;
          col = [
            arrayNumberOpenCode[indexRow][indexBall],
            true,
            checkConnectNonAppear[indexBallNumber],
          ];
          count[indexBall][indexBallNumber] - 1 &&
            countAverage[indexBall][indexBallNumber].push(
              count[indexBall][indexBallNumber] - 1,
            );
          count[indexBall][indexBallNumber] = 1;
          countAppear[indexBall][indexBallNumber]++;
        } else {
          let nonAppear = false;
          if (
            checkConnectNonAppear[indexBallNumber] === true ||
            checkConnectNonAppear[indexBallNumber] === undefined
          ) {
            checkConnectNonAppear[indexBallNumber] = true;
            nonAppear = true;
          }
          col = [count[indexBall][indexBallNumber], false, nonAppear];

          if (
            countBiggest[indexBall][indexBallNumber] <
            count[indexBall][indexBallNumber]
          ) {
            countBiggest[indexBall][indexBallNumber] =
              count[indexBall][indexBallNumber];
          }
          count[indexBall][indexBallNumber]++;
        }
        row.push(col);
      }
      data.push(row);

      rowConnectTemp.push(arrayNumberOpenCode[indexRow][indexBall]);
    }

    countConnect.push(rowConnectTemp);
    dataSourceArray.push(data);
  }

  //check last row of not appear value
  for (let lastAvgBall = 0; lastAvgBall < count.length; lastAvgBall++) {
    for (
      let lastInnerAvg = 0;
      lastInnerAvg < count[lastAvgBall].length;
      lastInnerAvg++
    ) {
      count[lastAvgBall][lastInnerAvg] - 1 &&
        countAverage[lastAvgBall][lastInnerAvg].push(
          count[lastAvgBall][lastInnerAvg] - 1,
        );
    }
  }

  for (let index = 0; index < countAverage.length; index++) {
    for (
      let indexBall = constGameBallStartNumber[group];
      indexBall < countAverage[index].length;
      indexBall++
    ) {
      const avr =
        countAverage[index][indexBall].reduce((a, b) => a + b, 0) /
        countAverage[index][indexBall].length;
      countAverage[index][indexBall] = Math.round(avr);
    }
  }

  for (let indexBall = 0; indexBall < constTotalCodeDigit[group]; indexBall++) {
    let connectBK = '';
    let connectCount = 0;
    let connectResultRow = [];
    for (
      let indexRow = 0;
      indexRow < countConnect[indexBall].length;
      indexRow++
    ) {
      if (connectBK === '') {
        connectBK = countConnect[indexBall][indexRow];
        connectCount = 1;
        connectResultRow[connectBK] = connectCount;
      } else {
        if (connectBK === countConnect[indexBall][indexRow]) {
          if (connectCount === 0) {
            connectCount = 2;
          } else {
            connectCount++;
          }

          if (connectResultRow[connectBK] < connectCount) {
            connectResultRow[connectBK] = connectCount;
          }
        } else {
          connectCount = 1;
          if (!connectResultRow[countConnect[indexBall][indexRow]]) {
            connectResultRow[countConnect[indexBall][indexRow]] = connectCount;
          }
          connectBK = countConnect[indexBall][indexRow];
        }
      }
    }

    for (
      let indexCheckEmpty = constGameBallStartNumber[group];
      indexCheckEmpty <= constGameBallNumber[group];
      indexCheckEmpty++
    ) {
      if (!connectResultRow[indexCheckEmpty]) {
        connectResultRow[indexCheckEmpty] = 0;
      }
    }
    connectResult.push(connectResultRow);
  }

  const result = {
    table: dataSourceArray,
    appear: countAppear,
    average: countAverage,
    biggest: countBiggest,
    connect: connectResult,
    openCodeByBall: countConnect,
  };

  return result;
}

export function getDataAnalysisPattern(resultTable, indexColMaxNumber) {
  let countAverage = [];
  let countBiggest = new Array(indexColMaxNumber).fill(0);
  let countConnect = new Array(indexColMaxNumber).fill(0);
  for (let indexCol = 0; indexCol < indexColMaxNumber; indexCol++) {
    const countAvgSet = [];
    let countAppearTime = 0;
    let connect = 0;

    for (let indexRow = 0; indexRow < resultTable.length; indexRow++) {
      if (typeof resultTable[indexRow][indexCol][0] === 'number') {
        // countNum = countNum+resultTable[indexRow][indexCol][0];
        countAppearTime++;

        //check if last row is number
        if (resultTable.length - 1 === indexRow) {
          countAvgSet.push(countAppearTime);
        }
      }

      if (countBiggest[indexCol] < resultTable[indexRow][indexCol][0]) {
        countBiggest[indexCol] = resultTable[indexRow][indexCol][0];
      }

      if (typeof resultTable[indexRow][indexCol][0] === 'string') {
        if (countAppearTime) {
          countAvgSet.push(countAppearTime);
          countAppearTime = 0;
        }
        connect++;
      } else {
        if (countConnect[indexCol] < connect) {
          countConnect[indexCol] = connect;
        }
        connect = 0;
      }

      if (indexRow === resultTable.length - 1) {
        if (countConnect[indexCol] < connect) {
          countConnect[indexCol] = connect;
        }
      }
    }

    countAverage.push(
      Math.round(countAvgSet.reduce((a, b) => a + b, 0) / countAvgSet.length),
    );
  }

  const result = {
    countAverage,
    countBiggest,
    countConnect,
  };
  return result;
}

export function iconLinkData(searchGame, gameNameInChinese) {
  return {
    icon: Images[searchGame],
    gameNameInChinese: gameNameInChinese,
  };
}

// value is 3 arrays; ['12']; {12: '木'} / {12: '虎'}
export function convertOpenCodeIntoZodiacOrWuxingAndNumber(value) {
  const result = [];
  for (let index = 0; index < value[0].length; index++) {
    const strNumber =
      typeof value[0][index] === 'string'
        ? value[0][index]
        : ('0' + value[0][index].toString()).slice(-2);
    const newValue = [parseInt(value[0][index]), value[1][strNumber]];
    result.push(newValue);
  }

  return result;
}

export function getCurrentYearZodiacByDate(date) {
  let openTime = date.split('-');
  const openYear = parseInt(openTime[0]);
  const openDate = parseInt(openTime[1] + openTime[2]);

  let determinedYear;
  if (openDate >= constCurrentYearZodiac[openYear].startTime) {
    determinedYear = openYear;
  } else {
    determinedYear = openYear - 1;
  }

  const zodiac = constCurrentYearZodiac[determinedYear].zodiac;

  return zodiac;
}

export function to2DecimalNoRounding(value) {
  return parseFloat((parseInt(value * 100) / 100).toFixed(2));
}

export function convertOpenTimeToChineseYear(value) {
  const openTimeDate = getOpenTimeDate(value);
  const openTimeDateArray = openTimeDate.split('-');
  const currentOpenYear = openTimeDateArray[0];
  const openTime = openTimeDateArray[1] + openTimeDateArray[2];

  let determinedYear;
  if (openTime >= constSixElements[currentOpenYear].startTime) {
    determinedYear = currentOpenYear;
  } else {
    determinedYear = currentOpenYear - 1;
  }

  return determinedYear;
}

export function convertNumberToWuXingXiao(value, element) {
  const tempValue = [];
  for (let index = 0; index < value.length; index++) {
    const num = parseInt(value[index]);
    tempValue.push([num, element[num]]);
  }
  return tempValue;
}

function getTranslationFile(language, word, short, special) {
  let translFile = '';

  if (language && special) {
    if (translationFileSpecial[language][word]) {
      return translationFileSpecial;
    } else if (translationFileShort[language][word]) {
      return translationFileShort;
    } else if (translationFile[language][word]) {
      return translationFile;
    }
  }

  if (language && short) {
    if (translationFileShort[language][word]) {
      return translationFileShort;
    } else if (translationFile[language][word]) {
      return translationFile;
    }
  }

  if (language && translationFile[language][word]) {
    return translationFile;
  }

  return translFile;
}

export function transl(
  word,
  arrayWord = [],
  short = false,
  special = false,
  lang = '',
) {
  const isBrowser = typeof window !== 'undefined';
  if (!isBrowser) {
    return;
  } else {
    const url_string = window.location.href;
    const url = new URL(url_string);
    let language = url.searchParams.get('language');

    if (lang !== '' && language === 'vi-VN') {
      language = lang;
    }

    if (language !== 'en-US' && language !== 'vi-VN') language = '';

    const translFile = getTranslationFile(language, word, short, special);

    let tlResult = (translFile && word && translFile[language][word]) || word;

    const regex = '/x/i';
    for (let index = 0; index < arrayWord.length; index++) {
      if (tlResult.includes(regex)) {
        let tlArrayWord =
          (language && translFile[language][arrayWord[index]]) ||
          arrayWord[index];

        tlResult = tlResult.replace(regex, tlArrayWord);
      }
    }

    return tlResult;
  }
}

import React, { useState } from 'react';
import { inject, observer } from 'mobx-react';
import styled from 'styled-components';
import { color } from '@helpers/const';
import withLocation from '@helpers/hoc/withLocation';
import { transl } from '@helpers/utils';

import { Images } from '@components/common/images';
import { constPlayRuleByGroup } from '@const/config';
import { constPlayRule } from '@const/playRule';

import { SettingOutlined } from '@ant-design/icons';

import { Drawer, Row, Col } from 'antd';

const { changLongTongJi, liShiHaoMa, daXiaoDanShuangLiShi } = constPlayRule;

// const onClickPlayRule = (openRule, setOpenRule) => {
//     if (constPlayRuleByGroup[group].length > 1) {
//         setOpenRule(true);
//     }
// };

const Header = ({ trendsStore, search, ruleName, setOpenRule, openRule }) => {
  const { group, primaryColor, fontColor, rule } = search;

  const { resultDisplay } = trendsStore;

  const [selectDisplayResult, setSelectDisplayResult] = useState(30);
  const [openSetting, setOpenSetting] = useState(false);

  const onClickMobileSettingConfirm = () => {
    trendsStore.changeResultNumber(selectDisplayResult);
    setOpenSetting(false);
  };

  const onCloseMobileSetting = () => {
    setSelectDisplayResult(resultDisplay);
    setOpenSetting(false);
  };

  const onResetMobileSetting = () => {
    trendsStore.changeResultNumber(30);
    setSelectDisplayResult(30);
    setOpenSetting(false);
  };

  // const [openMenu, setOpenMenu] = useState(false);

  // const getMenuButton = (hf) => {
  //     return (
  //         <ButtonWrapper>
  //             <Row gutter={16}>
  //                 {
  //                     listOfSIXGames.map((value)=>{
  //                         return(
  //                             value.startsWith('HF_') === hf && (
  //                                 <Col key={value} span={8}>
  //                                     <LcwButton
  //                                         text={constGameNameInChinese[value]}
  //                                         active={value === searchGame? true:false}
  //                                         url={URLS.iframe+"?searchGame="+value+"&group=SIX"}
  //                                         activeOnClick={()=>setOpenMenu(false)}
  //                                         btnStyle={8}
  //                                     />
  //                                 </Col>
  //                             )
  //                         )
  //                     })
  //                 }
  //             </Row>
  //         </ButtonWrapper>
  //     )
  // }

  let playRuleClass = constPlayRuleByGroup[group].length > 1 ? 'arrow' : '';

  if (openRule) {
    playRuleClass = playRuleClass + ' open';
  }

  let title = transl(ruleName);
  if (title.length > 7) {
    title = title.substring(0, 7);
    title = title + '...';
  }

  return (
    <Wrapper
      bgColor={primaryColor ? '#' + primaryColor : color.orange}
      fontColor={fontColor ? '#' + fontColor : color.white}>
      <PlayRuleWrapper>
        <div className="inner-wrapper">
          <PlayRule
            className={playRuleClass}
            onClick={() => {
              setOpenRule(!openRule);
            }}>
            {title}
          </PlayRule>
          {rule !== changLongTongJi &&
            rule !== liShiHaoMa &&
            rule !== daXiaoDanShuangLiShi && (
              <SettingWrapper>
                <button onClick={() => setOpenSetting(!openSetting)}>
                  <SettingOutlined style={{ fontSize: '20px' }} />
                </button>
                <Drawer
                  className="setting-drawer"
                  placement="top"
                  closable={false}
                  onClose={() => onCloseMobileSetting()}
                  visible={openSetting}
                  height="auto"
                  destroyOnClose={true}
                  style={{ top: '44px' }}
                  zIndex={4}>
                  <div className="selection">
                    <div className="title">{transl('近期设置')}</div>
                    <Row className="options" justify="space-between">
                      <Col
                        className={
                          selectDisplayResult === 30
                            ? 'option-wrapper active'
                            : 'option-wrapper'
                        }>
                        <button onClick={() => setSelectDisplayResult(30)}>
                          <div className="circle-wrapper">
                            <div className="circle"></div>
                          </div>
                          {transl('近/x/i期', ['30'])}
                        </button>
                      </Col>
                      <Col
                        className={
                          selectDisplayResult === 50
                            ? 'option-wrapper active'
                            : 'option-wrapper'
                        }>
                        <button onClick={() => setSelectDisplayResult(50)}>
                          <div className="circle-wrapper">
                            <div className="circle"></div>
                          </div>
                          {transl('近/x/i期', ['50'])}
                        </button>
                      </Col>
                      <Col
                        className={
                          selectDisplayResult === 100
                            ? 'option-wrapper active'
                            : 'option-wrapper'
                        }>
                        <button onClick={() => setSelectDisplayResult(100)}>
                          <div className="circle-wrapper">
                            <div className="circle"></div>
                          </div>
                          {transl('近/x/i期', ['100'])}
                        </button>
                      </Col>
                    </Row>
                  </div>
                  <Row className="action mobile">
                    <Col span={12}>
                      <button
                        onClick={() => {
                          onResetMobileSetting();
                        }}>
                        {transl('取消')}
                      </button>
                    </Col>
                    <Col span={12}>
                      <button
                        className="confirm"
                        onClick={() => {
                          onClickMobileSettingConfirm();
                        }}>
                        {transl('确定')}
                      </button>
                    </Col>
                  </Row>
                </Drawer>
              </SettingWrapper>
            )}
        </div>
      </PlayRuleWrapper>
      {/* <GameWrapper>
                <Game
                    onClick={()=>{
                        setOpenMenu(true)
                    }}
                >
                    彩种
                </Game>
                <Drawer
                    style={{top: 0, zIndex: 3}}
                    visible={openMenu}
                    onClose={()=> setOpenMenu(false)}
                >
                    <GameDrawer>
                        <div className="instruct">
                            选择彩种
                        </div>
                        <div className="current">
                            分分六合彩
                        </div>
                        <div className="hf">
                            <div className="title">高频彩</div>
                                {getMenuButton(true)}
                        </div>
                        <div>
                            <div className="title">低频彩</div>
                                {getMenuButton(false)}
                        </div>
                    </GameDrawer>
                </Drawer>
            </GameWrapper> */}
    </Wrapper>
  );
};

export default withLocation(
  inject('rootStore', 'trendsStore')(observer(Header)),
);

const Wrapper = styled.div`
  position: fixed;
  top: 0;
  z-index: 6;
  width: 100%;
  height: 44px;
  color: ${(props) => props.fontColor};
  background-color: ${(props) => props.bgColor};
  font-size: 18px;
`;

const PlayRuleWrapper = styled.div`
  font-size: 16px;

  .inner-wrapper {
    position: relative;
    display: table;
    height: 44px;
    margin: auto;
    max-width: 45%;
  }
`;

const PlayRule = styled.div`
  position: relative;
  text-align: center;
  padding-right: 14px;
  line-height: 18px;
  height: 44px;
  display: flex;
  align-items: center;

  @media only screen and (max-width: 576px) {
    padding-right: 0;
  }

  &.arrow {
    padding-right: 14px;

    &:after {
      content: '';
      background-image: url(${Images.icn_dropdown_white});
      position: absolute;
      width: 11px;
      height: 7px;
      top: 50%;
      right: 0;
      transform: translateY(-50%);
    }

    &.open {
      &:after {
        top: 48%;
        transform: translateY(-50%) rotate(180deg);
      }
    }
  }
`;

// const GameWrapper = styled.div`
//     position: absolute;
//     top: 50%;
//     right: 7px;
//     transform: translateY(-50%);
// `;

// const Game = styled.div`
//     position: relative;
//     text-align: right;
//     padding-right: 13px;
//     padding-left: 27px;

//     &:before{
//         content:'';
//         background-image: url(${Images.icn_mobile_menu});
//         position: absolute;
//         width: 22px;
//         height: 22px;
//         top: 50%;
//         left: 0%;
//         transform: translateY(-50%);
//     }

//     &:after{
//         content:'';
//         background-image: url(${Images.icn_dropdown_white});
//         position: absolute;
//         width: 11px;
//         height: 7px;
//         top: 50%;
//         right: 0;
//         transform: translateY(-50%);
//     }
// `;

// const GameDrawer = styled.div`
//     font-size: 15px;

//     .instruct {
//         padding-top: 15px;
//         color: ${color.fontGrey};
//         text-align: center;
//         line-height: 16px;
//         margin-bottom: 13px;
//     }

//     .current {
//         position: relative;
//         color: ${color.mobileBlack};
//         text-align: center;
//         line-height: 15px;
//         width: fit-content;
//         margin: auto auto 6px;

//         &:before,
//         &:after {
//             content: "";
//             position: absolute;
//             width: 65px;
//             height: 0.5px;
//             top: 50%;
//             transform: translateY(-50%);
//             background-color: ${color.borderGrey};
//         }

//         &:before {
//             left: -80px;
//         }

//         &:after {
//             right: -80px;
//         }
//     }

//     .title {
//         color: ${color.blue};
//         padding: 15px 27px 10px 16px;
//     }

//     .hf {
//         border-bottom: 1px solid ${color.borderGrey};

//         .title {
//             color: ${color.orange};
//         }
//     }
// `;

// const ButtonWrapper = styled.div`
//     padding: 0px 27px 5px 16px;
// `;

const SettingWrapper = styled.div`
  position: absolute;
  top: 50%;
  left: -40px;
  transform: translateY(-50%);
  line-height: 1;

  .action {
    border-bottom: 1px solid ${color.borderGrey};

    .ant-col {
      text-align: center;
      border-right: 1px solid ${color.borderGrey};

      &:nth-child(2) {
        border-right: none;
        color: ${color.orange};
      }
    }

    .confirm {
      color: ${color.orange};
    }
  }
`;

import {
  calcDuplicateCode,
  getTotalOddEven,
  getTotalBigSmall,
  findDragonTigerThousandDigit,
  transl,
} from '@helpers/utils';

import { styleDragonTiger } from '../TableHeader/style';

export const dragonHeader = () => {
  let columns = [];
  columns.push(
    {
      title: '冠亚龙虎',
      children: [
        {
          title: '单双大小龙虎',
          dataIndex: '单双大小龙虎',
          width: 100,
        },
      ],
    },
    {
      title: '冠亚和',
      children: [
        {
          title: '单',
          dataIndex: '冠亚和单',
          width: 90,
          render: (value, row, index) => {
            const childObj = {
              children: value ? value : 0,
            };

            return childObj;
          },
        },
        {
          title: '双',
          dataIndex: '冠亚和双',
          width: 90,
          render: (value, row, index) => {
            const childObj = {
              children: value ? value : 0,
            };

            return childObj;
          },
        },
        {
          title: '大',
          dataIndex: '冠亚和大',
          width: 88,
          render: (value, row, index) => {
            const childObj = {
              children: value ? value : 0,
            };

            return childObj;
          },
        },
        {
          title: '小',
          dataIndex: '冠亚和小',
          width: 88,
          render: (value, row, index) => {
            const childObj = {
              children: value ? value : 0,
            };

            return childObj;
          },
        },
      ],
    },
  );

  const dragonIndexKey = [
    '第一名龙虎',
    '第二名龙虎',
    '第三名龙虎',
    '第四名龙虎',
    '第五名龙虎',
  ];
  const dragonIndexKeyTitle = [
    '第一名',
    '第二名',
    '第三名',
    '第四名',
    '第五名',
  ];
  for (let col = 0; col < dragonIndexKey.length; col++) {
    columns.push({
      title: dragonIndexKeyTitle[col],
      children: [
        {
          title: '龙',
          dataIndex: dragonIndexKey[col] + '龙',
          width: 60,
          render: (value, row, index) => {
            const childObj = {
              children: value ? value : 0,
            };

            return childObj;
          },
        },
        {
          title: '虎',
          dataIndex: dragonIndexKey[col] + '虎',
          width: 60,
          render: (value, row, index) => {
            const childObj = {
              children: value ? value : 0,
            };

            return childObj;
          },
        },
      ],
    });
  }

  return columns;
};

export const dragonData = (data, group) => {
  let obj = [];
  if (data.length) {
    let sourceDataByChampEvenOdd = [];
    let sourceDataByChampBigSmall = [];
    let sourceDataByChampDragon = [];

    sourceDataByChampEvenOdd.push(
      data.map((item) => {
        return getTotalOddEven([item[0], item[1]]);
      }),
    );

    sourceDataByChampBigSmall.push(
      data.map((item) => {
        return getTotalBigSmall(item, group);
      }),
    );

    const backwardIndex = 9;
    for (let index = 0; index < 5; index++) {
      sourceDataByChampDragon.push(
        data.map((item) => {
          return findDragonTigerThousandDigit(
            item[index],
            item[backwardIndex - index],
          );
        }),
      );
    }

    const duplicateSourceDataByChampEvenOdd = calcDuplicateCode(
      sourceDataByChampEvenOdd,
    );
    const duplicateSourceDataByChampBigSmall = calcDuplicateCode(
      sourceDataByChampBigSmall,
    );

    obj.push({
      key: '1',
      单双大小龙虎: transl('出现次数', [], true, false, 'en-US'),
      冠亚和单: duplicateSourceDataByChampEvenOdd.单,
      冠亚和双: duplicateSourceDataByChampEvenOdd.双,
      冠亚和大: duplicateSourceDataByChampBigSmall.大,
      冠亚和小: duplicateSourceDataByChampBigSmall.小,
      第一名龙虎龙: calcDuplicateCode(sourceDataByChampDragon[0]).龙,
      第一名龙虎虎: calcDuplicateCode(sourceDataByChampDragon[0]).虎,
      第二名龙虎龙: calcDuplicateCode(sourceDataByChampDragon[1]).龙,
      第二名龙虎虎: calcDuplicateCode(sourceDataByChampDragon[1]).虎,
      第三名龙虎龙: calcDuplicateCode(sourceDataByChampDragon[2]).龙,
      第三名龙虎虎: calcDuplicateCode(sourceDataByChampDragon[2]).虎,
      第四名龙虎龙: calcDuplicateCode(sourceDataByChampDragon[3]).龙,
      第四名龙虎虎: calcDuplicateCode(sourceDataByChampDragon[3]).虎,
      第五名龙虎龙: calcDuplicateCode(sourceDataByChampDragon[4]).龙,
      第五名龙虎虎: calcDuplicateCode(sourceDataByChampDragon[4]).虎,
    });
  }

  return obj;
};

export const dragonChampHeaderMobilePK10 = () => {
  const columns = [
    {
      title: '球次',
      dataIndex: '球次',
      width: 100,
    },
    {
      title: '冠亚和',
      children: [
        {
          title: '单',
          className: 'fullTransl',
          dataIndex: '冠亚和单',
          width: 50,
        },
        {
          title: '双',
          className: 'fullTransl',
          dataIndex: '冠亚和双',
          width: 50,
        },
        {
          title: '大',
          className: 'fullTransl',
          dataIndex: '冠亚和大',
          width: 50,
        },
        {
          title: '小',
          className: 'fullTransl',
          dataIndex: '冠亚和小',
          width: 50,
        },
      ],
    },
  ];

  return columns;
};

export const dragonChampDataMobilePK10 = (data, group) => {
  let obj = [];
  if (data.length) {
    let sourceDataByChampEvenOdd = [];
    let sourceDataByChampBigSmall = [];

    sourceDataByChampEvenOdd.push(
      data.map((item) => {
        return getTotalOddEven([item[0], item[1]]);
      }),
    );

    sourceDataByChampBigSmall.push(
      data.map((item) => {
        return getTotalBigSmall(item, group);
      }),
    );

    const duplicateSourceDataByChampEvenOdd = calcDuplicateCode(
      sourceDataByChampEvenOdd,
    );
    const duplicateSourceDataByChampBigSmall = calcDuplicateCode(
      sourceDataByChampBigSmall,
    );

    obj.push({
      key: '1',
      球次: transl('出现次数', [], true, false, 'en-US'),
      冠亚和单: duplicateSourceDataByChampEvenOdd.单,
      冠亚和双: duplicateSourceDataByChampEvenOdd.双,
      冠亚和大: duplicateSourceDataByChampBigSmall.大,
      冠亚和小: duplicateSourceDataByChampBigSmall.小,
    });
  }

  return obj;
};

export const dragonHeaderMobilePK10 = () => {
  const columns = [
    {
      title: '龙虎',
      dataIndex: '龙虎',
      className: 'fullTransl',
      width: 100,
      render: (value) => {
        return styleDragonTiger(value, false);
      },
    },
    {
      title: '第一名',
      dataIndex: '第一名',
    },
    {
      title: '第二名',
      dataIndex: '第二名',
    },
    {
      title: '第三名',
      dataIndex: '第三名',
    },
    {
      title: '第四名',
      dataIndex: '第四名',
    },
    {
      title: '第五名',
      dataIndex: '第五名',
    },
  ];

  return columns;
};

export const dragonDataMobilePK10 = (data) => {
  let obj = [];
  if (data.length) {
    let sourceDataByChampDragon = [];

    const backwardIndex = 9;
    for (let index = 0; index < 5; index++) {
      sourceDataByChampDragon.push(
        data.map((item) => {
          return findDragonTigerThousandDigit(
            item[index],
            item[backwardIndex - index],
          );
        }),
      );
    }

    obj.push(
      {
        key: '1',
        龙虎: '龙',
        第一名: calcDuplicateCode(sourceDataByChampDragon[0]).龙,
        第二名: calcDuplicateCode(sourceDataByChampDragon[1]).龙,
        第三名: calcDuplicateCode(sourceDataByChampDragon[2]).龙,
        第四名: calcDuplicateCode(sourceDataByChampDragon[3]).龙,
        第五名: calcDuplicateCode(sourceDataByChampDragon[4]).龙,
      },
      {
        key: '2',
        龙虎: '虎',
        第一名: calcDuplicateCode(sourceDataByChampDragon[0]).虎,
        第二名: calcDuplicateCode(sourceDataByChampDragon[1]).虎,
        第三名: calcDuplicateCode(sourceDataByChampDragon[2]).虎,
        第四名: calcDuplicateCode(sourceDataByChampDragon[3]).虎,
        第五名: calcDuplicateCode(sourceDataByChampDragon[4]).虎,
      },
    );
  }

  return obj;
};

import React from 'react';
import styled from 'styled-components';
import { Row, Col } from 'antd';

import { color } from '@helpers/const';
import { oddEven, bigSmall, arrayStringToArrayInt } from '@helpers/utils';
import isMobile from '@helpers/hoc/isMobile';
import withLocation from '@helpers/hoc/withLocation';

import { constBigSmallThreshold } from '@const/playRule';

import { SummarizeTable } from '@components/common/table';
import { transl } from '@helpers/utils';

const getTableHeader = (isMobile) => {
  let columns = [
    {
      title: '日期',
      dataIndex: '日期',
      width: isMobile ? 100 : 250,
      render: (value, row, index) => {
        return (
          <Row
            gutter={{ md: 20 }}
            justify="center"
            className="date-issues-wrapper">
            <Col className="date">{value[0]}</Col>
            <Col className="issues-date">{value[1]}</Col>
          </Row>
        );
      },
    },
    {
      title: '单',
      dataIndex: '单',
      width: isMobile ? 40 : 100,
    },
    {
      title: '双',
      dataIndex: '双',
      width: isMobile ? 40 : 100,
    },
    {
      title: '大',
      dataIndex: '大',
      width: isMobile ? 40 : 100,
    },
    {
      title: '小',
      dataIndex: '小',
      width: isMobile ? 40 : 100,
    },
  ];

  return columns;
};

const findLiangMianTongJi = (
  allSingleGame,
  ballFilter,
  group,
  isMobile,
  isTransl,
) => {
  let result = [];
  let fromIssueNumber = '';
  let toIssueNumber = '';
  let openTime;
  let odd = 0;
  let even = 0;
  let big = 0;
  let small = 0;

  for (let index = 0; index < allSingleGame.length; index++) {
    const openCode = allSingleGame[index].openCode;

    const arrayNumbers = arrayStringToArrayInt(openCode);

    if (oddEven(arrayNumbers[ballFilter])) {
      odd++;
    } else {
      even++;
    }

    if (bigSmall(arrayNumbers[ballFilter], constBigSmallThreshold[group])) {
      big++;
    } else {
      small++;
    }

    if (index % 10 === 0 || index % 10 === 9) {
      openTime = allSingleGame[index].openTime;
      openTime =
        transl(openTime.slice(5, 7) + '月', [], false, false, 'en-US') +
        (isTransl ? ' ' : '') +
        openTime.slice(8, 10) +
        (isTransl ? '' : transl('日', [], false, false, 'en-US'));

      if (toIssueNumber === '') {
        toIssueNumber = allSingleGame[index].uniqueIssueNumber;
      } else if (fromIssueNumber === '') {
        fromIssueNumber = allSingleGame[index].uniqueIssueNumber;
      }
    }

    if (fromIssueNumber && toIssueNumber) {
      fromIssueNumber = fromIssueNumber.toString();
      toIssueNumber = toIssueNumber.toString();

      if (isMobile) {
        fromIssueNumber =
          fromIssueNumber.length > 4
            ? fromIssueNumber.slice(fromIssueNumber.length - 4)
            : fromIssueNumber;
        toIssueNumber =
          toIssueNumber.length > 4
            ? toIssueNumber.slice(toIssueNumber.length - 4)
            : toIssueNumber;
      }

      let obj = {};
      obj['openTime'] = openTime;
      obj['issueNumberRange'] =
        fromIssueNumber +
        ' - ' +
        toIssueNumber +
        (isTransl ? ' ' : '') +
        transl('期', [], false, true, 'en-US');
      obj['odd'] = odd;
      obj['even'] = even;
      obj['big'] = big;
      obj['small'] = small;
      result.push(obj);
      fromIssueNumber = '';
      toIssueNumber = '';
      odd = 0;
      even = 0;
      big = 0;
      small = 0;
    }
  }

  return result;
};

const getTableData = (allSingleGame, ballFilter, group, isMobile, isTransl) => {
  let dataSourceArray = [];

  const tableResult = findLiangMianTongJi(
    allSingleGame,
    ballFilter,
    group,
    isMobile,
    isTransl,
  );

  for (let indexRow = 0; indexRow < tableResult.length; indexRow++) {
    let resultObj = {};
    resultObj['key'] = indexRow;
    resultObj['日期'] = [
      tableResult[indexRow].openTime,
      tableResult[indexRow].issueNumberRange,
    ];
    resultObj['单'] = tableResult[indexRow].odd;
    resultObj['双'] = tableResult[indexRow].even;
    resultObj['大'] = tableResult[indexRow].big;
    resultObj['小'] = tableResult[indexRow].small;

    dataSourceArray.push(resultObj);
  }

  return dataSourceArray;
};

const MainTable = ({ isMobile, singleGame, ballFilter, group, search }) => {
  const { language } = search;

  let isTransl = false;

  if (language === 'en-US' || language === 'vi-VN') {
    isTransl = true;
  }

  const columns = getTableHeader(isMobile);
  const dataSourceArray = getTableData(
    singleGame,
    ballFilter,
    group,
    isMobile,
    isTransl,
  );

  return (
    <TrendsResultWrapper>
      <TrendsTableWrapper>
        <SummarizeTable
          tableData={dataSourceArray}
          tableHeader={columns}
          fixHeader={true}
          translHeaderFull={true}
        />
      </TrendsTableWrapper>
    </TrendsResultWrapper>
  );
};

export default withLocation(isMobile(MainTable));

const TrendsResultWrapper = styled.div`
  background-color: ${color.white};

  .ant-table table {
    th {
      padding: 15px 5px;
      font-size: 15px;
      background-color: ${color.backgroundGrey};
    }

    td {
      font-size: 14px;
      padding: 19px 0;
      color: ${color.black};
    }

    @media only screen and (max-width: 576px) {
      th {
        font-size: 13px;
        line-height: 29px;
        padding: 0;
        background-color: ${color.orange};
      }

      td {
        font-size: 13px;
        line-height: 40px;
        padding: 0;
        color: ${color.mobileBlack};

        .date-issues-wrapper {
          display: block;

          .date,
          .issues-date {
            line-height: 20px;
          }
        }
      }
    }
  }
`;

const TrendsTableWrapper = styled.div`
  padding: 0 25px 48px;

  @media only screen and (max-width: 576px) {
    padding: 0;
  }
`;

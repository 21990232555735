import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { inject, observer } from 'mobx-react';
import { toJS } from 'mobx';
import { Row, Col } from 'antd';

import withLayout from '@helpers/hoc/withLayout';
import withLocation from '@helpers/hoc/withLocation';
import { PaddingContainer } from '@helpers/hoc/withPaddingWidth';
import { color } from '@helpers/const';
import {
  constHotRecommended,
  constHomeFilter,
  constSideToolsData,
} from '@const/config';

import Recommendation from '@components/common/recommendation';
import SEO from '@components/common/seo';
import { UnderLineFilter } from '@components/common/filters';
import { HomeCard } from '@components/common/cards';
import SideBarTool from '@components/common/SideBarTool';
import BannerSlider from '@components/common/bannerSlider';
import { Images } from '@components/common/images';

const bannerSliderData = [Images.bannerSlider1];

let timerToNextDraw;
const fetchAllGamesTimer = (action, rootStore, allGames) => {
  if (action) {
    if (typeof allGames === 'object') {
      timerToNextDraw = setTimeout(() => {
        rootStore.fetchResultByBrand();
      }, 3500);
    }
  } else {
    clearTimeout(timerToNextDraw);
  }
};

const Home = ({ rootStore, homeStore, search, location }) => {
  const [homeFilter, setHomeFilter] = useState('HOT');

  const { allGames } = rootStore;

  useEffect(() => {
    fetchAllGamesTimer(true, rootStore, allGames);

    return function cleanup() {
      fetchAllGamesTimer(false);
    };
  }, [rootStore, allGames]);

  return (
    <HomeWrapper>
      <SEO title="首页" />
      <BannerSlider banner={bannerSliderData} />
      <PaddingContainer>
        <Row gutter={{ xs: 0, md: 15 }}>
          <Col xs={24} md={19} flex="auto">
            <Recommendation
              ribbon={Images.ribbonRecommendation}
              title="热门推荐"
              list={constHotRecommended}
              allGames={toJS(allGames)}
            />
            <UnderLineFilterWrapper>
              <UnderLineFilter
                filterList={constHomeFilter}
                onClickAction={(props) => {
                  setHomeFilter(props.filter);
                }}
                activeFilter={homeFilter}
                styleSet={1}
                selection={false}
              />
            </UnderLineFilterWrapper>
            <Row gutter={[{ md: 0, lg: 14 }, 11]}>
              {typeof allGames === 'object'
                ? constHomeFilter.map((confHome) => {
                    const { filter, showAll, games } = confHome;
                    return filter === homeFilter
                      ? showAll
                        ? toJS(allGames).map((game) => {
                            if (game.playGroup === filter) {
                              return (
                                <HomeCard key={game.gameUniqueId} {...game} />
                              );
                            } else {
                              return '';
                            }
                          })
                        : games.map((game, index) => {
                            // if(game === "HF_FFSSC"){
                            const currentGame = toJS(allGames).find(
                              (lottoDataElement) =>
                                lottoDataElement.gameUniqueId === game,
                            );

                            if (currentGame) {
                              return (
                                <HomeCard
                                  key={currentGame.gameUniqueId}
                                  {...currentGame}
                                />
                              );
                            } else {
                              return '';
                            }

                            // }else{
                            //     return '';
                            // }
                          })
                      : '';
                  })
                : ''}
            </Row>
          </Col>
          <Col xs={24} md={{ span: 5, flex: '259px' }}>
            <SideBarTool
              toolList={constSideToolsData}
              allGames={toJS(allGames)}
            />
          </Col>
        </Row>
      </PaddingContainer>
    </HomeWrapper>
  );
};

export default withLocation(
  withLayout(inject('rootStore', 'homeStore')(observer(Home))),
);

const HomeWrapper = styled.div`
  background-color: ${color.bodyGrey};
`;

const UnderLineFilterWrapper = styled.div`
  position: relative;
  line-height: 0;

  &:after {
    content: '';
    position: absolute;
    bottom: 16px;
    left: 0;
    width: 100%;
    height: 1px;
    background-color: ${color.greyLine};
  }

  @media only screen and (max-width: 576px) {
    flex-flow: unset;
    margin-bottom: 15px;

    &:after {
      bottom: 0;
    }

    .set-1 {
      .ant-col {
        // flex: 0 0 auto;
        // margin-right: 20px;

        div {
          p {
            font-size: 18px;
            position: relative;
            font-weight: 600;
          }

          &.active {
            p {
              &:after {
                content: '';
                position: absolute;
                left: -5px;
                bottom: 0;
                width: calc(100% + 10px);
                height: 2px;
                background-color: ${color.orange};
                z-index: 1;
              }
            }
          }
        }
      }
    }
  }
`;

import { arrayStringToArrayInt } from '@helpers/utils';

import {
  styleOddEven,
  styleBigSmall,
  styleShunZhi,
  getNumberCode,
  styleJiZhi,
  styleBoSe,
} from './style';

const k3Header = (
  singleGame,
  group,
  activeTab,
  setTab,
  subSubOption,
  isMobile,
  openDisplayType,
  setOpenDisplayType,
  activePattern,
  setPattern,
  openPattern,
  setOpenPattern,
) => {
  let columns = [];
  let temp = [];

  if (!isMobile) {
    columns = [
      {
        title: '时间',
        dataIndex: '时间',
        width: 80,
      },
    ];
  }
  columns.push(
    {
      title: '期数',
      dataIndex: '期数',
      width: isMobile ? 45 : 80,
      sorter: (a, b) => a.期数 - b.期数,
      defaultSortOrder: 'ascend',
      sortDirections: ['ascend', 'descend', 'ascend'],
    },
    {
      title: '开奖号码',
      dataIndex: '开奖号码',
      width: isMobile ? 90 : 149,
      render: (value, row, index) => {
        let pairStyle = [];

        let nextOpenCode = {};
        if (index < singleGame.length - 1) {
          nextOpenCode = singleGame[index + 1];
        } else {
          nextOpenCode = singleGame[index - 1];
        }

        nextOpenCode =
          nextOpenCode && arrayStringToArrayInt(nextOpenCode.openCode);
        if (nextOpenCode) {
          for (
            let indexDigit = 0;
            indexDigit < nextOpenCode.length;
            indexDigit++
          ) {
            if (value[indexDigit] === nextOpenCode[indexDigit]) {
              pairStyle.push(true);
              temp[indexDigit] = nextOpenCode[indexDigit];
            } else if (value[indexDigit] === temp[indexDigit]) {
              pairStyle.push(true);
              temp[indexDigit] = false;
            } else {
              pairStyle.push(false);
              temp[indexDigit] = false;
            }
          }
        }

        const obj = getNumberCode(value, subSubOption, pairStyle, group);
        return obj;
      },
    },
    {
      title: '总和',
      children: [
        {
          title: '总数',
          dataIndex: '总和',
          width: 38,
        },
        {
          title: '大小',
          dataIndex: '大小',
          width: 38,
          render: (value, row, index) => {
            return styleBigSmall(value);
          },
        },
        {
          title: '单双',
          dataIndex: '单双',
          width: 38,
          render: (value) => {
            return styleOddEven(value);
          },
        },
      ],
    },
    {
      title: '组选类型',
      children: [
        {
          title: '极值',
          dataIndex: '极值',
          width: 38,
          render: (value) => {
            return styleJiZhi(value);
          },
        },
        {
          title: '波色',
          dataIndex: '波色',
          width: 38,
          render: (value) => {
            return styleBoSe(value);
          },
        },
        {
          title: '形态',
          dataIndex: '形态',
          width: 38,
          render: (value) => {
            return styleShunZhi(value);
          },
        },
      ],
    },
  );

  return columns;
};

export default k3Header;

import React, { useState } from 'react';
import { toJS } from 'mobx';
import { inject, observer } from 'mobx-react';
import Filter from './Filter';
import FilterResult from './FilterResult';
import ModeFilter from './ModeFilter';

import withLocation from '@helpers/hoc/withLocation';
import isMobile from '@helpers/hoc/isMobile';

import { constPlayGroup } from '@const/game';
import { constLuZhuOptionBall, constLuZhuOptionType } from '@const/playRule';

import { openCodeToArray } from '@helpers/utils';

import Drawer from '@components/common/drawer';

import Reference from '@components/common/reference';

const { K3 } = constPlayGroup;

const constModeFilter = [
  {
    filter: 'totalMode',
    nameInChinese: '综合模式',
  },
  {
    filter: 'singleMode',
    nameInChinese: '单选模式',
  },
  {
    filter: 'doubleMode',
    nameInChinese: '两面模式',
  },
];

const LuZhuFenXi = ({ trendsStore, search, isMobile }) => {
  const { group } = search;

  const { singleGame } = trendsStore;

  let defaultBallArray = [];
  let defaultTypeArray = [];
  const [mode, setMode] = useState(constModeFilter[0]);
  const [bkMode, setBkMode] = useState(0);

  const [filterBall, setFilterBall] = useState(defaultBallArray);
  const [filterType, setFilterType] = useState(defaultTypeArray);

  const [openFilter, setOpenFilter] = useState(isMobile ? false : true);

  if (bkMode.filter !== mode.filter) {
    mode.filter === 'singleMode'
      ? defaultBallArray.push(constLuZhuOptionBall[group][0].value)
      : constLuZhuOptionBall[group].map((value, index) => {
          return defaultBallArray.push(index);
        });
    mode.filter === 'singleMode'
      ? constLuZhuOptionType[group].map((item) => {
          return defaultTypeArray.push(item.value);
        })
      : defaultTypeArray.push(constLuZhuOptionType[group][0].value);
    setBkMode(mode);
    setFilterBall(defaultBallArray);
    setFilterType(defaultTypeArray);
  }

  let openCodeArray;
  if (singleGame) {
    openCodeArray = openCodeToArray(toJS(singleGame));
  }

  let selectAllBtn;
  switch (group) {
    case K3:
      selectAllBtn = false;
      break;

    default:
      selectAllBtn = true;
      break;
  }

  let content = [
    'B&S - Big & Small',
    'O&E - Odd & Even',
    'B - Big',
    'S - Small',
    'E - Even',
    'O - Odd',
    'D - Dragon',
    'T - Tiger',
    'T - Tie',
  ];

  switch (group) {
    case K3:
      content = [
        'B&S - Big & Small',
        'O&E - Odd & Even',
        'B - Big',
        'S - Small',
        'E - Even',
        'O - Odd',
      ];
      break;

    default:
      selectAllBtn = true;
      break;
  }

  return (
    <>
      <ModeFilter
        mode={mode}
        setMode={setMode}
        modeFilter={constModeFilter}
        openFilter={openFilter}
        setOpenFilter={setOpenFilter}
      />
      {isMobile ? (
        <Drawer visible={openFilter} onClose={() => setOpenFilter(false)}>
          <Filter
            mode={mode}
            optionBall={constLuZhuOptionBall[group]}
            filterBall={filterBall}
            setFilterBall={setFilterBall}
            optionType={constLuZhuOptionType[group]}
            filterType={filterType}
            setFilterType={setFilterType}
            selectAllBtn={selectAllBtn}
            setOpenFilter={setOpenFilter}
          />
        </Drawer>
      ) : (
        <Filter
          mode={mode}
          optionBall={constLuZhuOptionBall[group]}
          filterBall={filterBall}
          setFilterBall={setFilterBall}
          optionType={constLuZhuOptionType[group]}
          filterType={filterType}
          setFilterType={setFilterType}
          selectAllBtn={selectAllBtn}
          setOpenFilter={setOpenFilter}
        />
      )}
      <Reference content={content} sticky={true} />
      <FilterResult
        openCodeArray={openCodeArray}
        filterBall={filterBall}
        filterType={filterType}
        group={group}
      />
    </>
  );
};

export default isMobile(
  withLocation(inject('trendsStore')(observer(LuZhuFenXi))),
);

import { arrayStringToArrayInt } from '@helpers/utils';

import {
  styleOddEven,
  styleBigSmall,
  headerClickedHandler,
  getNumberCode,
  getNumberBigSmall,
  getNumberOddEven,
  styleLunPan,
} from './style';

const threeDHeader = (
  singleGame,
  group,
  activeTab,
  setTab,
  subSubOption,
  isMobile,
  openDisplayType,
  setOpenDisplayType,
) => {
  let columns = [];
  let temp = [];

  if (isMobile) {
    columns.push(
      {
        title: '',
        className: 'no-line',
        children: [
          {
            title: '期数',
            dataIndex: '期数',
            width: 45,
            sorter: (a, b) => a.期数 - b.期数,
            defaultSortOrder: 'ascend',
            sortDirections: ['ascend', 'descend', 'ascend'],
          },
        ],
      },
      {
        title: '',
        className: 'no-line',
        children: [
          {
            title: '显示号码',
            className: openDisplayType
              ? 'tab active open tab1 d3 number'
              : 'tab active tab1 number',
            onHeaderCell: (column) => {
              return {
                onClick: () => {
                  setOpenDisplayType(!openDisplayType);
                },
              };
            },
            children: [
              {
                title: '显示大小',
                key: '显示大小',
                className: openDisplayType
                  ? 'tab open tab2 row2-extent'
                  : 'tab tab2 row2-extent',
                onHeaderCell: (column) => {
                  return {
                    onClick: () => {
                      setTab(column.key);
                      setOpenDisplayType(!openDisplayType);
                    },
                  };
                },
                children: [
                  {
                    title: '显示单双',
                    key: '显示单双',
                    dataIndex: '显示号码',
                    className: openDisplayType
                      ? 'tab open tab3 pk10 row2-extent'
                      : 'tab tab3 pk10 row2-extent',
                    onHeaderCell: (column) => {
                      return {
                        onClick: () => {
                          setTab(column.key);
                          setOpenDisplayType(!openDisplayType);
                        },
                      };
                    },
                    render: (value, row, index) => {
                      let pairStyle = [];

                      let nextOpenCode = {};
                      if (index < singleGame.length - 1) {
                        nextOpenCode = singleGame[index + 1];
                      } else {
                        nextOpenCode = singleGame[index - 1];
                      }

                      nextOpenCode =
                        nextOpenCode &&
                        arrayStringToArrayInt(nextOpenCode.openCode);
                      if (nextOpenCode) {
                        for (
                          let indexDigit = 0;
                          indexDigit < nextOpenCode.length;
                          indexDigit++
                        ) {
                          if (value[indexDigit] === nextOpenCode[indexDigit]) {
                            pairStyle.push(true);
                            temp[indexDigit] = nextOpenCode[indexDigit];
                          } else if (value[indexDigit] === temp[indexDigit]) {
                            pairStyle.push(true);
                            temp[indexDigit] = false;
                          } else {
                            pairStyle.push(false);
                            temp[indexDigit] = false;
                          }
                        }
                      }

                      const obj = {
                        children:
                          activeTab === '显示号码'
                            ? getNumberCode(
                                value,
                                subSubOption,
                                pairStyle,
                                group,
                              )
                            : activeTab === '显示大小'
                            ? getNumberBigSmall(
                                value,
                                subSubOption,
                                pairStyle,
                                group,
                              )
                            : getNumberOddEven(
                                value,
                                subSubOption,
                                pairStyle,
                                group,
                              ),
                        // props: { colSpan:3 },
                      };

                      return obj;
                    },
                  },
                ],
              },
            ],
          },
        ],
      },
      {
        title: '佰拾',
        children: [
          {
            title: '总数',
            dataIndex: '佰拾和Total',
            width: 25,
            className: 'vertical',
          },
          {
            title: '单双',
            dataIndex: '佰拾和OddEven',
            width: 25,
            className: 'vertical',
            render: (value, row, index) => {
              return styleOddEven(value);
            },
          },
        ],
      },
      {
        title: '拾个',
        children: [
          {
            title: '总数',
            dataIndex: '拾个和Total',
            width: 25,
            className: 'vertical',
          },
          {
            title: '单双',
            dataIndex: '拾个和OddEven',
            width: 25,
            className: 'vertical',
            render: (value, row, index) => {
              return styleOddEven(value);
            },
          },
        ],
      },
      {
        title: '总和',
        children: [
          {
            title: '总数',
            dataIndex: '总和Total',
            width: 25,
            className: 'vertical',
          },
          {
            title: '单双',
            dataIndex: '总和OddEven',
            width: 25,
            className: 'vertical',
            render: (value, row, index) => {
              return styleOddEven(value);
            },
          },
        ],
      },
      {
        title: '轮盘',
        children: [
          {
            title: '总数',
            dataIndex: '轮盘',
            width: 25,
            className: 'vertical',
            render: (value, row, index) => {
              return styleLunPan(value);
            },
          },
          {
            title: '大小',
            dataIndex: '轮盘BigSmall',
            width: 25,
            className: 'vertical',
            render: (value, row, index) => {
              return styleBigSmall(value);
            },
          },
          {
            title: '单双',
            dataIndex: '轮盘OddEven',
            width: 25,
            className: 'vertical',
            render: (value, row, index) => {
              return styleOddEven(value);
            },
          },
        ],
      },
    );

    switch (activeTab) {
      case '显示单双':
        columns[1].children[0].title = '显示单双';
        columns[1].children[0].children[0].title = '显示号码';
        columns[1].children[0].children[0].key = '显示号码';
        columns[1].children[0].children[0].children[0].title = '显示大小';
        columns[1].children[0].children[0].children[0].key = '显示大小';
        columns[1].children[0].children[0].children[0].dataIndex = '显示号码';
        break;
      case '显示大小':
        columns[1].children[0].title = '显示大小';
        columns[1].children[0].children[0].title = '显示号码';
        columns[1].children[0].children[0].key = '显示号码';
        columns[1].children[0].children[0].children[0].title = '显示单双';
        columns[1].children[0].children[0].children[0].key = '显示单双';
        columns[1].children[0].children[0].children[0].dataIndex = '显示号码';
        break;
      default:
        break;
    }
  } else {
    columns.push(
      {
        title: '时间',
        dataIndex: '时间',
        width: 120,
      },
      {
        title: '期数',
        dataIndex: '期数',
        width: 100,
        sorter: (a, b) => a.期数 - b.期数,
        defaultSortOrder: 'ascend',
        sortDirections: ['ascend', 'descend', 'ascend'],
      },
      {
        title: '显示号码',
        dataIndex: '显示号码',
        key: '显示号码',
        className: activeTab === '显示号码' ? 'tab active' : 'tab',
        width: 100,
        onHeaderCell: (column) => {
          return {
            onClick: () =>
              headerClickedHandler('JiShiKaiJiang', column, setTab),
          };
        },
        render: (value, row, index) => {
          let pairStyle = [];

          let nextOpenCode = {};
          if (index < singleGame.length - 1) {
            nextOpenCode = singleGame[index + 1];
          } else {
            nextOpenCode = singleGame[index - 1];
          }

          nextOpenCode =
            nextOpenCode && arrayStringToArrayInt(nextOpenCode.openCode);
          if (nextOpenCode) {
            for (
              let indexDigit = 0;
              indexDigit < nextOpenCode.length;
              indexDigit++
            ) {
              if (value[indexDigit] === nextOpenCode[indexDigit]) {
                pairStyle.push(true);
                temp[indexDigit] = nextOpenCode[indexDigit];
              } else if (value[indexDigit] === temp[indexDigit]) {
                pairStyle.push(true);
                temp[indexDigit] = false;
              } else {
                pairStyle.push(false);
                temp[indexDigit] = false;
              }
            }
          }

          const obj = {
            children:
              activeTab === '显示号码'
                ? getNumberCode(value, subSubOption, pairStyle, group)
                : activeTab === '显示大小'
                ? getNumberBigSmall(value, subSubOption, pairStyle, group)
                : getNumberOddEven(value, subSubOption, pairStyle, group),
            props: { colSpan: 3 },
          };

          return obj;
        },
      },
      {
        title: '显示大小',
        dataIndex: '显示大小',
        key: '显示大小',
        className: activeTab === '显示大小' ? 'tab active' : 'tab',
        width: 100,
        onHeaderCell: (column) => {
          return {
            onClick: () =>
              headerClickedHandler('JiShiKaiJiang', column, setTab),
          };
        },
        render: (value, row, index) => {
          const obj = {
            children: value,
            props: { colSpan: 0 },
          };

          return obj;
        },
      },
      {
        title: '显示单双',
        dataIndex: '显示单双',
        key: '显示单双',
        className: activeTab === '显示单双' ? 'tab active' : 'tab',
        width: 100,
        onHeaderCell: (column) => {
          return {
            onClick: () =>
              headerClickedHandler('JiShiKaiJiang', column, setTab),
          };
        },
        render: (value, row, index) => {
          const obj = {
            children: value,
            props: { colSpan: 0 },
          };

          return obj;
        },
      },
      {
        title: '佰拾和',
        dataIndex: '佰拾和Total',
        width: 40,
        colSpan: 2,
      },
      {
        dataIndex: '佰拾和OddEven',
        width: 40,
        colSpan: 0,
        render: (value, row, index) => {
          return styleOddEven(value);
        },
      },
      {
        title: '拾个和',
        dataIndex: '拾个和Total',
        width: 40,
        colSpan: 2,
      },
      {
        dataIndex: '拾个和OddEven',
        width: 40,
        colSpan: 0,
        render: (value, row, index) => {
          return styleOddEven(value);
        },
      },
      {
        title: '总和',
        dataIndex: '总和Total',
        width: 40,
        colSpan: 2,
      },
      {
        dataIndex: '总和OddEven',
        width: 40,
        colSpan: 0,
        render: (value, row, index) => {
          return styleOddEven(value);
        },
      },
      {
        title: '轮盘',
        dataIndex: '轮盘',
        width: 40,
        colSpan: 3,
        render: (value, row, index) => {
          return styleLunPan(value);
        },
      },
      {
        dataIndex: '轮盘BigSmall',
        width: 40,
        colSpan: 0,
        render: (value, row, index) => {
          return styleBigSmall(value);
        },
      },
      {
        dataIndex: '轮盘OddEven',
        width: 40,
        colSpan: 0,
        render: (value, row, index) => {
          return styleOddEven(value);
        },
      },
    );
  }

  return columns;
};

export default threeDHeader;

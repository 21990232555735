import React from 'react';
import { toJS } from 'mobx';
import { inject, observer } from 'mobx-react';

import isMobile from '@helpers/hoc/isMobile';
import withLocation from '@helpers/hoc/withLocation';
import { openCodeToArray } from '@helpers/utils';
import { getSelectAllOption } from '@helpers/utils';

import { constPlayGroup } from '@const/game';
import { constLuZhuName, constLuZhuOptionBall } from '@const/playRule';

import FilterResult from './FilterResult';

import PlayRuleTitle from '@components/common/playRuleTitle';

import Reference from '@components/common/reference';

const { PK10, XY10 } = constPlayGroup;

const { bigSmall, oddEven } = constLuZhuName;

const ZongHeLuZhu = ({ trendsStore, search, isMobile }) => {
  const { group } = search;

  const { singleGame } = trendsStore;

  const optionType = [bigSmall, oddEven];
  const filterBall =
    constLuZhuOptionBall[group][constLuZhuOptionBall[group].length - 1];
  const optionBall = constLuZhuOptionBall[group];
  const selectAllValueType = getSelectAllOption(optionType);

  let openCodeArray;
  if (singleGame) {
    openCodeArray = openCodeToArray(toJS(singleGame));
  }

  let title;
  switch (group) {
    case PK10:
    case XY10:
      title = '冠亚和路珠';
      break;

    default:
      title = '总和路珠';
      break;
  }

  const content = [
    'B&S - Big & Small',
    'O&E - Odd & Even',
    'B - Big',
    'S - Small',
    'E - Even',
    'O - Odd',
  ];

  return (
    <>
      {!isMobile && <PlayRuleTitle title={title} />}
      <Reference content={content} sticky={true} top={44} />
      <FilterResult
        openCodeArray={openCodeArray}
        filterBall={[filterBall.value]}
        filterType={selectAllValueType}
        optionBall={optionBall}
        group={group}
      />
    </>
  );
};

export default isMobile(
  withLocation(inject('trendsStore')(observer(ZongHeLuZhu))),
);

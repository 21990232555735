import React, { useEffect } from 'react';
import styled from 'styled-components';
import { Row, Col, Select } from 'antd';
import { toJS } from 'mobx';
import { inject, observer } from 'mobx-react';

import SEO from '@components/common/seo';
import LcwButton from '@components/common/button';
import { Images } from '@components/common/images';
import { TitleText } from '@components/common/text';
import ChangLongTable from '@components/views/changLong/changLongTable';

import { color } from '@helpers/const';
import withLayout from '@helpers/hoc/withLayout';
import withPaddingWidth from '@helpers/hoc/withPaddingWidth';
import withLocation from '@helpers/hoc/withLocation';

import { constTrendChartGameByGroup } from '@const/config';

import '@styles/components/selection.less';

const { Option } = Select;

function handleChangeSelection(props, changLongStore) {
  changLongStore.setLimiter(props);
}

const PeriodOption = ({ changLongStore }) => {
  let options = [];
  for (let i = 2; i < 15; i++) {
    options.push(
      <Option key={i} value={i}>
        {i}
      </Option>,
    );
  }
  return (
    <PeriodSelection
      defaultValue="2"
      style={{ width: 50 }}
      size="small"
      listHeight={600}
      dropdownClassName="periodOption"
      onChange={(props) => handleChangeSelection(props, changLongStore)}>
      {options}
    </PeriodSelection>
  );
};

const ChangLongPage = ({ rootStore, changLongStore, search, location }) => {
  const { allGames } = rootStore;

  const { filterGameList } = changLongStore;

  useEffect(() => {
    const { gameUniqueId, gameNameInChinese } = search;

    if (location.search) {
      if (changLongStore.dragonList.length) {
        changLongStore.clearAll();
      }
      changLongStore.setFilterGameList(gameUniqueId, gameNameInChinese);
      location.search = '';
      window.history.replaceState({}, document.title, location.pathname);
    } else if (filterGameList.length === 0) {
      changLongStore.setFilterGameList('HF_FFSSC', '分分时时彩');
    }
  }, [changLongStore, location, search, filterGameList.length]);

  return (
    <>
      <SEO title="Chang Long" />
      <ChangLongWrapper>
        <Row gutter={{ xs: 0, md: 24 }}>
          <Col>
            <FilterTitle justify="center">
              <Clock>
                <img src={Images.alarm} alt="长龙提醒" />
              </Clock>
              <TitleText text="长龙提醒" fontSize={20} />
            </FilterTitle>
            <FilterWrapper>
              <Row justify="space-between" gutter={[0, 10]} align="middle">
                <Col style={{ marginRight: '11px' }}>
                  <TitleText text="选择彩种" fontSize={16} />
                </Col>
                <Col style={{ marginRight: '6px' }}>
                  <Row>
                    <Col style={{ marginRight: '6px' }}>
                      <LcwButton
                        text="全选"
                        btnStyle={3}
                        activeOnClick={() => changLongStore.selectAll(allGames)}
                      />
                    </Col>
                    <Col>
                      <LcwButton
                        text="取消"
                        btnStyle={3}
                        activeOnClick={() => changLongStore.clearAll()}
                      />
                    </Col>
                  </Row>
                </Col>
              </Row>
              <div>
                {constTrendChartGameByGroup.map((item, index) => {
                  const { group, groupInChineseName } = item;
                  return (
                    <Row key={index} gutter={[0, 10]}>
                      <GroupTitle span={24}>
                        <TitleText text={groupInChineseName} fontSize={14} />
                      </GroupTitle>
                      {typeof allGames === 'object'
                        ? toJS(allGames).map(
                            (
                              { playGroup, gameUniqueId, gameNameInChinese },
                              indexGame,
                            ) => {
                              const comp = (
                                <Col span={24} key={indexGame}>
                                  <LcwButton
                                    text={gameNameInChinese}
                                    btnStyle={4}
                                    active={filterGameList.find(
                                      (list) =>
                                        list.gameUniqueId === gameUniqueId,
                                    )}
                                    activeOnClick={() =>
                                      filterGameList.find(
                                        (list) =>
                                          list.gameUniqueId === gameUniqueId,
                                      )
                                        ? changLongStore.removeFilterGameList(
                                            gameUniqueId,
                                          )
                                        : changLongStore.setFilterGameList(
                                            gameUniqueId,
                                            gameNameInChinese,
                                          )
                                    }
                                    tick={true}
                                  />
                                </Col>
                              );
                              if (group === playGroup) {
                                return comp;
                              } else if (
                                group === 'OTHER' &&
                                (playGroup === '3D' ||
                                  playGroup === 'XPK' ||
                                  playGroup === 'QXC')
                              ) {
                                return comp;
                              } else {
                                return '';
                              }
                            },
                          )
                        : ''}
                    </Row>
                  );
                })}
              </div>
            </FilterWrapper>
          </Col>
          <Col>
            <RowTitle align="middle">
              <Col xs={24} md={4}>
                <LcwButton text="连续开出" btnStyle={5} active={true} />
              </Col>
              <Col xs={24} md={4}>
                {/* <LcwButton text="连续未开" btnStyle={5}/> */}
              </Col>
              <Col className="selection-col" xs={24} md={7} offset={6}>
                <span>只显示 </span>
                <PeriodOption changLongStore={changLongStore} />
                <span> 期及以上的长龙</span>
              </Col>
              {/* <Col span={3}>
                            <Checkbox>声音报警</Checkbox>
                        </Col> */}
            </RowTitle>
            <Row className="tableWrapper">
              <ChangLongTable
                data={toJS(changLongStore.dragonList)}
                limiter={toJS(changLongStore.limiter)}
              />
            </Row>
          </Col>
        </Row>
      </ChangLongWrapper>
    </>
  );
};

export default withLocation(
  withLayout(
    withPaddingWidth(
      inject('rootStore', 'changLongStore')(observer(ChangLongPage)),
    ),
  ),
);

const ChangLongWrapper = styled.div`
  background-color: ${color.white};
  padding: 30px 28px 45px;

  @media only screen and (max-width: 825px) {
    padding: 20px 5px 35px;
  }

  .tableWrapper {
    th {
      background-color: ${color.white};
    }

    @media only screen and (max-width: 825px) {
      display: block;

      th {
        background-color: ${color.orange};
      }
    }
  }

  > .ant-row {
    > .ant-col {
      &:first-child {
        width: 259px;
      }

      &:nth-child(2) {
        width: calc(100% - 259px);
      }

      @media only screen and (max-width: 825px) {
        &:first-child {
          width: 100%;
        }

        &:nth-child(2) {
          width: 100%;
        }
      }
    }
  }
`;

const Clock = styled.span`
  width: 20px;
  margin-right: 6px;
  margin-top: 2px;
`;

const FilterTitle = styled(Row)`
  background-color: ${color.bodyGrey};
  padding: 10px 0 9px;
`;

const FilterWrapper = styled.div`
  padding: 20px 16px 0;
  border: 1px solid ${color.bodyGrey};
  border-top: none;
  max-height: 797px;
  overflow: auto;

  ::-webkit-scrollbar {
    width: 0;
  }

  scrollbar-width: none;

  @media only screen and (max-width: 825px) {
    margin-bottom: 30px;
    max-height: 300px;
  }
`;

const GroupTitle = styled(Col)`
  min-height: 20px;
  margin: 8px auto 7px;

  &:before {
    content: '';
    position: absolute;
    width: 100%;
    height: 1px;
    background-color: ${color.bodyGrey};
    top: 50%;
    left: 0;
    transform: translateY(-50%);
  }

  p {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: ${color.white};
    padding: 0 6px;
  }
`;

const RowTitle = styled(Row)`
  background-color: ${color.bodyGrey};
  margin-bottom: 19px;

  @media only screen and (max-width: 825px) {
    background-color: ${color.white};

    .selection-col {
      margin-top: 20px;
      margin-left: 0;
    }
  }
`;

const PeriodSelection = styled(Select)`
  margin: 0 6px;

  > .ant-select-selector {
    border-color: ${color.borderGrey} !important;
    box-shadow: none !important;
  }

  .ant-select-arrow {
    .anticon {
      display: none;
    }

    :after {
      content: '';
      position: absolute;
      top: 3px;
      right: 1px;
      width: 9px;
      height: 5px;
      background: url(${Images.drop_down_arrow}) center/cover;
      transition: all 0.2s linear;
    }
  }

  &.ant-select-open {
    .ant-select-arrow {
      :after {
        top: 2px;
        transform: rotate(180deg);
      }
    }
  }
`;

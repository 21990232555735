import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import ModeFilter from './ModeFilter';
import Filter from './Filter';
import MainTable from './MainTable';

import { color } from '@helpers/const';

import withLocation from '@helpers/hoc/withLocation';

import Reference from '@components/common/reference';

const KaiJiangJilu = ({ location }) => {
  const [filterMode, setFilterMode] = useState('zodiac');
  const [openFilter, setOpenFilter] = useState([false, false, false]);
  const [isTrendsPage, setIsTrendsPage] = useState(false);

  useEffect(() => {
    const { pathname } = location;
    if (pathname === '/app/trends') {
      setIsTrendsPage(true);
    }
  }, [openFilter, location]);

  const content = [
    'Dr. - Draw Number',
    'Sp.No. - Special Number',
    'O&E - Odd & Even',
    'B&S - Big & Small',
    'SCW - Seven Color Waves',
    'Tail B&S - Tail Big & Small',
    'O - Odd',
    'E - Even',
    'S - Small',
    'B - Big',
    'T - Tie',
    'G - Green',
    'R - Red',
    'B - Blue',
  ];

  return (
    <>
      <ModeFilter />
      <MainWrapper>
        <MainInnerWrapper>
          <Filter
            filterMode={filterMode}
            setFilterMode={setFilterMode}
            openFilter={openFilter}
            setOpenFilter={setOpenFilter}
            isTrendsPage={isTrendsPage}
          />
          <Reference content={content} />
          <MainTable filterMode={filterMode} isTrendsPage={isTrendsPage} />
        </MainInnerWrapper>
      </MainWrapper>
    </>
  );
};

export default withLocation(KaiJiangJilu);

const MainWrapper = styled.div`
  padding: 0 34px 48px;
  background-color: ${color.white};

  @media only screen and (max-width: 576px) {
    padding: 0;
  }
`;

const MainInnerWrapper = styled.div`
  border: 1px solid ${color.borderGrey};

  @media only screen and (max-width: 576px) {
    border: none;
  }
`;

import React from 'react';
import styled from 'styled-components';
import { Row, Col } from 'antd';
import { inject, observer } from 'mobx-react';
import LcwButton from './button';
import { color } from '@helpers/const';
import { constTrendGroupList } from '@const/config';

import { generateQueryString, parseQueryString } from '@helpers/utils';
import withLocation from '@helpers/hoc/withLocation';

import { toJS } from 'mobx';

export const GroupNav = ({ activeGroup, actionURL, removeGroup = [] }) => {
  return (
    <NavWrapper>
      <Row>
        {constTrendGroupList.map(
          ({ text, group, game, searchPlayGroup }, index) => {
            if (!removeGroup.includes(group)) {
              return (
                <Col key={index}>
                  <LcwButton
                    text={text}
                    btnStyle={6}
                    active={group === activeGroup ? true : false}
                    url={actionURL(group, game, searchPlayGroup)}
                  />
                </Col>
              );
            } else {
              return '';
            }
          },
        )}
      </Row>
    </NavWrapper>
  );
};

const NavWrapper = styled.div`
  .ant-row {
    background-color: ${color.white};
  }

  @media only screen and (max-width: 576px) {
    overflow-x: auto;
    padding-bottom: 10px;

    &::-webkit-scrollbar {
      width: 0px;
      height: 0px;
    }
    -webkit-overflow-scrolling: touch;
    overflow-scrolling: touch;
    scrollbar-width: none;

    .ant-row {
      flex-wrap: nowrap;
    }
  }
`;

export const GameNav = ({ data, activeGame, actionURL }) => {
  return (
    <NavMenuWrapper>
      <Row>
        {data.map(({ gameUniqueId, gameNameInChinese, playGroup }, index) => {
          return (
            <Col key={index}>
              <LcwButton
                text={gameNameInChinese}
                url={actionURL(gameUniqueId, playGroup)}
                btnStyle={7}
                active={gameUniqueId === activeGame ? true : false}
              />
            </Col>
          );
        })}
      </Row>
    </NavMenuWrapper>
  );
};

const NavMenuWrapper = styled.div`
  background-color: ${color.white};
  border-bottom: 1px solid ${color.borderGrey};
  over-flow: hidden;

  &::-webkit-scrollbar {
    width: 0px;
    height: 0px;
  }

  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
  overflow-scrolling: touch;
  scrollbar-width: none;

  .ant-row {
    flex-wrap: nowrap;
    overflow-y: auto;

    &::-webkit-scrollbar {
      width: 8px;
      height: 8px;
    }

    /* Track */
    &::-webkit-scrollbar-track {
      background: #f1f1f1;
    }

    /* Handle */
    &::-webkit-scrollbar-thumb {
      background: #cdcdcd;
    }

    /* Handle on hover */
    &::-webkit-scrollbar-thumb:hover {
      background: #797979;
    }

    &::-webkit-scrollbar-button {
      width: 0;
      height: 0;
      display: none;
    }

    scrollbar-width: thin;
  }
`;

const GameGroupNavSetComp = ({
  rootStore,
  search,
  location,
  noLowGame = false,
  removeGame = [],
  ...props
}) => {
  const { allGames } = rootStore;

  const { group, searchGame } = search;

  const { pathname } = location;

  const listGame = [];
  if (typeof toJS(allGames) === 'object') {
    for (let index = 0; index < allGames.length; index++) {
      const playGroup = allGames[index].playGroup;
      const gameUniqueId = allGames[index].gameUniqueId;
      const gameNameInChinese = allGames[index].gameNameInChinese;

      if (
        !(noLowGame && !gameUniqueId.startsWith('HF_')) &&
        !removeGame.includes(gameUniqueId)
      ) {
        if (
          group === 'OTHER' &&
          (playGroup === '3D' || playGroup === 'XPK' || playGroup === 'QXC')
        ) {
          listGame.push({
            gameUniqueId,
            gameNameInChinese,
            playGroup,
          });
        } else if (group === playGroup) {
          listGame.push({
            gameUniqueId,
            gameNameInChinese,
            playGroup,
          });
        }
      }
    }
  }

  return (
    <div>
      <div style={{ marginBottom: '19px' }}>
        <GroupNav
          activeGroup={group}
          actionURL={(group, game, playGroup) =>
            `${pathname}?${generateQueryString({
              ...parseQueryString(location.search),
              searchGame: game,
              group: group,
              searchPlayGroup: playGroup,
            })}`
          }
          {...props}
        />
      </div>
      <GameNav
        data={listGame}
        activeGame={searchGame}
        actionURL={(code, playGroup) =>
          `${pathname}?${generateQueryString({
            ...parseQueryString(location.search),
            searchGame: code,
            searchPlayGroup: playGroup,
          })}`
        }
      />
    </div>
  );
};

export const GameGroupNavSet = withLocation(
  inject('rootStore')(observer(GameGroupNavSetComp)),
);

import { arrayStringToArrayInt } from '@helpers/utils';

import {
  styleOddEven,
  styleBigSmall,
  styleDragonTiger,
  headerClickedHandler,
  getNumberCode,
  getNumberBigSmall,
  getNumberOddEven,
} from './style';

const pk10Header = (
  singleGame,
  group,
  activeTab,
  setTab,
  subSubOption,
  isMobile,
  openDisplayType,
  setOpenDisplayType,
  activePattern,
  setPattern,
  openPattern,
  setOpenPattern,
) => {
  let columns = [];
  let temp = [];

  if (isMobile) {
    columns = [
      {
        title: '',
        className: 'no-line',
        children: [
          {
            title: '',
            className: 'no-line tab hidden',
            children: [
              {
                title: '期数',
                dataIndex: '期数',
                width: 45,
                sorter: (a, b) => a.期数 - b.期数,
                defaultSortOrder: 'ascend',
                sortDirections: ['ascend', 'descend', 'ascend'],
              },
            ],
          },
        ],
      },
      {
        title: '',
        className: 'no-line',
        children: [
          {
            title: '',
            className: 'no-line tab hidden',
            children: [
              {
                title: '显示号码',
                dataIndex: '显示号码',
                key: '显示号码',
                className: openDisplayType
                  ? 'tab active open tab1 number row2'
                  : 'tab active tab1 number row2',
                onHeaderCell: (column) => {
                  return {
                    onClick: () => {
                      setOpenDisplayType(!openDisplayType);
                    },
                  };
                },
                children: [
                  {
                    title: '显示大小',
                    dataIndex: '显示大小',
                    key: '显示大小',
                    className: openDisplayType
                      ? 'tab open tab2 row2'
                      : 'tab tab2 row2',
                    onHeaderCell: (column) => {
                      return {
                        onClick: () => {
                          setTab(column.key);
                          setOpenDisplayType(!openDisplayType);
                        },
                      };
                    },
                    children: [
                      {
                        title: '显示单双',
                        dataIndex: '显示单双',
                        key: '显示单双',
                        className: openDisplayType
                          ? 'tab open tab3 pk10 row2'
                          : 'tab tab3 pk10 row2',
                        width: 200,
                        onHeaderCell: (column) => {
                          return {
                            onClick: () => {
                              setTab(column.key);
                              setOpenDisplayType(!openDisplayType);
                            },
                          };
                        },
                        render: (value, row, index) => {
                          let pairStyle = [];

                          let nextOpenCode = {};
                          if (index < singleGame.length - 1) {
                            nextOpenCode = singleGame[index + 1];
                          } else {
                            nextOpenCode = singleGame[index - 1];
                          }

                          nextOpenCode =
                            nextOpenCode &&
                            arrayStringToArrayInt(nextOpenCode.openCode);
                          if (nextOpenCode) {
                            for (
                              let indexDigit = 0;
                              indexDigit < nextOpenCode.length;
                              indexDigit++
                            ) {
                              if (
                                value[indexDigit] === nextOpenCode[indexDigit]
                              ) {
                                pairStyle.push(true);
                                temp[indexDigit] = nextOpenCode[indexDigit];
                              } else if (
                                value[indexDigit] === temp[indexDigit]
                              ) {
                                pairStyle.push(true);
                                temp[indexDigit] = false;
                              } else {
                                pairStyle.push(false);
                                temp[indexDigit] = false;
                              }
                            }
                          }

                          const obj = {
                            children:
                              activeTab === '显示号码'
                                ? getNumberCode(
                                    value,
                                    subSubOption,
                                    pairStyle,
                                    group,
                                  )
                                : activeTab === '显示大小'
                                ? getNumberBigSmall(
                                    value,
                                    subSubOption,
                                    pairStyle,
                                    group,
                                  )
                                : getNumberOddEven(
                                    value,
                                    subSubOption,
                                    pairStyle,
                                    group,
                                  ),
                            // props: { colSpan:3 },
                          };

                          return obj;
                        },
                      },
                    ],
                  },
                ],
              },
            ],
          },
        ],
      },
    ];
    switch (activeTab) {
      case '显示单双':
        columns[1].children[0].children[0].title = '显示单双';
        columns[1].children[0].children[0].dataIndex = '显示单双';
        columns[1].children[0].children[0].key = '显示单双';
        columns[1].children[0].children[0].children[0].title = '显示号码';
        columns[1].children[0].children[0].children[0].dataIndex = '显示号码';
        columns[1].children[0].children[0].children[0].key = '显示号码';
        columns[1].children[0].children[0].children[0].children[0].title =
          '显示大小';
        columns[1].children[0].children[0].children[0].children[0].dataIndex =
          '显示大小';
        columns[1].children[0].children[0].children[0].children[0].key =
          '显示大小';
        break;
      case '显示大小':
        columns[1].children[0].children[0].title = '显示大小';
        columns[1].children[0].children[0].dataIndex = '显示大小';
        columns[1].children[0].children[0].key = '显示大小';
        columns[1].children[0].children[0].children[0].title = '显示号码';
        columns[1].children[0].children[0].children[0].dataIndex = '显示号码';
        columns[1].children[0].children[0].children[0].key = '显示号码';
        columns[1].children[0].children[0].children[0].children[0].title =
          '显示单双';
        columns[1].children[0].children[0].children[0].children[0].dataIndex =
          '显示单双';
        columns[1].children[0].children[0].children[0].children[0].key =
          '显示单双';
        break;
      default:
        break;
    }
    if (activePattern === '冠亚和') {
      columns.push({
        title: '冠亚',
        className: openPattern
          ? 'tab active open tab1 pattern PK10'
          : 'tab active tab1 pattern PK10',
        onHeaderCell: (column) => {
          return {
            onClick: () => {
              setPattern('冠亚和');
              setOpenPattern(!openPattern);
            },
          };
        },
        children: [
          {
            title: '1-5龙虎',
            className: openPattern
              ? 'tab open tab2 pattern'
              : 'tab tab2 pattern',
            onHeaderCell: (column) => {
              return {
                onClick: () => {
                  setPattern('1-5龙虎');
                  setOpenPattern(!openPattern);
                },
              };
            },
            children: [
              {
                title: '和值',
                dataIndex: '冠亚和',
                width: 36,
              },
              {
                title: '单双',
                dataIndex: '冠亚和OddEven',
                width: 36,
                render: (value) => {
                  return styleOddEven(value);
                },
              },
              {
                title: '大小',
                dataIndex: '冠亚和BigSmall',
                width: 36,
                render: (value) => {
                  return styleBigSmall(value);
                },
              },
            ],
          },
        ],
      });
    } else {
      columns.push({
        title: '1-5龙虎',
        className: openPattern
          ? 'tab active open tab1 pattern PK10'
          : 'tab active tab1 pattern PK10',
        onHeaderCell: (column) => {
          return {
            onClick: () => {
              setPattern('1-5龙虎');
              setOpenPattern(!openPattern);
            },
          };
        },
        children: [
          {
            title: '冠亚',
            dataIndex: '龙虎',
            key: '龙虎',
            className: openPattern
              ? 'tab open tab2 pattern'
              : 'tab tab2 pattern',
            onHeaderCell: (column) => {
              return {
                onClick: () => {
                  setPattern('冠亚和');
                  setOpenPattern(!openPattern);
                },
              };
            },
            render: (value) => {
              return styleDragonTiger(value);
            },
            children: [
              {
                title: '龙虎',
                colSpan: 5,
                dataIndex: '龙虎',
                className: 'fullTransl',
                width: 27,
                render: (value) => {
                  return styleDragonTiger(value);
                },
              },
              {
                title: '1-5龙虎',
                dataIndex: '龙虎2',
                colSpan: 0,
                width: 27,
                render: (value) => {
                  return styleDragonTiger(value);
                },
              },
              {
                title: '1-5龙虎',
                dataIndex: '龙虎3',
                colSpan: 0,
                width: 27,
                render: (value) => {
                  return styleDragonTiger(value);
                },
              },
              {
                title: '1-5龙虎',
                dataIndex: '龙虎4',
                colSpan: 0,
                width: 27,
                render: (value) => {
                  return styleDragonTiger(value);
                },
              },
              {
                title: '1-5龙虎',
                dataIndex: '龙虎5',
                colSpan: 0,
                width: 27,
                render: (value) => {
                  return styleDragonTiger(value);
                },
              },
            ],
          },
        ],
      });
    }
  } else {
    columns = [
      {
        title: '时间',
        dataIndex: '时间',
        width: 160,
      },
      {
        title: '期数',
        dataIndex: '期数',
        width: 140,
        sorter: (a, b) => a.期数 - b.期数,
        defaultSortOrder: 'ascend',
        sortDirections: ['ascend', 'descend', 'ascend'],
      },
      {
        title: '显示号码',
        dataIndex: '显示号码',
        key: '显示号码',
        className: activeTab === '显示号码' ? 'tab active' : 'tab',
        width: 149,
        onHeaderCell: (column) => {
          return {
            onClick: () =>
              headerClickedHandler('JiShiKaiJiang', column, setTab),
          };
        },
        render: (value, row, index) => {
          let pairStyle = [];

          let nextOpenCode = {};
          if (index < singleGame.length - 1) {
            nextOpenCode = singleGame[index + 1];
          } else {
            nextOpenCode = singleGame[index - 1];
          }

          nextOpenCode =
            nextOpenCode && arrayStringToArrayInt(nextOpenCode.openCode);
          if (nextOpenCode) {
            for (
              let indexDigit = 0;
              indexDigit < nextOpenCode.length;
              indexDigit++
            ) {
              if (value[indexDigit] === nextOpenCode[indexDigit]) {
                pairStyle.push(true);
                temp[indexDigit] = nextOpenCode[indexDigit];
              } else if (value[indexDigit] === temp[indexDigit]) {
                pairStyle.push(true);
                temp[indexDigit] = false;
              } else {
                pairStyle.push(false);
                temp[indexDigit] = false;
              }
            }
          }

          const obj = {
            children:
              activeTab === '显示号码'
                ? getNumberCode(value, subSubOption, pairStyle, group)
                : activeTab === '显示大小'
                ? getNumberBigSmall(value, subSubOption, pairStyle, group)
                : getNumberOddEven(value, subSubOption, pairStyle, group),
            props: { colSpan: 3 },
          };

          return obj;
        },
      },
      {
        title: '显示大小',
        dataIndex: '显示大小',
        key: '显示大小',
        className: activeTab === '显示大小' ? 'tab active' : 'tab',
        width: 149,
        onHeaderCell: (column) => {
          return {
            onClick: () =>
              headerClickedHandler('JiShiKaiJiang', column, setTab),
          };
        },
        render: (value, row, index) => {
          const obj = {
            children: value,
            props: { colSpan: 0 },
          };

          return obj;
        },
      },
      {
        title: '显示单双',
        dataIndex: '显示单双',
        key: '显示单双',
        className: activeTab === '显示单双' ? 'tab active' : 'tab',
        width: 149,
        onHeaderCell: (column) => {
          return {
            onClick: () =>
              headerClickedHandler('JiShiKaiJiang', column, setTab),
          };
        },
        render: (value, row, index) => {
          const obj = {
            children: value,
            props: { colSpan: 0 },
          };

          return obj;
        },
      },
      {
        title: '冠亚和',
        dataIndex: '冠亚和',
        colSpan: 3,
        className: 'total',
        width: 50,
      },
      {
        title: '冠亚和OddEven',
        dataIndex: '冠亚和OddEven',
        colSpan: 0,
        width: 50,
        render: (value) => {
          return styleOddEven(value);
        },
      },
      {
        title: '冠亚和BigSmall',
        dataIndex: '冠亚和BigSmall',
        colSpan: 0,
        width: 50,
        render: (value) => {
          return styleBigSmall(value);
        },
      },
      {
        title: '1-5龙虎',
        dataIndex: '龙虎',
        colSpan: 5,
        width: 50,
        render: (value) => {
          return styleDragonTiger(value);
        },
      },
      {
        title: '1-5龙虎',
        dataIndex: '龙虎2',
        colSpan: 0,
        width: 50,
        render: (value) => {
          return styleDragonTiger(value);
        },
      },
      {
        title: '1-5龙虎',
        dataIndex: '龙虎3',
        colSpan: 0,
        width: 50,
        render: (value) => {
          return styleDragonTiger(value);
        },
      },
      {
        title: '1-5龙虎',
        dataIndex: '龙虎4',
        colSpan: 0,
        width: 50,
        render: (value) => {
          return styleDragonTiger(value);
        },
      },
      {
        title: '1-5龙虎',
        dataIndex: '龙虎5',
        colSpan: 0,
        width: 50,
        render: (value) => {
          return styleDragonTiger(value);
        },
      },
    ];
  }

  return columns;
};

export default pk10Header;

import { constBigSmallThreshold } from '@const/playRule';
import { constPlayGroup, constTotalCodeDigit } from '@const/game';
import {
  calcDuplicateCode,
  getTotalOddEven,
  getTotalBigSmall,
  oddEven,
  bigSmall,
  transl,
} from '@helpers/utils';

const { SSC, D11, PK10, XY10, KL10F, PCDD, THREED, QXC } = constPlayGroup;

const dataIndexKey = [
  '总和',
  '第一球',
  '第二球',
  '第三球',
  '第四球',
  '第五球',
  '第六球',
  '第七球',
  '第八球',
];
const dataIndexKeyPK10 = [
  '冠军',
  '亚军',
  '第三名',
  '第四名',
  '第五名',
  '第六名',
  '第七名',
  '第八名',
  '第九名',
  '第十名',
];

export const typeHeader = (group, isMobile) => {
  let columns = [];
  if (isMobile) {
    columns.push(
      {
        title: '球次',
        dataIndex: '球次',
        width: 80,
      },
      {
        title: '单',
        dataIndex: '单',
        className: 'fullTransl',
        width: 60,
        render: (value, row, index) => {
          const childObj = {
            children: value ? value : 0,
          };

          return childObj;
        },
      },
      {
        title: '双',
        dataIndex: '双',
        className: 'fullTransl',
        width: 60,
        render: (value, row, index) => {
          const childObj = {
            children: value ? value : 0,
          };

          return childObj;
        },
      },
      {
        title: '大',
        dataIndex: '大',
        className: 'fullTransl',
        width: 60,
        render: (value, row, index) => {
          const childObj = {
            children: value ? value : 0,
          };

          return childObj;
        },
      },
      {
        title: '小',
        dataIndex: '小',
        className: 'fullTransl',
        width: 60,
        render: (value, row, index) => {
          const childObj = {
            children: value ? value : 0,
          };

          return childObj;
        },
      },
    );
  } else {
    switch (group) {
      case SSC:
      case PCDD:
      case QXC:
      case D11:
      case THREED:
        columns.push({
          title: '球次',
          dataIndex: '球次',
          width: 100,
        });

        for (let col = 0; col <= constTotalCodeDigit[group]; col++) {
          let obj = {
            title: dataIndexKey[col],
            children: [
              {
                title: '单',
                dataIndex: dataIndexKey[col] + '单',
                width: 60,
                render: (value, row, index) => {
                  const childObj = {
                    children: value ? value : 0,
                  };

                  return childObj;
                },
              },
              {
                title: '双',
                dataIndex: dataIndexKey[col] + '双',
                width: 60,
                render: (value, row, index) => {
                  const childObj = {
                    children: value ? value : 0,
                  };

                  return childObj;
                },
              },
            ],
          };

          if (group !== THREED || (group === THREED && col !== 0)) {
            obj.children.push(
              {
                title: '大',
                dataIndex: dataIndexKey[col] + '大',
                width: 60,
                render: (value, row, index) => {
                  const childObj = {
                    children: value ? value : 0,
                  };

                  return childObj;
                },
              },
              {
                title: '小',
                dataIndex: dataIndexKey[col] + '小',
                width: 60,
                render: (value, row, index) => {
                  const childObj = {
                    children: value ? value : 0,
                  };

                  return childObj;
                },
              },
            );
          }
          columns.push(obj);
        }
        break;
      case PK10:
      case XY10:
        columns.push({
          title: '名次',
          children: [
            {
              title: '单双大小',
              dataIndex: '单双大小',
              width: 80,
            },
          ],
        });

        for (let col = 0; col < 10; col++) {
          columns.push({
            title: dataIndexKeyPK10[col],
            children: [
              {
                title: '单',
                dataIndex: dataIndexKeyPK10[col] + '单',
                width: 60,
                render: (value, row, index) => {
                  const childObj = {
                    children: value ? value : 0,
                  };

                  return childObj;
                },
              },
              {
                title: '双',
                dataIndex: dataIndexKeyPK10[col] + '双',
                width: 60,
                render: (value, row, index) => {
                  const childObj = {
                    children: value ? value : 0,
                  };

                  return childObj;
                },
              },
              {
                title: '大',
                dataIndex: dataIndexKeyPK10[col] + '大',
                width: 60,
                render: (value, row, index) => {
                  const childObj = {
                    children: value ? value : 0,
                  };

                  return childObj;
                },
              },
              {
                title: '小',
                dataIndex: dataIndexKeyPK10[col] + '小',
                width: 60,
                render: (value, row, index) => {
                  const childObj = {
                    children: value ? value : 0,
                  };

                  return childObj;
                },
              },
            ],
          });
        }
        break;
      case KL10F:
        columns.push({
          title: '球次',
          dataIndex: '球次',
          width: 80,
        });

        for (let col = 0; col < 9; col++) {
          if (col === 0) {
            columns.push({
              title: dataIndexKey[col],
              children: [
                {
                  title: '单',
                  dataIndex: dataIndexKey[col] + '单',
                  width: 60,
                  render: (value, row, index) => {
                    const childObj = {
                      children: value ? value : 0,
                    };

                    return childObj;
                  },
                },
                {
                  title: '双',
                  dataIndex: dataIndexKey[col] + '双',
                  width: 60,
                  render: (value, row, index) => {
                    const childObj = {
                      children: value ? value : 0,
                    };

                    return childObj;
                  },
                },
                {
                  title: '大',
                  dataIndex: dataIndexKey[col] + '大',
                  width: 60,
                  render: (value, row, index) => {
                    const childObj = {
                      children: value ? value : 0,
                    };

                    return childObj;
                  },
                },
                {
                  title: '小',
                  dataIndex: dataIndexKey[col] + '小',
                  width: 60,
                  render: (value, row, index) => {
                    const childObj = {
                      children: value ? value : 0,
                    };

                    return childObj;
                  },
                },
                {
                  title: '和',
                  dataIndex: dataIndexKey[col] + '和',
                  width: 60,
                  render: (value, row, index) => {
                    const childObj = {
                      children: value ? value : 0,
                    };

                    return childObj;
                  },
                },
              ],
            });
          } else {
            columns.push({
              title: dataIndexKey[col],
              children: [
                {
                  title: '单',
                  dataIndex: dataIndexKey[col] + '单',
                  width: 60,
                  render: (value, row, index) => {
                    const childObj = {
                      children: value ? value : 0,
                    };

                    return childObj;
                  },
                },
                {
                  title: '双',
                  dataIndex: dataIndexKey[col] + '双',
                  width: 60,
                  render: (value, row, index) => {
                    const childObj = {
                      children: value ? value : 0,
                    };

                    return childObj;
                  },
                },
                {
                  title: '大',
                  dataIndex: dataIndexKey[col] + '大',
                  width: 60,
                  render: (value, row, index) => {
                    const childObj = {
                      children: value ? value : 0,
                    };

                    return childObj;
                  },
                },
                {
                  title: '小',
                  dataIndex: dataIndexKey[col] + '小',
                  width: 60,
                  render: (value, row, index) => {
                    const childObj = {
                      children: value ? value : 0,
                    };

                    return childObj;
                  },
                },
              ],
            });
          }
        }
        break;
      default:
        break;
    }
  }

  return columns;
};

export const typeData = (group, data, isMobile) => {
  let dataSource = [];
  let obj;

  if (isMobile) {
    if (data.length) {
      let sourceDataByBallTotalEvenOdd = [];
      sourceDataByBallTotalEvenOdd.push(
        data.map((item) => {
          return getTotalOddEven(item);
        }),
      );

      let sourceDataByBallTotalBigSmall = [];
      sourceDataByBallTotalBigSmall.push(
        data.map((item) => {
          return getTotalBigSmall(item, group);
        }),
      );

      let sourceDataByBallOneToFive = [];
      for (let index = 0; index < data[0].length; index++) {
        sourceDataByBallOneToFive.push(
          calcDuplicateCode(
            data.map((item) => {
              const threshold = constBigSmallThreshold[group];
              const valueBigSmall = bigSmall(item[index], threshold)
                ? '大'
                : '小';
              const valueOddEven = oddEven(item[index]) ? '单' : '双';
              return [valueBigSmall, valueOddEven];
            }),
          ),
        );
      }

      const sourceDataColTotalEvenOdd = calcDuplicateCode(
        sourceDataByBallTotalEvenOdd,
      );
      const sourceDataColTotalBigSmall = calcDuplicateCode(
        sourceDataByBallTotalBigSmall,
      );

      switch (group) {
        case SSC:
        case D11:
        case THREED:
          let objTotal = {
            key: '总和',
            球次: transl('总和', [], false, false, 'en-US'),
            单: sourceDataColTotalEvenOdd.单,
            双: sourceDataColTotalEvenOdd.双,
            大: sourceDataColTotalBigSmall.大,
            小: sourceDataColTotalBigSmall.小,
          };
          if (group === THREED) {
            objTotal.大 = '-';
            objTotal.小 = '-';
          }
          dataSource.push(objTotal);
          for (let index = 0; index < data[0].length; index++) {
            dataSource.push({
              key: index,
              球次: transl(dataIndexKey[index + 1], [], false, false, 'en-US'),
              单: sourceDataByBallOneToFive[index].单,
              双: sourceDataByBallOneToFive[index].双,
              大: sourceDataByBallOneToFive[index].大,
              小: sourceDataByBallOneToFive[index].小,
            });
          }
          break;
        case PCDD:
          dataSource.push({
            key: '总和',
            球次: transl('总和', [], false, false, 'en-US'),
            单: sourceDataColTotalEvenOdd.单,
            双: sourceDataColTotalEvenOdd.双,
            大: sourceDataColTotalBigSmall.大,
            小: sourceDataColTotalBigSmall.小,
          });
          for (let index = 0; index < data[0].length; index++) {
            dataSource.push({
              key: index,
              球次: transl(dataIndexKey[index + 1], [], false, false, 'en-US'),
              单: sourceDataByBallOneToFive[index].单,
              双: sourceDataByBallOneToFive[index].双,
              大: sourceDataByBallOneToFive[index].大,
              小: sourceDataByBallOneToFive[index].小,
            });
          }
          break;
        case PK10:
        case XY10:
          for (let index = 0; index < data[0].length; index++) {
            dataSource.push({
              key: index,
              球次: transl(dataIndexKeyPK10[index], [], true, false, 'en-US'),
              单: sourceDataByBallOneToFive[index].单,
              双: sourceDataByBallOneToFive[index].双,
              大: sourceDataByBallOneToFive[index].大,
              小: sourceDataByBallOneToFive[index].小,
            });
          }
          break;
        case KL10F:
          dataSource.push({
            key: '总和',
            球次: transl('总和', [], false, false, 'en-US'),
            单: sourceDataColTotalEvenOdd.单,
            双: sourceDataColTotalEvenOdd.双,
            大: sourceDataColTotalBigSmall.大,
            小: sourceDataColTotalBigSmall.小,
          });
          for (let index = 0; index < data[0].length; index++) {
            dataSource.push({
              key: index,
              球次: transl(dataIndexKey[index + 1], [], false, false, 'en-US'),
              单: sourceDataByBallOneToFive[index].单,
              双: sourceDataByBallOneToFive[index].双,
              大: sourceDataByBallOneToFive[index].大,
              小: sourceDataByBallOneToFive[index].小,
            });
          }
          break;
        default:
          break;
      }
    }
  } else {
    if (data.length) {
      let sourceDataByBallTotalEvenOdd = [];
      sourceDataByBallTotalEvenOdd.push(
        data.map((item) => {
          return getTotalOddEven(item);
        }),
      );

      let sourceDataByBallTotalBigSmall = [];
      sourceDataByBallTotalBigSmall.push(
        data.map((item) => {
          return getTotalBigSmall(item, group);
        }),
      );

      let sourceDataByBallOneToFive = [];
      for (let index = 0; index < data[0].length; index++) {
        sourceDataByBallOneToFive.push(
          calcDuplicateCode(
            data.map((item) => {
              return oddEven(item[index]) ? '单' : '双';
            }),
          ),
        );
        sourceDataByBallOneToFive.push(
          calcDuplicateCode(
            data.map((item) => {
              const threshold = constBigSmallThreshold[group];

              return bigSmall(item[index], threshold) ? '大' : '小';
            }),
          ),
        );
      }

      const sourceDataColTotalEvenOdd = calcDuplicateCode(
        sourceDataByBallTotalEvenOdd,
      );
      const sourceDataColTotalBigSmall = calcDuplicateCode(
        sourceDataByBallTotalBigSmall,
      );

      switch (group) {
        case SSC:
          obj = {
            key: '1',
            球次: transl('出现次数', [], true, false, 'en-US'),
            总和单: sourceDataColTotalEvenOdd.单,
            总和双: sourceDataColTotalEvenOdd.双,
            总和大: sourceDataColTotalBigSmall.大,
            总和小: sourceDataColTotalBigSmall.小,
            第一球单: sourceDataByBallOneToFive[0].单,
            第一球双: sourceDataByBallOneToFive[0].双,
            第一球大: sourceDataByBallOneToFive[1].大,
            第一球小: sourceDataByBallOneToFive[1].小,
            第二球单: sourceDataByBallOneToFive[2].单,
            第二球双: sourceDataByBallOneToFive[2].双,
            第二球大: sourceDataByBallOneToFive[3].大,
            第二球小: sourceDataByBallOneToFive[3].小,
            第三球单: sourceDataByBallOneToFive[4].单,
            第三球双: sourceDataByBallOneToFive[4].双,
            第三球大: sourceDataByBallOneToFive[5].大,
            第三球小: sourceDataByBallOneToFive[5].小,
            第四球单: sourceDataByBallOneToFive[6].单,
            第四球双: sourceDataByBallOneToFive[6].双,
            第四球大: sourceDataByBallOneToFive[7].大,
            第四球小: sourceDataByBallOneToFive[7].小,
            第五球单: sourceDataByBallOneToFive[8].单,
            第五球双: sourceDataByBallOneToFive[8].双,
            第五球大: sourceDataByBallOneToFive[9].大,
            第五球小: sourceDataByBallOneToFive[9].小,
          };
          dataSource = [obj];
          break;
        case QXC:
          obj = {
            key: '1',
            球次: transl('出现次数', [], true, false, 'en-US'),
            总和单: sourceDataColTotalEvenOdd.单,
            总和双: sourceDataColTotalEvenOdd.双,
            总和大: sourceDataColTotalBigSmall.大,
            总和小: sourceDataColTotalBigSmall.小,
            第一球单: sourceDataByBallOneToFive[0].单,
            第一球双: sourceDataByBallOneToFive[0].双,
            第一球大: sourceDataByBallOneToFive[1].大,
            第一球小: sourceDataByBallOneToFive[1].小,
            第二球单: sourceDataByBallOneToFive[2].单,
            第二球双: sourceDataByBallOneToFive[2].双,
            第二球大: sourceDataByBallOneToFive[3].大,
            第二球小: sourceDataByBallOneToFive[3].小,
            第三球单: sourceDataByBallOneToFive[4].单,
            第三球双: sourceDataByBallOneToFive[4].双,
            第三球大: sourceDataByBallOneToFive[5].大,
            第三球小: sourceDataByBallOneToFive[5].小,
            第四球单: sourceDataByBallOneToFive[6].单,
            第四球双: sourceDataByBallOneToFive[6].双,
            第四球大: sourceDataByBallOneToFive[7].大,
            第四球小: sourceDataByBallOneToFive[7].小,
          };
          dataSource = [obj];
          break;
        case PCDD:
          obj = {
            key: '1',
            球次: transl('出现次数', [], true, false, 'en-US'),
            总和单: sourceDataColTotalEvenOdd.单,
            总和双: sourceDataColTotalEvenOdd.双,
            总和大: sourceDataColTotalBigSmall.大,
            总和小: sourceDataColTotalBigSmall.小,
            第一球单: sourceDataByBallOneToFive[0].单,
            第一球双: sourceDataByBallOneToFive[0].双,
            第一球大: sourceDataByBallOneToFive[1].大,
            第一球小: sourceDataByBallOneToFive[1].小,
            第二球单: sourceDataByBallOneToFive[2].单,
            第二球双: sourceDataByBallOneToFive[2].双,
            第二球大: sourceDataByBallOneToFive[3].大,
            第二球小: sourceDataByBallOneToFive[3].小,
            第三球单: sourceDataByBallOneToFive[4].单,
            第三球双: sourceDataByBallOneToFive[4].双,
            第三球大: sourceDataByBallOneToFive[5].大,
            第三球小: sourceDataByBallOneToFive[5].小,
          };
          dataSource = [obj];
          break;
        case THREED:
          obj = {
            key: '1',
            球次: transl('出现次数', [], true, false, 'en-US'),
            总和单: sourceDataColTotalEvenOdd.单,
            总和双: sourceDataColTotalEvenOdd.双,
            第一球单: sourceDataByBallOneToFive[0].单,
            第一球双: sourceDataByBallOneToFive[0].双,
            第一球大: sourceDataByBallOneToFive[1].大,
            第一球小: sourceDataByBallOneToFive[1].小,
            第二球单: sourceDataByBallOneToFive[2].单,
            第二球双: sourceDataByBallOneToFive[2].双,
            第二球大: sourceDataByBallOneToFive[3].大,
            第二球小: sourceDataByBallOneToFive[3].小,
            第三球单: sourceDataByBallOneToFive[4].单,
            第三球双: sourceDataByBallOneToFive[4].双,
            第三球大: sourceDataByBallOneToFive[5].大,
            第三球小: sourceDataByBallOneToFive[5].小,
          };
          dataSource = [obj];
          break;
        case D11:
          obj = {
            key: '1',
            球次: transl('出现次数', [], true, false, 'en-US'),
            总和单: sourceDataColTotalEvenOdd.单,
            总和双: sourceDataColTotalEvenOdd.双,
            总和大: sourceDataColTotalBigSmall.大,
            总和小: sourceDataColTotalBigSmall.小,
            总和和: sourceDataColTotalBigSmall.和,
            第一球单: sourceDataByBallOneToFive[0].单,
            第一球双: sourceDataByBallOneToFive[0].双,
            第一球大: sourceDataByBallOneToFive[1].大,
            第一球小: sourceDataByBallOneToFive[1].小,
            第二球单: sourceDataByBallOneToFive[2].单,
            第二球双: sourceDataByBallOneToFive[2].双,
            第二球大: sourceDataByBallOneToFive[3].大,
            第二球小: sourceDataByBallOneToFive[3].小,
            第三球单: sourceDataByBallOneToFive[4].单,
            第三球双: sourceDataByBallOneToFive[4].双,
            第三球大: sourceDataByBallOneToFive[5].大,
            第三球小: sourceDataByBallOneToFive[5].小,
            第四球单: sourceDataByBallOneToFive[6].单,
            第四球双: sourceDataByBallOneToFive[6].双,
            第四球大: sourceDataByBallOneToFive[7].大,
            第四球小: sourceDataByBallOneToFive[7].小,
            第五球单: sourceDataByBallOneToFive[8].单,
            第五球双: sourceDataByBallOneToFive[8].双,
            第五球大: sourceDataByBallOneToFive[9].大,
            第五球小: sourceDataByBallOneToFive[9].小,
          };
          dataSource = [obj];
          break;
        case PK10:
        case XY10:
          obj = {
            key: '1',
            单双大小: transl('出现次数', [], true, false, 'en-US'),
            冠军单: sourceDataByBallOneToFive[0].单,
            冠军双: sourceDataByBallOneToFive[0].双,
            冠军大: sourceDataByBallOneToFive[1].大,
            冠军小: sourceDataByBallOneToFive[1].小,
            亚军单: sourceDataByBallOneToFive[2].单,
            亚军双: sourceDataByBallOneToFive[2].双,
            亚军大: sourceDataByBallOneToFive[3].大,
            亚军小: sourceDataByBallOneToFive[3].小,
            第三名单: sourceDataByBallOneToFive[4].单,
            第三名双: sourceDataByBallOneToFive[4].双,
            第三名大: sourceDataByBallOneToFive[5].大,
            第三名小: sourceDataByBallOneToFive[5].小,
            第四名单: sourceDataByBallOneToFive[6].单,
            第四名双: sourceDataByBallOneToFive[6].双,
            第四名大: sourceDataByBallOneToFive[7].大,
            第四名小: sourceDataByBallOneToFive[7].小,
            第五名单: sourceDataByBallOneToFive[8].单,
            第五名双: sourceDataByBallOneToFive[8].双,
            第五名大: sourceDataByBallOneToFive[9].大,
            第五名小: sourceDataByBallOneToFive[9].小,
            第六名单: sourceDataByBallOneToFive[10].单,
            第六名双: sourceDataByBallOneToFive[10].双,
            第六名大: sourceDataByBallOneToFive[11].大,
            第六名小: sourceDataByBallOneToFive[11].小,
            第七名单: sourceDataByBallOneToFive[12].单,
            第七名双: sourceDataByBallOneToFive[12].双,
            第七名大: sourceDataByBallOneToFive[13].大,
            第七名小: sourceDataByBallOneToFive[13].小,
            第八名单: sourceDataByBallOneToFive[14].单,
            第八名双: sourceDataByBallOneToFive[14].双,
            第八名大: sourceDataByBallOneToFive[15].大,
            第八名小: sourceDataByBallOneToFive[15].小,
            第九名单: sourceDataByBallOneToFive[16].单,
            第九名双: sourceDataByBallOneToFive[16].双,
            第九名大: sourceDataByBallOneToFive[17].大,
            第九名小: sourceDataByBallOneToFive[17].小,
            第十名单: sourceDataByBallOneToFive[18].单,
            第十名双: sourceDataByBallOneToFive[18].双,
            第十名大: sourceDataByBallOneToFive[19].大,
            第十名小: sourceDataByBallOneToFive[19].小,
          };
          dataSource = [obj];
          break;
        case KL10F:
          obj = {
            key: '1',
            球次: transl('出现次数', [], true, false, 'en-US'),
            总和单: sourceDataColTotalEvenOdd.单,
            总和双: sourceDataColTotalEvenOdd.双,
            总和大: sourceDataColTotalBigSmall.大,
            总和小: sourceDataColTotalBigSmall.小,
            总和和: sourceDataColTotalBigSmall.和,
            第一球单: sourceDataByBallOneToFive[0].单,
            第一球双: sourceDataByBallOneToFive[0].双,
            第一球大: sourceDataByBallOneToFive[1].大,
            第一球小: sourceDataByBallOneToFive[1].小,
            第二球单: sourceDataByBallOneToFive[2].单,
            第二球双: sourceDataByBallOneToFive[2].双,
            第二球大: sourceDataByBallOneToFive[3].大,
            第二球小: sourceDataByBallOneToFive[3].小,
            第三球单: sourceDataByBallOneToFive[4].单,
            第三球双: sourceDataByBallOneToFive[4].双,
            第三球大: sourceDataByBallOneToFive[5].大,
            第三球小: sourceDataByBallOneToFive[5].小,
            第四球单: sourceDataByBallOneToFive[6].单,
            第四球双: sourceDataByBallOneToFive[6].双,
            第四球大: sourceDataByBallOneToFive[7].大,
            第四球小: sourceDataByBallOneToFive[7].小,
            第五球单: sourceDataByBallOneToFive[8].单,
            第五球双: sourceDataByBallOneToFive[8].双,
            第五球大: sourceDataByBallOneToFive[9].大,
            第五球小: sourceDataByBallOneToFive[9].小,
            第六球单: sourceDataByBallOneToFive[10].单,
            第六球双: sourceDataByBallOneToFive[10].双,
            第六球大: sourceDataByBallOneToFive[11].大,
            第六球小: sourceDataByBallOneToFive[11].小,
            第七球单: sourceDataByBallOneToFive[12].单,
            第七球双: sourceDataByBallOneToFive[12].双,
            第七球大: sourceDataByBallOneToFive[13].大,
            第七球小: sourceDataByBallOneToFive[13].小,
            第八球单: sourceDataByBallOneToFive[14].单,
            第八球双: sourceDataByBallOneToFive[14].双,
            第八球大: sourceDataByBallOneToFive[15].大,
            第八球小: sourceDataByBallOneToFive[15].小,
          };
          dataSource = [obj];
          break;
        default:
          break;
      }
    }
  }

  return dataSource;
};

import React from 'react';
import styled from 'styled-components';
import { Row, Col, Checkbox } from 'antd';
import { inject, observer } from 'mobx-react';

import LcwButton from '@components/common/button';

import withLocation from '@helpers/hoc/withLocation';
import isMobile from '@helpers/hoc/isMobile';
import { color } from '@helpers/const';
import { getSelectAllOption, transl } from '@helpers/utils';

const ToTalMode = ({
  optionBall,
  filterBall,
  setFilterBall,
  optionType,
  filterType,
  setFilterType,
  selectAllValueBall,
  selectAllValueType,
  selectAllBtn,
  isMobile,
  setOpenFilter,
  language,
}) => {
  return (
    <FilterWrapper>
      <Row>
        <Col className="title">{transl('筛选名次：')}&nbsp;</Col>
        <Col>
          <Checkbox.Group
            value={filterBall}
            onChange={(props) => setFilterBall(props)}>
            <Row gutter={{ md: 12.5 }}>
              {optionBall &&
                optionBall.map(({ label, value }) => {
                  return (
                    <Col key={label} className={language}>
                      <Checkbox value={value}>{transl(label)}</Checkbox>
                    </Col>
                  );
                })}
              {selectAllBtn && !isMobile ? (
                <Col>
                  <LcwButton
                    text="全选"
                    btnStyle={3}
                    activeOnClick={() => {
                      setFilterBall(selectAllValueBall);
                      setFilterType(selectAllValueType);
                    }}
                  />
                </Col>
              ) : (
                ''
              )}
              {selectAllBtn && !isMobile ? (
                <Col>
                  <LcwButton
                    text="清空"
                    btnStyle={3}
                    activeOnClick={() => {
                      setFilterBall([optionBall[0].value]);
                      setFilterType([optionType[0].value]);
                    }}
                  />
                </Col>
              ) : (
                ''
              )}
            </Row>
          </Checkbox.Group>
        </Col>
      </Row>
      <Row>
        <Col className="title">{transl('筛选路珠：')}&nbsp;</Col>
        <Col>
          <Checkbox.Group
            value={filterType}
            onChange={(props) => setFilterType(props)}>
            <Row gutter={{ md: 12.5 }}>
              {optionType &&
                optionType.map(({ label, value }) => {
                  return (
                    <Col key={label} className={language}>
                      <Checkbox value={value}>{transl(label)}</Checkbox>
                    </Col>
                  );
                })}
            </Row>
          </Checkbox.Group>
        </Col>
      </Row>
      {isMobile ? (
        <Row className="action">
          <Col className="action" span={12}>
            <button
              text=""
              onClick={() => {
                setFilterBall([optionBall[0].value]);
                setFilterType([optionType[0].value]);
                setOpenFilter(false);
              }}>
              {transl('清空')}
            </button>
          </Col>
          <Col className="action confirm" span={12}>
            <button
              text="确定"
              onClick={() => {
                setOpenFilter(false);
              }}>
              {transl('确定')}
            </button>
          </Col>
        </Row>
      ) : (
        ''
      )}
    </FilterWrapper>
  );
};

const SingleMode = ({
  optionBall,
  filterBall,
  setFilterBall,
  optionType,
  filterType,
  setFilterType,
  isMobile,
  setOpenFilter,
  language,
}) => {
  return (
    <FilterWrapper>
      <Row>
        <Col className="title">{transl('筛选路珠：')}&nbsp;</Col>
        <Col>
          <Checkbox.Group
            value={filterType}
            onChange={(props) => {
              setFilterType(props);
            }}>
            <Row gutter={{ md: 12.5 }}>
              {optionType.map(({ label, value }) => {
                return (
                  <Col key={label} className={language}>
                    <Checkbox value={value}>{transl(label)}</Checkbox>
                  </Col>
                );
              })}
            </Row>
          </Checkbox.Group>
        </Col>
      </Row>
      <Row>
        <Col className="title">{transl('筛选名次：')}&nbsp;</Col>
        <Col>
          <Row gutter={{ md: 12.5 }}>
            {optionBall.map(({ label, value }) => {
              return (
                <Col key={label} className={language}>
                  <LcwButton
                    text={label}
                    btnStyle={isMobile ? 9 : 1}
                    active={filterBall.includes(value) ? true : false}
                    activeOnClick={() => setFilterBall([value])}
                  />
                </Col>
              );
            })}
          </Row>
        </Col>
      </Row>
      {isMobile ? (
        <Row className="action">
          <Col className="action" span={12}>
            <button
              text=""
              onClick={() => {
                setFilterBall([optionBall[0].value]);
                setFilterType([optionType[0].value]);
              }}>
              {transl('清空')}
            </button>
          </Col>
          <Col className="action confirm" span={12}>
            <button
              text="确定"
              onClick={() => {
                setOpenFilter(false);
              }}>
              {transl('确定')}
            </button>
          </Col>
        </Row>
      ) : (
        ''
      )}
    </FilterWrapper>
  );
};

const DoubleMode = ({
  optionBall,
  filterBall,
  setFilterBall,
  optionType,
  filterType,
  setFilterType,
  selectAllValueBall,
  selectAllBtn,
  isMobile,
  setOpenFilter,
  language,
}) => {
  return (
    <FilterWrapper>
      <Row>
        <Col className="title">{transl('筛选路珠：')}&nbsp;</Col>
        <Col>
          <Checkbox.Group
            value={filterBall}
            onChange={(props) => {
              setFilterBall(props);
            }}>
            <Row gutter={{ md: 12.5 }}>
              {optionBall.map(({ label, value }) => {
                return (
                  <Col key={label} className={language}>
                    <Checkbox value={value}>{transl(label)}</Checkbox>
                  </Col>
                );
              })}
              {selectAllBtn && !isMobile ? (
                <Col>
                  <LcwButton
                    text="全选"
                    btnStyle={3}
                    activeOnClick={() => setFilterBall(selectAllValueBall)}
                  />
                </Col>
              ) : (
                ''
              )}
              {selectAllBtn && !isMobile ? (
                <Col>
                  <LcwButton
                    text="清空"
                    btnStyle={3}
                    activeOnClick={() => setFilterBall([optionBall[0].value])}
                  />
                </Col>
              ) : (
                ''
              )}
            </Row>
          </Checkbox.Group>
        </Col>
      </Row>
      <Row>
        <Col className="title">{transl('筛选名次：')}&nbsp;</Col>
        <Col>
          <Row gutter={{ md: 12.5 }}>
            {optionType.map(({ label, value }) => {
              return (
                <Col key={label} className={language}>
                  <LcwButton
                    text={label}
                    btnStyle={isMobile ? 9 : 1}
                    active={filterType.includes(value) ? true : false}
                    activeOnClick={() => setFilterType([value])}
                  />
                </Col>
              );
            })}
          </Row>
        </Col>
      </Row>
      {isMobile ? (
        <Row className="action">
          <Col className="action" span={12}>
            <button
              text=""
              onClick={() => {
                setFilterBall([optionBall[0].value]);
                setFilterType([optionType[0].value]);
              }}>
              {transl('清空')}
            </button>
          </Col>
          <Col className="action confirm" span={12}>
            <button
              text="确定"
              onClick={() => {
                setOpenFilter(false);
              }}>
              {transl('确定')}
            </button>
          </Col>
        </Row>
      ) : (
        ''
      )}
    </FilterWrapper>
  );
};

const Filter = ({
  isMobile,
  mode,
  optionBall,
  filterBall,
  setFilterBall,
  optionType,
  filterType,
  setFilterType,
  selectAllBtn,
  setOpenFilter,
  search,
}) => {
  const selectAllValueBall = getSelectAllOption(optionBall);
  const selectAllValueType = getSelectAllOption(optionType);

  const { language } = search;

  switch (mode.filter) {
    case 'totalMode':
      return (
        <ToTalMode
          key={mode.filterBall}
          optionBall={optionBall}
          filterBall={filterBall}
          setFilterBall={setFilterBall}
          optionType={optionType}
          filterType={filterType}
          setFilterType={setFilterType}
          selectAllValueBall={selectAllValueBall}
          selectAllValueType={selectAllValueType}
          selectAllBtn={selectAllBtn}
          isMobile={isMobile}
          setOpenFilter={setOpenFilter}
          language={language}
        />
      );
    case 'singleMode':
      return (
        <SingleMode
          key={mode.filterBall}
          optionBall={optionBall}
          filterBall={filterBall}
          setFilterBall={setFilterBall}
          optionType={optionType}
          filterType={filterType}
          setFilterType={setFilterType}
          isMobile={isMobile}
          setOpenFilter={setOpenFilter}
          language={language}
        />
      );
    case 'doubleMode':
      return (
        <DoubleMode
          key={mode.filterBall}
          optionBall={optionBall}
          filterBall={filterBall}
          setFilterBall={setFilterBall}
          optionType={optionType}
          filterType={filterType}
          setFilterType={setFilterType}
          selectAllValueBall={selectAllValueBall}
          selectAllBtn={selectAllBtn}
          isMobile={isMobile}
          setOpenFilter={setOpenFilter}
          language={language}
        />
      );
    default:
      console.log('error 路珠分析 filter.');
      break;
  }
};

export default isMobile(withLocation(inject('trendsStore')(observer(Filter))));

const FilterWrapper = styled.div`
    padding: 0 25px 23px;
    background-color: ${color.white};
    
    >.ant-row {
        &:first-child {
            margin-bottom: 15px;
        }
    }

    @media only screen and (max-width: 576px) {
        // position: sticky;
        // top: 88px;
        // left: 0;
        // width: 100%;
        padding: 0;
        // border-bottom: 1px solid ${color.borderGrey};
        // z-index: 2;

        >.ant-row {
            max-height: 27vh;
            overflow: auto;
            border-top: 1px solid ${color.borderGrey};
            padding: 13px 21px 0px 26px;

            &:first-child {
                margin-bottom: 0;
            }

            .title {
                width: 100%;
                font-size: 15px;
                line-height: 16px;
                color: ${color.lightFontGrey};
                margin-bottom: 11px;
            }
            
            .ant-col {
                width: 100%;
            }

            .ant-checkbox-group {
                width: 100%;
            }

            .ant-row {
                .ant-col {
                    margin-bottom: 16px;
                    flex: 0 0 33%;

                    &.en-US,
                    &.vi-VN {
                        flex: 0 0 50%;
                    }

                    span {
                        color: ${color.mobileBlack};
                        font-size: 14px;
                        line-height: 16px;
                    }
                }
            }

            &:last-child {
                border-top: none;
                padding: 0;
            }
        }
    }

`;

import React from 'react';
import styled from 'styled-components';

const FilterSticky = (ComponentToWrap) => (props) => {
  return (
    <FilterStickyWrapper>
      <ComponentToWrap {...props} />
    </FilterStickyWrapper>
  );
};

export default FilterSticky;

const FilterStickyWrapper = styled.div`
  @media only screen and (max-width: 576px) {
    position: sticky;
    top: 44px;
    z-index: 4;
  }
`;

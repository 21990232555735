import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { Row, Col } from 'antd';
import { inject, observer } from 'mobx-react';
import { toJS } from 'mobx';

// import { TitleText } from "@components/common/text";
import { SummarizeTable } from '@components/common/table';
import { Images, MarkSixImage } from '@components/common/images';

import {
  getOpenTimeDate,
  arrayStringToArrayInt,
  sumOfNumber,
  getTotalOddEven,
  getTotalBigSmall,
  getMostColor,
  oddEven,
  bigSmall,
  someOfDigit,
  getNumberOfDigit,
  convertOpenTimeToChineseYear,
  convertNumberToWuXingXiao,
  transl,
} from '@helpers/utils';
import { color } from '@helpers/const';

import {
  constSixAttribute,
  constSixGameNumberColor,
  arraySixGameColor,
} from '@const/playRule';

const { zodiac, element } = constSixAttribute;

const tableHeaderTotal = (
  isMobile,
  setDisplayMode,
  dropDownDisplayMode,
  setDropDownDisplayMode,
) => {
  let column;
  if (isMobile) {
    column = {
      title: '总和',
      className: dropDownDisplayMode ? 'switch open' : 'switch',
      onHeaderCell: (column) => {
        return {
          onClick: () => setDropDownDisplayMode(!dropDownDisplayMode),
        };
      },
      children: [
        {
          title: '特码',
          className: dropDownDisplayMode ? 'switch-mode open' : 'switch-mode',
          onHeaderCell: (column) => {
            return {
              onClick: () => {
                setDisplayMode('special');
                setDropDownDisplayMode(false);
              },
            };
          },
          children: [
            {
              title: '总\n数',
              dataIndex: '总和总数',
              width: 32,
            },
            {
              title: '单\n双',
              dataIndex: '总和单双',
              width: 32,
              render: (value) => {
                return styleOddEven(value);
              },
            },
            {
              title: '大\n小',
              dataIndex: '总和大小',
              width: 32,
              render: (value) => {
                return styleBigSmall(value);
              },
            },
            {
              title: '七\n色\n波',
              dataIndex: '总和七色波',
              width: 32,
              render: (value) => {
                return styleSevenColor(value);
              },
            },
          ],
        },
      ],
    };
  } else {
    column = {
      title: '总和',
      children: [
        {
          title: '总数',
          dataIndex: '总和总数',
          width: 48,
        },
        {
          title: '单双',
          dataIndex: '总和单双',
          width: 50,
          render: (value) => {
            return styleOddEven(value);
          },
        },
        {
          title: '大小',
          dataIndex: '总和大小',
          width: 50,
          render: (value) => {
            return styleBigSmall(value);
          },
        },
        {
          title: '七色波',
          dataIndex: '总和七色波',
          width: 55,
          render: (value) => {
            return styleSevenColor(value);
          },
        },
      ],
    };
  }

  return column;
};

const tableHeaderSpecial = (
  isMobile,
  setDisplayMode,
  dropDownDisplayMode,
  setDropDownDisplayMode,
) => {
  let column;
  if (isMobile) {
    column = {
      title: '特码',
      className: dropDownDisplayMode ? 'switch open' : 'switch',
      onHeaderCell: (column) => {
        return {
          onClick: () => setDropDownDisplayMode(!dropDownDisplayMode),
        };
      },
      children: [
        {
          title: '总和',
          className: dropDownDisplayMode ? 'switch-mode open' : 'switch-mode',
          onHeaderCell: (column) => {
            return {
              onClick: () => {
                setDisplayMode('total');
                setDropDownDisplayMode(false);
              },
            };
          },
          children: [
            {
              title: '单\n双',
              dataIndex: '特码单双',
              width: 17,
              render: (value) => {
                return styleOddEven(value);
              },
            },
            {
              title: '大\n小',
              dataIndex: '特码大小',
              width: 17,
              render: (value) => {
                return styleBigSmall(value);
              },
            },
            {
              title: '合',
              children: [
                {
                  title: '单双',
                  dataIndex: '特码合单双',
                  width: 32,
                  render: (value) => {
                    return styleOddEven(value);
                  },
                },
                {
                  title: '大小',
                  dataIndex: '特码合大小',
                  width: 32,
                  render: (value) => {
                    return styleBigSmall(value);
                  },
                },
              ],
            },
            {
              title: '尾\n大\n小',
              dataIndex: '特码尾大小',
              width: 28,
              render: (value) => {
                return styleBigSmall(value);
              },
            },
          ],
        },
      ],
    };
  } else {
    column = {
      title: '特码',
      children: [
        {
          title: '单双',
          dataIndex: '特码单双',
          width: 50,
          render: (value) => {
            return styleOddEven(value);
          },
        },
        {
          title: '大小',
          dataIndex: '特码大小',
          width: 50,
          render: (value) => {
            return styleBigSmall(value);
          },
        },
        {
          title: '合单双',
          dataIndex: '特码合单双',
          width: 55,
          render: (value) => {
            return styleOddEven(value);
          },
        },
        {
          title: '合大小',
          dataIndex: '特码合大小',
          width: 55,
          render: (value) => {
            return styleBigSmall(value);
          },
        },
        {
          title: '尾大小',
          dataIndex: '特码尾大小',
          width: 55,
          render: (value) => {
            return styleBigSmall(value);
          },
        },
      ],
    };
  }
  return column;
};

const styleOddEven = (value) => {
  return (
    <span className={value.indexOf('双') > -1 ? 'red-text' : 'blue-text'}>
      {transl(value, [], true, false, 'en-US')}
    </span>
  );
};

const styleBigSmall = (value) => {
  return (
    <span
      className={
        value.indexOf('大') > -1
          ? 'red-text'
          : value.indexOf('小') > -1
          ? 'blue-text'
          : ''
      }>
      {transl(value, [], true, false, 'en-US')}
    </span>
  );
};

const styleSevenColor = (value) => {
  return (
    <span
      className={
        value === '红'
          ? 'red-text'
          : value === '蓝'
          ? 'blue-text'
          : value === '绿'
          ? 'green-text'
          : ''
      }>
      {transl(value, [], true, false, 'en-US')}
    </span>
  );
};

const matchingZodiac = (sixCriteria, zodiac, sixAttribute) => {
  let match = false;
  for (let index = 0; index < sixCriteria.length; index++) {
    if (sixAttribute === constSixAttribute.zodiac) {
      if (sixCriteria[index] === zodiac) {
        match = true;
      }
    } else if (sixAttribute === element) {
      if (sixCriteria[index] === zodiac) {
        match = true;
      }
    }
  }

  return match;
};

const filterStyleLogic = (
  ballIndex,
  sixBall,
  special,
  sixCriteria,
  zodiac,
  sixAttribute,
) => {
  let visible = true;

  if (sixBall.length && sixCriteria.length) {
    visible = false;

    if (special) {
      if (sixBall.includes(6)) {
        visible = matchingZodiac(sixCriteria, zodiac, sixAttribute);
      }
    } else if (sixBall.includes(ballIndex)) {
      visible = matchingZodiac(sixCriteria, zodiac, sixAttribute);
    }
  } else if (sixBall.length) {
    visible = false;

    if (special) {
      if (sixBall.includes(6)) {
        visible = true;
      }
    } else if (sixBall.includes(ballIndex)) {
      visible = true;
    }
  } else if (sixCriteria.length) {
    visible = false;
    visible = matchingZodiac(sixCriteria, zodiac, sixAttribute);
  }

  if (visible) {
    visible = {
      opacity: '1',
    };
  } else {
    visible = {
      opacity: '0.3',
    };
  }

  return visible;
};

const styleNumberZodiac = (
  array,
  sixBall,
  special = false,
  sixCriteria,
  sixAttribute,
) => {
  let styledNumberZodiac = [];
  array.map((value, ballIndex) => {
    let tempNumberZodiac;
    for (let index = 0; index < arraySixGameColor.length; index++) {
      const findColor = constSixGameNumberColor[
        arraySixGameColor[index]
      ].includes(value[0]);
      const number = value[0] < 10 ? '0' + value[0] : value[0];
      if (findColor) {
        const opacityStyle = filterStyleLogic(
          ballIndex,
          sixBall,
          special,
          sixCriteria,
          value[1],
          sixAttribute,
        );

        tempNumberZodiac = (
          <Col
            className="mark6-num"
            key={ballIndex + ' ' + value[0]}
            style={opacityStyle}>
            <span className={arraySixGameColor[index] + '-ball'}>{number}</span>
            <div className="mark6-icon">
              <MarkSixImage name={value[1]} />
            </div>
          </Col>
        );
        styledNumberZodiac.push(tempNumberZodiac);
        break;
      }
    }

    return styledNumberZodiac;
  });

  return styledNumberZodiac;
};

const getTableHeader = (
  sixBall,
  sixCriteria,
  sixAttribute,
  isMobile,
  displayMode,
  setDisplayMode,
  dropDownDisplayMode,
  setDropDownDisplayMode,
) => {
  let columns = [
    {
      title: '时间',
      dataIndex: '时间',
      width: 90,
      responsive: ['md'],
    },
    {
      title: '期数',
      dataIndex: '期数',
      width: isMobile ? 30 : 55,
      sorter: (a, b) => a.期数 - b.期数,
      defaultSortOrder: 'ascend',
      sortDirections: ['ascend', 'descend', 'ascend'],
    },
    {
      title: isMobile ? sixAttribute + '正码' : '正码',
      dataIndex: '正码',
      width: isMobile ? '' : 400,
      className: 'ball-wrapper main',
      render: (value) => {
        let newValue;
        let rowNumber;
        if (sixAttribute === zodiac) {
          newValue = convertNumberToWuXingXiao(value[0], value[2]);
        } else if (sixAttribute === element) {
          newValue = convertNumberToWuXingXiao(value[0], value[1]);
        }
        rowNumber = (
          <Row justify="center">
            {styleNumberZodiac(
              newValue,
              sixBall,
              false,
              sixCriteria,
              sixAttribute,
            )}
          </Row>
        );
        return rowNumber;
      },
    },
    {
      title: '特码',
      dataIndex: '特码',
      width: isMobile ? 30 : 66,
      className: 'ball-wrapper sp',
      render: (value) => {
        let newValue;
        if (sixAttribute === zodiac) {
          newValue = convertNumberToWuXingXiao(value[0], value[2]);
        } else if (sixAttribute === element) {
          newValue = convertNumberToWuXingXiao(value[0], value[1]);
        }
        return styleNumberZodiac(
          newValue,
          sixBall,
          true,
          sixCriteria,
          sixAttribute,
        );
      },
    },
  ];

  if (isMobile) {
    if (displayMode === 'total') {
      columns.push(
        tableHeaderTotal(
          isMobile,
          setDisplayMode,
          dropDownDisplayMode,
          setDropDownDisplayMode,
        ),
      );
    } else {
      columns.push(
        tableHeaderSpecial(
          isMobile,
          setDisplayMode,
          dropDownDisplayMode,
          setDropDownDisplayMode,
        ),
      );
    }
  } else {
    columns.push(tableHeaderTotal(isMobile));
    columns.push(tableHeaderSpecial(isMobile));
  }

  return columns;
};

const getTableData = (
  singleGame,
  isMobile,
  displayMode,
  markSixByYearConversion,
) => {
  let data = [];
  if (isMobile) {
    data.push(
      singleGame.map(({ openCode, planNo, openTime }, index) => {
        const numberOpenCode = arrayStringToArrayInt(openCode);
        const arrayOpenCode = openCode.split(',');
        const standardCode = arrayOpenCode.slice(0, 6);
        const specialCode = arrayOpenCode.slice(6, 7);
        const specialSumOfDigit = someOfDigit(specialCode);
        const specialOddEven = oddEven(specialSumOfDigit) ? '合单' : '合双';
        const specialBigSmall = bigSmall(specialSumOfDigit, 6)
          ? '合大'
          : '合小';
        const specialDigitNumber = getNumberOfDigit(specialCode);
        const stringIssueNumber = planNo.toString();
        const shortenIssueNumber =
          stringIssueNumber.length > 4
            ? stringIssueNumber.slice(stringIssueNumber.length - 4)
            : stringIssueNumber;
        const chineseYear = convertOpenTimeToChineseYear(openTime);
        const markSixConversion = markSixByYearConversion[chineseYear];
        const { xiaoMap, wuXingMap } = markSixConversion;
        let rowData = {
          key: index,
          期数: shortenIssueNumber,
          正码: [standardCode, wuXingMap, xiaoMap],
          特码: [specialCode, wuXingMap, xiaoMap],
        };
        if (displayMode === 'total') {
          rowData['总和总数'] = sumOfNumber(numberOpenCode);
          rowData['总和单双'] = getTotalOddEven(numberOpenCode);
          rowData['总和大小'] = getTotalBigSmall(numberOpenCode, 'SIX');
          rowData['总和七色波'] = getMostColor(numberOpenCode);
        } else {
          rowData['特码单双'] = oddEven(specialCode) ? '单' : '双';
          rowData['特码大小'] = bigSmall(specialCode, 24) ? '大' : '小';
          rowData['特码合单双'] = specialOddEven;
          rowData['特码合大小'] = specialBigSmall;
          rowData['特码尾大小'] = specialCode.includes(49)
            ? '和局'
            : bigSmall(specialDigitNumber, 4)
            ? '尾大'
            : '尾小';
        }

        return rowData;
      }),
    );
  } else {
    data.push(
      singleGame.map(({ openTime, openCode, planNo }, index) => {
        const numberOpenCode = arrayStringToArrayInt(openCode);
        const arrayOpenCode = openCode.split(',');
        const standardCode = arrayOpenCode.slice(0, 6);
        const specialCode = arrayOpenCode.slice(6, 7);
        const specialSumOfDigit = someOfDigit(specialCode);
        const specialOddEven = oddEven(specialSumOfDigit) ? '合单' : '合双';
        const specialBigSmall = bigSmall(specialSumOfDigit, 6)
          ? '合大'
          : '合小';
        const specialDigitNumber = getNumberOfDigit(specialCode);
        const openDate = getOpenTimeDate(openTime);
        const chineseYear = convertOpenTimeToChineseYear(openTime);
        const markSixConversion = markSixByYearConversion[chineseYear];
        const { xiaoMap, wuXingMap } = markSixConversion;
        const rowData = {
          key: index,
          时间: openDate,
          期数: planNo,
          正码: [standardCode, wuXingMap, xiaoMap],
          特码: [specialCode, wuXingMap, xiaoMap],
          总和总数: sumOfNumber(numberOpenCode),
          总和单双: getTotalOddEven(numberOpenCode),
          总和大小: getTotalBigSmall(numberOpenCode, 'SIX'),
          总和七色波: getMostColor(numberOpenCode),
          特码单双: oddEven(specialCode) ? '单' : '双',
          特码大小: bigSmall(specialCode, 24) ? '大' : '小',
          特码合单双: specialOddEven,
          特码合大小: specialBigSmall,
          特码尾大小: specialCode.includes(49)
            ? '和局'
            : bigSmall(specialDigitNumber, 4)
            ? '尾大'
            : '尾小',
        };

        return rowData;
      }),
    );
  }

  data = data.flat();

  return data;
};

const MainTable = ({ rootStore, trendsStore, isTrendsPage }) => {
  const [displayMode, setDisplayMode] = useState('total');
  const [dropDownDisplayMode, setDropDownDisplayMode] = useState(false);
  const [switcherWidth, setSwitcherWidth] = useState(0);

  const { markSixByYearConversion } = rootStore;
  const { singleGame, sixBall, sixAttribute, sixCriteria } = trendsStore;

  useEffect(() => {
    if (
      Math.max(
        document.documentElement.clientWidth || 0,
        window.innerWidth || 0,
      ) <= 576
    ) {
      setTimeout(function () {
        const switcher = document.getElementsByClassName('switch');
        setSwitcherWidth(switcher[0].clientWidth - 1);
      }, 500);
    }
  }, [displayMode]);

  const isMobile =
    Math.max(
      document.documentElement.clientWidth || 0,
      window.innerWidth || 0,
    ) <= 576
      ? true
      : false;
  const header = getTableHeader(
    toJS(sixBall),
    toJS(sixCriteria),
    toJS(sixAttribute),
    isMobile,
    displayMode,
    setDisplayMode,
    dropDownDisplayMode,
    setDropDownDisplayMode,
  );
  const data =
    singleGame && markSixByYearConversion
      ? getTableData(
          toJS(singleGame),
          isMobile,
          displayMode,
          markSixByYearConversion,
        )
      : [];

  return (
    <BodyWrapper
      switcherWidth={switcherWidth}
      className={isTrendsPage ? 'not-iframe' : ''}>
      <SummarizeTable
        tableHeader={header}
        tableData={data}
        fixHeader={true}
        headerHight={displayMode === 'total' ? 79 : 89}
      />
    </BodyWrapper>
  );
};

export default inject('rootStore', 'trendsStore')(observer(MainTable));

const BodyWrapper = styled.div`
    // border: 1px solid ${color.borderGrey};
    // border-top: none;
    padding: 0 21px 29px 21px;

    .ball-wrapper {
        .mark6-num {
            display: flex;
            align-items: center;
            justify-content: center;

            .mark6-icon {
                width: 25px;
            }
            
            @media only screen and (max-width: 576px) {
                flex-direction: column;

                .mark6-icon {
                    margin: auto;
                    width: 18px;
                }
            }
        }

        .ant-col {
            .red-ball,
            .blue-ball,
            .green-ball {
                display: inline-block;
                width: 25px;
                height: 25px;
                border-radius: 50%;
                color: ${color.white};
                line-height: 25px;
                margin-right: 3px;
                font-weight: bold;
    
                @media only screen and (max-width: 576px) {
                    width: 20px;
                    height: 20px;
                    line-height: 20px;
                    margin-right: 0;
                    margin-bottom: 2px;
                }
            }
    
            .red-ball {
                background-color: ${color.red};
            }
        
            .blue-ball {
                background-color: ${color.blue};
            }
        
            .green-ball {
                background-color: ${color.green};
            }

            @media only screen and (max-width: 576px) {
                width: 20px;
                margin: auto;
            }
        }

        &.main {
            .ant-col {
                margin-right: 10px;
                
                &:last-child {
                    margin-right: 0;
                }
            }

            @media only screen and (max-width: 576px) {
                width: 48%;

                .ant-row {
                    flex-flow: nowrap;
                    
                    .ant-col {
                        margin-right: 0;
    
                        &:first-child {
                            margin-left: 0;
                        }
    
                        &:last-child {
                            margin-right: 0;
                        }
                    }
                }
            }
        }
    }

    @media only screen and (max-width: 576px) {
        padding: 0;
        margin-top: -1px;

        &.not-iframe {
            padding-top: 0;
        }

        table {
            white-space: break-spaces;

            .ant-table-thead {
                tr {
                    &:first-child {
                        th {
                            &:first-child {
                                line-height: 1;
                            }

                            .ant-table-column-sorters {
                                .ant-table-column-sorter {
                                    margin-top: 0;
                                }
                            }
                        }

                        th {
                            &:last-child {
                                padding: 0;

                                &:after{
                                    content:'';
                                    background-image: url(${
                                      Images.icn_dropdown_white
                                    });
                                    position: relative;
                                    display: inline-block;
                                    margin-left: 5px;
                                    width: 11px;
                                    height: 7px;
                                }
                                
                                &.open {
                                    &:after{
                                        background-image: url(${
                                          Images.icn_dropdown_up_white
                                        });
                                    }
                                }
                            }
                        }
                    }

                    &:nth-child(3) {
                        th {
                            line-height: 1;
                        }
                    }
                }

                th {
                    position: sticky;
                    color: ${color.white};
                    background-color: ${color.orange};
                    padding: 4px 0;

                    &.sp {
                        padding: 4px 2px;
                    }

                    &.switch-mode {
                        position: absolute;
                        width: ${(props) => props.switcherWidth}px;
                        padding: 0;
                        line-height: 26px;
                        z-index: -1;

                        &.open {
                            background-color: ${color.orangeTransparent};
                            z-index: 1;
                        }
                    }

                    &.ball-wrapper {
                        line-height: 15px;
                    }
                }
            }

            .ant-table-tbody {
                tr {
                    &:nth-child(2n) {
                        background-color: ${color.backgroundGrey};
                    }
                }
                td {
                    padding: 5px 2px 4px;
                    line-height: 1;
                }
            }
        }
    }
`;

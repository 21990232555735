import React, { useState } from 'react';
import { inject, observer } from 'mobx-react';
import { toJS } from 'mobx';

import Filter from './Filter';
import MainTable from './MainTable';
import withLocation from '@helpers/hoc/withLocation';
import isMobile from '@helpers/hoc/isMobile';

import { constLuZhuOptionBall } from '@const/playRule';

import PlayRuleTitle from '@components/common/playRuleTitle';

const LiangMianTongJi = ({ trendsStore, search, isMobile }) => {
  const { group } = search;

  const { singleGame } = trendsStore;

  const firstDefaultBall = constLuZhuOptionBall[group][0].value;

  const [ballFilter, setBallFilter] = useState(firstDefaultBall);

  return (
    <>
      {!isMobile && <PlayRuleTitle title="两面统计" />}
      <Filter
        ballFilter={ballFilter}
        setBallFilter={setBallFilter}
        group={group}
      />
      {singleGame.length ? (
        <MainTable
          ballFilter={ballFilter}
          singleGame={toJS(singleGame)}
          group={group}
        />
      ) : (
        ''
      )}
    </>
  );
};

export default isMobile(
  withLocation(inject('trendsStore')(observer(LiangMianTongJi))),
);

import React, { useEffect, useState, useRef } from 'react';
import styled from 'styled-components';
import { inject, observer } from 'mobx-react';
import { toJS } from 'mobx';

import { arrayStringToArrayInt } from '@helpers/utils';
import { color } from '@helpers/const';

import withLocation from '@helpers/hoc/withLocation';
import isMobile from '@helpers/hoc/isMobile';
import {
  getDataAnalysis,
  getDataAnalysisPattern,
  transl,
} from '@helpers/utils';

import {
  constPlayGroup,
  constGameBallNumber,
  constGameBallStartNumber,
} from '@const/game';
import {
  constTrendBallList,
  basicColumnBackgroundColor,
} from '@const/playRule';

import { Row, Col } from 'antd';

import LeftTable from '@components/common/trendsChartTable/leftTable';

const { PK10, XY10, D11, KL10F, K3 } = constPlayGroup;

const getTableHeaderData = () => {
  let columns = [
    {
      title: '期数',
      dataIndex: '期数',
      width: 120,
      sorter: true,
    },
    {
      title: '开奖号码',
      dataIndex: '开奖号码',
      width: 120,
      className: 'open-code',
    },
  ];

  return columns;
};

const getTableData = (singleGame, isMobile) => {
  let arrayNumberOpenCode = [];

  singleGame.map((game) => {
    return arrayNumberOpenCode.push(arrayStringToArrayInt(game.openCode));
  });

  let dataSourceArray = [];

  for (let index = 0; index < singleGame.length; index++) {
    const stringIssueNumber = singleGame[index].uniqueIssueNumber.toString();
    const shortenIssueNumber =
      stringIssueNumber.length > 4
        ? stringIssueNumber.slice(stringIssueNumber.length - 4)
        : stringIssueNumber;
    let data = {
      key: index,
      uniqueIssueNumber: isMobile ? shortenIssueNumber : stringIssueNumber,
      openCode: arrayNumberOpenCode[index],
    };
    dataSourceArray.push(data);
  }

  return dataSourceArray;
};

const getTableHeaderAnalysis = (group, ballFilter) => {
  let result = [];
  let header;
  switch (group) {
    case KL10F:
      header = [
        {
          title: '小区',
          dataIndex: '小区',
        },
        {
          title: '大区',
          dataIndex: '大区',
        },
      ];

      let startIndex = [1, 11];

      let maxIndex = [10, 20];

      for (let index = 0; index < header.length; index++) {
        let ball = [];

        let header = {
          title: constTrendBallList[index].text,
          dataIndex: constTrendBallList[index].text,
        };

        for (
          let indexChild = startIndex[index];
          indexChild <= maxIndex[index];
          indexChild++
        ) {
          const ballCol = {
            title: indexChild.toString(),
            dataIndex: indexChild,
          };
          ball.push(ballCol);
        }

        header.children = ball;
        result.push(header);
      }
      break;
    default:
      for (
        let indexBallFilter = 0;
        indexBallFilter < ballFilter.length;
        indexBallFilter++
      ) {
        header = {
          title: constTrendBallList[ballFilter[indexBallFilter]].text,
          dataIndex: constTrendBallList[ballFilter[indexBallFilter]].text,
        };

        let ball = [];
        for (
          let indexChild = constGameBallStartNumber[group];
          indexChild <= constGameBallNumber[group];
          indexChild++
        ) {
          const ballCol = {
            title: indexChild.toString(),
            dataIndex:
              constTrendBallList[ballFilter[indexBallFilter]].text + indexChild,
          };
          ball.push(ballCol);
        }

        header.children = ball;
        result.push(header);
      }

      break;
  }

  const objPatternHeader = [
    {
      title: '龙虎',
      children: ['龙', '虎', '和'],
    },
  ];

  for (let index = 0; index < objPatternHeader.length; index++) {
    header = {
      title: objPatternHeader[index].title,
      dataIndex: objPatternHeader[index].title,
    };

    let pattern = [];
    for (
      let indexChild = 0;
      indexChild < objPatternHeader[index].children.length;
      indexChild++
    ) {
      const patternCol = {
        title: objPatternHeader[index].children[indexChild],
        dataIndex: objPatternHeader[index].children[indexChild],
      };
      pattern.push(patternCol);
    }

    header.children = pattern;
    result.push(header);
  }

  switch (group) {
    case PK10:
    case XY10:
    case D11:
    case KL10F:
      result[2].children = result[2].children.splice(0, 2);
      break;

    default:
      break;
  }

  return result;
};

const getTableDataAnalysis = (singleGame, group, ballFilter) => {
  let arrayNumberOpenCode = [];

  singleGame.map((game) => {
    return arrayNumberOpenCode.push(arrayStringToArrayInt(game.openCode));
  });

  const resultDataAnalysis = getDataAnalysis(
    group,
    arrayNumberOpenCode,
    ballFilter,
  );
  const {
    appear,
    average,
    biggest,
    connect,
    openCodeByBall,
    table,
  } = resultDataAnalysis;
  const result = {
    table: [table[ballFilter[0]], table[ballFilter[1]]],
    appear: [appear[ballFilter[0]], appear[ballFilter[1]]],
    average: [average[ballFilter[0]], average[ballFilter[1]]],
    biggest: [biggest[ballFilter[0]], biggest[ballFilter[1]]],
    connect: [connect[ballFilter[0]], connect[ballFilter[1]]],
    openCodeByBall: [
      openCodeByBall[ballFilter[0]],
      openCodeByBall[ballFilter[1]],
    ],
  };

  return result;
};

const getPatternAnalysis = (openCode, group, findSingleGame, ballFilter) => {
  let result;
  let resultTable = [];
  let isLongHu;
  let countLongHu = [0, 0, 0];
  let countAppear = [0, 0, 0];
  let countNonAppear = [true, true, true];

  for (let index = 0; index < openCode[0].length; index++) {
    let arrayLongHu;
    if (openCode[0][index] > openCode[1][index]) {
      if (isLongHu === 0) {
        countLongHu[1]++;
        countLongHu[2]++;
      } else {
        countLongHu[0] = 0;
        countLongHu[1]++;
        countLongHu[2]++;
      }
      isLongHu = 0;
      countNonAppear[0] = false;
      arrayLongHu = [
        ['龙', countNonAppear[0]],
        [countLongHu[1], countNonAppear[1]],
        [countLongHu[2], countNonAppear[2]],
      ];
      countAppear[0]++;
    } else if (openCode[0][index] < openCode[1][index]) {
      if (isLongHu === 1) {
        countLongHu[0]++;
        countLongHu[2]++;
      } else {
        countLongHu[1] = 0;
        countLongHu[0]++;
        countLongHu[2]++;
      }
      isLongHu = 1;
      countNonAppear[1] = false;
      arrayLongHu = [
        [countLongHu[0], countNonAppear[0]],
        ['虎', countNonAppear[1]],
        [countLongHu[2], countNonAppear[2]],
      ];
      countAppear[1]++;
    } else {
      if (isLongHu === 2) {
        countLongHu[0]++;
        countLongHu[1]++;
      } else {
        countLongHu[2] = 0;
        countLongHu[0]++;
        countLongHu[1]++;
      }
      isLongHu = 2;
      countNonAppear[2] = false;
      arrayLongHu = [
        [countLongHu[0], countNonAppear[0]],
        [countLongHu[1], countNonAppear[1]],
        ['和', countNonAppear[1]],
      ];
      countAppear[2]++;
    }

    resultTable.push(arrayLongHu);
  }

  const calDataAnalysisPattern = getDataAnalysisPattern(resultTable, 3);

  let { countAverage, countBiggest, countConnect } = calDataAnalysisPattern;

  // remove draw
  switch (group) {
    case PK10:
    case XY10:
    case D11:
    case KL10F:
      countAppear = countAppear.splice(0, 2);
      countAverage = countAverage.splice(0, 2);
      countBiggest = countBiggest.splice(0, 2);
      countConnect = countConnect.splice(0, 2);
      for (let index = 0; index < resultTable.length; index++) {
        resultTable[index] = resultTable[index].splice(0, 2);
      }
      break;

    default:
      break;
  }

  result = {
    table: resultTable,
    appear: countAppear,
    average: countAverage,
    biggest: countBiggest,
    connect: countConnect,
  };

  return result;
};

function drawCanvas(tableDataAnalysis, context, group, section) {
  let sectionCount = 0;
  let condX;
  let condY;
  switch (group) {
    case K3:
      condX = 50;
      condY = 42;
      break;
    case PK10:
    case XY10:
    case D11:
      condX = 35;
      condY = 36;
      break;
    default:
      condX = 37;
      condY = 36;
      break;
  }
  for (let indexBall = 0; indexBall < tableDataAnalysis.length; indexBall++) {
    context.beginPath();
    for (
      let indexRow = 0;
      indexRow < tableDataAnalysis[indexBall].length;
      indexRow++
    ) {
      if (section) {
        sectionCount = Math.floor(indexRow / 5);
      }
      for (
        let indexNumber = 0;
        indexNumber < tableDataAnalysis[indexBall][indexRow].length;
        indexNumber++
      ) {
        if (tableDataAnalysis[indexBall][indexRow][indexNumber][1]) {
          const numberPos = indexNumber + 1;
          const ballPos =
            indexBall *
            (constGameBallNumber[group] - constGameBallStartNumber[group] + 1);

          if (indexRow === 0) {
            context.moveTo(
              (numberPos + ballPos) * condX - condX / 2,
              1 * condY - condY / 2 + sectionCount,
            );
          } else {
            context.lineTo(
              (numberPos + ballPos) * condX - condX / 2,
              (indexRow + 1) * condY - condY / 2 + sectionCount,
            );
          }
        }
      }
    }
    context.strokeStyle = basicColumnBackgroundColor[indexBall];
    context.stroke();
  }
}

const ConclusionResultTable = ({ valueBall, group }) => {
  return (
    <div className={'counter ' + group}>
      <span>{valueBall}</span>
    </div>
  );
};

const MainTable = ({ trendsStore, search, isMobile, filter, ballFilter }) => {
  const [sorting, setSorting] = useState(true);

  const canvasRef = useRef(null);
  const mainTable = useRef(null);
  const [context, setContext] = useState(null);
  const [tableWidth, setTableWidth] = useState(0);
  const [tableHeight, setTableHeight] = useState(0);

  const { singleGame, findSingleGame } = trendsStore;

  const { group, language } = search;

  useEffect(() => {
    if (canvasRef.current) {
      const renderCtx = canvasRef.current.getContext('2d');

      if (renderCtx) {
        setContext(renderCtx);
      }
    }

    if (mainTable.current) {
      setTableWidth(mainTable.current.clientWidth);
      setTableHeight(mainTable.current.clientHeight);
    }
  }, [canvasRef, mainTable, context, singleGame]);

  const headerData = getTableHeaderData();
  const headerAnalysis = getTableHeaderAnalysis(group, ballFilter);
  const tableData = singleGame ? getTableData(toJS(singleGame), isMobile) : [];
  const tableDataAnalysis = singleGame
    ? getTableDataAnalysis(toJS(singleGame), group, ballFilter)
    : [];
  const tableDataPattern =
    singleGame && findSingleGame
      ? getPatternAnalysis(
          tableDataAnalysis.openCodeByBall,
          group,
          toJS(findSingleGame),
          ballFilter,
        )
      : '';

  const sortedTableData = tableData.slice();

  const rightTableData = tableDataAnalysis.table.slice();

  const sortedTableDataPattern = tableDataPattern.table.slice();

  if (sorting) {
    sortedTableData.reverse();
    sortedTableDataPattern.reverse();

    for (let index = 0; index < rightTableData.length; index++) {
      const temp = rightTableData[index].slice();
      rightTableData[index] = temp.reverse();
    }
  }

  setTimeout(() => {
    if (
      mainTable.current &&
      context &&
      rightTableData.length &&
      group !== KL10F
    ) {
      context.clearRect(0, 0, tableWidth, tableHeight);
      if (filter.includes('line')) {
        drawCanvas(rightTableData, context, group, filter.includes('section'));
      }
    }
  }, 100);

  return (
    <TrendsResultWrapper>
      <TrendsTableWrapper>
        <div>
          <Row className="table-wrapper">
            <Col className="table-left">
              <LeftTable
                isMobile={isMobile}
                headerData={headerData}
                tableData={sortedTableData}
                filter={filter}
                group={group}
                ball={ballFilter}
                normal={true}
                desktopRow={2}
                sorting={sorting}
                setSorting={setSorting}
              />
            </Col>
            <Col className="table-right">
              <Row style={{ width: '100%' }} className={language}>
                {headerAnalysis.map(({ dataIndex, title, children }, index) => {
                  return (
                    <Col key={dataIndex}>
                      {!isMobile ? (
                        <div className="ball-number">
                          <b>{transl(title, [], false, false, 'en-US')}</b>
                        </div>
                      ) : (
                        ''
                      )}
                      <Row className="row-number">
                        {children.map(({ dataIndex, title }) => {
                          if (isMobile) {
                            if (index > 1) {
                              return (
                                <Col
                                  key={dataIndex}
                                  className={'number ' + group}>
                                  <b>
                                    {transl(title, [], false, false, 'en-US')}
                                  </b>
                                </Col>
                              );
                            } else {
                              return '';
                            }
                          } else {
                            let styleClass = 'number ' + group;
                            styleClass =
                              styleClass + (title === '龙' ? ' red' : '');
                            styleClass =
                              styleClass + (title === '虎' ? ' blue' : '');
                            return (
                              <Col key={dataIndex} className={styleClass}>
                                <b>{transl(title, [], true, false, 'en-US')}</b>
                              </Col>
                            );
                          }
                        })}
                      </Row>
                    </Col>
                  );
                })}
              </Row>
              <div className="result-wrapper" ref={mainTable}>
                {!isMobile ? (
                  <canvas
                    id="canvas"
                    ref={canvasRef}
                    width={tableWidth}
                    height={tableHeight}></canvas>
                ) : (
                  ''
                )}
                <Row>
                  {!isMobile &&
                    rightTableData.map((valueRow, index) => {
                      return (
                        <Col key={index} className="ball-column">
                          {valueRow.map((valueBall, indexRow) => {
                            return (
                              <Row
                                key={indexRow}
                                className={
                                  indexRow % 5 === 4 &&
                                  filter.includes('section')
                                    ? 'separate'
                                    : ''
                                }>
                                {valueBall.map((value, numberIndex) => {
                                  const classStyle =
                                    filter.includes('hightLightCount') &&
                                    value[2]
                                      ? 'non-appear counter ' + group
                                      : 'counter ' + group;
                                  return (
                                    <div
                                      key={indexRow + '' + numberIndex}
                                      className={classStyle}>
                                      <span
                                        className={value[1] ? 'circle' : ''}>
                                        {filter.includes('count') || value[1]
                                          ? value[0]
                                          : ''}
                                      </span>
                                    </div>
                                  );
                                })}
                              </Row>
                            );
                          })}
                        </Col>
                      );
                    })}
                  <Col className="ball-column">
                    {tableDataPattern &&
                      sortedTableDataPattern.map((row, indexRow) => {
                        return (
                          <Row
                            key={indexRow}
                            className={
                              indexRow % 5 === 4 && filter.includes('section')
                                ? 'separate'
                                : ''
                            }>
                            {row.map((value, index) => {
                              let classStyle =
                                typeof value[0] === 'string'
                                  ? 'counter pattern ' + group
                                  : 'counter ' + group;
                              classStyle =
                                filter.includes('hightLightCount') && value[1]
                                  ? classStyle + ' non-appear'
                                  : classStyle;
                              return (
                                <div key={index} className={classStyle}>
                                  <span>
                                    {filter.includes('count') || isNaN(value[0])
                                      ? transl(
                                          value[0],
                                          [],
                                          isMobile ? false : true,
                                          false,
                                          'en-US',
                                        )
                                      : ''}
                                  </span>
                                </div>
                              );
                            })}
                          </Row>
                        );
                      })}
                  </Col>
                </Row>
              </div>
              <Row style={{ width: '100%' }}>
                {!isMobile &&
                  headerAnalysis.map(
                    ({ dataIndex, title, children }, index) => {
                      return (
                        <Col key={dataIndex}>
                          <div className="ball-number btm-part">
                            <b>{transl(title)}</b>
                          </div>
                          <Row className="row-number btm-part">
                            {children.map(({ dataIndex, title }) => {
                              if (isMobile) {
                                if (index > 1) {
                                  return (
                                    <Col
                                      key={dataIndex}
                                      className={'number ' + group}>
                                      <b>{transl(title)}</b>
                                    </Col>
                                  );
                                } else {
                                  return '';
                                }
                              } else {
                                return (
                                  <Col
                                    key={dataIndex}
                                    className={'number ' + group}>
                                    <b>
                                      {transl(
                                        title,
                                        [],
                                        isMobile ? false : true,
                                      )}
                                    </b>
                                  </Col>
                                );
                              }
                            })}
                          </Row>
                        </Col>
                      );
                    },
                  )}
              </Row>
              <div className="result-wrapper btm-part">
                <Row>
                  {!isMobile &&
                    tableDataAnalysis.appear.map((valueBall, indexBall) => {
                      return valueBall.map((value, index) => {
                        return (
                          <Col key={indexBall + '' + index}>
                            <ConclusionResultTable
                              valueBall={value}
                              group={group}
                            />
                          </Col>
                        );
                      });
                    })}
                  {tableDataPattern &&
                    tableDataPattern.appear.map((valueBall, index) => {
                      return (
                        <Col key={index}>
                          <ConclusionResultTable
                            valueBall={valueBall}
                            group={group}
                          />
                        </Col>
                      );
                    })}
                </Row>
              </div>
              <div className="result-wrapper btm-part">
                <Row>
                  {!isMobile &&
                    tableDataAnalysis.average.map((valueBall, indexBall) => {
                      return valueBall.map((value, index) => {
                        return (
                          <Col key={indexBall + '' + index}>
                            <ConclusionResultTable
                              valueBall={value}
                              group={group}
                            />
                          </Col>
                        );
                      });
                    })}
                  {tableDataPattern &&
                    tableDataPattern.average.map((valueBall, index) => {
                      return (
                        <Col key={index}>
                          <ConclusionResultTable
                            valueBall={valueBall}
                            group={group}
                          />
                        </Col>
                      );
                    })}
                </Row>
              </div>
              <div className="result-wrapper btm-part">
                <Row>
                  {!isMobile &&
                    tableDataAnalysis.biggest.map((valueBall, indexBall) => {
                      return valueBall.map((value, index) => {
                        return (
                          <Col key={indexBall + '' + index}>
                            <ConclusionResultTable
                              valueBall={value}
                              group={group}
                            />
                          </Col>
                        );
                      });
                    })}
                  {tableDataPattern &&
                    tableDataPattern.biggest.map((valueBall, index) => {
                      return (
                        <Col key={index}>
                          <ConclusionResultTable
                            valueBall={valueBall}
                            group={group}
                          />
                        </Col>
                      );
                    })}
                </Row>
              </div>
              <div className="result-wrapper btm-part">
                <Row>
                  {!isMobile &&
                    tableDataAnalysis.connect.map((valueBall, indexBall) => {
                      return valueBall.map((value, index) => {
                        return (
                          <Col key={indexBall + '' + index}>
                            <ConclusionResultTable
                              valueBall={value}
                              group={group}
                            />
                          </Col>
                        );
                      });
                    })}
                  {tableDataPattern &&
                    tableDataPattern.connect.map((valueBall, index) => {
                      return (
                        <Col key={index}>
                          <ConclusionResultTable
                            valueBall={valueBall}
                            group={group}
                          />
                        </Col>
                      );
                    })}
                </Row>
              </div>
            </Col>
          </Row>
        </div>
      </TrendsTableWrapper>
    </TrendsResultWrapper>
  );
};

export default isMobile(
  withLocation(inject('trendsStore')(observer(MainTable))),
);

const TrendsResultWrapper = styled.div`
  background-color: ${color.white};

  .ant-table table {
    th {
      padding: 7px 0;
      font-size: 15px;
      background-color: ${color.white};

      &.number {
        padding: 5px 0;
      }
    }
  }
`;

const TrendsTableWrapper = styled.div`
  position: relative;
  padding: 0 25px 48px;

  @media only screen and (max-width: 576px) {
    padding: 0;
  }

  #canvas {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
  }

  .table-wrapper {
    flex-flow: unset;

    .result-wrapper {
      position: relative;

      .ball-column {
        &:first-child {
          background-color: ${color.opacityYellow};

          @media only screen and (max-width: 576px) {
            background-color: ${color.white};

            > .ant-row:nth-child(2n) {
              background-color: ${color.backgroundGrey};
            }
          }

          .circle {
            background-color: ${color.lightYellow};
          }
        }

        &:nth-child(2) {
          background-color: ${color.opacityRed};

          .circle {
            background-color: ${color.lightRed};
          }
        }

        .separate {
          .counter {
            border-bottom: 2px solid ${color.tableBorderGrey};
            height: 37px;
          }
        }
      }

      .counter {
        display: flex;
        flex: 1 1;
        justify-content: center;
        width: 37px;
        height: 36px;
        align-items: center;
        color: ${color.lightFontGrey};
        border: 1px solid ${color.tableBorderGrey};
        border-top: 0;
        border-left: 0;

        @media only screen and (max-width: 576px) {
          width: 70px;
          padding: 0;
          flex: 1;

          &.conclusion {
            color: ${color.white};
            background-color: ${color.orange};
          }
        }

        &.K3 {
          width: 50px;
        }

        &.PK10,
        &.XY10,
        &.D11 {
          width: 35px;
          padding: 7.5px 0;

          @media only screen and (max-width: 576px) {
            height: 36px;
            line-height: 36px;
            padding: 0;

            &.PK10,
            &.D11 {
              width: 70px;
            }
          }
        }

        &.KL10F {
          width: 40px;

          &:nth-child(-n + 10) {
            background-color: ${color.opacityYellow};

            .circle {
              background-color: ${color.lightYellow};
            }
          }

          &:nth-last-child(-n + 10) {
            background-color: ${color.opacityRed};

            .circle {
              background-color: ${color.lightRed};
            }
          }

          &.non-appear {
            background-color: ${color.darkGrey};
            color: ${color.white};
          }
        }

        &.non-appear {
          background-color: ${color.darkGrey};
          color: ${color.white};
        }

        &.pattern {
          color: ${color.white};
          background-color: ${color.lightBlue};

          &:nth-child(2) {
            background-color: ${color.lightYellow};
          }

          &:nth-child(3) {
            background-color: ${color.lightGreen};
          }
        }

        span {
          display: block;
          min-width: 20px;
          height: 20px;
          padding: 0;
          line-height: 19px;
          text-align: center;

          @media only screen and (max-width: 576px) {
            height: auto;
            line-height: 36px;
            font-size: 13px;
          }

          &.circle {
            position: relative;
            border-radius: 100%;
            color: ${color.white};
            z-index: 1;
          }
        }
      }

      @media only screen and (max-width: 576px) {
        &.btm-part {
          background-color: ${color.orange};

          span,
          b {
            font-size: 13px;
            color: ${color.white};
          }
        }
      }
    }

    .ant-row {
      flex-flow: unset;
    }

    .ant-col {
      max-width: unset;
    }

    .table-left {
      flex: 1;
    }

    .table-right {
      overflow-x: auto;

      @media only screen and (max-width: 576px) {
        overflow-x: unset;

        > .ant-row {
          position: sticky;
          top: 88px;
          z-index: 2;

          &.en-US,
          &.vi-VN {
            top: 135px;
          }
        }
      }

      &::-webkit-scrollbar {
        width: 8px;
        height: 8px;
      }

      /* Track */
      &::-webkit-scrollbar-track {
        background: #f1f1f1;
      }

      /* Handle */
      &::-webkit-scrollbar-thumb {
        background: #cdcdcd;
      }

      /* Handle on hover */
      &::-webkit-scrollbar-thumb:hover {
        background: #797979;
      }

      &::-webkit-scrollbar-button {
        width: 0;
        height: 0;
        display: none;
      }

      scrollbar-width: thin;

      .ball-number {
        text-align: center;
        font-size: 15px;
        padding: 3px 0;
        border: 1px solid ${color.tableBorderGrey};
        border-left: 0;
        background-color: ${color.backgroundGrey};

        &.btm-part {
          border-top: 0;
          height: 31px;
        }
      }

      .row-number {
        text-align: center;
        font-size: 15px;
        background-color: ${color.backgroundGrey};

        @media only screen and (max-width: 576px) {
          font-size: 13px;
        }

        .number {
          width: 37px;
          padding: 3px 0;
          border: 1px solid ${color.tableBorderGrey};
          border-left: 0;
          border-top: 0;

          @media only screen and (max-width: 576px) {
            width: 70px;
            height: 29px;
            line-height: 29px;
            padding: 0;
            border-top: 1px solid ${color.tableBorderGrey};
            background-color: ${color.orange};
            color: ${color.white};

            b {
              font-weight: 400;
            }
          }

          &.K3 {
            width: 50px;
          }

          &.PK10,
          &.XY10,
          &.D11 {
            width: 35px;

            @media only screen and (max-width: 576px) {
              &.PK10,
              &.D11 {
                width: 70px;
              }
            }
          }

          &.KL10F {
            width: 40px;
          }
        }
      }
    }
  }
`;

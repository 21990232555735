import React from 'react';
import styled from 'styled-components';

const withPaddingWidth = (ComponentToWrap) => (props) => (
  <PaddingContainer>
    <ComponentToWrap {...props} />
  </PaddingContainer>
);

export default withPaddingWidth;

export const PaddingContainer = styled.div`
  margin: 0 auto;
  max-width: 1200px;
  width: 100%;

  @media (max-width: 1210px) {
    // padding: 0 20px;
  }
`;

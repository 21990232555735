import React from 'react';
import styled from 'styled-components';
import { Link } from 'gatsby';

import { color } from '@helpers/const';

function addClass(type) {
  let classString;
  switch (type) {
    case 1:
      classString = 'big noLink';
      break;
    default:
      break;
  }

  return classString;
}

const IconLink = ({ data: { icon, gameNameInChinese, url }, type }) => {
  return (
    <LottoLinkWrapper className={addClass(type)}>
      {url ? (
        <LottoLink to={url}>
          <LottoIcon>
            <img src={icon} alt={gameNameInChinese} />
          </LottoIcon>
          {gameNameInChinese}
        </LottoLink>
      ) : (
        <div>
          <LottoIcon>
            <img src={icon} alt={gameNameInChinese} />
          </LottoIcon>
          {gameNameInChinese}
        </div>
      )}
    </LottoLinkWrapper>
  );
};

export default IconLink;

const LottoIcon = styled.div`
  width: 60px;
  margin: auto;
  margin-bottom: 10px;
`;

const LottoLinkWrapper = styled.div`
  width: fit-content;
  margin: auto;
  text-align: center;

  &.noLink {
    a {
      color: ${color.black};
      cursor: default;

      &:hover {
        color: ${color.black};
      }
    }
  }

  &.big {
    font-size: 18px;
    font-weight: 600;

    @media only screen and (max-width: 576px) {
      font-size: 14px;
    }

    ${LottoIcon} {
      width: 80px;
      margin-bottom: 14px;

      @media only screen and (max-width: 576px) {
        width: 60px;
        margin-bottom: 5px;
      }
    }
  }
`;

const LottoLink = styled(Link)`
  color: ${color.black};
  font-size: 14px;
  line-height: 20px;
  margin-bottom: 0;

  &:hover {
    color: ${color.orange};
  }
`;

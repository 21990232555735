import React from 'react';
import styled from 'styled-components';

import { UnderLineFilter } from '@components/common/filters';

import { color } from '@helpers/const';

import { transl } from '@helpers/utils';

const constKaiJiangJiluFilter = [
  {
    filter: 'KaiJiangJilu',
    nameInChinese: transl('开奖记录'),
  },
  // {
  //     filter: "singleMode",
  //     nameInChinese: "单选模式",
  // },
  // {
  //     filter: "doubleMode",
  //     nameInChinese: "两面模式",
  // },
];

const ModeFilter = () => {
  return (
    <UnderLineFilterWrapper>
      <UnderLineFilter
        filterList={constKaiJiangJiluFilter}
        onClickAction={(props) => {
          console.log(props);
        }}
        activeFilter="KaiJiangJilu"
      />
    </UnderLineFilterWrapper>
  );
};

export default ModeFilter;

const UnderLineFilterWrapper = styled.div`
  position: relative;
  line-height: 0;
  background-color: ${color.white};
  padding: 16px 34px 0px;
  overflow: hidden;

  &:after {
    content: '';
    position: absolute;
    left: -10px;
    bottom: 16px;
    width: calc(100% + 20px);
    height: 1px;
    background: ${color.borderGrey};
  }

  @media only screen and (max-width: 576px) {
    display: none;
  }
`;

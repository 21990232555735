import { arrayStringToArrayInt } from '@helpers/utils';

import {
  styleOddEven,
  styleBigSmall,
  styleDragonTiger,
  styleShunZhi,
  headerClickedHandler,
  getNumberCode,
  getNumberBigSmall,
  getNumberOddEven,
} from './style';

const d11Header = (
  singleGame,
  group,
  activeTab,
  setTab,
  subSubOption,
  isMobile,
  openDisplayType,
  setOpenDisplayType,
  activePattern,
  setPattern,
  openPattern,
  setOpenPattern,
) => {
  let columns = [];
  let temp = [];

  if (isMobile) {
    columns = [
      {
        title: '期数',
        dataIndex: '期数',
        width: 45,
        sorter: (a, b) => a.期数 - b.期数,
        defaultSortOrder: 'ascend',
        sortDirections: ['ascend', 'descend', 'ascend'],
      },
      {
        title: '显示号码',
        className: openDisplayType
          ? 'tab active open tab1 number'
          : 'tab active tab1 number',
        onHeaderCell: (column) => {
          return {
            onClick: () => {
              setOpenDisplayType(!openDisplayType);
            },
          };
        },
        children: [
          {
            title: '显示大小',
            key: '显示大小',
            className: openDisplayType ? 'tab open tab2' : 'tab tab2',
            onHeaderCell: (column) => {
              return {
                onClick: () => {
                  setTab(column.key);
                  setOpenDisplayType(!openDisplayType);
                },
              };
            },
            children: [
              {
                title: '显示单双',
                dataIndex: '显示号码',
                key: '显示单双',
                className: openDisplayType
                  ? 'tab open tab3 pk10'
                  : 'tab tab3 pk10',
                width: 180,
                onHeaderCell: (column) => {
                  return {
                    onClick: () => {
                      setTab(column.key);
                      setOpenDisplayType(!openDisplayType);
                    },
                  };
                },
                render: (value, row, index) => {
                  let pairStyle = [];

                  let nextOpenCode = {};
                  if (index < singleGame.length - 1) {
                    nextOpenCode = singleGame[index + 1];
                  } else {
                    nextOpenCode = singleGame[index - 1];
                  }

                  nextOpenCode =
                    nextOpenCode &&
                    arrayStringToArrayInt(nextOpenCode.openCode);
                  if (nextOpenCode) {
                    for (
                      let indexDigit = 0;
                      indexDigit < nextOpenCode.length;
                      indexDigit++
                    ) {
                      if (value[indexDigit] === nextOpenCode[indexDigit]) {
                        pairStyle.push(true);
                        temp[indexDigit] = nextOpenCode[indexDigit];
                      } else if (value[indexDigit] === temp[indexDigit]) {
                        pairStyle.push(true);
                        temp[indexDigit] = false;
                      } else {
                        pairStyle.push(false);
                        temp[indexDigit] = false;
                      }
                    }
                  }

                  const obj = {
                    children:
                      activeTab === '显示号码'
                        ? getNumberCode(value, subSubOption, pairStyle, group)
                        : activeTab === '显示大小'
                        ? getNumberBigSmall(
                            value,
                            subSubOption,
                            pairStyle,
                            group,
                          )
                        : getNumberOddEven(
                            value,
                            subSubOption,
                            pairStyle,
                            group,
                          ),
                    // props: { colSpan:3 },
                  };

                  return obj;
                },
              },
            ],
          },
        ],
      },
    ];
    switch (activeTab) {
      case '显示单双':
        columns[1].title = '显示单双';
        columns[1].children[0].title = '显示号码';
        columns[1].children[0].key = '显示号码';
        columns[1].children[0].children[0].title = '显示大小';
        columns[1].children[0].children[0].dataIndex = '显示号码';
        columns[1].children[0].children[0].key = '显示大小';
        break;
      case '显示大小':
        columns[1].title = '显示大小';
        columns[1].children[0].title = '显示号码';
        columns[1].children[0].key = '显示号码';
        columns[1].children[0].children[0].title = '显示单双';
        columns[1].children[0].children[0].dataIndex = '显示号码';
        columns[1].children[0].children[0].key = '显示单双';
        break;
      default:
        break;
    }
    if (activePattern === 1) {
      columns.push(
        {
          title: '总和/龙虎',
          colSpan: 4,
          className: openPattern
            ? 'tab active open tab1 pattern D11'
            : 'tab active tab1 pattern D11',
          onHeaderCell: (column) => {
            return {
              onClick: () => {
                setPattern(1);
                setOpenPattern(!openPattern);
              },
            };
          },
          children: [
            {
              title: '前中后三',
              dataIndex: '总和',
              className: openPattern
                ? 'tab open tab2 pattern D11 total'
                : 'tab tab2 pattern D11 total',
              onHeaderCell: (column) => {
                return {
                  onClick: () => {
                    setPattern(2);
                    setOpenPattern(!openPattern);
                  },
                };
              },
            },
          ],
        },
        {
          title: '总和OddEven',
          dataIndex: '总和OddEven',
          colSpan: 0,
          render: (value) => {
            return styleOddEven(value);
          },
        },
        {
          title: '总和BigSmall',
          dataIndex: '总和BigSmall',
          colSpan: 0,
          render: (value) => {
            return styleBigSmall(value);
          },
        },
        {
          title: '龙虎',
          dataIndex: '龙虎',
          colSpan: 0,
          render: (value) => {
            return styleDragonTiger(value);
          },
        },
      );
    } else {
      columns.push(
        {
          title: '前中后三',
          className: openPattern
            ? 'tab active open tab1 pattern D11'
            : 'tab active tab1 pattern D11',
          onHeaderCell: (column) => {
            return {
              onClick: () => {
                setPattern(2);
                setOpenPattern(!openPattern);
              },
            };
          },
          children: [
            {
              title: '总和/龙虎',
              dataIndex: '前三',
              colSpan: 3,
              className: openPattern
                ? 'tab open tab2 pattern D11'
                : 'tab tab2 pattern D11',
              onHeaderCell: (column) => {
                return {
                  onClick: () => {
                    setPattern(1);
                    setOpenPattern(!openPattern);
                  },
                };
              },
              render: (value) => {
                return styleShunZhi(value);
              },
            },
          ],
        },
        {
          title: '中三',
          dataIndex: '中三',
          colSpan: 0,
          render: (value) => {
            return styleShunZhi(value);
          },
        },
        {
          title: '后三',
          dataIndex: '后三',
          colSpan: 0,
          render: (value) => {
            return styleShunZhi(value);
          },
        },
      );
    }
  } else {
    columns = [
      {
        title: '时间',
        dataIndex: '时间',
        width: 160,
      },
      {
        title: '期数',
        dataIndex: '期数',
        width: 140,
        sorter: (a, b) => a.期数 - b.期数,
        defaultSortOrder: 'ascend',
        sortDirections: ['ascend', 'descend', 'ascend'],
      },
      {
        title: '显示号码',
        dataIndex: '显示号码',
        key: '显示号码',
        className: activeTab === '显示号码' ? 'tab active' : 'tab',
        onHeaderCell: (column) => {
          return {
            onClick: () =>
              headerClickedHandler('JiShiKaiJiang', column, setTab),
          };
        },
        render: (value, row, index) => {
          let pairStyle = [];

          let nextOpenCode = {};
          if (index < singleGame.length - 1) {
            nextOpenCode = singleGame[index + 1];
          } else {
            nextOpenCode = singleGame[index - 1];
          }

          nextOpenCode =
            nextOpenCode && arrayStringToArrayInt(nextOpenCode.openCode);
          if (nextOpenCode) {
            for (
              let indexDigit = 0;
              indexDigit < nextOpenCode.length;
              indexDigit++
            ) {
              if (value[indexDigit] === nextOpenCode[indexDigit]) {
                pairStyle.push(true);
                temp[indexDigit] = nextOpenCode[indexDigit];
              } else if (value[indexDigit] === temp[indexDigit]) {
                pairStyle.push(true);
                temp[indexDigit] = false;
              } else {
                pairStyle.push(false);
                temp[indexDigit] = false;
              }
            }
          }

          const obj = {
            children:
              activeTab === '显示号码'
                ? getNumberCode(value, subSubOption, pairStyle, group)
                : activeTab === '显示大小'
                ? getNumberBigSmall(value, subSubOption, pairStyle, group)
                : getNumberOddEven(value, subSubOption, pairStyle, group),
            props: { colSpan: 3 },
          };

          return obj;
        },
      },
      {
        title: '显示大小',
        dataIndex: '显示大小',
        key: '显示大小',
        className: activeTab === '显示大小' ? 'tab active' : 'tab',
        width: 149,
        onHeaderCell: (column) => {
          return {
            onClick: () =>
              headerClickedHandler('JiShiKaiJiang', column, setTab),
          };
        },
        render: (value, row, index) => {
          const obj = {
            children: value,
            props: { colSpan: 0 },
          };

          return obj;
        },
      },
      {
        title: '显示单双',
        dataIndex: '显示单双',
        key: '显示单双',
        className: activeTab === '显示单双' ? 'tab active' : 'tab',
        width: 149,
        onHeaderCell: (column) => {
          return {
            onClick: () =>
              headerClickedHandler('JiShiKaiJiang', column, setTab),
          };
        },
        render: (value, row, index) => {
          const obj = {
            children: value,
            props: { colSpan: 0 },
          };

          return obj;
        },
      },
      {
        title: '总和',
        dataIndex: '总和',
        colSpan: 3,
        className: 'total',
        width: 50,
      },
      {
        title: '总和OddEven',
        dataIndex: '总和OddEven',
        colSpan: 0,
        width: 50,
        render: (value) => {
          return styleOddEven(value);
        },
      },
      {
        title: '总和BigSmall',
        dataIndex: '总和BigSmall',
        colSpan: 0,
        width: 50,
        render: (value) => {
          return styleBigSmall(value);
        },
      },
      {
        title: '龙虎',
        dataIndex: '龙虎',
        width: 70,
        render: (value) => {
          return styleDragonTiger(value);
        },
      },
      {
        title: '前三',
        dataIndex: '前三',
        width: 70,
        render: (value) => {
          return styleShunZhi(value);
        },
      },
      {
        title: '中三',
        dataIndex: '中三',
        width: 70,
        render: (value) => {
          return styleShunZhi(value);
        },
      },
      {
        title: '后三',
        dataIndex: '后三',
        width: 70,
        render: (value) => {
          return styleShunZhi(value);
        },
      },
    ];
  }

  return columns;
};

export default d11Header;

import React from 'react';
import styled from 'styled-components';

import { color } from '@helpers/const';
import isMobile from '@helpers/hoc/isMobile';
import { oddEven, bigSmall, openCodeToArray } from '@helpers/utils';

import { constBigSmallThreshold, constLuZhuOptionBall } from '@const/playRule';

import { SummarizeTable } from '@components/common/table';

const getTableHeader = (group, isMobile, filter) => {
  let columns = [
    {
      title: '日期',
      dataIndex: '日期',
      width: isMobile ? 100 : 190,
    },
  ];

  for (let index = 0; index < constLuZhuOptionBall[group].length - 1; index++) {
    if (isMobile) {
      if (index === filter) {
        columns.push(
          {
            title: '大',
            dataIndex: constLuZhuOptionBall[group][index].label + '大',
            width: 45,
          },
          {
            title: '小',
            dataIndex: constLuZhuOptionBall[group][index].label + '小',
            width: 45,
          },
          {
            title: '单',
            dataIndex: constLuZhuOptionBall[group][index].label + '单',
            width: 45,
          },
          {
            title: '双',
            dataIndex: constLuZhuOptionBall[group][index].label + '双',
            width: 45,
          },
        );
      }
    } else {
      const addClassName = index % 2 === 0 ? 'hight-light' : '';
      columns.push({
        title: constLuZhuOptionBall[group][index].label,
        children: [
          {
            title: '大',
            dataIndex: constLuZhuOptionBall[group][index].label + '大',
            width: 45,
            className: addClassName,
          },
          {
            title: '小',
            dataIndex: constLuZhuOptionBall[group][index].label + '小',
            width: 45,
            className: addClassName,
          },
          {
            title: '单',
            dataIndex: constLuZhuOptionBall[group][index].label + '单',
            width: 45,
            className: addClassName,
          },
          {
            title: '双',
            dataIndex: constLuZhuOptionBall[group][index].label + '双',
            width: 45,
            className: addClassName,
          },
        ],
      });
    }
  }

  return columns;
};

const getTableData = (openCodeArray, group) => {
  let dataSourceArray = [];

  for (let indexDate = 0; indexDate < openCodeArray.length; indexDate++) {
    let countOpenCode = {};

    const openCode = openCodeToArray(openCodeArray[indexDate]);
    countOpenCode.日期 =
      openCodeArray[indexDate].length &&
      openCodeArray[indexDate][0].stopOrderTime.slice(0, 10);

    for (
      let indexRow = 0;
      indexRow < openCodeArray[indexDate].length;
      indexRow++
    ) {
      for (
        let indexNumber = 0;
        indexNumber < constLuZhuOptionBall[group].length - 1;
        indexNumber++
      ) {
        const number = openCode[indexRow][indexNumber];

        countOpenCode['key'] = indexDate + '' + indexRow;

        const keyLabelBall = constLuZhuOptionBall[group][indexNumber].label;

        let keyName;
        if (bigSmall(number, constBigSmallThreshold[group])) {
          keyName = keyLabelBall + '大';
        } else {
          keyName = keyLabelBall + '小';
        }

        countOpenCode[keyName] = countOpenCode[keyName]
          ? countOpenCode[keyName] + 1
          : 1;

        if (oddEven(number)) {
          keyName = keyLabelBall + '单';
        } else {
          keyName = keyLabelBall + '双';
        }

        countOpenCode[keyName] = countOpenCode[keyName]
          ? countOpenCode[keyName] + 1
          : 1;
      }
    }

    dataSourceArray.push(countOpenCode);
  }

  return dataSourceArray;
};

const MainTable = ({ isMobile, openCodeArray, group, filter }) => {
  const columns = getTableHeader(group, isMobile, filter);
  let dataSourceArray = [];
  dataSourceArray =
    openCodeArray.length > 1 && getTableData(openCodeArray, group);

  return (
    <TrendsResultWrapper>
      <TrendsTableWrapper>
        <SummarizeTable
          tableData={dataSourceArray}
          tableHeader={columns}
          fixHeader={true}
          translHeaderFull={true}
        />
      </TrendsTableWrapper>
    </TrendsResultWrapper>
  );
};

export default isMobile(MainTable);

const TrendsResultWrapper = styled.div`
  background-color: ${color.white};

  .ant-table-wrapper {
    .ant-table {
      .ant-table-container {
        > .ant-table-content {
          > table {
            > thead {
              th {
                background-color: ${color.backgroundGrey};
                padding: 10px 0;

                @media only screen and (max-width: 576px) {
                  line-height: 29px;
                  background-color: ${color.orange};
                  padding: 0;
                }
              }
            }

            @media only screen and (max-width: 576px) {
              tbody {
                tr {
                  &:nth-child(2n) {
                    background-color: ${color.backgroundGrey};
                  }
                }
              }
            }
          }
        }
      }

      td {
        color: ${color.black};

        @media only screen and (max-width: 576px) {
          color: ${color.mobileBlack};
        }

        &.hight-light {
          background-color: ${color.backgroundLightYellow};
        }
      }

      tr:nth-child(2n + 2) {
        td {
          &.hight-light {
            background-color: ${color.backgroundGrey};
          }
        }
      }
    }
  }
`;

const TrendsTableWrapper = styled.div`
  padding: 0 25px 48px;

  @media only screen and (max-width: 576px) {
    padding: 0;
  }
`;

import React, { useState } from 'react';
import styled from 'styled-components';
import { DatePicker as DatePickerANT, Row, Col } from 'antd';

import { Images } from '@components/common/images';
import LcwButton from '@components/common/button';

const constDay = [
  {
    text: '今天',
    value: 0,
  },
  {
    text: '昨天',
    value: 1,
  },
  {
    text: '前天',
    value: 2,
  },
  {
    text: '选择日期',
    value: 3,
  },
];

const DatePicker = ({ onDateClick }) => {
  const [dayFilter, setDayFilter] = useState(0);
  const [dayPick, setDayPick] = useState([null, null]);

  const today = new Date();
  const yesterday = new Date();
  const dayBefore = new Date();
  yesterday.setDate(today.getDate() - 1);
  dayBefore.setDate(today.getDate() - 2);

  const todayDate = today.toISOString().slice(0, 10);
  const yesterdayDate = yesterday.toISOString().slice(0, 10);
  const dayBeforeDate = dayBefore.toISOString().slice(0, 10);

  constDay[0].date = todayDate;
  constDay[1].date = yesterdayDate;
  constDay[2].date = dayBeforeDate;

  return (
    <DatePickerWrapper>
      <Row gutter={30}>
        {constDay.map(({ text, value, date }) => {
          return (
            <Col key={value}>
              <Row gutter={10}>
                <Col>
                  <LcwButton
                    text={text}
                    btnStyle={10}
                    active={value === dayFilter ? true : false}
                    activeOnClick={() => {
                      setDayFilter(value);
                      value === 3
                        ? onDateClick([value, dayPick[1]])
                        : onDateClick([value, date]);
                    }}
                  />
                </Col>
                {value === 3 && (
                  <Col>
                    <DatePickerANT
                      suffixIcon={null}
                      value={dayPick[0]}
                      onChange={(date, dateString) => {
                        setDayFilter(value);
                        setDayPick([date, dateString]);
                        onDateClick([value, dateString]);
                      }}
                    />
                  </Col>
                )}
              </Row>
            </Col>
          );
        })}
      </Row>
    </DatePickerWrapper>
  );
};

export default DatePicker;

const DatePickerWrapper = styled.div`
  .lcw-button.noBorderNoBG {
    height: 24px;
    line-height: 31px;
  }

  .ant-picker-input {
    &:before {
      content: '';
      background-image: url(${Images.icn_calendar});
      position: absolute;
      left: 0;
      top: 50%;
      transform: translateY(-50%);
      width: 15px;
      height: 15px;
    }

    input {
      margin-left: 25px;
    }
  }
`;

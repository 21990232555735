import React, { useEffect } from 'react';
import styled from 'styled-components';
import { ConfigProvider } from 'antd';
import { inject, observer } from 'mobx-react';
import 'moment/locale/zh-cn';
import zhCN from 'antd/lib/locale/zh_CN';

import { Router } from '@reach/router';

import Home from '@views/home';
import Lottery from '@views/lottery';
import TrendChart from '@views/trendChart';
import AboutUs from '@views/aboutUs';
import ChangLong from '@views/changLong';
import Trends from '@views/trends';
import Iframe from '@views/iframe';
import SuggestPlan from '@views/suggestPlan';

import NotFound from '@pages/404';

import withLocation from '@helpers/hoc/withLocation';

const Route = ({ rootStore, search }) => {
  // const { isLoading } = rootStore;

  const { language } = search;

  useEffect(() => {
    if (language === 'en-US' || language === 'vi-VN') {
      let body = document.querySelector('body');
      body.setAttribute('data-language', language);
    }
  }, [language]);

  //custom route redirect that require param goes here
  return (
    <ConfigProvider locale={zhCN}>
      <RouterWrapper>
        {/* <Spin spinning={isLoading} size="large"> */}
        <Router basepath="/app">
          <NotFound default />
          <Home path="/home" />
          <Lottery path="/lottery" />
          <TrendChart path="/trend-chart" />
          <AboutUs path="/about-us" />
          <ChangLong path="/chang-long" />
          <Trends path="/trends" />
          <Iframe path="/iframe" />
          <SuggestPlan path="/suggest-plan" />
        </Router>
        {/* </Spin> */}
      </RouterWrapper>
    </ConfigProvider>
  );
};

export default withLocation(inject('rootStore')(observer(Route)));

const RouterWrapper = styled.div`
  .ant-spin-nested-loading {
    .ant-spin {
      position: fixed;
      max-height: 100vh;
    }
  }
`;

import React from 'react';
import styled from 'styled-components';
import { Col } from 'antd';

import LcwButton from '@components/common/button';

import { color } from '@helpers/const';

import { constLiShiHaoMaFilter } from '@const/playRule';

const Filter = ({ group, filter, setFilter }) => {
  return (
    <FilterWrapper className="ball-filter-wrapper hide-scrollbar">
      {constLiShiHaoMaFilter[group].map(({ label, value }) => {
        return (
          <Col key={label} className={'button-wrapper ' + group}>
            <LcwButton
              text={label}
              active={value === filter}
              activeOnClick={() => setFilter(value)}
              btnStyle={10}
            />
          </Col>
        );
      })}
    </FilterWrapper>
  );
};

export default Filter;

const FilterWrapper = styled.div`
  @media only screen and (max-width: 576px) {
    position: relative;
    z-index: 3;
    background-color: ${color.white};

    &.ball-filter-wrapper {
      display: flex;
      width: 100%;
      overflow-x: auto;
      flex-flow: unset;

      .button-wrapper {
        min-width: 25%;
        flex: 1 1 25%;

        &.KL10F {
          min-width: 28%;
          flex: 1 1 28%;
        }
      }
    }
  }
`;

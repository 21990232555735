import React from 'react';

import DescriptionBox from '@components/common/descriptionBox';

const ruleDescription = [
  '遗漏期数：自上期开出到本期间隔的期数；',
  '分隔线：每五期使用分隔线，使横向导航更加清晰；',
  '出现次数：统计周期内，某个号码的累计出现次数；',
  '平均遗漏：统计期数内遗漏的平均值。（计算公式：平均遗漏＝统计期数/出现次数)；',
  '最大遗漏：统计期数内遗漏的最大值；',
  '最大连出值：统计期数内连续开出的最大值；',
];

const descByGroup = {
  SSC: [
    {
      desc:
        '龙：开出之号码第一球大于第/x/i球，为龙。如，第一球开出4第五球开出2；',
      replace: ['5'],
    },
    {
      desc:
        '虎：开出之号码第一球小于第/x/i球，为虎。如，第一球开出7第五球开出9；',
      replace: ['5'],
    },
    {
      desc:
        '和：开出之号码第一球等于第/x/i球，为和；如开出结果：2***2、9***9等则和局；',
      replace: ['5'],
    },
  ],
  PK10: [
    {
      desc:
        '龙：开出之号码第一球大于第/x/i球，为龙。如，第一球开出4第五球开出2；',
      replace: ['10'],
    },
    {
      desc:
        '虎：开出之号码第一球小于第/x/i球，为虎。如，第一球开出7第五球开出9；',
      replace: ['10'],
    },
    {
      desc:
        '和：开出之号码第一球等于第/x/i球，为和；如开出结果：2***2、9***9等则和局；',
      replace: ['10'],
    },
  ],
  XY10: [
    {
      desc:
        '龙：开出之号码第一球大于第/x/i球，为龙。如，第一球开出4第五球开出2；',
      replace: ['10'],
    },
    {
      desc:
        '虎：开出之号码第一球小于第/x/i球，为虎。如，第一球开出7第五球开出9；',
      replace: ['10'],
    },
    {
      desc:
        '和：开出之号码第一球等于第/x/i球，为和；如开出结果：2***2、9***9等则和局；',
      replace: ['10'],
    },
  ],
  D11: [
    {
      desc:
        '龙：开出之号码第一球大于第/x/i球，为龙。如，第一球开出4第五球开出2；',
      replace: ['5'],
    },
    {
      desc:
        '虎：开出之号码第一球小于第/x/i球，为虎。如，第一球开出7第五球开出9；',
      replace: ['5'],
    },
    {
      desc:
        '和：开出之号码第一球等于第/x/i球，为和；如开出结果：2***2、9***9等则和局；',
      replace: ['5'],
    },
  ],
};

const Description = ({ group }) => {
  return (
    <>
      <DescriptionBox
        descByGroup={descByGroup[group]}
        generalDescription={ruleDescription}
      />
    </>
  );
};

export default Description;

import React from 'react';
import PropTypes from 'prop-types';
import { StaticQuery, graphql } from 'gatsby';
import styled from 'styled-components';

import Header from './header';
import Footer from './footer';
import './index.css';

import { color } from '@helpers/const';
import { URLS } from '@const/config';
import withLocation from '@helpers/hoc/withLocation';

const Layout = ({ children, location }) => {
  const [menuExpand, setMenuExpand] = React.useState(false);

  const { pathname } = location;

  let addPadding = false;
  if (pathname === URLS.home || pathname === URLS.suggestPlan) {
    addPadding = true;
  }

  return (
    <StaticQuery
      query={graphql`
        query SiteTitleQuery {
          site {
            siteMetadata {
              title
            }
          }
        }
      `}
      render={(data) => (
        <>
          <Header
            siteTitle={data.site.siteMetadata.title}
            menuExpand={menuExpand}
            triggerMenu={(flag) => setMenuExpand(flag)}
          />
          <BodyWrapper addPadding={addPadding}>{children}</BodyWrapper>
          <Footer
            siteTitle={data.site.siteMetadata.title}
            menuExpand={menuExpand}
            triggerMenu={(flag) => setMenuExpand(flag)}
          />
        </>
      )}
    />
  );
};

Layout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default withLocation(Layout);

const BodyWrapper = styled.div`
  background-color: ${color.bodyGrey};
  padding: ${(props) => (props.addPadding ? '0' : '20px 0')};

  @media only screen and (max-width: 576px) {
    padding: 0;
    padding-bottom: ${(props) => (props.addPadding ? '0' : '11px')};
  }
`;

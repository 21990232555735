import React from 'react';
import styled from 'styled-components';
import { Row, Col, Checkbox } from 'antd';

import { Images } from '@components/common/images';
import Drawer from '@components/common/drawer';

import { color } from '@helpers/const';
import isMobile from '@helpers/hoc/isMobile';
import { transl } from '@helpers/utils';

import { constBallNameByGroup } from '@const/playRule';

import withLocation from '@helpers/hoc/withLocation';

const Filter = ({
  isMobile,
  filter,
  setFilter,
  ball,
  setBall,
  options,
  group,
  openFilter,
  setOpenFilter,
  search,
}) => {
  const { language } = search;

  return (
    <FilterWrapper>
      <Row className="no-wrap" gutter={{ md: 20 }}>
        <Col>
          {isMobile ? (
            <button
              className="selection"
              onClick={() => {
                setOpenFilter(!openFilter);
              }}>
              <div
                className={
                  filter.length > 0 && openFilter
                    ? 'active open'
                    : filter.length > 0
                    ? 'active'
                    : openFilter
                    ? 'open'
                    : ''
                }>
                {transl('标注选择')}
              </div>
            </button>
          ) : (
            <div
              className="selection"
              // onClick={()=>{watchConfirmButton(props.filter, props.setFilter)}}
            >
              {transl('标注选择')}
            </div>
          )}
        </Col>
        {isMobile ? (
          <Drawer visible={openFilter} onClose={() => setOpenFilter(false)}>
            <MobileOptionWrapper>
              <div className="option">
                <div className="title">{transl('标注选择')}</div>
                <Checkbox.Group
                  value={filter}
                  onChange={(props) => setFilter(props)}>
                  <Row gutter={{ md: 12.5 }}>
                    {options.map(({ label, value }) => {
                      return (
                        <Col key={label} className={language}>
                          <Checkbox value={value}>{transl(label)}</Checkbox>
                        </Col>
                      );
                    })}
                  </Row>
                </Checkbox.Group>
              </div>
              <Row className="action mobile">
                <Col span={12}>
                  <button
                    onClick={() => {
                      setFilter([]);
                      setOpenFilter(false);
                    }}>
                    {transl('清空')}
                  </button>
                </Col>
                <Col span={12}>
                  <button
                    className="confirm"
                    onClick={() => {
                      setOpenFilter(false);
                    }}>
                    {transl('确定')}
                  </button>
                </Col>
              </Row>
            </MobileOptionWrapper>
          </Drawer>
        ) : (
          <Col>
            <div className="option">
              <Checkbox.Group
                value={filter}
                onChange={(props) => setFilter(props)}>
                <Row gutter={{ md: 12.5 }}>
                  {options.map(({ label, value }) => {
                    return (
                      <Col key={label}>
                        <Checkbox value={value}>{transl(label)}</Checkbox>
                      </Col>
                    );
                  })}
                </Row>
              </Checkbox.Group>
            </div>
          </Col>
        )}
        {isMobile ? (
          <Col className="ball-col">
            <div className="filter-ball-wrapper">
              {constBallNameByGroup[group].map(({ label, value }, index) => {
                return (
                  <div key={value} className="filter-ball">
                    <button
                      className={value === ball ? 'active' : ''}
                      onClick={() => {
                        setBall(value);
                      }}>
                      {transl(label, [], true)}
                    </button>
                  </div>
                );
              })}
            </div>
          </Col>
        ) : (
          ''
        )}
        {/* <Col>
                    <LcwButton text={"开奖历史"}/>
                </Col> */}
      </Row>
    </FilterWrapper>
  );
};

export default withLocation(isMobile(Filter));

const FilterWrapper = styled.div`
  position: relative;
  padding: 0 25px 15px;
  background-color: ${color.white};

  @media only screen and (max-width: 576px) {
    position: sticky;
    top: 44px;
    font-size: 15px;
    padding: 0;
    z-index: 4;
    border-bottom: 1px solid ${color.borderGrey};

    .title-wrapper {
      display: none;
    }

    button {
      color: ${color.mobileBlack};
      background-color: transparent;
      border: none;
      padding: 0;
      display: block;
      width: 100%;
      height: 100%;

      &:focus {
        outline: none;
      }
    }

    .no-wrap {
      flex-flow: nowrap;
    }

    .selection {
      font-size: 13px;
      line-height: 16px;
      width: 104px;
      height: 44px;
      color: ${color.lightFontGrey};
      background-color: ${color.backgroundGrey};
      border-right: 1px solid ${color.borderGrey};

      div {
        margin: 0 10px;
        position: relative;

        &:after {
          content: '';
          background-image: url(${Images.icn_dropdown_grey});
          position: absolute;
          width: 11px;
          height: 7px;
          top: 50%;
          right: 0;
          transform: translateY(-50%);
        }

        &.open {
          &:after {
            background-image: url(${Images.icn_dropdown_up_orange});
          }
        }

        &.active {
          color: ${color.orange};
        }
      }
    }

    .ball-col {
      flex: auto;
      overflow-x: auto;

      .filter-ball-wrapper {
        display: flex;
        overflow-x: auto;
        overflow: -moz-scrollbars-none;
        -ms-overflow-style: none;

        &::-webkit-scrollbar {
          width: 0 !important;
          display: none;
        }

        .filter-ball {
          min-width: 28%;
          flex: 1 0 auto;
          margin: 0 5px;

          button {
            font-size: 13px;
            height: 44px;

            &.active {
              color: ${color.orange};
            }
          }
        }
      }
    }
  }
`;

const MobileOptionWrapper = styled(Col)`
  .option {
    padding: 12px 25px 0;

    .title {
      color: ${color.lightFontGrey};
      margin-bottom: 13px;
    }

    .ant-checkbox-group {
      width: 100%;

      .ant-row {
        .ant-col {
          min-width: 33%;
          flex: 1 0 33%;
          margin-bottom: 18px;

          &.en-US,
          &.vi-VN {
            min-width: 50%;
            flex: 1 0 50%;
          }

          span {
            font-size: 14px;
            color: ${color.mobileBlack};
          }
        }
      }
    }
  }

  .action {
    border-bottom: 1px solid ${color.borderGrey};

    .ant-col {
      text-align: center;
      border-right: 1px solid ${color.borderGrey};

      &:nth-child(2) {
        border-right: none;
        color: ${color.orange};
      }
    }

    .confirm {
      color: ${color.orange};
    }
  }
`;

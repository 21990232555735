import React from 'react';
import { Menu } from 'antd';
import { Link } from 'gatsby';
import styled from 'styled-components';

import { color } from '@helpers/const';

const SideMenu = ({ data, search }) => {
  return (
    <SideMenuWrapper selectedKeys={[search.tab ? search.tab : '1']}>
      {data.map(({ text, url, id }) => {
        return (
          <Menu.Item key={id}>
            <Link to={url}>{text}</Link>
          </Menu.Item>
        );
      })}
    </SideMenuWrapper>
  );
};

export default SideMenu;

const SideMenuWrapper = styled(Menu)`
  > li.ant-menu-item.ant-menu-item-only-child {
    height: 47px;
    padding-top: 4px;
    margin-top: 0;
    margin-bottom: 0;
    text-align: center;
    border: 1px solid ${color.greyLine};
    border-top: 0;
    overflow: visible;
    transition: none;

    a {
      color: ${color.black};
      font-size: 16px;
      font-weight: 600;
    }

    &:first-child {
      border-top: 1px solid ${color.greyLine};
    }

    &:before {
      content: '';
      position: absolute;
      width: 15px;
      height: 15px;
      top: 50%;
      right: -9px;
      background-color: ${color.orange};
      transform: translateY(-50%) rotate(45deg);
      cursor: default;
      opacity: 0;

      @media only screen and (max-width: 576px) {
        content: none;
      }
    }

    &.ant-menu-item-selected {
      background-color: ${color.orange};
      border-color: ${color.orange};

      &:before {
        opacity: 1;
      }

      a {
        color: ${color.white};
      }
    }
  }
`;

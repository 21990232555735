import React, { useState } from 'react';
import { options } from '@components/views/trends/playRule/jiShiKaiJiang';
import Filter from '@components/views/trends/playRule/jiShiKaiJiang/Filter';
import FilterOption from '@components/views/trends/playRule/jiShiKaiJiang/FilterOption';
import DragonStatistics from '@components/views/trends/playRule/jiShiKaiJiang/DragonStatistics';
import NumberStatistics from '@components/views/trends/playRule/jiShiKaiJiang/numberStatistics';
import PlayRuleTitle from '@components/common/playRuleTitle';
import MainTable from './MainTable';

import withLocation from '@helpers/hoc/withLocation';
import isMobile from '@helpers/hoc/isMobile';
import { inject, observer } from 'mobx-react';
import { toJS } from 'mobx';

import { constLowFrequencyGame } from '@const/playRule';

import { openCodeToArray } from '@helpers/utils';

import Reference from '@components/common/reference';

const ZongHeFenXi = ({ trendsStore, search, isMobile }) => {
  const [filter, setFilter] = useState([]);
  const [ballNumber, setBallNumber] = useState([]);
  const [ballType, setBallType] = useState([]);

  const { histByDate, currentTwoGame, dragonList } = trendsStore;

  const { group, searchGame } = search;

  let openCodeInArray = openCodeToArray(histByDate);

  let finalizeOptions = options;
  if (constLowFrequencyGame[searchGame]) {
    finalizeOptions = [{ label: '号码分析', value: 'numberSeparate' }];
  } else if (dragonList.length < 1) {
    finalizeOptions = [
      { label: '今日双面/号码统计', value: 'numberAnalysis' },
      { label: '号码分析', value: 'numberSeparate' },
    ];
  }

  let content;

  if (isMobile) {
    content = [
      'Dr. - Draw Number',
      '1-5 B&S - 1-5 Ball Big & Small',
      '1-5 O&E - 1-5 Ball Odd & Even',
      'B - Big',
      'S - Small',
      'E - Even',
      'O - Odd',
    ];
  } else {
    content = [
      'Dr. - Draw Number',
      '1-5 B&S - 1-5 Ball Big & Small',
      '1-5 O&E - 1-5 Ball Odd & Even',
      'FT - First Three',
      'MT - Middle Three',
      'LT - Last Three',
      'B - Big',
      'S - Small',
      'E - Even',
      'O - Odd',
      'BZ - Leopard',
      'SZ - Straight',
      'DZ - Pair',
      'BS - Semi-smooth',
      'JL - All different numbers',
    ];
  }

  return (
    <>
      {!isMobile && <PlayRuleTitle title="综合分析" />}
      <Filter
        filter={filter}
        setFilter={setFilter}
        setBallNumber={setBallNumber}
        setBallType={setBallType}
        haveFilter={ballNumber.concat(ballType)}
        options={finalizeOptions}
      />
      {filter.includes('numberAnalysis') ? (
        <NumberStatistics
          data={openCodeInArray}
          group={group}
          isMobile={isMobile}
        />
      ) : (
        ''
      )}
      {filter.includes('numberSeparate') ? (
        <FilterOption
          ballNumber={ballNumber}
          setBallNumber={setBallNumber}
          ballType={ballType}
          setBallType={setBallType}
          filter={filter}
          setFilter={setFilter}
          group={group}
        />
      ) : (
        ''
      )}
      {filter.includes('dragon') ? (
        <DragonStatistics
          currentTwoGame={toJS(currentTwoGame)}
          dragonList={toJS(dragonList)}
        />
      ) : (
        ''
      )}
      {/* <FilterResult filter={filter} options={options}/> */}
      <Reference content={content} />
      <MainTable filterBall={ballNumber} filterType={ballType} />
    </>
  );
};

export default isMobile(
  withLocation(inject('trendsStore')(observer(ZongHeFenXi))),
);

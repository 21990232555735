import React, { useState } from 'react';
import Filter from './Filter';
import MainTable from './MainTable';
import FilterOption from './FilterOption';
import NumberStatistics from './numberStatistics';
import DragonStatistics from './DragonStatistics';

import withLocation from '@helpers/hoc/withLocation';
import isMobile from '@helpers/hoc/isMobile';
import { inject, observer } from 'mobx-react';
import { toJS } from 'mobx';

import { constLowFrequencyGame } from '@const/playRule';

import { openCodeToArray } from '@helpers/utils';

import PlayRuleTitle from '@components/common/playRuleTitle';

import Reference from '@components/common/reference';

export const options = [
  { label: '今日双面/号码统计', value: 'numberAnalysis' },
  { label: '号码分析', value: 'numberSeparate' },
  { label: '长龙提醒', value: 'dragon' },
];

const JiShiKaiJiang = ({ trendsStore, search, isMobile }) => {
  const [filter, setFilter] = useState([]);
  const [ballNumber, setBallNumber] = useState([]);
  const [ballType, setBallType] = useState([]);

  const { histByDate, currentTwoGame, dragonList } = trendsStore;

  const { group, searchGame } = search;

  let openCodeInArray = openCodeToArray(histByDate);

  let finalizeOptions = options;
  if (constLowFrequencyGame[searchGame] || group === 'MAX3D') {
    finalizeOptions = [{ label: '号码分析', value: 'numberSeparate' }];
  } else if (dragonList.length < 1) {
    finalizeOptions = [
      { label: '今日双面/号码统计', value: 'numberAnalysis' },
      { label: '号码分析', value: 'numberSeparate' },
    ];
  }

  let content = [
    'Dr. - Draw Number',
    'O&E - Odd & Even',
    'B&S - Big & Small',
    'O - Odd',
    'E - Even',
    'S - Small',
    'B - Big',
  ];

  switch (group) {
    case 'D11':
      content = [
        'Dr. - Draw Number',
        'DT. - Dragon Tiger',
        'FT. - First three',
        'MT. - Mid three',
        'LT. - Last three',
        'O - Odd',
        'E - Even',
        'S - Small',
        'B - Big',
        'BZ - Leopard',
        'SZ - Straight',
        'DZ - Pair',
        'BS - Semi-smooth',
        'JL - All different numbers',
      ];
      break;
    case 'PK10':
      content = [
        'BSOE - Big Small Odd Even',
        'O&E - Odd & Even',
        'B&S - Big & Small',
        'O - Odd',
        'E - Even',
        'S - Small',
        'B - Big',
        '1st&2nd DT - Champion & runner-up Dragon Tiger',
        'BSOE DT - Big Small Odd Even Dragon Tiger',
        '1st&2nd Sum - Sum champion runner-up',
        'DT. - Dragon Tiger',
        'T. - Tiger',
        'D. - Dragon',
        'Appear Times - Appear Times count',
      ];
      if (isMobile) {
        content = [
          'Dr. - Draw Number',
          '1-5 DT - 1-5 Dragon Tiger',
          '1st&2nd Sum - Sum of champion runner-up',
          'T. - Tiger',
          'D. - Dragon',
          'O - Odd',
          'E - Even',
          'S - Small',
          'B - Big',
          'Appear Times - Appear Times count',
        ];
      }
      break;
    case 'K3':
      content = [
        'Dr. - Draw Number',
        'O&E - Odd & Even',
        'B&S - Big & Small',
        'C - Cross',
        'B - Big',
        'S - Small',
        'O - Odd',
        'E - Even',
        '2 Cons.No. - 2 Consecutive Numbers',
        '3 Uni.No. - 3 Unique Numbers',
        '3 Cons.No. - 3 Consecutive Numbers',
        '3 Iden.No. - 3 Identical Numbers',
        '2 Same No. - 2 Same Numbers',
      ];
      break;
    case 'KL10F':
      content = [
        'Dr. - Draw Number',
        'O - Odd',
        'E - Even',
        'S - Small',
        'B - Big',
        'C - Cross',
      ];
      break;
    case 'THREED':
      content = [
        'Dr. - Draw Number',
        'T2Sum. - Top 2 Sum',
        'L2Sum. - Last 2 Sum',
        'O - Odd',
        'E - Even',
        'S - Small',
        'B - Big',
      ];
      break;
    case 'PCDD':
      content = [
        'Dr. - Draw Number',
        'B&S - Big & Small',
        'O&E - Odd & Even',
        'ER - Extreme Range',
        'WC - Color Waves',
        'PAT - Pattern',
        'B - Big',
        'S - Small',
        'O - Odd',
        'E - Even',
        'EB - Extreme Big',
        'ES - Extreme Small',
        'S - Special',
        'B - Blue',
        'R - Red',
        'G - Green',
        'BZ - Leopard',
        'SZ - Straight',
        'DZ - Pair',
        'BS - Semi-smooth',
        'JL - All different numbers',
      ];
      break;
    case 'QXC':
      content = [
        'Dr. - Draw Number',
        'FT - Top three',
        'LT - Last three',
        'O - Odd',
        'E - Even',
        'S - Small',
        'B - Big',
      ];
      break;
    default:
      break;
  }

  return (
    <>
      {!isMobile && <PlayRuleTitle title="即时开奖" />}
      <Filter
        filter={filter}
        setFilter={setFilter}
        setBallNumber={setBallNumber}
        setBallType={setBallType}
        haveFilter={ballNumber.concat(ballType)}
        options={finalizeOptions}
      />
      <Reference content={content} />
      {filter.includes('numberAnalysis') ? (
        <NumberStatistics
          data={openCodeInArray}
          group={group}
          isMobile={isMobile}
        />
      ) : (
        ''
      )}
      {filter.includes('numberSeparate') ? (
        <FilterOption
          ballNumber={ballNumber}
          setBallNumber={setBallNumber}
          ballType={ballType}
          setBallType={setBallType}
          filter={filter}
          setFilter={setFilter}
          group={group}
        />
      ) : (
        ''
      )}
      {filter.includes('dragon') ? (
        <DragonStatistics
          currentTwoGame={toJS(currentTwoGame)}
          dragonList={toJS(dragonList)}
        />
      ) : (
        ''
      )}
      {/* <FilterResult filter={filter} options={options}/> */}
      <MainTable filterBall={ballNumber} filterType={ballType} />
    </>
  );
};

export default isMobile(
  withLocation(inject('trendsStore')(observer(JiShiKaiJiang))),
);

import React from 'react';
import styled from 'styled-components';
import { Row, Col } from 'antd';

import Description from './Description';

import LcwButton from '@components/common/button';
import DatePicker from '@components/common/datePicker';

import { color } from '@helpers/const';

import { constSuggestPlan } from '@const/playRule';

const Filter = ({ filterPlan, setFilterPlan, ...props }) => {
  return (
    <FilterWrapper>
      <TitleWrapper>
        <Col>
          <Title>
            <span style={{ fontSize: '18px', fontWeight: '600' }}>
              购彩计划
            </span>
          </Title>
        </Col>
        <Col>
          <DatePicker {...props} />
        </Col>
      </TitleWrapper>
      <Row>
        <Description />
      </Row>
      <Row
        gutter={[{ md: 0 }, { md: 15 }]}
        justify="space-between"
        align="middle"
        className="block">
        <Col>
          <Row gutter={{ md: 20 }} className="block">
            <Col>
              <Row
                gutter={{ md: 12 }}
                className="ball-filter-wrapper hide-scrollbar">
                {constSuggestPlan.map(({ text, plan }, index) => {
                  return (
                    <Col key={text} className="button-wrapper">
                      <LcwButton
                        text={text}
                        active={index === filterPlan ? true : false}
                        activeOnClick={() => setFilterPlan(index)}
                      />
                    </Col>
                  );
                })}
              </Row>
            </Col>
          </Row>
        </Col>
      </Row>
    </FilterWrapper>
  );
};

export default Filter;

const FilterWrapper = styled.div`
  padding: 0 25px 4px;
  background-color: ${color.white};

  @media only screen and (max-width: 576px) {
    padding: 0;

    .ant-row {
      flex-flow: unset;
    }

    .block {
      display: block;
    }

    .ball-filter-wrapper {
      overflow-x: auto;

      .button-wrapper {
        min-width: 22%;
        flex: 1 0 auto;
      }
    }
  }
`;

const TitleWrapper = styled(Row)`
  position: relative;
  padding-top: 18px;
  padding-bottom: 15px;
  margin-bottom: 19px;
  justify-content: space-between;

  &:after {
    content: '';
    position: absolute;
    width: calc(100% + 50px);
    height: 1px;
    left: -25px;
    bottom: 0;
    background-color: ${color.borderGrey};
  }
`;

const Title = styled.div`
  position: relative;
`;

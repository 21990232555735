import React from 'react';
import styled from 'styled-components';

import { color } from '@helpers/const';

import { Images } from '@components/common/images';

export const PKNumber = ({ number, flower }) => {
  return (
    <Number className={'number f' + flower}>
      {number === 11 ? 'J' : number === 12 ? 'Q' : number === 13 ? 'K' : number}
    </Number>
  );
};

export const PKFlower = ({ flower }) => {
  return (
    <>
      {flower === '1' ? (
        <Flower className={'flower f' + flower}></Flower>
      ) : flower === '2' ? (
        <Flower className={'flower f' + flower}></Flower>
      ) : flower === '3' ? (
        <Flower className={'flower f' + flower}></Flower>
      ) : (
        <Flower className={'flower f' + flower}></Flower>
      )}
    </>
  );
};

const PKCard = ({ number, flower }) => {
  return (
    <PKNumberWrapper>
      <PKNumber number={number} flower={flower} />
      <PKFlower flower={flower} />
    </PKNumberWrapper>
  );
};

export default PKCard;

const PKNumberWrapper = styled.div`
  position: relative;
  width: 48px;
  height: 60px;
  border: 1px solid ${color.borderGrey};
  border-radius: 4px;
  box-shadow: 0 2px 4px -1px rgb(76 76 76 / 20%),
    0 4px 5px 0 rgb(76 76 76 / 14%), 0 1px 10px 0 rgb(76 76 76 / 12%);

  .number {
    position: absolute;
    font-size: 16px;
    line-height: 1;
    top: 5px;
    left: 7px;

    &.f2,
    &.f4 {
      color: ${color.red};
    }
  }

  .flower {
    position: absolute;
    width: 18px;
    height: auto;
    bottom: 3px;
    left: 50%;
    height: 18px;
    width: 18px;
    transform: translateX(-50%);
  }
`;

const Flower = styled.span`
  position: relative;
  display: flex;
  height: 100%;
  min-width: unset;
  height: 14px;
  width: 14px;

  @media only screen and (max-width: 576px) {
    height: 10px;
    width: 10px;
  }

  &:after {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    width: 100%;
    height: 100%;
    transform: translate(-50%, -50%);
    background-size: cover;
  }

  &.f1 {
    &:after {
      background-image: url(${Images.spades});
    }
  }

  &.f2 {
    &:after {
      background-image: url(${Images.hearts});
    }
  }

  &.f3 {
    &:after {
      background-image: url(${Images.clubs});
    }
  }

  &.f4 {
    &:after {
      background-image: url(${Images.diams});
    }
  }
`;

const Number = styled.span`
  &.f1,
  &.f3 {
    color: ${color.black};

    @media only screen and (max-width: 576px) {
      color: ${color.mobileBlack};
    }
  }

  &.f2,
  &.f4 {
    color: ${color.red};
  }
`;

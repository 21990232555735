import React, { useState } from 'react';
import { Typography, Row, Col } from 'antd';
import styled from 'styled-components';

import { Images } from '@components/common/images';
import { colorContent } from '@components/common/reference';

import { color } from '@helpers/const';

import { transl } from '@helpers/utils';

const { Paragraph } = Typography;

const DescriptionBox = ({ descByGroup, generalDescription, title }) => {
  let arrayDesc = [];

  if (descByGroup) {
    arrayDesc = descByGroup;
  }

  if (generalDescription) {
    arrayDesc = arrayDesc.concat(generalDescription);
  }

  let allowDropDown = true;

  if (arrayDesc.length < 2) {
    allowDropDown = false;
  }

  const [openDesc, setOpenDesc] = useState(false);

  return (
    <DescriptionWrapper>
      {allowDropDown ? (
        <InnerWrapper
          className={openDesc ? 'arrow open' : 'arrow'}
          onClick={() => {
            setOpenDesc(true);
          }}
          onMouseLeave={() => setOpenDesc(false)}>
          <Row gutter={14}>
            <Col>
              <b>{transl(title ? title : '说明：')}</b>
            </Col>
            <Col>
              {arrayDesc.map((value, index) => {
                let comp;
                if (typeof value === 'object') {
                  const { desc, replace } = value;
                  comp = (
                    <Paragraph key={index}>
                      {index + 1}. {colorContent(transl(desc, replace), true)}
                    </Paragraph>
                  );
                } else {
                  comp = (
                    <Paragraph key={index}>
                      {index + 1}. {colorContent(transl(value), true)}
                    </Paragraph>
                  );
                }
                return comp;
              })}
            </Col>
          </Row>
        </InnerWrapper>
      ) : (
        <InnerWrapper>
          <Row gutter={14}>
            <Col>
              <b>{transl('说明：')}</b>
            </Col>
            <Col>
              {arrayDesc.map((value, index) => {
                return (
                  <Paragraph key={index}>
                    {index + 1}. {colorContent(transl(value), true)}
                  </Paragraph>
                );
              })}
            </Col>
          </Row>
        </InnerWrapper>
      )}
    </DescriptionWrapper>
  );
};

export default DescriptionBox;

const DescriptionWrapper = styled.div`
  min-height: 30px;
  width: 100%;
  margin-bottom: 19px;
  position: relative;
`;

const InnerWrapper = styled.div`
  overflow: hidden;
  background-color: ${color.lightOrange};
  position: absolute;
  max-height: 30px;
  width: 100%;
  padding: 4px 21px;
  border: 1px solid ${color.borderGrey};
  z-index: 5;
  transition: all linear 0.2s;

  .ant-row {
    flex-flow: nowrap;
  }

  &.arrow {
    cursor: pointer;

    &:before {
      content: '';
      position: absolute;
      width: 9px;
      height: 5px;
      right: 20px;
      top: 12px;
      background: url(${Images.arrowDownBlack}) center/cover;
      transition: all linear 0.2s;
    }

    &.open {
      max-height: 1000px;
      z-index: 6;

      &:before {
        transform: rotate(180deg);
      }
    }
  }
`;

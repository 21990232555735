import React, { useState } from 'react';
import Filter from './Filter';
import MainTable from './MainTable';
import withLocation from '@helpers/hoc/withLocation';
import isMobile from '@helpers/hoc/isMobile';

import PlayRuleTitle from '@components/common/playRuleTitle';

import { defaultXingTaiByGroup } from '@const/playRule';

import Reference from '@components/common/reference';

const LongHuZouShi = ({ search, isMobile }) => {
  const { group } = search;

  const firstDefaultBall = defaultXingTaiByGroup[group][0].ball;

  const [filter, setFilter] = useState(['count', 'line']);
  const [ballFilter, setBallFilter] = useState(firstDefaultBall);

  let options = [
    { label: '遗漏', value: 'count' },
    { label: '拆线', value: 'line' },
    { label: '遗漏分成', value: 'hightLightCount' },
    { label: '分隔线', value: 'section' },
  ];

  if (group === 'KL10F') {
    options = [
      { label: '遗漏', value: 'count' },
      { label: '遗漏分成', value: 'hightLightCount' },
      { label: '分隔线', value: 'section' },
    ];
  }

  const content = [
    'Dr. - Draw number',
    'No. - Winning number',
    'PAT - Pattern',
    'BZ - Leopard',
    'SZ - Straight',
    'DZ - Pair',
    'BS - Semi-smooth',
    'JL - All different numbers',
    'Play Type - Group play type',
    'Z3 - Group three',
    'Z6 - Group six',
    'Appear Times - Number of appear times',
    'Avg N/O Streak - Average no open streak count',
    'Lgt N/O Streak - Longest no open streak count',
    'Lgt Streak - Longest open streak',
  ];

  return (
    <>
      {!isMobile && <PlayRuleTitle title="形态走势" />}
      <Filter
        filter={filter}
        setFilter={setFilter}
        options={options}
        ballFilter={ballFilter}
        setBallFilter={setBallFilter}
        group={group}
      />
      <Reference content={content} sticky={true} />
      <MainTable filter={filter} ballFilter={ballFilter} />
    </>
  );
};

export default withLocation(isMobile(LongHuZouShi));

import React from 'react';

import DescriptionBox from '@components/common/descriptionBox';

const generalDescription = [
  '以每10期为一组，统计最近10组中单双大小的出现次数。',
];

const Description = () => {
  return (
    <>
      <DescriptionBox generalDescription={generalDescription} />
    </>
  );
};

export default Description;

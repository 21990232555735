import React from 'react';
import styled from 'styled-components';
import withLocation from '@helpers/hoc/withLocation';
import isMobile from '@helpers/hoc/isMobile';
import { color } from '@helpers/const';
import { transl } from '@helpers/utils';
import { Link } from 'gatsby';
import { Images } from '@components/common/images';

const addButtonClass = (btnStyle, active, tick) => {
  let classString;
  switch (btnStyle) {
    case 1:
      classString = 'greyBgBlackFont' + (active ? ' isActive' : '');
      break;
    case 2:
      classString = 'whiteBgWithOrangeBorder' + (active ? ' isActive' : '');
      break;
    case 3:
      classString = 'whiteBgWithGreyBorder' + (active ? ' isActive' : '');
      break;
    case 4:
      classString =
        'greyBgBlackFontRoundBorder' +
        (active ? ' isActive' : '') +
        (tick ? ' tick' : '');
      break;
    case 5:
      classString =
        'greyBgHoverOrangeBorderArrow' + (active ? ' isActive' : '');
      break;
    case 6:
      classString = 'whiteBgHoverOrangeBgArrow' + (active ? ' isActive' : '');
      break;
    case 7:
      classString = 'whiteBgHoverWhiteBgArrow' + (active ? ' isActive' : '');
      break;
    case 8:
      classString = 'whiteMobile' + (active ? ' isActive' : '');
      break;
    case 9:
      classString = 'radioButton' + (active ? ' isActive' : '');
      break;
    case 10:
      classString = 'noBorderNoBG' + (active ? ' isActive' : '');
      break;
    default:
      break;
  }

  return classString;
};

const LcwButton = ({
  isMobile,
  search,
  text,
  url,
  btnStyle = 1,
  active = false,
  activeOnClick,
  tick = false,
  styleCss,
  translShort = false,
}) => {
  const { primaryColor, language } = search;

  return (
    <ButtonWrapper
      primaryColor={
        isMobile && primaryColor ? '#' + primaryColor : color.orange
      }>
      {url ? (
        <Link
          className={
            'lcw-button ' +
            addButtonClass(btnStyle, active, tick) +
            ' ' +
            language
          }
          to={url}
          onClick={activeOnClick}
          style={styleCss}>
          {translShort ? transl(text, [], true) : transl(text)}
        </Link>
      ) : (
        <button
          className={
            'lcw-button ' +
            addButtonClass(btnStyle, active, tick) +
            ' ' +
            language
          }
          onClick={activeOnClick}
          style={styleCss}>
          {translShort ? transl(text, [], true) : transl(text)}
        </button>
      )}
    </ButtonWrapper>
  );
};

export default isMobile(withLocation(LcwButton));

const ButtonWrapper = styled.div`
  display: flex;
  height: 100%;

  .lcw-button {
    cursor: pointer;
    text-align: center;
    display: block;
    color: ${color.fontGrey};
    padding: 7px 14px;
    background-color: ${color.white};
    border: 1px solid ${color.orange};
    line-height: 1;
    font-size: 14px;
    width: 100%;
    transition: all 0.15s linear;

    &.greyBgBlackFont {
      color: ${color.black};
      background-color: ${color.backgroundGrey};
      border: 1px solid ${color.borderGrey};

      &.isActive {
        color: ${color.white};
        background-color: ${color.orange};
        border-color: ${color.orange};
      }
    }

    &.whiteBgWithOrangeBorder {
      color: ${color.orange};
      border-radius: 4px;
    }

    &.whiteBgWithGreyBorder {
      padding: 3px 0;
      width: 48px;
      border: 1px solid ${color.borderGrey};
      border-radius: 11px;
    }

    &.greyBgBlackFontRoundBorder {
      padding: 9px 15px;
      background-color: ${color.backgroundGrey};
      border-color: ${color.borderGrey};
      border-radius: 4px;

      &.isActive {
        color: ${color.white};
        background-color: ${color.orange};
        border-color: ${color.orange};

        &.tick {
          &:after {
            content: '';
            background: url(${Images.whiteTick}) no-repeat center/cover;
            position: absolute;
            width: 12px;
            height: 8.1px;
            right: 15%;
            top: 50%;
            transform: translateY(-50%);
          }
        }
      }
    }

    &.greyBgHoverOrangeBorderArrow {
      position: relative;
      color: ${color.black};
      padding: 13px 0;
      width: 124px;
      background-color: ${color.bodyGrey};
      line-height: 19px;
      font-size: 16px;
      font-weight: 600;
      border-color: transparent;

      &.isActive {
        color: ${color.orange};
        background-color: ${color.white};
        border-color: ${color.orange};

        &:before {
          content: '';
          position: absolute;
          width: 12px;
          height: 12px;
          border-bottom: 1px solid ${color.orange};
          border-right: 1px solid ${color.orange};
          background-color: ${color.white};
          bottom: -7px;
          left: 50%;
          transform: translateX(-50%) rotate(45deg);
          cursor: default;
        }
      }
    }

    &.whiteBgHoverOrangeBgArrow {
      color: ${color.black};
      padding: 11.5px 0;
      width: 116px;
      background-color: ${color.white};
      line-height: 19px;
      font-size: 16px;
      font-weight: 600;
      border-color: transparent;

      &.isActive {
        color: ${color.white};
        background-color: ${color.orange};

        &:before {
          content: '';
          position: absolute;
          left: 50%;
          bottom: -6px;
          width: 12px;
          height: 12px;
          background-color: ${color.orange};
          transform: translateX(-50%) rotate(45deg);
        }
      }
    }

    &.whiteBgHoverWhiteBgArrow {
      position: relative;
      color: ${color.black};
      padding: 11px 10px;
      min-width: 114px;
      background-color: ${color.white};
      line-height: 19px;
      font-size: 14px;
      border-color: transparent;
      text-align: center;
      // width: 100%;
      box-sizing: border-box;

      :after {
        content: '';
        position: absolute;
        width: 1px;
        height: 20px;
        right: -2px;
        top: 50%;
        transform: translateY(-50%);
        background-color: ${color.borderGrey};
        z-index: 1;
      }

      &.isActive {
        color: ${color.orange};
        border-color: ${color.orange};
        z-index: 1;

        :after {
          opacity: 0;
        }
      }
    }

    &.whiteMobile {
      display: flex;
      justify-content: center;
      align-items: center;
      color: ${color.fontGrey};
      padding: 10px 4px 9px;
      text-align: center;
      line-height: 19px;
      background-color: ${color.backgroundGrey};
      border: 1px solid ${color.borderGrey};
      border-radius: 8px;
      margin-bottom: 10px;
      &.isActive {
        color: ${color.white};
        background-color: ${(props) => props.primaryColor};
      }

      &:hover {
        color: ${color.white};
        background-color: ${(props) => props.primaryColor};
      }

      &.en-US,
      &.vi-VN {
        font-size: 12px;
      }
    }

    &.radioButton {
      font-size: 15px;
      color: ${color.mobileBlack};
      border: none;
      background-color: transparent;
      width: auto;
      padding-left: 25px;
      text-align: left;

      &:before {
        content: '';
        position: absolute;
        top: 50%;
        left: 0;
        transform: translateY(-50%);
        width: 16px;
        height: 16px;
        border: 1px solid ${color.orange};
        border-radius: 50%;
      }

      &.isActive {
        &:after {
          content: '';
          position: absolute;
          top: 50%;
          left: 2px;
          transform: translateY(-50%);
          width: 12px;
          height: 12px;
          background-color: ${color.orange};
          border-radius: 50%;
        }
      }
    }

    &.noBorderNoBG {
      width: 100%;
      height: 44px;
      font-size: 15px;
      line-height: 43px;
      color: ${color.mobileBlack};
      background-color: transparent;
      border: none;
      padding: 0;
      display: block;

      @media only screen and (max-width: 576px) {
        font-size: 13px;
      }

      &:focus,
      &:hover {
        color: ${color.mobileBlack};
        outline: none;
        background-color: transparent;

        &.isActive {
          color: ${color.orange};
        }
      }

      &.isActive {
        color: ${color.orange};
      }
    }

    &:hover {
      color: ${color.white};
      background-color: ${color.orange};
      border-color: ${color.orange};

      &.whiteBgWithGreyBorder,
      &.greyBgHoverOrangeBorderArrow {
        color: ${color.orange};
        background-color: ${color.white};
      }

      &.whiteBgHoverWhiteBgArrow {
        color: ${color.orange};
        background-color: ${color.white};
        z-index: 1;

        :after {
          opacity: 0;
        }
      }

      &.radioButton {
        color: ${color.black};
        background-color: transparent;
        border-color: transparent;
        text-align: left;
      }
    }

    &:focus {
      outline: none;
      border-color: ${color.orange};

      &.greyBgHoverOrangeBorderArrow {
        color: ${color.orange};
        background-color: ${color.white};
      }

      &.whiteBgHoverOrangeBgArrow {
        color: ${color.white};
        background-color: ${color.orange};
      }

      &.greyBgBlackFontRoundBorder {
        border-color: ${color.borderGrey};
      }
    }
  }
`;

import React from 'react';
import { Location } from '@reach/router';
import queryString from 'query-string';

const withLocation = (ComponentToWrap) => (props) => (
  <Location>
    {({ location, navigate, history }) => {
      let searchParam = {};
      if (location.search) {
        searchParam = queryString.parse(location.search);
        if (searchParam.group && searchParam.group === '3D') {
          searchParam.group = 'THREED';
        }

        if (searchParam.group && searchParam.group === 'XPK') {
          searchParam.rule = 'pkZhouShi';
        }
      }

      return (
        <ComponentToWrap
          {...props}
          location={location}
          navigate={navigate}
          search={searchParam}
        />
      );
    }}
  </Location>
);

export default withLocation;

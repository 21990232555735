import React from 'react';
import styled from 'styled-components';
import { Row, Col, Checkbox } from 'antd';

import LcwButton from '@components/common/button';

import { color } from '@helpers/const';

import isMobile from '@helpers/hoc/isMobile';
import filterSticky from '@helpers/hoc/filterSticky';
import { transl } from '@helpers/utils';

import { constGameBallStartNumber } from '@const/game';
import { constLuZhuMobileNumber } from '@const/playRule';

const FilterList = ({
  isMobile,
  optionBall,
  filterBall,
  setFilterBall,
  selectAllValueBall,
  group,
}) => {
  return (
    <Row gutter={[0, { md: 10 }]} className="filter-list">
      {!isMobile ? <Col>{transl('筛选名次：')}&nbsp;</Col> : ''}
      <Col>
        {!isMobile ? (
          <Checkbox.Group
            value={filterBall}
            onChange={(props) => setFilterBall(props)}>
            <Row gutter={[12, 10]}>
              {optionBall.map(({ label, value }) => {
                return (
                  <Col key={label}>
                    <Checkbox value={value}>{transl(label)}</Checkbox>
                  </Col>
                );
              })}
              <Col>
                <LcwButton
                  text="全选"
                  btnStyle={3}
                  activeOnClick={() => {
                    setFilterBall(selectAllValueBall);
                  }}
                />
              </Col>
              <Col>
                <LcwButton
                  text="清空"
                  btnStyle={3}
                  activeOnClick={() => {
                    setFilterBall([
                      optionBall[constGameBallStartNumber[group]].value,
                    ]);
                  }}
                />
              </Col>
            </Row>
          </Checkbox.Group>
        ) : (
          <Row className="ball-filter-wrapper hide-scrollbar">
            {constLuZhuMobileNumber[group].map(({ label, value }) => {
              return (
                <Col key={label} className="button-wrapper">
                  <LcwButton
                    text={label}
                    active={value === filterBall}
                    activeOnClick={() => setFilterBall(value)}
                    btnStyle={isMobile ? 10 : 1}
                  />
                </Col>
              );
            })}
          </Row>
        )}
      </Col>
    </Row>
  );
};

const Filter = ({
  isMobile,
  optionBall,
  filterBall,
  setFilterBall,
  selectAllValueBall,
  group,
}) => {
  return (
    <FilterWrapper>
      <Row justify="space-between" align="middle">
        <FilterList
          optionBall={optionBall}
          filterBall={filterBall}
          setFilterBall={setFilterBall}
          selectAllValueBall={selectAllValueBall}
          group={group}
          isMobile={isMobile}
        />
      </Row>
    </FilterWrapper>
  );
};

export default filterSticky(isMobile(Filter));

const FilterWrapper = styled.div`
  padding: 0 25px 2px;
  background-color: ${color.white};

  @media only screen and (max-width: 576px) {
    padding: 0;
    border-bottom: 1px solid ${color.borderGrey};

    .filter-list {
      flex: 1;

      .ant-col {
        flex: 1;

        .ball-filter-wrapper {
          width: 100%;
          flex: 1;
          flex-flow: unset;
          overflow: auto;

          .button-wrapper {
            min-width: 28%;
            flex: 1 0 28%;
          }
        }
      }
    }
  }
`;

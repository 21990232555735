import React from 'react';
import styled from 'styled-components';
import { Row, Col, Checkbox } from 'antd';

import { color } from '@helpers/const';
import isMobile from '@helpers/hoc/isMobile';
import withLocation from '@helpers/hoc/withLocation';
import { transl } from '@helpers/utils';

import {
  constPlayGroup,
  constGameBallStartNumber,
  constGameBallNumber,
} from '@const/game';

import LcwButton from '@components/common/button';

import { TitleText } from '@components/common/text';

import Drawer from '@components/common/drawer';

const { K3, MAX3D } = constPlayGroup;

const notDisplayTypeFilterGroup = [K3, MAX3D];

const numberSeparateByType = [
  {
    text: '单',
    value: '单',
    type: 2,
  },
  {
    text: '双',
    value: '双',
    type: 2,
  },
  {
    text: '大',
    value: '大',
    type: 3,
  },
  {
    text: '小',
    value: '小',
    type: 3,
  },
  {
    text: '对子号',
    value: '对子号',
    type: 4,
  },
];

const PCOption = ({
  group,
  numberBall,
  activeNumber,
  activeType,
  setBallNumber,
  setBallType,
  resetAction,
}) => {
  return (
    <>
      <TitleTextWrapper style={{ marginBottom: '12px' }}>
        <TitleText text="号码分析" fontSize={16} />
      </TitleTextWrapper>
      <Row gutter={[{ xs: 4, md: 12 }, 12]} style={{ marginBottom: '6px' }}>
        <Col>
          <TitleText text="查看球号分布:" fontSize={16} />
        </Col>
        {numberBall.map(({ text, value }, index) => {
          return (
            <Col key={text}>
              <LcwButton
                text={text}
                // url={location.search}
                activeOnClick={() => {
                  setBallNumber(value);
                }}
                active={activeNumber.includes(value) ? true : false}
              />
            </Col>
          );
        })}
        {notDisplayTypeFilterGroup.includes(group) ? (
          <Col>
            <LcwButton
              text="还原"
              styleCss={{
                width: '100px',
                backgroundColor: color.black,
                color: color.white,
                border: 'none',
              }}
              // url={location.search}
              activeOnClick={() => {
                resetAction();
              }}
            />
          </Col>
        ) : (
          ''
        )}
      </Row>
      {!notDisplayTypeFilterGroup.includes(group) ? (
        <Row gutter={[12, 6]} style={{ marginBottom: '0' }}>
          <Col>
            <TitleText text="大小单双分布:" fontSize={16} />
          </Col>
          {numberSeparateByType.map(({ text, value }) => {
            return (
              <Col key={text}>
                <LcwButton
                  text={text}
                  styleCss={{ padding: '7px 15px' }}
                  // url={location.search}
                  activeOnClick={() => {
                    setBallType(value);
                  }}
                  active={activeType.includes(value) ? true : false}
                />
              </Col>
            );
          })}
          <Col>
            <LcwButton
              text="还原"
              styleCss={{
                width: '100px',
                backgroundColor: color.black,
                color: color.white,
                border: 'none',
              }}
              // url={location.search}
              activeOnClick={() => {
                resetAction();
              }}
            />
          </Col>
        </Row>
      ) : (
        ''
      )}
    </>
  );
};

const watchPCBallNumberLogic = (e, ballNumber, setBallNumber, setBallType) => {
  const tempBallNumber = [...ballNumber];
  const indexEvent = tempBallNumber.indexOf(e);
  if (indexEvent > -1) {
    tempBallNumber.splice(indexEvent, 1);
  } else {
    tempBallNumber.push(e);
  }
  setBallType([]);
  setBallNumber(tempBallNumber);
};

const watchPCBallTypeLogic = (e, ballType, setBallType, setBallNumber) => {
  let tempBallType = [...ballType];
  const indexEvent = tempBallType.indexOf(e);
  let indexEvent2;
  let indexEvent3;
  if (indexEvent > -1) {
    tempBallType.splice(indexEvent, 1);
  } else {
    switch (e) {
      case '对子号':
        tempBallType = ['对子号'];
        break;
      case '单':
        indexEvent2 = tempBallType.indexOf('大');
        indexEvent3 = tempBallType.indexOf('小');

        if (indexEvent2 > -1) {
          tempBallType = ['单', '大'];
        } else if (indexEvent3 > -1) {
          tempBallType = ['单', '小'];
        } else {
          tempBallType = ['单'];
        }
        break;
      case '双':
        indexEvent2 = tempBallType.indexOf('大');
        indexEvent3 = tempBallType.indexOf('小');

        if (indexEvent2 > -1) {
          tempBallType = ['双', '大'];
        } else if (indexEvent3 > -1) {
          tempBallType = ['双', '小'];
        } else {
          tempBallType = ['双'];
        }
        break;
      case '大':
        indexEvent2 = tempBallType.indexOf('单');
        indexEvent3 = tempBallType.indexOf('双');

        if (indexEvent2 > -1) {
          tempBallType = ['大', '单'];
        } else if (indexEvent3 > -1) {
          tempBallType = ['大', '双'];
        } else {
          tempBallType = ['大'];
        }
        break;
      case '小':
        indexEvent2 = tempBallType.indexOf('单');
        indexEvent3 = tempBallType.indexOf('双');

        if (indexEvent2 > -1) {
          tempBallType = ['小', '单'];
        } else if (indexEvent3 > -1) {
          tempBallType = ['小', '双'];
        } else {
          tempBallType = ['小'];
        }
        break;

      default:
        break;
    }
  }
  setBallNumber([]);
  setBallType(tempBallType);
};

const MobileOption = withLocation(
  ({ search, title, option, activeValue, onClickAction }) => {
    const { language } = search;

    let colSpan = 8;
    if (language === 'en-US' || language === 'vi-VN') colSpan = 12;

    return (
      <>
        <div className="filter">
          <div className="filter-title">{transl(title)}</div>
          <div>
            <Checkbox.Group
              value={activeValue}
              onChange={(props) => {
                onClickAction(props);
              }}>
              <Row>
                {option.map(({ value, text }) => {
                  return (
                    <Col key={value} className="filter-checkbox" span={colSpan}>
                      <Checkbox value={value}>{transl(text)}</Checkbox>
                    </Col>
                  );
                })}
              </Row>
            </Checkbox.Group>
          </div>
        </div>
      </>
    );
  },
);

const watchBallTypeLogic = (e, setBallNumber, setBallType) => {
  if (e.length) {
    let index;
    let index2;
    switch (e[e.length - 1]) {
      case '对子号':
        setBallType(['对子号']);
        setBallNumber([]);
        break;
      case '双':
        index = e.indexOf('对子号');
        index2 = e.indexOf('单');
        if (index > -1) {
          e.splice(index, 1);
        }
        if (index2 > -1) {
          e.splice(index2, 1);
        }
        setBallType(e);
        setBallNumber([]);
        break;
      case '单':
        index = e.indexOf('对子号');
        index2 = e.indexOf('双');
        if (index > -1) {
          e.splice(index, 1);
        }
        if (index2 > -1) {
          e.splice(index2, 1);
        }
        setBallType(e);
        setBallNumber([]);
        break;
      case '大':
        index = e.indexOf('对子号');
        index2 = e.indexOf('小');
        if (index > -1) {
          e.splice(index, 1);
        }
        if (index2 > -1) {
          e.splice(index2, 1);
        }
        setBallType(e);
        setBallNumber([]);
        break;
      case '小':
        index = e.indexOf('对子号');
        index2 = e.indexOf('大');
        if (index > -1) {
          e.splice(index, 1);
        }
        if (index2 > -1) {
          e.splice(index2, 1);
        }
        setBallType(e);
        setBallNumber([]);
        break;

      default:
        setBallNumber(e);
        setBallType([]);
        break;
    }
  } else {
    setBallNumber(e);
    setBallType([]);
  }
};

const watchConfirmButton = (filter, setFilter) => {
  const index = filter.indexOf('numberSeparate');
  const tempFilter = [...filter];
  if (index > -1) {
    tempFilter.splice(index, 1);
  }

  setFilter(tempFilter);
};

const generateNumberBall = (group) => {
  let result = [];
  for (
    let index = constGameBallStartNumber[group];
    index <= constGameBallNumber[group];
    index++
  ) {
    const obj = {
      text: '号码' + index,
      value: index,
    };
    result.push(obj);
  }

  return result;
};

const FilterOption = ({
  group,
  isMobile,
  ballNumber,
  setBallNumber,
  setBallType,
  ballType,
  filter,
  setFilter,
}) => {
  const numberBall = generateNumberBall(group);

  const index = filter.indexOf('numberSeparate');

  const tempFilter = [...filter];

  index > -1 && tempFilter.splice(index, 1);

  return (
    <>
      {isMobile ? (
        <Drawer
          visible={filter.includes('numberSeparate')}
          onClose={() => (index > -1 ? setFilter(tempFilter) : '')}>
          <FilterOptionWrapper>
            <MobileOption
              key="number"
              title="查看球号分布:"
              option={numberBall}
              activeValue={ballNumber}
              onClickAction={(e) =>
                watchBallTypeLogic(e, setBallNumber, setBallType)
              }
            />
            {!notDisplayTypeFilterGroup.includes(group) && (
              <MobileOption
                key="type"
                title="大小单双分布:"
                option={numberSeparateByType}
                activeValue={ballType}
                onClickAction={(e) =>
                  watchBallTypeLogic(e, setBallNumber, setBallType)
                }
              />
            )}
            <Row className="action mobile">
              <Col span={12}>
                <button
                  onClick={() => {
                    setBallNumber([]);
                    setBallType([]);
                  }}>
                  {transl('清空')}
                </button>
              </Col>
              <Col span={12}>
                <button
                  className="confirm"
                  onClick={() => {
                    watchConfirmButton(filter, setFilter);
                  }}>
                  {transl('确定')}
                </button>
              </Col>
            </Row>
          </FilterOptionWrapper>
        </Drawer>
      ) : (
        <FilterOptionWrapper>
          <PCOption
            group={group}
            activeNumber={ballNumber}
            activeType={ballType}
            resetAction={() => {
              setBallNumber([]);
              setBallType([]);
            }}
            setBallNumber={(e) =>
              watchPCBallNumberLogic(e, ballNumber, setBallNumber, setBallType)
            }
            setBallType={(e) =>
              watchPCBallTypeLogic(e, ballType, setBallType, setBallNumber)
            }
            numberBall={numberBall}
          />
        </FilterOptionWrapper>
      )}
    </>
  );
};

export default isMobile(FilterOption);

const FilterOptionWrapper = styled(Col)`
  background-color: ${color.white};
  padding: 15px 25px;
  border-bottom: 1px solid ${color.borderGrey};

  > div > p {
    font-weight: 400;
  }

  .dragon-result-row {
    .ant-col {
      display: flex;

      div {
        text-align: center;
        width: 86px;
        padding: 9px 0;
        border: 1px solid ${color.borderGrey};

        @media only screen and (max-width: 576px) {
          width: auto;
          padding: 7px 10px;
        }

        &:first-child {
          border-right: none;
        }
      }

      .title {
        font-weight: 600;
        background-color: ${color.backgroundGrey};
      }
    }
  }

  @media only screen and (max-width: 576px) {
    // position: absolute;
    // top: 87px;
    // left: 0;
    padding: 0;
    // z-index: 5;
    // overflow-y: auto;
    max-height: calc(100vh - 87px);

    button {
      color: ${color.mobileBlack};
      background-color: transparent;
      border: none;
      padding: 0;
      display: block;
      width: 100%;
      height: 100%;

      &:focus {
        outline: none;
      }
    }

    .filter {
      max-height: 33vh;
      overflow: auto;
      padding: 13px 21px 0px 26px;
      border-top: 1px solid ${color.borderGrey};

      .filter-title {
        font-size: 15px;
        color: ${color.lightFontGrey};
        line-height: 16px;
        margin-bottom: 13px;
      }

      .ant-checkbox-group {
        width: 100%;

        .filter-checkbox {
          margin-bottom: 18px;

          &:last-child {
            flex: 0 0 50%;
            max-width: 50%;
          }

          span {
            font-size: 14px;
            color: ${color.mobileBlack};
          }
        }
      }
    }
  }
`;

const TitleTextWrapper = styled.div`
  @media only screen and (max-width: 576px) {
    display: none;
  }
`;

import React, { useState, useEffect } from 'react';
import { toJS } from 'mobx';
import { inject, observer } from 'mobx-react';
import Filter from './Filter';

import withLocation from '@helpers/hoc/withLocation';
import isMobile from '@helpers/hoc/isMobile';

import {
  constLuZhuName,
  constLuZhuOptionBall,
  constLuZhuMobileBall,
} from '@const/playRule';

import { openCodeToArray } from '@helpers/utils';
import FilterResult from './FilterResult';
import { getSelectAllOption } from '@helpers/utils';

import PlayRuleTitle from '@components/common/playRuleTitle';

import Reference from '@components/common/reference';

const { bigSmall, oddEven } = constLuZhuName;

const DaXiaoDanShuangLuzhu = ({ trendsStore, search, isMobile }) => {
  const { group } = search;

  const { singleGame } = trendsStore;

  const optionType = [bigSmall, oddEven];
  const optionBall = constLuZhuOptionBall[group].slice(0, -1);
  const selectAllValueType = getSelectAllOption(optionType);

  const [filterBall, setFilterBall] = useState([
    constLuZhuOptionBall[group][0].value,
  ]);
  const [filterType, setFilterType] = useState(selectAllValueType);

  useEffect(() => {
    if (isMobile) {
      setFilterBall(constLuZhuMobileBall[group][0].value);
    }
  }, [isMobile, group]);

  let openCodeArray;
  if (singleGame) {
    openCodeArray = openCodeToArray(toJS(singleGame));
  }

  const content = ['B - Big', 'S - Small', 'E - Even', 'O - Odd'];

  return (
    <>
      {!isMobile && <PlayRuleTitle title="大小单双路珠" />}
      <Filter
        optionBall={optionBall}
        filterBall={filterBall}
        setFilterBall={setFilterBall}
        optionType={optionType}
        filterType={filterType}
        setFilterType={setFilterType}
        group={group}
      />

      <Reference content={content} sticky={true} />

      <FilterResult
        openCodeArray={openCodeArray}
        filterBall={filterBall}
        filterType={filterType}
        optionBall={optionBall}
        group={group}
      />
    </>
  );
};

export default isMobile(
  withLocation(inject('trendsStore')(observer(DaXiaoDanShuangLuzhu))),
);

import React, { useEffect, useState } from 'react';
import { toJS } from 'mobx';
import { Row, Col } from 'antd';
import styled from 'styled-components';
import { inject, observer } from 'mobx-react';

import { color } from '@helpers/const';
import withLocation from '@helpers/hoc/withLocation';

import { constPlayGroup, constSuggestPlanBetRange } from '@const/game';
import { constSuggestPlan, arrayZodiac } from '@const/playRule';

import { DrawNumber } from '@components/common/number';
import { SummarizeTable } from '@components/common/table';

import {
  arrayStringToArrayInt,
  convertNumberToWuXingXiao,
  getCurrentYearZodiacByDate,
  to2DecimalNoRounding,
  convertOpenTimeToChineseYear,
} from '@helpers/utils';

const { SSC, PK10, D11, K3, KL10F, PCDD, SIX } = constPlayGroup;

function getRandomInt(plan, min, max, searchPlayGroup) {
  let maxDigit = constSuggestPlan[plan].betNumber[searchPlayGroup];

  const numberRange = [];
  for (let index = min; index <= max; index++) {
    numberRange.push(index);
  }

  const result = [];
  while (result.length < maxDigit) {
    const num = Math.floor(Math.random() * numberRange.length);
    result.push(numberRange[num]);
    numberRange.splice(num, 1);
  }

  return result.sort();
}

const MainTable = ({
  rootStore,
  trendsStore,
  search,
  filterPlan,
  setBetDetail,
}) => {
  const { searchPlayGroup, searchGame } = search;

  const { markSixByYearConversion } = rootStore;
  const { histByDate, prizeSettings } = trendsStore;
  const { allGamesPrizeSettings } = prizeSettings;

  const [planNumberState, setPlanNumberState] = useState([[], [], []]);
  const [dataSourceArray, setDataSourceArray] = useState([]);

  useEffect(() => {
    const tempDataSourceArray = [];
    if (
      histByDate &&
      prizeSettings &&
      Object.keys(allGamesPrizeSettings).length !== 0
    ) {
      let todayDate;
      for (let index = 0; index < histByDate.length; index++) {
        const { uniqueIssueNumber, openCode, officialOpenTime } = histByDate[
          index
        ];
        const openTime = officialOpenTime.slice(
          11,
          officialOpenTime.length - 6,
        );
        const openDate = officialOpenTime.slice(0, 10);

        let arrayNumbers = arrayStringToArrayInt(openCode);

        if (searchPlayGroup === SIX) {
          arrayNumbers = arrayNumbers.slice(0, 6);
        }

        const chineseYear = convertOpenTimeToChineseYear(officialOpenTime);
        const markSixConversion = markSixByYearConversion[chineseYear];
        const { xiaoMap } = markSixConversion;

        const obj = {
          key: uniqueIssueNumber + openTime,
          开奖期数: uniqueIssueNumber + ' 期',
          开奖结果: [arrayNumbers, '', xiaoMap],
          开奖时间: openTime,
        };
        todayDate = openDate;
        tempDataSourceArray.push(obj);
      }

      const reverseHistByDate = toJS(histByDate).slice().reverse();
      const currentBetAmount =
        constSuggestPlan[filterPlan].betNumber[searchPlayGroup];

      let planNumber = [];
      let bkHit;
      let currentPlanNumber;
      let bet = currentBetAmount;
      let totalBet = 0;
      let profit = 0;
      let countLose = 1;

      let thisYearZodiac;

      let gamePrizeAmount = 1;
      switch (searchPlayGroup) {
        case SSC:
        case PK10:
          gamePrizeAmount =
            allGamesPrizeSettings[searchGame].singleGamePrizeSettings.DN
              .prizeSettings[0].prizeAmount;
          break;
        case D11:
          gamePrizeAmount =
            allGamesPrizeSettings[searchGame].singleGamePrizeSettings.Q1Z
              .prizeSettings[0].prizeAmount;
          break;
        case SIX:
          thisYearZodiac = getCurrentYearZodiacByDate(todayDate);
          break;

        default:
          break;
      }

      for (let index = 0; index < reverseHistByDate.length; index++) {
        const { openCode, officialOpenTime } = reverseHistByDate[index];
        const arrayNumbers = arrayStringToArrayInt(openCode);
        const chineseYear = convertOpenTimeToChineseYear(officialOpenTime);
        const markSixConversion = markSixByYearConversion[chineseYear];
        const { xiaoMap } = markSixConversion;

        if (planNumberState[filterPlan][index]) {
          currentPlanNumber = planNumberState[filterPlan][index];
          planNumber.push(planNumberState[filterPlan][index]);

          if (index === 0 || bkHit) {
            bet = currentBetAmount;
            countLose = 1;
          } else {
            if (countLose === 10) {
              bet = currentBetAmount;
              countLose = 1;
            } else {
              bet = bet * 2;
              countLose++;
            }
          }
        } else {
          if (index === 0 || bkHit) {
            currentPlanNumber = getRandomInt(
              filterPlan,
              constSuggestPlanBetRange[searchPlayGroup].from,
              constSuggestPlanBetRange[searchPlayGroup].to,
              searchPlayGroup,
            );
            planNumber.push(currentPlanNumber);
            bet = currentBetAmount;
            countLose = 1;
          } else {
            if (countLose === 10) {
              currentPlanNumber = getRandomInt(
                filterPlan,
                constSuggestPlanBetRange[searchPlayGroup].from,
                constSuggestPlanBetRange[searchPlayGroup].to,
                searchPlayGroup,
              );
              planNumber.push(currentPlanNumber);
              bet = currentBetAmount;
              countLose = 1;
            } else {
              planNumber.push(currentPlanNumber);
              bet = bet * 2;
              countLose++;
            }
          }
        }

        totalBet = totalBet + bet;
        let hit = currentPlanNumber.includes(arrayNumbers[0]);
        bkHit = hit;

        let sumOfOpenCode = 0;
        if (searchPlayGroup === SIX) {
          const prizeZodiac = convertNumberToWuXingXiao(
            arrayNumbers.slice(6, 7),
            xiaoMap,
          )[0][1];
          const prizeIndex = thisYearZodiac === prizeZodiac ? 0 : 1;

          gamePrizeAmount = to2DecimalNoRounding(
            allGamesPrizeSettings[searchGame].singleGamePrizeSettings.SX
              .prizeSettings[prizeIndex].prizeAmount,
          );
        } else if (searchPlayGroup === K3) {
          sumOfOpenCode = arrayNumbers.reduce((a, b) => a + b, 0);
          const sumOfOpenCodeIndex = sumOfOpenCode - 3;
          gamePrizeAmount = to2DecimalNoRounding(
            allGamesPrizeSettings[searchGame].singleGamePrizeSettings.HZ
              .prizeSettings[sumOfOpenCodeIndex].prizeAmount,
          );
          hit = currentPlanNumber.includes(sumOfOpenCode);
          bkHit = hit;
        } else if (searchPlayGroup === KL10F) {
          gamePrizeAmount = to2DecimalNoRounding(
            allGamesPrizeSettings[searchGame].singleGamePrizeSettings.ST1
              .prizeSettings[0].prizeAmount,
          );
        } else if (searchPlayGroup === PCDD) {
          sumOfOpenCode = arrayNumbers.reduce((a, b) => a + b, 0);

          hit = currentPlanNumber.includes(sumOfOpenCode);
          bkHit = hit;

          gamePrizeAmount = to2DecimalNoRounding(
            allGamesPrizeSettings[searchGame].singleGamePrizeSettings.SP
              .prizeSettings[sumOfOpenCode].prizeAmount,
          );
        } else if (searchPlayGroup === '3D') {
          gamePrizeAmount = to2DecimalNoRounding(
            allGamesPrizeSettings[searchGame].singleGamePrizeSettings.SP
              .prizeSettings[0].prizeAmount,
          );
        }

        const winLoseAmount = (bet / currentBetAmount) * gamePrizeAmount;

        if (hit && searchPlayGroup !== SIX) {
          profit = profit + winLoseAmount;
        } else if (hit === false && searchPlayGroup !== SIX) {
          profit = profit - bet;
        }

        const reverseDataSourceArray =
          tempDataSourceArray[reverseHistByDate.length - 1 - index];
        reverseDataSourceArray.开奖结果[1] = hit;
        reverseDataSourceArray.第一球购彩 = [
          currentPlanNumber,
          arrayNumbers[0],
        ];
        reverseDataSourceArray.当前成本 = bet;
        reverseDataSourceArray.累计成本 = totalBet;

        if (searchPlayGroup === SIX) {
          const resultZodiac = convertNumberToWuXingXiao(
            [arrayNumbers[6]],
            xiaoMap,
          );

          const currentPlanNumberZodiac = [];
          for (let index = 0; index < currentPlanNumber.length; index++) {
            currentPlanNumberZodiac.push(arrayZodiac[currentPlanNumber[index]]);
          }

          const zodiacHit = currentPlanNumberZodiac.includes(
            resultZodiac[0][1],
          );
          bkHit = zodiacHit;

          if (zodiacHit) {
            profit = profit + winLoseAmount;
          } else {
            profit = profit - bet;
          }

          reverseDataSourceArray.特码 = [
            arrayNumbers.slice(6, 7),
            xiaoMap,
            zodiacHit,
          ];
          reverseDataSourceArray.第一球购彩[1] = resultZodiac[0][1];
        }

        if (searchPlayGroup === K3) {
          reverseDataSourceArray.和值 = [sumOfOpenCode, hit];
          reverseDataSourceArray.第一球购彩[1] = sumOfOpenCode;
        }

        if (searchPlayGroup === PCDD) {
          reverseDataSourceArray.和值 = [sumOfOpenCode, hit];
          reverseDataSourceArray.第一球购彩[1] = sumOfOpenCode;
        }
        reverseDataSourceArray.赢利亏损 = to2DecimalNoRounding(profit);

        if (reverseHistByDate.length - 1 === index) {
          const betDetailObj = {
            betNumber: currentPlanNumber.join(),
            currentCost: bet,
            totalCost: totalBet,
            totalProfit: to2DecimalNoRounding(profit),
          };
          setBetDetail(betDetailObj);
        }
      }

      const tempPlanNumber = planNumberState.slice();
      tempPlanNumber[filterPlan] = planNumber;
      setPlanNumberState(tempPlanNumber);
    }

    setDataSourceArray(tempDataSourceArray);
  }, [
    histByDate,
    prizeSettings,
    searchPlayGroup,
    filterPlan,
    searchGame,
    allGamesPrizeSettings,
    markSixByYearConversion,
    planNumberState,
    setBetDetail,
  ]);

  let columns = [];
  if (histByDate && histByDate.length && prizeSettings) {
    const planTitle =
      searchPlayGroup === K3
        ? '和值购彩'
        : searchPlayGroup === SIX
        ? '特码购彩'
        : '第一球购彩';

    columns = [
      {
        title: '开奖期数',
        dataIndex: '开奖期数',
        width: searchPlayGroup === K3 || searchPlayGroup === KL10F ? 100 : 130,
      },
      {
        title: '开奖结果',
        dataIndex: '开奖结果',
        className: searchPlayGroup,
        width:
          searchPlayGroup === PK10
            ? 430
            : searchPlayGroup === SIX
            ? 360
            : searchPlayGroup === K3 || searchPlayGroup === PCDD
            ? 120
            : searchPlayGroup === KL10F
            ? 180
            : searchPlayGroup === '3D'
            ? 200
            : '',
        render: (value, row, index) => {
          return (
            <Row
              justify={searchPlayGroup !== KL10F && 'center'}
              gutter={[10, 10]}
              style={{ marginBottom: '-5px' }}>
              {value[0].map((num, index) => {
                return (
                  <Col key={index + ' ' + num} className="table-number">
                    {value[1] &&
                      index === 0 &&
                      searchPlayGroup !== SIX &&
                      searchPlayGroup !== K3 &&
                      searchPlayGroup !== PCDD && (
                        <div className={'hit ' + searchPlayGroup}>中</div>
                      )}
                    {
                      <DrawNumber
                        group={searchPlayGroup}
                        xiaoMap={toJS(value[2])}
                        cssStyle={{
                          opacity:
                            index > 0 ||
                            searchPlayGroup === SIX ||
                            searchPlayGroup === K3 ||
                            searchPlayGroup === PCDD
                              ? 0.3
                              : 1,
                        }}
                        number={num}
                      />
                    }
                  </Col>
                );
              })}
            </Row>
          );
        },
      },
      {
        title: planTitle + ' ' + constSuggestPlan[filterPlan].text,
        dataIndex: '第一球购彩',
        className: searchPlayGroup,
        width:
          searchPlayGroup === PK10
            ? 240
            : searchPlayGroup === KL10F
            ? 180
            : searchPlayGroup === PCDD
            ? 380
            : searchPlayGroup === SIX
            ? 220
            : 270,
        render: (value, row, index) => {
          return (
            <Row
              justify={searchPlayGroup !== KL10F && 'center'}
              gutter={[10, 10]}
              style={{ marginBottom: '-5px' }}>
              {value &&
                value[0].map((num, index) => {
                  let number = num;
                  if (searchPlayGroup === SIX) {
                    number = arrayZodiac[number];
                  }
                  return (
                    <Col key={index + ' ' + number} className="table-number">
                      {number === value[1] && (
                        <div className={'hit ' + searchPlayGroup}>中</div>
                      )}
                      {
                        <DrawNumber
                          group={searchPlayGroup === K3 ? SSC : searchPlayGroup}
                          number={number}
                          mode="zodiac"
                        />
                      }
                    </Col>
                  );
                })}
            </Row>
          );
        },
      },
      {
        title: '当前成本',
        dataIndex: '当前成本',
        width: 80,
      },
      {
        title: '累计成本',
        dataIndex: '累计成本',
        width: 80,
      },
      {
        title: '赢利/亏损',
        dataIndex: '赢利亏损',
        width: 80,
        render: (value, row, index) => {
          return (
            <span className={value > 0 ? 'red-text' : 'blue-text'}>
              {value}
            </span>
          );
        },
      },
      {
        title: '开奖时间',
        dataIndex: '开奖时间',
        width: 80,
      },
    ];

    if (searchPlayGroup === SIX) {
      const obj = {
        title: '特码',
        dataIndex: '特码',
        className: 'SIX-special',
        width: 90,
        render: (value, row) => {
          return (
            <Row justify="center">
              <Col className={'table-number ' + searchPlayGroup}>
                {value[2] && <div className="hit special">中</div>}
                {value && value.length && value[0].length && (
                  <DrawNumber
                    group={searchPlayGroup}
                    number={value[0][0]}
                    xiaoMap={value[1]}
                  />
                )}
              </Col>
            </Row>
          );
        },
      };

      columns.splice(2, 0, obj);
    }

    if (searchPlayGroup === K3 || searchPlayGroup === PCDD) {
      const obj = {
        title: '和值',
        dataIndex: '和值',
        width: 70,
        render: (value, row) => {
          return (
            <Row justify="center">
              <Col className="table-number">
                {value[1] && <div className="hit sum">中</div>}
                <div>{value[0]}</div>
              </Col>
            </Row>
          );
        },
      };

      columns.splice(2, 0, obj);
    }
  }

  return (
    <TableWrapper>
      <SummarizeTable
        tableData={dataSourceArray}
        tableHeader={columns}
        pagination={true}
      />
    </TableWrapper>
  );
};

export default withLocation(
  inject('rootStore', 'trendsStore')(observer(MainTable)),
);

const TableWrapper = styled.div`
  padding: 0 25px 20px;
  background-color: ${color.white};

  table {
    tr {
      th,
      td {
        padding: 6px 0;

        // .ant-row {
        //     flex-flow: nowrap;
        // }

        .table-number {
          position: relative;

          div {
            margin: 0;
          }

          .hit {
            position: absolute;
            width: 18px;
            height: 18px;
            font-size: 10px;
            line-height: 16px;
            border-radius: 50%;
            color: ${color.white};
            background-color: ${color.red};
            border: 1px solid ${color.white};
            margin: 0;
            z-index: 1;
            top: 0;
            right: -5px;

            &.special {
              top: -5px;
              right: 13px;
            }

            &.sum {
              top: -5px;
              right: -15px;
            }

            &.SIX {
              right: -5px;
            }
          }
        }

        &.SIX-special {
          .table-number {
            position: relative;

            div {
              margin: 0;
            }

            > span {
              > span {
                margin-right: 10px;
              }
            }
          }
        }

        &.KL10F {
          padding: 6px;
        }
      }
    }
  }
`;

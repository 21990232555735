import React from 'react';
import styled from 'styled-components';
import { inject, observer } from 'mobx-react';
import { toJS } from 'mobx';

import { arrayStringToArrayInt } from '@helpers/utils';
import { color } from '@helpers/const';
import withLocation from '@helpers/hoc/withLocation';
import isMobile from '@helpers/hoc/isMobile';
import { getDataAnalysis } from '@helpers/utils';

import {
  constPlayGroup,
  constTotalCodeDigit,
  constGameBallNumber,
  constGameBallStartNumber,
} from '@const/game';
import { constTrendBallList } from '@const/playRule';

import TrendsChartTable from '@components/common/trendsChartTable';

const { KL10F } = constPlayGroup;

const getTableHeaderData = () => {
  let columns = [
    {
      title: '期数',
      dataIndex: '期数',
      width: 120,
      sorter: true,
    },
    {
      title: '开奖号码',
      dataIndex: '开奖号码',
      width: 120,
      className: 'open-code',
    },
  ];

  return columns;
};

const getTableData = (singleGame, group, isMobile) => {
  let arrayNumberOpenCode = [];

  singleGame.map((game) => {
    return arrayNumberOpenCode.push(arrayStringToArrayInt(game.openCode));
  });

  let dataSourceArray = [];

  for (let index = 0; index < singleGame.length; index++) {
    const stringIssueNumber = singleGame[index].uniqueIssueNumber.toString();
    const shortenIssueNumber =
      stringIssueNumber.length > 4
        ? stringIssueNumber.slice(stringIssueNumber.length - 4)
        : stringIssueNumber;
    let data = {
      key: index,
      uniqueIssueNumber: isMobile ? shortenIssueNumber : stringIssueNumber,
      openCode: arrayNumberOpenCode[index],
    };
    dataSourceArray.push(data);
  }

  return dataSourceArray;
};

const getTableHeaderAnalysis = (group) => {
  let result = [];
  switch (group) {
    case KL10F:
      const headerKL10F = ['小区', '大区'];

      let startIndex = [1, 11];

      let maxIndex = [10, 20];

      for (let index = 0; index < headerKL10F.length; index++) {
        let ball = [];

        let header = {
          title: headerKL10F[index],
          dataIndex: headerKL10F[index],
        };

        for (
          let indexChild = startIndex[index];
          indexChild <= maxIndex[index];
          indexChild++
        ) {
          const ballCol = {
            title: indexChild.toString(),
            dataIndex: indexChild,
          };
          ball.push(ballCol);
        }

        header.children = ball;
        result.push(header);
      }
      break;
    default:
      for (let index = 0; index < constTotalCodeDigit[group]; index++) {
        let header = {
          title: constTrendBallList[index].text,
          dataIndex: constTrendBallList[index].text,
        };

        let ball = [];
        for (
          let indexChild = constGameBallStartNumber[group];
          indexChild <= constGameBallNumber[group];
          indexChild++
        ) {
          const ballCol = {
            title: indexChild.toString(),
            dataIndex: constTrendBallList[index].text + indexChild,
          };
          ball.push(ballCol);
        }

        header.children = ball;
        result.push(header);
      }
      break;
  }

  return result;
};

const getTableDataAnalysis = (singleGame, group, ball, isMobile) => {
  let arrayNumberOpenCode = [];

  singleGame.map((game) => {
    return arrayNumberOpenCode.push(arrayStringToArrayInt(game.openCode));
  });

  let dataSourceArray = [];
  let count = [];
  let countAppear = [];
  const countAverage = [];
  let countBiggest = [];
  const connectResult = [];

  let resultTest;
  switch (group) {
    case KL10F:
      const appear = [];
      const biggest = [];
      let startBallNumber = 1;
      let maxBallNumber = 10;

      if (isMobile) {
        if (ball === 1) {
          startBallNumber = 11;
          maxBallNumber = 20;
        }
      } else {
        maxBallNumber = 20;
      }

      const calAvgSet = [];
      for (let index = startBallNumber; index <= maxBallNumber; index++) {
        count[index] = 1;
        appear.push(0);
        biggest.push(1);
        calAvgSet.push([]);
        connectResult.push(0);
      }
      countAppear.push(appear);
      countBiggest.push(biggest);

      let data = [];
      let checkConnectNonAppear = [];

      for (let indexRow = 0; indexRow < singleGame.length; indexRow++) {
        let row = [];
        for (
          let indexBallNumber = startBallNumber;
          indexBallNumber <= maxBallNumber;
          indexBallNumber++
        ) {
          let col;

          if (arrayNumberOpenCode[indexRow].includes(indexBallNumber)) {
            checkConnectNonAppear[indexBallNumber] = false;
            col = [
              indexBallNumber,
              true,
              checkConnectNonAppear[indexBallNumber],
            ];
            count[indexBallNumber] = 1;

            if (ball === 1) {
              countAppear[0][indexBallNumber - 11]++;
            } else {
              countAppear[0][indexBallNumber - 1]++;
            }
          } else {
            let nonAppear = false;
            if (
              checkConnectNonAppear[indexBallNumber] === true ||
              checkConnectNonAppear[indexBallNumber] === undefined
            ) {
              checkConnectNonAppear[indexBallNumber] = true;
              nonAppear = true;
            }

            col = [count[indexBallNumber], false, nonAppear];

            if (ball === 1) {
              if (
                countBiggest[0][indexBallNumber - 11] < count[indexBallNumber]
              ) {
                countBiggest[0][indexBallNumber - 11] = count[indexBallNumber];
              }
            } else {
              if (
                countBiggest[0][indexBallNumber - 1] < count[indexBallNumber]
              ) {
                countBiggest[0][indexBallNumber - 1] = count[indexBallNumber];
              }
            }

            count[indexBallNumber]++;
          }
          row.push(col);
        }
        data.push(row);
      }
      dataSourceArray.push(data);

      let avgCont;
      if (isMobile) {
        avgCont = new Array(10).fill(0);
      } else {
        avgCont = new Array(20).fill(0);
      }

      const calConnectSet = new Array(20).fill(0);
      //gather avr array
      for (let indexRow = 0; indexRow < dataSourceArray[0].length; indexRow++) {
        for (
          let indexBall = 0;
          indexBall < dataSourceArray[0][indexRow].length;
          indexBall++
        ) {
          if (dataSourceArray[0][indexRow][indexBall][1] === false) {
            avgCont[indexBall]++;
            if (indexRow === dataSourceArray[0].length - 1) {
              calAvgSet[indexBall].push(avgCont[indexBall]);
              avgCont[indexBall] = 0;
            }

            // reset connect 0
            calConnectSet[indexBall] = 0;
          } else {
            if (avgCont[indexBall]) {
              calAvgSet[indexBall].push(avgCont[indexBall]);
              avgCont[indexBall] = 0;
            }

            // still connect ++
            calConnectSet[indexBall]++;
            if (connectResult[indexBall] < calConnectSet[indexBall]) {
              connectResult[indexBall] = calConnectSet[indexBall];
            }
          }
        }
      }

      // connectResult.push(connectResultRow);

      // calculate avg
      for (let index = 0; index < calAvgSet.length; index++) {
        countAverage.push(
          Math.round(
            calAvgSet[index].reduce((a, b) => a + b, 0) /
              calAvgSet[index].length,
          ),
        );
      }

      break;
    default:
      resultTest = getDataAnalysis(group, arrayNumberOpenCode);
      break;
  }

  const result = resultTest
    ? resultTest
    : {
        table: dataSourceArray,
        appear: countAppear,
        average: [countAverage],
        biggest: countBiggest,
        connect: [connectResult],
      };

  return result;
};

const MainTable = ({ trendsStore, search, isMobile, filter, ball }) => {
  const { singleGame } = trendsStore;

  const { group } = search;

  const headerData = getTableHeaderData();
  const headerAnalysis = getTableHeaderAnalysis(group, isMobile, ball);
  const tableData = singleGame
    ? getTableData(toJS(singleGame), group, isMobile)
    : [];
  const tableDataAnalysis = singleGame
    ? getTableDataAnalysis(toJS(singleGame), group, ball, isMobile)
    : [];

  return (
    <TrendsResultWrapper>
      <TrendsChartTable
        headerData={headerData}
        tableData={tableData}
        headerAnalysis={headerAnalysis}
        tableDataAnalysis={tableDataAnalysis}
        group={group}
        filter={filter}
        ball={ball}
      />
    </TrendsResultWrapper>
  );
};

export default isMobile(
  withLocation(inject('trendsStore')(observer(MainTable))),
);

const TrendsResultWrapper = styled.div`
  background-color: ${color.white};
`;

import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { Row, Col } from 'antd';

import { Images } from '@components/common/images';
import PKCard from '@components/common/pkCard';

import { color } from '@helpers/const';
import { constPlayGroup, constTotalCodeDigit } from '@const/game';
import { constSixGameNumberColor } from '@const/playRule';
import {
  arrayStringToArrayInt,
  sumOfNumber,
  convertNumberToWuXingXiao,
} from '@helpers/utils';

const { dice1, dice2, dice3, dice4, dice5, dice6 } = Images;
const {
  SSC,
  D11,
  PK10,
  XY10,
  K3,
  KL10F,
  PCDD,
  THREED,
  XPK,
  SIX,
  MAX3D,
} = constPlayGroup;

export const BoxNumber = ({ number, cssStyle, size }) => {
  return (
    <NumberBox
      data-number={number}
      style={cssStyle}
      className={size ? size + ' number-box' : 'number-box'}></NumberBox>
  );
};

const NumberBox = styled.div`
  width: 36px;
  height: 36px;
  margin-right: 6.1px;

  &.big {
    width: 42.7px;
    height: 42.7px;

    @media only screen and (max-width: 576px) {
      width: 20px;
      height: 20px;
    }
  }

  &:last-child {
    margin-right: 0;
  }

  &[data-number='1'] {
    background: url(${Images.pkf1}) center/cover;
  }

  &[data-number='2'] {
    background: url(${Images.pkf2}) center/cover;
  }

  &[data-number='3'] {
    background: url(${Images.pkf3}) center/cover;
  }

  &[data-number='4'] {
    background: url(${Images.pkf4}) center/cover;
  }

  &[data-number='5'] {
    background: url(${Images.pkf5}) center/cover;
  }

  &[data-number='6'] {
    background: url(${Images.pkf6}) center/cover;
  }

  &[data-number='7'] {
    background: url(${Images.pkf7}) center/cover;
  }

  &[data-number='8'] {
    background: url(${Images.pkf8}) center/cover;
  }

  &[data-number='9'] {
    background: url(${Images.pkf9}) center/cover;
  }

  &[data-number='10'] {
    background: url(${Images.pkf10}) center/cover;
  }
`;

export const DiceNumber = ({ number, cssStyle, size }) => {
  return (
    <Dice
      data-number={number}
      style={cssStyle}
      className={size ? size : ''}></Dice>
  );
};
const Dice = styled.div`
  width: 36px;
  height: 36px;
  margin-right: 6.1px;

  &.big {
    width: 42.7px;
    height: 42.7px;

    @media only screen and (max-width: 576px) {
      width: 30px;
      height: 30px;
    }
  }

  &:last-child {
    margin-right: 0;
  }

  &[data-number='1'] {
    background: url(${dice1}) center/cover;
  }

  &[data-number='2'] {
    background: url(${dice2}) center/cover;
  }

  &[data-number='3'] {
    background: url(${dice3}) center/cover;
  }

  &[data-number='4'] {
    background: url(${dice4}) center/cover;
  }

  &[data-number='5'] {
    background: url(${dice5}) center/cover;
  }

  &[data-number='6'] {
    background: url(${dice6}) center/cover;
  }
`;

export const CircleNumber = ({ number, cssStyle, size }) => {
  return (
    <CirlceNumberItem style={cssStyle} className={size ? size : ''}>
      {number}
    </CirlceNumberItem>
  );
};

export const CircleNumberRolling = ({ playGroup, size }) => {
  const [number, setNumber] = useState(0);
  useEffect(() => {
    const digit = playGroup === K3 ? 6 : 10;
    const interval = setInterval(function () {
      const randNum = Math.floor(Math.random() * digit) + 1;
      setNumber(randNum);
    }, 200);
    return () => clearInterval(interval);
  }, [playGroup]);

  let numberItem;

  switch (playGroup) {
    case SSC:
    case D11:
    case KL10F:
    case SIX:
    case THREED:
      numberItem = (
        <CirlceNumberItem className={(size ? size : '') + ' rolling'}>
          {number}
        </CirlceNumberItem>
      );
      break;
    case PK10:
    case XY10:
      numberItem = <BoxNumber number={number} size={size} />;
      break;
    case K3:
      numberItem = <DiceNumber number={number} size={size} />;
      break;
    case XPK:
      numberItem = <PKCard number={number} flower={number} />;
      break;
    default:
      numberItem = (
        <CirlceNumberItem className={(size ? size : '') + ' rolling'}>
          {number}
        </CirlceNumberItem>
      );
      break;
  }

  return numberItem;
};

const CirlceNumberItem = styled.div`
  width: 36px;
  height: 36px;
  text-align: center;
  font-size: 18px;
  line-height: 2.1;
  color: ${color.white};
  border-radius: 50%;
  background-color: ${color.lightYellow};
  overflow: hidden;

  &.big {
    width: 45px;
    height: 45px;
    font-size: 26px;
    line-height: 1.7;

    @media only screen and (max-width: 576px) {
      width: 30px;
      height: 30px;
      font-size: 18px;
    }
  }

  &.medium {
    width: 40px;
    height: 40px;
    font-size: 21px;
    line-height: 40px;
  }

  &.rolling {
    animation: rolling 0.2s infinite linear;
  }

  @keyframes rolling {
    from {
      line-height: 0;
    }
    to {
      line-height: 5;
    }
  }
`;

const countPCDDNumber = (kaiJiangHao, size) => {
  let result = [];
  for (let index = 0; index < kaiJiangHao.length; index++) {
    result.push(
      <Col key={index}>
        <CircleNumber number={kaiJiangHao[index]} size={size} />
      </Col>,
    );
    if (index !== kaiJiangHao.length - 1) {
      result.push(
        <Col key={index + '+'}>
          <Symbol className="plus">+</Symbol>
        </Col>,
      );
    }
  }
  const total = sumOfNumber(kaiJiangHao);
  result.push(
    <Col key="=">
      <Symbol className="equal">=</Symbol>
    </Col>,
  );
  result.push(
    <Col key="total">
      <CircleNumber
        number={total}
        size={size}
        cssStyle={{ backgroundColor: color.orange }}
      />
    </Col>,
  );

  return result;
};

const Symbol = styled.span`
  font-size: 26px;
`;

const getPKNumber = (kaiJiangHao) => {
  let result = [];

  for (let index = 0; index < kaiJiangHao.length; index++) {
    const sNumber = kaiJiangHao[index].toString();
    const flower = sNumber.charAt(0);
    const number = parseInt(sNumber.substring(1, 3));
    result.push(
      <Col key={index}>
        <PKCard flower={flower} number={number} />
      </Col>,
    );
  }

  return result;
};

export const NumberRow = ({
  kaiJiangHao,
  playGroup,
  kaiJiangStyle,
  size,
  rolling,
}) => {
  kaiJiangHao = arrayStringToArrayInt(kaiJiangHao);

  return (
    <Row
      gutter={[size === 'big' || size === 'medium' ? 10 : 6.1, 5]}
      style={{ marginBottom: '-2.5px', marginTop: '-2.5px' }}
      className={size ? size : ''}>
      {kaiJiangHao && !rolling ? (
        kaiJiangStyle === SSC ||
        kaiJiangStyle === D11 ||
        kaiJiangStyle === SIX ||
        kaiJiangStyle === THREED ||
        kaiJiangStyle === '3D' ||
        kaiJiangStyle === KL10F ? (
          kaiJiangHao.map((num, index) => {
            return (
              <Col key={index}>
                <CircleNumber number={num} size={size} />
              </Col>
            );
          })
        ) : kaiJiangStyle === MAX3D ? (
          kaiJiangHao.map((num, index) => {
            if (index < 2) {
              const numArray = Array.from(num.toString()).map(Number);
              return numArray.map((digit, indexDigit) => {
                return (
                  <Col key={indexDigit + '' + digit}>
                    <CircleNumber number={digit} size={size} />
                  </Col>
                );
              });
            } else {
              return '';
            }
          })
        ) : kaiJiangStyle === K3 ? (
          kaiJiangHao.map((num, index) => {
            return (
              <Col key={index}>
                <DiceNumber number={num} size={size} />
              </Col>
            );
          })
        ) : kaiJiangStyle === XPK ? (
          getPKNumber(kaiJiangHao)
        ) : kaiJiangStyle === PCDD ? (
          countPCDDNumber(kaiJiangHao, size)
        ) : (
          kaiJiangHao.map((num, index) => {
            return (
              <Col key={index}>
                <BoxNumber number={num} size={size} />
              </Col>
            );
          })
        )
      ) : (
        <DummyRollingNumber playGroup={playGroup} size={size} />
      )}
    </Row>
  );
};

const DummyRollingNumber = ({ playGroup, size }) => {
  let numberArray = [];
  for (let i = 0; i < constTotalCodeDigit[playGroup]; i++) {
    numberArray.push(
      <Col key={i}>
        <CircleNumberRolling playGroup={playGroup} size={size} />
      </Col>,
    );
  }
  return <>{numberArray}</>;
};

const MarkSixNumber = ({ number, xiaoMap, mode, cssStyle }) => {
  if ((number && xiaoMap) || mode === 'zodiac') {
    let num;
    let zodiac;
    let result;
    if (mode !== 'zodiac') {
      result = convertNumberToWuXingXiao([number], xiaoMap);
      num = result[0][0];
      zodiac = result[0][1];
    }

    let className = 'red';
    if (constSixGameNumberColor.blue.includes(num)) {
      className = 'blue';
    } else if (constSixGameNumberColor.green.includes(num)) {
      className = 'green';
    } else if (mode === 'zodiac') {
      className = 'yellow zodiac-only';
    }

    return (
      <MarkSixNumberItem>
        <span style={cssStyle} className={className}>
          {mode === 'zodiac' ? number : num}
        </span>
        {mode !== 'zodiac' && zodiac}
      </MarkSixNumberItem>
    );
  }

  return '';
};

const MarkSixNumberItem = styled.span`
  span {
    display: inline-block;
    color: ${color.white};
    font-size: 14px;
    line-height: 27px;
    width: 27px;
    height: 27px;
    border-radius: 50%;
    margin-right: 5px;

    &.red {
      background-color: ${color.red};
    }

    &.blue {
      background-color: ${color.blue};
    }

    &.green {
      background-color: ${color.green};
    }

    &.yellow {
      background-color: ${color.lightYellow};

      &.zodiac-only {
        margin-right: 0;
      }
    }
  }
`;

export const DrawNumber = ({ group, ...props }) => {
  switch (group) {
    case K3:
      return <DiceNumber {...props} />;
    case PK10:
    case XY10:
      return <BoxNumber {...props} />;
    case SIX:
      return <MarkSixNumber {...props} />;
    default:
      return <CircleNumber {...props} />;
  }
};

import React from 'react';
import styled from 'styled-components';
import { Col } from 'antd';

import LcwButton from '@components/common/button';

import { color } from '@helpers/const';

import { constLuZhuOptionBall } from '@const/playRule';
import { constTotalCodeDigit } from '@const/game';

const FilterOption = ({ group, filter, setFilter }) => {
  let options = [];
  for (let index = 0; index < constTotalCodeDigit[group]; index++) {
    const { label, value } = constLuZhuOptionBall[group][index];
    options.push(
      <Col key={index} className="button-wrapper">
        <LcwButton
          text={label}
          active={value === filter}
          activeOnClick={() => setFilter(value)}
          btnStyle={10}
        />
      </Col>,
    );
  }

  return options;
};

const Filter = ({ ...props }) => {
  return (
    <FilterWrapper className="ball-filter-wrapper hide-scrollbar">
      <FilterOption {...props} />
    </FilterWrapper>
  );
};

export default Filter;

const FilterWrapper = styled.div`
  @media only screen and (max-width: 576px) {
    position: relative;
    z-index: 3;
    background-color: ${color.white};

    &.ball-filter-wrapper {
      display: flex;
      width: 100%;
      flex: 1;
      flex-flow: unset;
      overflow: auto;

      .button-wrapper {
        min-width: 22%;
        flex: 1 0 auto;
        margin: 0 5px;
      }
    }
  }
`;

import React from 'react';
import styled from 'styled-components';

import LuzhuTable from '@components/common/luzhuTable';

import { constPlayGroup } from '@const/game';
import { constLuZhuName } from '@const/playRule';

import { color } from '@helpers/const';
import { generateLuzhuResult } from '@helpers/utils';

const { PK10, XY10 } = constPlayGroup;

const { haoMa } = constLuZhuName;

const FilterResult = ({ openCodeArray, filterBall, optionBall, group }) => {
  return (
    <TrendsResultWrapper>
      <GenerateLuzhuTable
        openCodeArray={openCodeArray}
        filterBall={filterBall}
        optionBall={optionBall}
        group={group}
      />
    </TrendsResultWrapper>
  );
};

export default FilterResult;

const GenerateLuzhuTable = ({
  openCodeArray,
  filterBall,
  optionBall,
  group,
}) => {
  let table = [];
  let groupDuplicateArray;

  if (openCodeArray && filterBall.length) {
    for (let indexBall = 0; indexBall < filterBall.length; indexBall++) {
      groupDuplicateArray = generateLuzhuResult(
        openCodeArray,
        filterBall[indexBall],
        haoMa.value,
        group,
      );

      let defaultFilter;
      switch (group) {
        case PK10:
        case XY10:
          defaultFilter = ['front', 'back'];
          break;

        default:
          defaultFilter = ['coming', 'not-coming'];
          break;
      }

      const { label } = optionBall[filterBall[indexBall]];

      table.push(
        <LuzhuTable
          key={filterBall[indexBall]}
          groupDuplicateArray={groupDuplicateArray}
          defaultFilter={defaultFilter}
          filterType={haoMa.value}
          filterBall={filterBall[indexBall]}
          optionBallName={label}
          group={group}
        />,
      );
    }
  }
  return table;
};

const TrendsResultWrapper = styled.div`
  background-color: ${color.white};
`;

import React from 'react';
import Layout from '@components/layout';

const withLayout = (ComponentToWrap) => (props) => (
  <Layout>
    <ComponentToWrap {...props} />
  </Layout>
);

export default withLayout;

import React from 'react';
import styled from 'styled-components';
import { Row, Col, Checkbox } from 'antd';

import { color } from '@helpers/const';
import { transl } from '@helpers/utils';
import { Images } from '@components/common/images';

const Filter = ({
  filter,
  setFilter,
  options,
  haveFilter,
  setBallNumber,
  setBallType,
}) => {
  return (
    <FilterWrapper>
      <Checkbox.Group value={filter} onChange={(props) => setFilter(props)}>
        <Row gutter={{ md: 12.5 }}>
          <Col
            className={
              haveFilter.length ? 'mobile reset active' : 'mobile reset'
            }
            onClick={() => {
              setFilter([]);
              setBallNumber([]);
              setBallType([]);
            }}>
            {transl('还原')}
          </Col>
          {options.map(({ label, value }) => {
            return (
              <Col
                className={
                  haveFilter.length > 0
                    ? value + ' filter-col have-filter'
                    : value + ' filter-col'
                }
                key={label}>
                <Checkbox value={value}>{transl(label)}</Checkbox>
              </Col>
            );
          })}
        </Row>
      </Checkbox.Group>
    </FilterWrapper>
  );
};

export default Filter;

const FilterWrapper = styled.div`
  padding: 0 25px 7px;
  background-color: ${color.white};

  .mobile {
    display: none;
  }

  @media only screen and (max-width: 576px) {
    position: sticky;
    top: 44px;
    z-index: 4;
    padding: 0;

    .mobile {
      display: block;
    }

    .desktop {
      display: none;
    }

    .ant-checkbox-group {
      width: 100%;

      .reset {
        text-align: center;
        width: 65px;
        padding: 0;
        font-size: 14px;
        line-height: 44px;
        background-color: ${color.backgroundGrey};
        border-right: 1px solid ${color.borderGrey};
        color: ${color.lightFontGrey};

        &.active {
          color: ${color.mobileBlack};
        }
      }

      .filter-col {
        text-align: center;
        padding: 0 4px;
        display: flex;
        align-items: center;
        justify-content: center;
        line-height: 16px;

        span {
          color: ${color.mobileBlack};
          position: relative;
          font-size: 13px;
          padding-right: 0;
          padding-left: 0;

          &:after {
            content: '';
            background-image: url(${Images.icn_dropdown_grey});
            position: absolute;
            width: 11px;
            height: 7px;
            top: 50%;
            right: -16px;
            transform: translateY(-50%);
          }
        }

        .ant-checkbox-wrapper-checked {
          span {
            color: ${color.orange};

            &:after {
              background-image: url(${Images.icn_dropdown_up_orange});
            }
          }
        }

        &.numberSeparate {
          &.have-filter {
            span {
              color: ${color.orange};
            }
          }
        }

        .ant-checkbox {
          display: none;
        }

        &.numberAnalysis {
          width: calc(66% - 65px);
        }
        &.numberSeparate {
          width: 34%;
        }
        &.dragon {
          display: none;
        }
      }
    }
  }
`;

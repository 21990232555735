import React from 'react';
import styled from 'styled-components';

import { color } from '@helpers/const';

import { CaretUpOutlined, CaretDownOutlined } from '@ant-design/icons';

const Sorter = ({ sorting }) => {
  return (
    <SorterWrapper>
      <div className={sorting ? 'icon-wrapper up active' : 'icon-wrapper up'}>
        <CaretUpOutlined />
      </div>
      <div
        className={!sorting ? 'icon-wrapper down active' : 'icon-wrapper down'}>
        <CaretDownOutlined />
      </div>
    </SorterWrapper>
  );
};

export default Sorter;

const SorterWrapper = styled.div`
  line-height: 1;
  align-self: center;
  margin-top: 0px;

  .icon-wrapper {
    font-size: 11px;
    height: 11px;
    color: #bfbfbf;

    &.down {
      margin-top: -3px;
    }

    &.active {
      color: ${color.orange};

      @media only screen and (max-width: 576px) {
        color: ${color.white};
      }
    }
  }
`;

import React, { useState } from 'react';
import styled from 'styled-components';
import { inject, observer } from 'mobx-react';
import { toJS } from 'mobx';

import { PKFlower, PKNumber } from '@components/common/pkCard';
import Sorter from '@components/common/trendsChartTable/sorter';

import {
  arrayStringToArrayInt,
  convertNumberToPK,
  findShunZi,
  transl,
} from '@helpers/utils';
import { color } from '@helpers/const';
import withLocation from '@helpers/hoc/withLocation';

import { Row, Col } from 'antd';

const getTableHeaderData = () => {
  let columns = [
    {
      title: '期数',
      dataIndex: '期数',
      width: 120,
      sorter: true,
    },
    {
      title: '开奖号码',
      dataIndex: '开奖号码',
      width: 120,
      className: 'open-code',
    },
  ];

  return columns;
};

const getTableData = (singleGame, group, isMobile) => {
  let arrayNumberOpenCode = [];

  singleGame.map((game) => {
    return arrayNumberOpenCode.push(arrayStringToArrayInt(game.openCode));
  });

  let dataSourceArray = [];

  for (let index = 0; index < singleGame.length; index++) {
    let stringIssueNumber = singleGame[index].uniqueIssueNumber.toString();
    if (isMobile) {
      stringIssueNumber =
        stringIssueNumber.length > 4
          ? stringIssueNumber.slice(stringIssueNumber.length - 4)
          : stringIssueNumber;
    }
    let data = {
      key: index,
      uniqueIssueNumber: stringIssueNumber,
      openCode: arrayNumberOpenCode[index],
    };
    dataSourceArray.push(data);
  }

  return dataSourceArray;
};

const getTableHeaderAnalysis = () => {
  let result = [];
  let header = {
    title: '号码分布',
    dataIndex: '号码分布',
  };

  let ball = [];
  for (let indexChild = 1; indexChild <= 13; indexChild++) {
    const pkNumber = convertNumberToPK(indexChild);
    const ballCol = {
      title: pkNumber,
      dataIndex: indexChild,
    };
    ball.push(ballCol);
  }

  header.children = ball;
  result.push(header);

  return result;
};

const getTableDataAnalysis = (singleGame, group) => {
  let arrayNumberOpenCode = [];

  singleGame.map((game) => {
    return arrayNumberOpenCode.push(arrayStringToArrayInt(game.openCode));
  });

  let dataSourceArray = [];
  let data = [];
  let dataStyle = [];
  let rowConnectTemp = new Array(13).fill(0);
  let rowContinue = new Array(13).fill(true);

  let rowConnectStyle = new Array(6).fill(0);

  for (let indexRow = 0; indexRow < arrayNumberOpenCode.length; indexRow++) {
    let rowData = [];
    let number = [];
    let flowers = [];
    for (
      let indexCol = 0;
      indexCol < arrayNumberOpenCode[indexRow].length;
      indexCol++
    ) {
      const sNumber = arrayNumberOpenCode[indexRow][indexCol].toString();
      const flower = sNumber.charAt(0);
      flowers.push(flower);
      number.push(parseInt(sNumber.substring(1, 3)));
    }

    for (let IndexPkNum = 0; IndexPkNum < 13; IndexPkNum++) {
      if (
        number[0] === IndexPkNum + 1 ||
        number[1] === IndexPkNum + 1 ||
        number[2] === IndexPkNum + 1
      ) {
        rowConnectTemp[IndexPkNum] = 0;
        rowContinue[IndexPkNum] = false;
        let duplicate = 0;
        let duplicateNumber = 0;
        if (number[0] === number[1] && number[0] === number[2]) {
          duplicate = 3;
          duplicateNumber = number[0];
        } else if (number[0] === number[1] || number[0] === number[2]) {
          duplicate = 2;
          duplicateNumber = number[0];
        } else if (number[1] === number[2]) {
          duplicate = 2;
          duplicateNumber = number[1];
        }

        switch (IndexPkNum) {
          case 10:
            rowData.push([
              'J',
              0,
              rowContinue[IndexPkNum],
              duplicateNumber === IndexPkNum + 1 ? duplicate : 0,
            ]);
            break;
          case 11:
            rowData.push([
              'Q',
              0,
              rowContinue[IndexPkNum],
              duplicateNumber === IndexPkNum + 1 ? duplicate : 0,
            ]);
            break;
          case 12:
            rowData.push([
              'K',
              0,
              rowContinue[IndexPkNum],
              duplicateNumber === IndexPkNum + 1 ? duplicate : 0,
            ]);
            break;

          default:
            rowData.push([
              IndexPkNum + 1,
              0,
              rowContinue[IndexPkNum],
              duplicateNumber === IndexPkNum + 1 ? duplicate : 0,
            ]);
            break;
        }
      } else {
        rowConnectTemp[IndexPkNum]++;
        rowData.push([
          0,
          rowConnectTemp[IndexPkNum],
          rowContinue[IndexPkNum],
          0,
        ]);
      }
    }

    data.push(rowData);

    //acc same flower
    let shunZhiResult = findShunZi('firstThree', number, group);
    switch (shunZhiResult) {
      case '豹子':
        for (let index = 0; index < rowConnectStyle.length; index++) {
          if (index === 5) {
            rowConnectStyle[index] = '豹子';
          } else {
            if (isNaN(rowConnectStyle[index])) {
              rowConnectStyle[index] = 1;
            } else {
              rowConnectStyle[index]++;
            }
          }
        }
        break;
      case '对子':
        for (let index = 0; index < rowConnectStyle.length; index++) {
          if (index === 4) {
            rowConnectStyle[index] = '对子';
          } else {
            if (isNaN(rowConnectStyle[index])) {
              rowConnectStyle[index] = 1;
            } else {
              rowConnectStyle[index]++;
            }
          }
        }
        break;
      case '顺子':
        if (flowers[0] === flowers[1] && flowers[0] === flowers[2]) {
          for (let index = 0; index < rowConnectStyle.length; index++) {
            if (index === 3) {
              rowConnectStyle[index] = '同花顺';
            } else {
              if (isNaN(rowConnectStyle[index])) {
                rowConnectStyle[index] = 1;
              } else {
                rowConnectStyle[index]++;
              }
            }
          }
        } else {
          for (let index = 0; index < rowConnectStyle.length; index++) {
            if (index === 1) {
              rowConnectStyle[index] = '顺子';
            } else {
              if (isNaN(rowConnectStyle[index])) {
                rowConnectStyle[index] = 1;
              } else {
                rowConnectStyle[index]++;
              }
            }
          }
        }
        break;
      default:
        if (flowers[0] === flowers[1] && flowers[0] === flowers[2]) {
          for (let index = 0; index < rowConnectStyle.length; index++) {
            if (index === 2) {
              rowConnectStyle[index] = '同花';
            } else {
              if (isNaN(rowConnectStyle[index])) {
                rowConnectStyle[index] = 1;
              } else {
                rowConnectStyle[index]++;
              }
            }
          }
        } else {
          for (let index = 0; index < rowConnectStyle.length; index++) {
            if (index === 0) {
              rowConnectStyle[index] = '散牌';
            } else {
              if (isNaN(rowConnectStyle[index])) {
                rowConnectStyle[index] = 1;
              } else {
                rowConnectStyle[index]++;
              }
            }
          }
        }
        break;
    }
    let clone = rowConnectStyle.slice();
    dataStyle.push(clone);
  }

  dataSourceArray = data;
  let countAppear = new Array(13).fill(0);
  let countNonAppear = new Array(13).fill(0);
  let countNonAppearTotal = new Array(13).fill(0);
  let checkNonAppear = new Array(13).fill(false);
  let countBiggestNonAppear = new Array(13).fill(0);
  let countConnectResult = new Array(13).fill(0);
  let countConnectResultTemp = new Array(13).fill(0);

  for (let indexRow = 0; indexRow < dataSourceArray.length; indexRow++) {
    for (
      let indexCol = 0;
      indexCol < dataSourceArray[indexRow].length;
      indexCol++
    ) {
      if (dataSourceArray[indexRow][indexCol][1] === 0) {
        if (dataSourceArray[indexRow][indexCol][3] === 2) {
          countAppear[indexCol]++;
        } else if (dataSourceArray[indexRow][indexCol][3] === 3) {
          countAppear[indexCol]++;
          countAppear[indexCol]++;
        }
        countAppear[indexCol]++;
        countConnectResultTemp[indexCol]++;

        if (checkNonAppear[indexCol]) {
          checkNonAppear[indexCol] = false;
          countNonAppear[indexCol]++;
          countNonAppearTotal[indexCol] =
            countNonAppearTotal[indexCol] +
            dataSourceArray[indexRow - 1][indexCol][1];
        }
      } else {
        checkNonAppear[indexCol] = true;

        if (
          countBiggestNonAppear[indexCol] <
          dataSourceArray[indexRow][indexCol][1]
        ) {
          countBiggestNonAppear[indexCol] =
            dataSourceArray[indexRow][indexCol][1];
        }

        if (countConnectResult[indexCol] < countConnectResultTemp[indexCol]) {
          countConnectResult[indexCol] = countConnectResultTemp[indexCol];
        }

        countConnectResultTemp[indexCol] = 0;
      }
    }
  }

  // check last row
  for (
    let indexCol = 0;
    indexCol < dataSourceArray[dataSourceArray.length - 1].length;
    indexCol++
  ) {
    if (checkNonAppear[indexCol]) {
      checkNonAppear[indexCol] = false;
      countNonAppear[indexCol]++;
      countNonAppearTotal[indexCol] =
        countNonAppearTotal[indexCol] +
        dataSourceArray[dataSourceArray.length - 1][indexCol][1];
    }
  }

  let countAppearStyle = new Array(6).fill(0);
  let countStyleNonAppear = new Array(6).fill(0);
  let countStyleNonAppearTotal = new Array(6).fill(0);
  let checkStyleNonAppear = new Array(6).fill(false);
  let countStyleBiggestNonAppear = new Array(6).fill(0);
  let countStyleBiggestNonAppearTemp = new Array(6).fill(0);
  let countStyleConnect = new Array(6).fill(0);
  let countStyleConnectTemp = new Array(6).fill(0);
  for (let indexRow = 0; indexRow < dataStyle.length; indexRow++) {
    for (let indexCol = 0; indexCol < dataStyle[indexRow].length; indexCol++) {
      if (isNaN(dataStyle[indexRow][indexCol])) {
        countAppearStyle[indexCol]++;
        if (
          countStyleBiggestNonAppear[indexCol] <
          countStyleBiggestNonAppearTemp[indexCol]
        ) {
          countStyleBiggestNonAppear[indexCol] =
            countStyleBiggestNonAppearTemp[indexCol];
        }
        countStyleBiggestNonAppearTemp[indexCol] = 0;
        countStyleConnectTemp[indexCol]++;

        if (checkStyleNonAppear[indexCol]) {
          checkStyleNonAppear[indexCol] = false;
          countStyleNonAppear[indexCol]++;
          countStyleNonAppearTotal[indexCol] =
            countStyleNonAppearTotal[indexCol] +
            dataStyle[indexRow - 1][indexCol];
        }
      } else {
        checkStyleNonAppear[indexCol] = true;

        countStyleBiggestNonAppearTemp[indexCol]++;
        if (countStyleConnect[indexCol] < countStyleConnectTemp[indexCol]) {
          countStyleConnect[indexCol] = countStyleConnectTemp[indexCol];
        }

        countStyleConnectTemp[indexCol] = 0;

        if (dataStyle.length - 1 === indexRow) {
          if (
            countStyleBiggestNonAppear[indexCol] <
            countStyleBiggestNonAppearTemp[indexCol]
          ) {
            countStyleBiggestNonAppear[indexCol] =
              countStyleBiggestNonAppearTemp[indexCol];
          }
        }
      }
    }
  }

  // check last row
  for (let indexCol = 0; indexCol < 6; indexCol++) {
    if (checkStyleNonAppear[indexCol]) {
      checkStyleNonAppear[indexCol] = false;
      countStyleNonAppear[indexCol]++;
      countStyleNonAppearTotal[indexCol] =
        countStyleNonAppearTotal[indexCol] +
        dataStyle[dataStyle.length - 1][indexCol];
    }
  }

  let countAverage = [];
  for (let index = 0; index < countNonAppearTotal.length; index++) {
    countAverage.push(
      Math.round(countNonAppearTotal[index] / countNonAppear[index]),
    );
  }

  let countAverageStyle = [];
  for (let index = 0; index < countStyleNonAppearTotal.length; index++) {
    countAverageStyle.push(
      Math.round(countStyleNonAppearTotal[index] / countStyleNonAppear[index]),
    );
  }
  const result = {
    table: dataSourceArray,
    style: dataStyle,
    appear: countAppear,
    average: countAverage,
    biggest: countBiggestNonAppear,
    connect: countConnectResult,
    countAppearStyle: countAppearStyle,
    countAverageStyle: countAverageStyle,
    countStyleBiggestNonAppear: countStyleBiggestNonAppear,
    countStyleConnect: countStyleConnect,
  };

  return result;
};

const getTableHeaderStyle = () => {
  let columns = [
    {
      title: '散牌',
      dataIndex: '散牌',
    },
    {
      title: '顺子',
      dataIndex: '顺子',
    },
    {
      title: '同花',
      dataIndex: '同花',
    },
    {
      title: '同花顺',
      dataIndex: '同花顺',
    },
    {
      title: '对子',
      dataIndex: '对子',
    },
    {
      title: '豹子',
      dataIndex: '豹子',
    },
  ];

  return columns;
};

const ConclusionResultTable = ({ valueBall, isMobile, ball }) => {
  const Comp = ({ numberArray }) => (
    <Col className="ball-column conclusion">
      <Row>
        {numberArray.map((value, numberIndex) => {
          return (
            <div key={numberIndex} className="counter style">
              <span>{value}</span>
            </div>
          );
        })}
      </Row>
    </Col>
  );

  let numberArray;
  if (isMobile) {
    if (ball === 0) {
      numberArray = valueBall[0].slice(0, 7);
      return <Comp numberArray={numberArray} />;
    } else if (ball === 1) {
      numberArray = valueBall[0].slice(7, 13);
      return <Comp numberArray={numberArray} />;
    } else if (ball === 2) {
      return <Comp numberArray={valueBall[1]} />;
    }
  } else {
    return (
      <Col className="ball-column conclusion">
        <Row>
          {valueBall[0].concat(valueBall[1]).map((value, numberIndex) => {
            return (
              <div
                key={numberIndex}
                className={
                  numberIndex < 13 ? 'counter style' : 'counter style wider'
                }>
                <span>{value}</span>
              </div>
            );
          })}
        </Row>
      </Col>
    );
  }
};

const MainTable = ({ trendsStore, search, filter, isMobile, ball }) => {
  const [sorting, setSorting] = useState(true);

  const { singleGame } = trendsStore;

  const { group, language } = search;

  const headerData = getTableHeaderData();
  const headerAnalysis = getTableHeaderAnalysis(group);
  const tableData = singleGame
    ? getTableData(toJS(singleGame), group, isMobile)
    : [];
  const tableDataAnalysis = singleGame
    ? getTableDataAnalysis(toJS(singleGame), group)
    : [];

  const headerStyle = getTableHeaderStyle(toJS(singleGame));

  const sortedTableData = tableData.slice();

  const rightTableData = tableDataAnalysis.table.slice();
  const rightTableDataStyle = tableDataAnalysis.style.slice();

  if (sorting) {
    sortedTableData.reverse();
    rightTableData.reverse();
    rightTableDataStyle.reverse();
  }

  let connectStyle = new Array(6).fill(true);

  return (
    <TrendsResultWrapper>
      <TrendsTableWrapper>
        <div>
          <Row className="table-wrapper">
            <Col className="table-left">
              <Row className={language}>
                {headerData.map(({ title, dataIndex, sorter }) => {
                  const headerClass = sorter
                    ? 'header-left ' + group + ' sorter-col'
                    : 'header-left ' + group;
                  return (
                    <Col key={dataIndex} className={headerClass}>
                      {sorter ? (
                        <button
                          className="sorter"
                          onClick={() => setSorting(!sorting)}>
                          <Row justify="center" gutter={4}>
                            <Col>
                              <b>{transl(title, [], true, false, 'en-US')}</b>
                            </Col>
                            <Col
                              style={{
                                display: 'flex',
                              }}>
                              <Sorter sorting={sorting} />
                            </Col>
                          </Row>
                        </button>
                      ) : (
                        <b>{transl(title, [], true, false, 'en-US')}</b>
                      )}
                    </Col>
                  );
                })}
              </Row>
              {sortedTableData.map(
                ({ key, openCode, uniqueIssueNumber }, index) => {
                  return (
                    <Row
                      key={key}
                      className={
                        index % 5 === 4 && filter.includes('section')
                          ? 'separate'
                          : ''
                      }>
                      <Col className={'data-left ' + group}>
                        {uniqueIssueNumber}
                      </Col>
                      <Col className={'data-left open-code ' + group}>
                        <Row>
                          {openCode.map((value, index) => {
                            const sNumber = value.toString();
                            const flower = sNumber.charAt(0);
                            const number = parseInt(sNumber.substring(1, 3));
                            return (
                              <Col key={index}>
                                <Row className="number-result">
                                  <Col>
                                    <PKFlower flower={flower} />
                                  </Col>
                                  <Col>
                                    <PKNumber number={number} flower={flower} />
                                  </Col>
                                </Row>
                              </Col>
                            );
                          })}
                        </Row>
                      </Col>
                    </Row>
                  );
                },
              )}
              {!isMobile && (
                <Row>
                  <Col className="header-left span-2">
                    <b>{transl('数据统计', [], true, false, 'en-US')}</b>
                  </Col>
                </Row>
              )}
              <Row>
                <Col className="span-2">
                  {transl('出现次数', [], true, false, 'en-US')}
                </Col>
              </Row>
              <Row>
                <Col className="span-2">
                  {transl('平均遗漏', [], true, false, 'en-US')}
                </Col>
              </Row>
              <Row>
                <Col className="span-2">
                  {transl('最大遗漏', [], true, false, 'en-US')}
                </Col>
              </Row>
              <Row>
                <Col className="span-2">
                  {transl('最大连出', [], true, false, 'en-US')}
                </Col>
              </Row>
            </Col>
            <Col className="table-right">
              <Row className={language}>
                {((isMobile && (ball === 0 || ball === 1)) || !isMobile) &&
                  headerAnalysis.map(({ dataIndex, title, children }) => {
                    return (
                      <Col key={dataIndex}>
                        <div className="ball-number">
                          <b>{transl(title, [], true, false, 'en-US')}</b>
                        </div>
                        <Row className="row-number">
                          {children.map(({ dataIndex, title }, index) => {
                            const comp = (
                              <Col
                                key={dataIndex}
                                className={'number ' + group}>
                                <b>{transl(title, [], true, false, 'en-US')}</b>
                              </Col>
                            );
                            if (isMobile) {
                              if (ball === 0 && index < 7) {
                                return comp;
                              } else if (ball === 1 && index > 6) {
                                return comp;
                              } else {
                                return '';
                              }
                            } else {
                              return comp;
                            }
                          })}
                        </Row>
                      </Col>
                    );
                  })}
                {(isMobile && ball === 2) || !isMobile
                  ? headerStyle.map(({ dataIndex, title, children }) => {
                      return (
                        <Col key={dataIndex}>
                          <div className="ball-number style">
                            <b>{transl(title, [], true, false, 'en-US')}</b>
                          </div>
                        </Col>
                      );
                    })
                  : ''}
              </Row>
              <Row className="result-wrapper">
                {(isMobile && (ball === 0 || ball === 1)) || !isMobile ? (
                  <Col>
                    {rightTableData.map((valueRow, index) => {
                      return (
                        <Row
                          key={index}
                          className={
                            index % 5 === 4 && filter.includes('section')
                              ? 'separate'
                              : ''
                          }>
                          {valueRow.map((value, numberIndex) => {
                            const classStyle =
                              filter.includes('hightLightCount') && value[2]
                                ? 'non-appear counter ' + group
                                : 'counter ' + group;
                            const comp = (
                              <Col
                                className="ball-column"
                                key={index + ' ' + numberIndex}>
                                <div
                                  key={index + '' + numberIndex}
                                  className={classStyle}>
                                  <span className={value[0] ? 'circle' : ''}>
                                    {filter.includes('count') && value[1] !== 0
                                      ? value[1]
                                      : value[1] === 0
                                      ? value[0]
                                      : ''}
                                    {value[3] ? (
                                      <span className="power">{value[3]}</span>
                                    ) : (
                                      ''
                                    )}
                                  </span>
                                </div>
                              </Col>
                            );
                            if (isMobile) {
                              if (ball === 0 && numberIndex < 7) {
                                return comp;
                              } else if (ball === 1 && numberIndex > 6) {
                                return comp;
                              } else {
                                return '';
                              }
                            } else {
                              return comp;
                            }
                          })}
                        </Row>
                      );
                    })}
                  </Col>
                ) : (
                  ''
                )}
                {(isMobile && ball === 2) || !isMobile ? (
                  <Col>
                    {rightTableDataStyle.map((valueRow, index) => {
                      return (
                        <Row
                          key={index}
                          className={
                            index % 5 === 4 && filter.includes('section')
                              ? 'separate'
                              : ''
                          }>
                          {valueRow.map((value, numberIndex) => {
                            if (isNaN(value)) {
                              connectStyle[numberIndex] = false;
                            }
                            const classStyle =
                              filter.includes('hightLightCount') &&
                              connectStyle[numberIndex]
                                ? 'counter non-appear'
                                : isNaN(value)
                                ? 'counter text'
                                : 'counter';

                            return (
                              <Col
                                className="ball-column style"
                                key={index + ' ' + numberIndex}>
                                <div className={classStyle}>
                                  <div>
                                    {transl(
                                      isNaN(value)
                                        ? value
                                        : filter.includes('count')
                                        ? value
                                        : ' ',
                                      [],
                                      true,
                                      false,
                                      'en-US',
                                    )}
                                  </div>
                                </div>
                              </Col>
                            );
                          })}
                        </Row>
                      );
                    })}
                  </Col>
                ) : (
                  ''
                )}
              </Row>
              {!isMobile && (
                <Row>
                  {headerAnalysis.map(({ dataIndex, title, children }) => {
                    return (
                      <Col key={dataIndex}>
                        <div className="ball-number">
                          <b>{transl(title, [], true, false, 'en-US')}</b>
                        </div>
                        <Row className="row-number">
                          {children.map(({ dataIndex, title }) => {
                            return (
                              <Col
                                key={dataIndex}
                                className={'number ' + group}>
                                <b>{transl(title, [], true, false, 'en-US')}</b>
                              </Col>
                            );
                          })}
                        </Row>
                      </Col>
                    );
                  })}
                  {headerStyle.map(({ dataIndex, title, children }) => {
                    return (
                      <Col key={dataIndex}>
                        <div className="ball-number style">
                          <b>{transl(title, [], true, false, 'en-US')}</b>
                        </div>
                      </Col>
                    );
                  })}
                </Row>
              )}
              <Row className="result-wrapper">
                <ConclusionResultTable
                  valueBall={[
                    tableDataAnalysis.appear,
                    tableDataAnalysis.countAppearStyle,
                  ]}
                  group={group}
                  isMobile={isMobile}
                  ball={ball}
                />
              </Row>
              <Row className="result-wrapper">
                <ConclusionResultTable
                  valueBall={[
                    tableDataAnalysis.average,
                    tableDataAnalysis.countAverageStyle,
                  ]}
                  group={group}
                  isMobile={isMobile}
                  ball={ball}
                />
              </Row>
              <Row className="result-wrapper">
                <ConclusionResultTable
                  valueBall={[
                    tableDataAnalysis.biggest,
                    tableDataAnalysis.countStyleBiggestNonAppear,
                  ]}
                  group={group}
                  isMobile={isMobile}
                  ball={ball}
                />
              </Row>
              <Row className="result-wrapper">
                <ConclusionResultTable
                  valueBall={[
                    tableDataAnalysis.connect,
                    tableDataAnalysis.countStyleConnect,
                  ]}
                  group={group}
                  isMobile={isMobile}
                  ball={ball}
                />
              </Row>
            </Col>
          </Row>
        </div>
      </TrendsTableWrapper>
    </TrendsResultWrapper>
  );
};

export default withLocation(inject('trendsStore')(observer(MainTable)));

const TrendsResultWrapper = styled.div`
  background-color: ${color.white};

  .ant-table table {
    th {
      padding: 7px 0;
      font-size: 15px;
      background-color: ${color.white};

      &.number {
        padding: 5px 0;
      }
    }
  }

  @media only screen and (max-width: 576px) {
    // position: relative;
    // max-width: 100vw;
  }
`;

const TrendsTableWrapper = styled.div`
    position: relative;
    padding: 0 25px 48px;

    @media only screen and (max-width: 576px) {
        padding: 0;
    }
  
    .table-wrapper {
        flex-flow: unset;

        .result-wrapper {
            position: relative;

            @media only screen and (max-width: 576px) {
                >.ant-col {
                    flex: 1;
                }
            }

            .ant-row {
                &:last-child {
                    .counter  {
                        border-bottom: 0;

                        @media only screen and (max-width: 576px) {
                             border-bottom: 1px solid ${color.tableBorderGrey};
                        }

                        &.style {
                            border-bottom: 1px solid ${color.tableBorderGrey};

                            &.wider {
                                background-color: ${color.opacityRed};
                                width: 55px;

                                @media only screen and (max-width: 576px) {
                                    width: auto;
                                    background-color: ${color.orange};
                                }
                            }
                        }
                    }
                    
                    &.separate {
                        .counter  {
                            border-bottom: 1px solid ${color.tableBorderGrey};

                            @media only screen and (max-width: 576px) {
                                border-bottom: 2px solid ${color.tableBorderGrey};
                           }
                        }
                    }
                }
            }

            .ball-column {
                position: relative;
                background-color: ${color.opacityYellow};

                @media only screen and (max-width: 576px) {
                    flex: 1;
                    background-color: transparent;
                }

                .counter {
                    display: flex;
                    justify-content: center;
                    width: 38px;
                    padding: 10px 0;
                    font-size: 12px;
                    color: ${color.lightFontGrey};
                    border: 1px solid ${color.tableBorderGrey};
                    border-top: 0;
                    border-left: 0;

                    div {
                        height: 18px;
                    }

                    @media only screen and (max-width: 576px) {
                        width: auto;
                        padding: 0;
                        height: 30px;
                        align-items: center;
                    }

                    &.non-appear {
                        background-color: ${color.darkGrey};
                        color: ${color.white};
                    }

                    span {
                        display: block;
                        min-width: 20px;
                        height: 20px;
                        padding: 0;
                        line-height: 20px;
                        text-align: center;

                        &.circle {
                            position:relative;
                            border-radius: 100%;
                            color: ${color.white};
                            z-index: 1;
                            background-color: ${color.lightYellow};
                        }       
                    }
                }

                span {
                    &.power {
                        position: absolute;
                        font-size: 10px;
                        min-width: 15px;
                        height: 15px;
                        line-height: 15px;
                        border-radius: 50%;
                        top: -7px;
                        right: -7px;
                        z-index: 1;
                        background-color: ${color.darkGrey};
                        color: ${color.white};
                        
                        @media only screen and (max-width: 576px) {
                            top: -4px;
                            right: -7px;
                            font-size: 9px;
                            min-width: 13px;
                            height: 13px;
                            font-weight: 600;
                            line-height: 11px;
                            color: ${color.red};
                            background-color: ${color.white};
                            border: 1px solid ${color.red};
                        }
                    } 
                }

                &.style {
                    background-color: ${color.opacityRed};

                    .counter {
                        width: 55px;
                        padding: 11px 0;

                        @media only screen and (max-width: 576px) {
                            width: auto;
                            padding: 0px 0px;
                            text-align: center;
                            
                            div {
                                padding: 0px 5px;
                            }
                        }

                        &.text {
                            color: ${color.red};
                        }
                    }
                }

                &.conclusion {
                    .counter { 
                        border-bottom: 1px solid ${color.tableBorderGrey};
                        padding: 11px 0;
                        height: 42.4px;
                    }

                    @media only screen and (max-width: 576px) {
                        background-color: ${color.orange};

                        span {
                            color: ${color.white};
                        }

                        .counter { 
                            padding: 0;
                            height: 30px;           
                            flex: 1;
                        }
                    }
                }
            }

            .separate {
                .counter {
                    border-bottom: 2px solid ${color.tableBorderGrey};
                }
            }
        }

        .ant-row {
            flex-flow: unset;
        }

        .ant-col {
            max-width: unset;
        }

        .table-left {
            text-align: center;
    
            @media only screen and (max-width: 576px) {
                >.ant-row {
                    &:first-child {
                        position: sticky;
                        top: 88px;
                        z-index: 2;

                        &.en-US,
                        &.vi-VN {
                            top: 135px;
                        }
                    }
                }
            }

            .header-left {
                font-size: 15px;
                width: 150px;
                padding: 18px 0;
                border: 1px solid ${color.tableBorderGrey};
                background-color: ${color.backgroundGrey};

                &.sorter-col {
                    padding: 0;

                    .sorter {
                        height: 100%;
                    }
                }

                @media only screen and (max-width: 576px) {
                    font-size: 13px;
                    line-height: 40px;
                    padding: 9px 5px;
                    height: 58px;
                    color: ${color.white};
                    width: 50px;
                    background-color: ${color.orange};

                    &:first-child {
                        width: 50px;
                        padding: 0;
                        line-height: 40px;
                    }

                    b {
                        font-weight: 400;
                    }
                }

                &:first-child {
                    border-right: 0;
                }

                &:nth-child(2) {
                    flex: 1;
                }

                &.sorter-col {
                    .sorter {
                        width: 100%;
                        height: calc(100% - 2px);
                        padding: 0;
                        background-color: ${color.backgroundGrey};
                        border: none;
                        cursor: pointer;

                        @media only screen and (max-width: 576px) {
                            background-color: ${color.orange};
                        }
                    }
                }

                &.span-2{
                    padding: 18px 0;
                    border: 1px solid ${color.tableBorderGrey};
                    border-top: 0;

                    @media only screen and (max-width: 576px) {
                        font-size: 13px;
                        padding: 0;
                        height: 30px;
                        line-height: 30px;
                        color: ${color.mobileBlack};

                        &:first-child {
                            width: 100%;
                        }
                    }
                }
            }

            .data-left {
                width: 150px;
                padding: 9px 0px;
                border: 1px solid ${color.tableBorderGrey};
                border-top: 0;
                
                &:first-child {
                    border-right: 0;
                }

                @media only screen and (max-width: 576px) {
                    font-size: 13px;
                    padding: 0;
                    height: 30px;
                    line-height: 30px;
                    color: ${color.mobileBlack};
                    width: 50px;
                    
                    &:first-child {
                        width: 50px;
                    }

                    &:nth-child(2) {
                        flex: 1;
                    }
                }
                
                &.open-code {
                    display: flex;
                    flex: 1 1;
                    width: 100px;
                    justify-content: center;
                    color: ${color.red};

                    @media only screen and (max-width: 576px) {
                        flex: none;
                    }

                    .number-result {
                        margin: 0 2px;

                        >.ant-col {
                            align-self: center;
                            
                            span {
                                display: block;
                                min-width: 17px;
                                margin: 0 1px;
                                
                                &.flower {
                                    display: flex;
                                    min-width: 0;
                                }
                            }
                        }
                    }

                    @media only screen and (max-width: 576px) {
                        >.ant-row {
                            align-self: center;
                            margin-top: -3px;

                            .number-result {
                                span {
                                    min-width: 15px;
                                    margin: 0;
                                    line-height: 13px;

                                    &.flower {
                                        min-width: 0;
                                        margin: auto;
                                    }
                                }
                            }
                        }
                    } 

                }
            }

            .separate {
                .data-left {
                    border-bottom: 2px solid ${color.tableBorderGrey};
                }
            }

            .span-2 {
                width: 100%;
                padding: 9.7px 0px;
                border: 1px solid ${color.tableBorderGrey};
                border-top: 0;

                @media only screen and (max-width: 576px) {
                    padding: 0;
                    height: 30px;
                    line-height: 30px;
                    color: ${color.white};
                    background-color: ${color.orange};
                }
            }

            @media only screen and (max-width: 576px) {
                >.ant-row {
                    &:nth-child(2n+1) {
                        background-color ${color.backgroundGrey};
                    }
                }
            }
        }
    
        .table-right {
            flex: 1;
            // overflow-x: auto;

            @media only screen and (max-width: 576px) {
                // overflow-x: unset;

                >.ant-row {
                    &:first-child {
                        position: sticky;
                        top: 88px;
                        z-index: 2;

                        &.en-US,
                        &.vi-VN {
                            top: 135px;
                        }
                        
                        >.ant-col {
                            flex: 1;
                            width: auto;
                        }
                    }
                }
            }

            &::-webkit-scrollbar {
                width: 8px;
                height: 8px;
            }
            
            /* Track */
            &::-webkit-scrollbar-track {
                background: #f1f1f1;
            }
            
            /* Handle */
            &::-webkit-scrollbar-thumb {
                background: #cdcdcd;
            }
            
            /* Handle on hover */
            &::-webkit-scrollbar-thumb:hover {
                background: #797979;
            }
        
            &::-webkit-scrollbar-button {
                width: 0;
                height: 0;
                display: none;
            }
        
            scrollbar-width: thin;

            .ball-number {
                text-align: center;
                font-size: 15px;
                height: 28px;
                line-height: 28px;
                border: 1px solid ${color.tableBorderGrey};
                border-left: 0;
                background-color: ${color.backgroundGrey};

                &.btm-part {
                    border-top: 0;
                }

                &.style {
                    width: 55px;
                    height: 61px;
                    line-height: 61px;
                }
                
                @media only screen and (max-width: 576px) {
                    font-size: 13px;
                    color: ${color.white};
                    background-color: ${color.orange};
                    border-bottom: 0;
                    
                    b {
                        font-weight: 400;
                    }

                    >.row-number {
                        background-color: ${color.orange};
                    }

                    &.style {
                        width: auto;
                        height: 58px;
                        line-height: 15px;
                        padding: 5px 8px;
                        background-color: ${color.orange};
                        border-bottom: 1px solid ${color.borderGrey};
                        display: flex;
                        align-items: center;
                        justify-content: center;

                        &.number {
                            height: 30px;
                        }

                        &.divider {
                            height: 28px;
                            border-right: 0;
                            border-bottom: 0;
                        }

                        b {
                            font-weight: 400;
                        }
                    }
                }
            }
    
            .row-number {
                text-align: center;
                font-size: 15px;
                background-color: ${color.backgroundGrey};

                .number {
                    width: 38px;
                    height: 33px;
                    line-height: 33px;
                    border: 1px solid ${color.tableBorderGrey};
                    border-left: 0;
                    border-top: 0;
                }

                @media only screen and (max-width: 576px) {
                    font-size: 13px;
                    color: ${color.white};
                    background-color: ${color.orange};

                    .number {
                        flex: 1;
                        width: auto;
                        height: 30px;
                        line-height: 30px;
                        padding: 0;
                        border: 1px solid ${color.tableBorderGrey};
                        background-color: ${color.orange};
                        border-left: 0;

                        b {
                            font-weight: 400;
                        }
                    }
                }
            }

            @media only screen and (max-width: 576px) {
                >.ant-row {
                    // flex: 1;

                    >.ant-col {
                        // flex: 1;

                        >.ant-row {
                            // flex: 1;

                            &:nth-child(2n) {
                                background-color: ${color.backgroundGrey};
                            }
                        }

                        &:nth-child(3) {
                            .divider {
                                border-right: 1px solid ${color.borderGrey};
                            }
                        }
                    }
                }
            }
        }
    }
`;

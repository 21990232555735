import React, { useState } from 'react';
import Filter from './Filter';
import MainTable from './MainTable';
import withLocation from '@helpers/hoc/withLocation';
import isMobile from '@helpers/hoc/isMobile';

import PlayRuleTitle from '@components/common/playRuleTitle';

import { transl } from '@helpers/utils';

import Reference from '@components/common/reference';

const LengReFenXi = ({ search, isMobile }) => {
  const [filter, setFilter] = useState(['count', 'line']);
  const [ballFilter, setBallFilter] = useState(0);

  const { group } = search;

  let options = [
    { label: '遗漏', value: 'count' },
    { label: '拆线', value: 'line' },
    { label: '遗漏分成', value: 'hightLightCount' },
    { label: '分隔线', value: 'section' },
  ];

  if (group === 'KL10F') {
    options = [
      { label: '遗漏', value: 'count' },
      { label: '遗漏分成', value: 'hightLightCount' },
      { label: '分隔线', value: 'section' },
    ];
  }

  const content = [
    'Dr. - Draw Number',
    'Winner No. - Winner Number',
    'Pattern Charc. - Pattern Characteristic',
    'Divide 3 - Divide 3 balance game',
    'Movement - Movement game',
    'B - Big',
    'S - Small',
    'O - Odd',
    'E - Even',
    'P - Praying mantis',
    'G - Grasshopper',
    'U - Up',
    'E - Equal',
    'D - Down',
    'Appear Times - Number of appear times',
    'Avg N/O Streak - Average no open streak count',
    'Lgt N/O Streak - Longest no open streak count',
    'Lgt Streak - Longest open streak',
  ];

  return (
    <>
      {!isMobile && <PlayRuleTitle title={transl('定位走势')} />}
      <Filter
        filter={filter}
        setFilter={setFilter}
        options={options}
        ballFilter={ballFilter}
        setBallFilter={setBallFilter}
        group={group}
      />
      <Reference content={content} sticky={true} />
      <MainTable filter={filter} ballFilter={ballFilter} />
    </>
  );
};

export default withLocation(isMobile(LengReFenXi));

import React, { useState, useEffect } from 'react';
import { toJS } from 'mobx';
import { inject, observer } from 'mobx-react';
import Filter from './Filter';

import withLocation from '@helpers/hoc/withLocation';
import isMobile from '@helpers/hoc/isMobile';

import { constGameBallNumber, constGameBallStartNumber } from '@const/game';
import { constLuZhuMobileNumber } from '@const/playRule';

import { openCodeToArray } from '@helpers/utils';
import FilterResult from './FilterResult';
import { getSelectAllOption } from '@helpers/utils';

import PlayRuleTitle from '@components/common/playRuleTitle';
import Reference from '@components/common/reference';

const generateOptionBall = (group) => {
  let optionBall = [];

  for (
    let index = constGameBallStartNumber[group];
    index <= constGameBallNumber[group];
    index++
  ) {
    const obj = {
      label: '号码' + index,
      value: index,
    };

    // optionBall.splice(index, 0, obj);
    optionBall[index] = obj;
  }

  // optionBall = optionBall.filter(function (el) {
  //     return el != null;
  // });

  return optionBall;
};

const HaoMaLuzhu = ({ trendsStore, search, isMobile }) => {
  const { group } = search;

  const { singleGame } = trendsStore;

  const optionBall = generateOptionBall(group);
  const selectAllValueBall = getSelectAllOption(optionBall);

  const [filterBall, setFilterBall] = useState(selectAllValueBall);

  useEffect(() => {
    if (isMobile) {
      setFilterBall(constLuZhuMobileNumber[group][0].value);
    }
  }, [isMobile, group]);

  let openCodeArray;
  if (singleGame) {
    openCodeArray = openCodeToArray(toJS(singleGame));
  }

  let content = ['No. - Number'];

  switch (group) {
    case 'PK10':
      content = ['No. - Number', 'F - Front', 'B - Back'];
      break;

    default:
      break;
  }
  return (
    <>
      {!isMobile && <PlayRuleTitle title="号码路珠" />}
      <Filter
        optionBall={optionBall}
        filterBall={filterBall}
        setFilterBall={setFilterBall}
        selectAllValueBall={selectAllValueBall}
        group={group}
      />
      <Reference content={content} sticky={true} />
      <FilterResult
        openCodeArray={openCodeArray}
        filterBall={filterBall}
        optionBall={optionBall}
        group={group}
      />
    </>
  );
};

export default isMobile(
  withLocation(inject('trendsStore')(observer(HaoMaLuzhu))),
);

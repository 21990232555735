import React, { useState } from 'react';
import { toJS } from 'mobx';
import { inject, observer } from 'mobx-react';
import MainTable from './MainTable';
import Filter from './Filter';

import withLocation from '@helpers/hoc/withLocation';

// import styled from "styled-components";

// import { color } from "@helpers/const";
import isMobile from '@helpers/hoc/isMobile';

import { constLiShiHaoMaFilter } from '@const/playRule';

import PlayRuleTitle from '@components/common/playRuleTitle';

import Reference from '@components/common/reference';

const ChangLongTongJi = ({ trendsStore, search, isMobile }) => {
  const { group } = search;

  const { histByDate, histByDateBy19Days } = trendsStore;

  const [filter, setFilter] = useState(constLiShiHaoMaFilter[group][0].value);

  let openCodeArray = [];
  if (histByDate && histByDateBy19Days) {
    openCodeArray = toJS(histByDateBy19Days);
    openCodeArray.unshift(toJS(histByDate));
  }

  let content = ['O - Odd', 'E - Even', 'S - Small', 'B - Big'];

  if (isMobile) {
    content = ['BSOE - Big small odd even'];
  }

  return (
    <>
      {!isMobile ? (
        <PlayRuleTitle title="历史号码统计" />
      ) : (
        <Filter group={group} filter={filter} setFilter={setFilter} />
      )}
      <Reference content={content} />
      <MainTable openCodeArray={openCodeArray} group={group} filter={filter} />
    </>
  );
};

export default isMobile(
  withLocation(inject('trendsStore')(observer(ChangLongTongJi))),
);

// const TitleWrapper = styled.div`
//     padding: 0 25px 4px;
//     background-color: ${color.white};
// `;

// const Title = styled.div`
//     position: relative;
//     padding-bottom: 15px;
//     margin-bottom: 19px;

//     &:after {
//         content: "";
//         position: absolute;
//         width: calc(100% + 50px);
//         height: 1px;
//         left: -25px;
//         bottom: 0;
//         background-color: ${color.borderGrey};
//     }
// `;

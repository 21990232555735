import React from 'react';
import styled from 'styled-components';
import { Row, Col } from 'antd';

import Description from './Description';

import LcwButton from '@components/common/button';

import { color } from '@helpers/const';
import isMobile from '@helpers/hoc/isMobile';

import { constTotalCodeDigit } from '@const/game';
import { constLuZhuOptionBall } from '@const/playRule';

import { transl } from '@helpers/utils';

const BallFilter = ({ group, ballFilter, setBallFilter, isMobile }) => {
  let ballButton = [];

  let groupBall = constLuZhuOptionBall[group];

  for (let index = 0; index < constTotalCodeDigit[group]; index++) {
    ballButton.push(
      <Col key={groupBall[index].label}>
        <LcwButton
          text={groupBall[index].label}
          active={
            JSON.stringify(ballFilter) ===
            JSON.stringify(groupBall[index].value)
              ? true
              : false
          }
          activeOnClick={() => setBallFilter(groupBall[index].value)}
          btnStyle={isMobile ? 10 : 1}
        />
      </Col>,
    );
  }

  return ballButton;
};

const Filter = ({ isMobile, ballFilter, setBallFilter, group }) => {
  return (
    <FilterWrapper>
      {!isMobile ? (
        <Row>
          <Description />
        </Row>
      ) : (
        ''
      )}
      <Row gutter={[{ md: 20 }, { md: 15 }]} align="middle">
        {!isMobile ? (
          <Col style={{ lineHeight: '28px' }}>{transl('选择名次：')}</Col>
        ) : (
          ''
        )}
        <Col>
          <Row
            gutter={[{ md: 12 }, { md: 10 }]}
            className="ball-filter-wrapper hide-scrollbar">
            <BallFilter
              group={group}
              ballFilter={ballFilter}
              setBallFilter={setBallFilter}
              isMobile={isMobile}
            />
          </Row>
        </Col>
      </Row>
    </FilterWrapper>
  );
};

export default isMobile(Filter);

const FilterWrapper = styled.div`
  padding: 0 25px 4px;
  background-color: ${color.white};

  @media only screen and (max-width: 576px) {
    padding: 0;

    > .ant-row {
      display: block;

      .ball-filter-wrapper {
        flex: 1;
        flex-flow: unset;
        width: 100%;
        overflow: auto;

        > .ant-col {
          min-width: 22%;
          flex: 0 0 auto;
          margin: 0 5px;
        }
      }
    }
  }
`;

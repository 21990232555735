import React from 'react';
import styled from 'styled-components';
import { Col } from 'antd';

import { color } from '@helpers/const';
import { constPlayGroup } from '@const/game';
import { calcDuplicateCode, transl } from '@helpers/utils';

import { TitleText } from '@components/common/text';
import { SummarizeTable } from '@components/common/table';

import { ballHeader, ballData } from './ballStatistics';
import { typeHeader, typeData } from './typeStatistics';
import {
  dragonHeader,
  dragonData,
  dragonChampHeaderMobilePK10,
  dragonChampDataMobilePK10,
  dragonHeaderMobilePK10,
  dragonDataMobilePK10,
} from './dragonStatistics';

const { SSC, D11, PK10, XY10, K3, KL10F, PCDD, THREED, QXC } = constPlayGroup;

const NumberStatistics = ({ data, group, isMobile }) => {
  let sourceData = calcDuplicateCode(data);
  let tableHeaderData1;
  let tableBodyData1;
  let tableHeaderData2;
  let tableBodyData2;
  let tableHeaderData3;
  let tableBodyData3;
  switch (group) {
    case SSC:
    case D11:
    case PCDD:
    case THREED:
      tableHeaderData1 = ballHeader(group);
      tableBodyData1 = ballData(group, sourceData);
      tableHeaderData2 = typeHeader(group, isMobile);
      tableBodyData2 = typeData(group, data, isMobile);
      break;
    case QXC:
      tableHeaderData1 = ballHeader(group);
      tableBodyData1 = ballData(group, sourceData);
      break;
    case PK10:
    case XY10:
      tableHeaderData1 = typeHeader(group, isMobile);
      tableBodyData1 = typeData(group, data, isMobile);
      if (isMobile) {
        tableHeaderData2 = dragonChampHeaderMobilePK10();
        tableBodyData2 = dragonChampDataMobilePK10(data, group);
        tableHeaderData3 = dragonHeaderMobilePK10(data, group);
        tableBodyData3 = dragonDataMobilePK10(data, group);
      } else {
        tableHeaderData2 = dragonHeader();
        tableBodyData2 = dragonData(data, group);
      }

      break;
    case K3:
      tableHeaderData1 = ballHeader(group);
      tableBodyData1 = ballData(group, sourceData);
      break;
    case KL10F:
      tableHeaderData2 = typeHeader(group, isMobile);
      tableBodyData2 = typeData(group, data, isMobile);
      break;
    default:
      break;
  }

  return (
    <SectionWrapper md={24}>
      <TitleTextWrapper style={{ marginBottom: '12px' }}>
        <TitleText text={transl('今日双面/号码统计')} fontSize={16} />
      </TitleTextWrapper>
      {tableHeaderData1 && tableBodyData1 ? (
        <SummarizeTableWrapper>
          <SummarizeTable
            tableHeader={tableHeaderData1}
            tableData={tableBodyData1}
            fixSubTable={true}
          />
        </SummarizeTableWrapper>
      ) : (
        ''
      )}
      {tableHeaderData2 && tableBodyData2 ? (
        <SummarizeTableWrapper>
          <SummarizeTable
            tableHeader={tableHeaderData2}
            tableData={tableBodyData2}
            fixSubTable={true}
          />
        </SummarizeTableWrapper>
      ) : (
        ''
      )}
      {tableHeaderData3 && tableBodyData3 ? (
        <SummarizeTableWrapper>
          <SummarizeTable
            tableHeader={tableHeaderData3}
            tableData={tableBodyData3}
            fixSubTable={true}
          />
        </SummarizeTableWrapper>
      ) : (
        ''
      )}
    </SectionWrapper>
  );
};

export default NumberStatistics;

const SectionWrapper = styled(Col)`
  background-color: ${color.white};
  padding: 15px 25px;
  border-bottom: 1px solid ${color.borderGrey};

  > div > p {
    font-weight: 400;
  }

  .dragon-result-row {
    .ant-col {
      display: flex;

      div {
        text-align: center;
        width: 86px;
        padding: 9px 0;
        border: 1px solid ${color.borderGrey};

        @media only screen and (max-width: 576px) {
          width: auto;
          padding: 7px 10px;
        }

        &:first-child {
          border-right: none;
        }
      }

      .title {
        font-weight: 600;
        background-color: ${color.backgroundGrey};
      }
    }
  }

  @media only screen and (max-width: 576px) {
    padding: 0;
  }
`;

const TitleTextWrapper = styled.div`
  @media only screen and (max-width: 576px) {
    display: none;
  }
`;

const SummarizeTableWrapper = styled.div`
  margin-top: 12px;

  @media only screen and (max-width: 576px) {
    margin-top: 0;
    margin-bottom: 3px;
  }
`;

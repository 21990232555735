import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { Row, Col } from 'antd';
import { inject, observer } from 'mobx-react';
import { toJS } from 'mobx';

import withLayout from '@helpers/hoc/withLayout';
import withPaddingWidth from '@helpers/hoc/withPaddingWidth';
import { color } from '@helpers/const';
import { constPlayGroup } from '@const/game';
import { constPlayRuleByGroup } from '@const/config';
import { calcLeftPlanNo } from '@helpers/utils';
import withLocation from '@helpers/hoc/withLocation';

import SEO from '@components/common/seo';
import IconLink from '@components/common/iconLink';
import { DrawNoText } from '@components/common/text';
// import { CircleNumber } from "@components/common/number";

import Timer from '@components/common/timer';
import { NumberRow } from '@components/common/number';

import PlayRule from '@components/views/trends/playRule';

import { findUniqueIssueNumberCount } from '@services/general';

import { constGameNameInChinese } from '@const/game';

import { iconLinkData } from '@helpers/utils';

const TrendsGameDetail = ({
  searchGame,
  gameNameInChinese,
  uniqueIssueNumber,
  lastOpenCode,
  group,
  openPlan,
  timerToNextOpen,
}) => {
  const [rolling, setRolling] = useState(false);

  const leftOpenPlanNo = calcLeftPlanNo(searchGame, openPlan);

  return (
    <TrendsUpperWrapper>
      <Row>
        <Col className="col1">
          <IconLink
            data={iconLinkData(searchGame, gameNameInChinese)}
            type={1}
          />
        </Col>
        <Col className="col2" flex="auto">
          <DrawNoText no={uniqueIssueNumber} textEnd="期开奖号码" />
          <NumberWrapper>
            <NumberRow
              kaiJiangHao={lastOpenCode}
              playGroup={group}
              kaiJiangStyle={group}
              size="big"
              rolling={rolling}
            />
          </NumberWrapper>
          <div style={{ color: color.fontGrey }}>
            {leftOpenPlanNo && openPlan
              ? '已开' + openPlan + '期，还有' + leftOpenPlanNo + '期'
              : ''}
          </div>
        </Col>
        <Col className="col3">
          <div>
            <DrawNoText
              no={uniqueIssueNumber}
              textStart="距"
              textEnd="期开奖"
            />
          </div>
          <Timer
            key={lastOpenCode}
            timer={timerToNextOpen}
            setRolling={setRolling}
            rolling={rolling}
            size="big"
          />
        </Col>
      </Row>
    </TrendsUpperWrapper>
  );
};

const TrendsPage = ({ trendsStore, search, location }) => {
  let timerToNextOpen = 0;

  const [openPlan, setOpenPlan] = useState(null);
  const [currentPlanNo, setCurrentPlanNo] = useState(0);

  const { currentTwoGame } = trendsStore;

  const { searchGame, group } = search;

  const { SIX } = constPlayGroup;

  useEffect(() => {
    if (searchGame) {
      trendsStore.fetchTrends(searchGame);
      if (
        constPlayRuleByGroup[group].includes('changLongTongJi') ||
        constPlayRuleByGroup[group].includes('liShiHaoMa') ||
        constPlayRuleByGroup[group].includes('daXiaoDanShuangLiShi')
      ) {
        trendsStore.fetchHistByDate19Days(searchGame);
      }

      if (group !== SIX) {
        trendsStore.fetchCurrentTwo(searchGame, group);
      }
    }

    return () => {
      trendsStore.clearTimeout();
      trendsStore.resetObservable();
    };
  }, [trendsStore, searchGame, group, SIX]);

  if (group !== SIX) {
    if (typeof toJS(currentTwoGame) === 'object') {
      const {
        openCode,
        nextStopOrderTimeEpoch,
        uniqueIssueNumber,
      } = currentTwoGame.lastOpen;

      const {
        currentTimeEpoch,
        nextPlanNo,
        gameNameInChinese,
      } = currentTwoGame.current;

      timerToNextOpen =
        openCode && nextStopOrderTimeEpoch && currentTimeEpoch
          ? nextStopOrderTimeEpoch - currentTimeEpoch
          : 0;

      if (nextPlanNo !== currentPlanNo) {
        async function findIssueNumber(searchGame) {
          let response = await findUniqueIssueNumberCount(searchGame);
          setOpenPlan(response);
        }

        findIssueNumber(searchGame);
        setCurrentPlanNo(nextPlanNo);
      }

      return (
        <>
          <SEO title="Trends" />
          {
            <TrendsGameDetail
              searchGame={searchGame}
              gameNameInChinese={gameNameInChinese}
              uniqueIssueNumber={uniqueIssueNumber}
              lastOpenCode={openCode}
              group={group}
              openPlan={openPlan}
              timerToNextOpen={timerToNextOpen}
            />
          }
          <PlayRule />
        </>
      );
    } else {
      return (
        <>
          <SEO title="Trends" />
          {
            <TrendsGameDetail
              searchGame={searchGame}
              gameNameInChinese={constGameNameInChinese[searchGame]}
              group={group}
            />
          }
          <PlayRule />
        </>
      );
    }
  } else {
    return (
      <>
        <SEO title="Trends" />
        <PlayRule />
      </>
    );
  }
};

export default withLocation(
  withLayout(withPaddingWidth(inject('trendsStore')(observer(TrendsPage)))),
);

const TrendsUpperWrapper = styled.div`
  padding: 18px 35px;
  background-color: ${color.white};
  margin-bottom: 11px;

  > .ant-row {
    flex-flow: nowrap;
  }

  @media only screen and (max-width: 576px) {
    position: relative;
    padding: 5px;
    z-index: 4;
  }

  .col1 {
    margin-right: 37px;
    align-self: center;

    &:after {
      content: '';
      position: absolute;
      width: 1px;
      height: calc(100% + 16px);
      right: -38px;
      top: 50%;
      transform: translateY(-50%);
      background-color: ${color.borderGrey};
    }

    @media only screen and (max-width: 576px) {
      margin-right: 10px;
      width: 100%;
      flex: 1 1 20%;
      max-width: 20%;
      align-self: unset;
      margin-bottom: 5px;

      &:after {
        content: none;
      }
    }
  }

  .col2 {
    margin-left: 37px;
    margin-right: 55px;

    p {
      font-size: 16px;
    }

    @media only screen and (max-width: 576px) {
      margin-left: 0;
      margin-right: 0;
      margin-bottom: 5px;
      flex: 1 1 75%;
      max-width: 75%;

      p {
        line-height: 16px;
        margin-bottom: 6px;
      }
    }
  }

  .col3 {
    flex: 0 0 280px;

    @media only screen and (max-width: 576px) {
      flex: 1;
      display: flex;
    }

    > div {
      margin-bottom: 11px;

      @media only screen and (max-width: 576px) {
        margin-right: 15px;
      }

      p {
        font-size: 16px;
        margin-left: 45px;

        @media only screen and (max-width: 576px) {
          margin-left: 0;
          margin-bottom: 0;
          font-size: 14px;
          line-height: 30px;
        }
      }

      @media only screen and (max-width: 576px) {
        margin-bottom: 0px;
      }
    }
  }
`;

const NumberWrapper = styled.div`
  padding: 12px;
  border: 1px solid ${color.borderGrey};
  border-radius: 4px;
  background-color: ${color.backgroundGrey};
  margin-bottom: 11px;

  @media only screen and (max-width: 576px) {
    padding: 5px;
    margin-bottom: 0px;
  }
`;

import React, { useState } from 'react';
import Filter from './Filter';
import MainTable from './MainTable';

import isMobile from '@helpers/hoc/isMobile';

import PlayRuleTitle from '@components/common/playRuleTitle';

const LengReFenXi = ({ isMobile }) => {
  const [showNumber, setShowNumber] = useState(false);
  return (
    <>
      {!isMobile && <PlayRuleTitle title="冷热分析" />}
      <Filter setShowNumber={setShowNumber} />
      <MainTable showNumber={showNumber} />
    </>
  );
};

export default isMobile(LengReFenXi);

import React from 'react';
import styled from 'styled-components';
import { Row, Col } from 'antd';

import { color } from '@helpers/const';
import { transl } from '@helpers/utils';

import { TitleText } from '@components/common/text';

const filterChangRong = (title) => {
  let result;
  if (
    title.includes('一球VS第二') ||
    title.includes('一球VS第三') ||
    title.includes('一球VS第四') ||
    title.includes('二球VS第') ||
    title.includes('三球VS第') ||
    title.includes('四球VS第') ||
    title.includes('五球VS第')
  ) {
    result = false;
  } else {
    result = true;
  }

  return result;
};

const filterChangeRongTitle = (title) => {
  switch (title) {
    case '第一球VS第五球':
      title = '1-5龙虎';
      break;
    default:
      break;
  }
  return title;
};

const DragonResult = ({ issueNumber, dragonList }) => {
  let result = [];
  dragonList.map(({ title, dragon, dragonNum }) => {
    if (filterChangRong(title)) {
      const dragonText = parseInt(dragon, 10)
        ? parseInt(dragon, 10) + '号'
        : dragon;
      return result.push(
        <Col key={issueNumber + title + dragon + dragonNum}>
          <div className="title">{transl(filterChangeRongTitle(title))}</div>
          <div>{transl(dragonText) + ' ' + dragonNum + ' ' + transl('期')}</div>
        </Col>,
      );
    } else {
      return '';
    }
  });

  return result;
};

const DragonStatistics = ({ currentTwoGame, dragonList }) => {
  let comp = [];
  if (dragonList.length && currentTwoGame) {
    comp.push(
      <SectionWrapper key={currentTwoGame.lastOpen.uniqueIssueNumber}>
        <div style={{ marginBottom: '12px' }}>
          <TitleText text="长龙提醒" fontSize={16} />
        </div>
        <Row
          className="dragon-result-row"
          gutter={[{ xs: 10, md: 12 }, 12]}
          style={{ marginBottom: '-3px' }}>
          <DragonResult
            issueNumber={currentTwoGame.lastOpen.uniqueIssueNumber}
            dragonList={dragonList}
          />
        </Row>
      </SectionWrapper>,
    );
  }

  return comp;
};

export default DragonStatistics;

const SectionWrapper = styled(Col)`
  background-color: ${color.white};
  padding: 15px 25px;
  border-bottom: 1px solid ${color.borderGrey};

  > div > p {
    font-weight: 400;
  }

  .dragon-result-row {
    .ant-col {
      display: flex;

      div {
        text-align: center;
        padding: 9px;
        border: 1px solid ${color.borderGrey};

        @media only screen and (max-width: 576px) {
          width: auto;
          padding: 7px 10px;
        }

        &:first-child {
          border-right: none;
        }
      }

      .title {
        font-weight: 600;
        background-color: ${color.backgroundGrey};
      }
    }
  }

  @media only screen and (max-width: 576px) {
    padding: 0;
  }
`;

import React from 'react';

import DescriptionBox from '@components/common/descriptionBox';

const descByGroup = {
  SSC: [
    '豹子：第一球、第二球、第三球号码相同。如中奖号码为000、111、999等；',
    '顺子：第一球、第二球、第三球数字都相连，不分顺序。如号码为123、901、321、546等;',
    '对子：第一球、第二球、第三球任意两位数字相同。如号码为001、112、696等；',
    '半顺：第一球、第二球、第三球任意两位数字相连，不分顺序。如中奖号码为540、390、706等;',
    '杂六：不包括豹子、顺子、对子、半顺的所有组合号码，如号码为157、964等，号码位数之间没有关联性。',
    '组三：第一球、第二球、第三球任意两位数字重复则为组三。例如：112、211、122、221;',
    '组六：第一球、第二球、第三球没有任何号码重复则为组六。例如：123、321、132、213；',
  ],
  D11: [
    '顺子：第一球、第二球、第三球数字都相连，不分顺序。如号码为123、901、321、546等;',
    '半顺：第一球、第二球、第三球任意两位数字相连，不分顺序。如中奖号码为540、390、706等;',
    '杂六：不包括豹子、顺子、对子、半顺的所有组合号码，如号码为157、964等，号码位数之间没有关联性。',
  ],
};

const Description = ({ group }) => {
  return (
    <>
      <DescriptionBox descByGroup={descByGroup[group]} />
    </>
  );
};

export default Description;

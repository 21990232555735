import React from 'react';
import styled from 'styled-components';

import { color } from '@helpers/const';
import { transl } from '@helpers/utils';

export const BgText = ({ bg, title }) => {
  return (
    <TitleBGWrapper bg={bg}>
      <p>{transl(title)}</p>
    </TitleBGWrapper>
  );
};

const TitleBGWrapper = styled.div`
  position: absolute;
  width: auto;
  height: 30px;
  padding: 0 43px;
  top: -4px;
  left: 50%;
  transform: translateX(-50%);
  text-align: center;
  background: url(${(props) => props.bg}) center/cover;

  P {
    color: ${color.white};
    font-size: 18px;
    letter-spacing: 2.25px;
    font-weight: 600;
    margin-bottom: 0;
  }
`;

export const TitleText = ({ text, fontSize = 18, color = '#000000' }) => {
  return (
    <TitleTextWrapper fontSize={fontSize} color={color}>
      {transl(text)}
    </TitleTextWrapper>
  );
};

const TitleTextWrapper = styled.p`
  font-size: ${(props) => props.fontSize}px;
  font-weight: 600;
  color: ${(props) => props.color};
  line-height: 28px;
  margin-bottom: 0;
`;

export const DrawNoText = ({
  textStart = '第',
  textEnd = '期开奖 ',
  no = '0000000',
}) => {
  return (
    <DrawNoTextWrapper>
      {textStart}
      <FontBolBlack>{no}</FontBolBlack>
      {textEnd}
    </DrawNoTextWrapper>
  );
};

const DrawNoTextWrapper = styled.p`
  font-size: 18px;
  color: ${color.fontGrey};
  margin-bottom: 11px;

  @media only screen and (max-width: 576px) {
    font-size: 17px;
  }
`;

const FontBolBlack = styled.span`
  color: ${color.black};
  font-weight: bold;
`;

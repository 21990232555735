import React, { useEffect, useState, useRef } from 'react';
import styled from 'styled-components';
import { inject, observer } from 'mobx-react';
import { toJS } from 'mobx';

import Sorter from '@components/common/trendsChartTable/sorter';

import { arrayStringToArrayInt, findShunZi, transl } from '@helpers/utils';
import { color } from '@helpers/const';
import withLocation from '@helpers/hoc/withLocation';
import isMobile from '@helpers/hoc/isMobile';

import { getDataAnalysis, getDataAnalysisPattern } from '@helpers/utils';

import {
  constPlayGroup,
  constGameBallNumber,
  constGameBallStartNumber,
} from '@const/game';
import {
  constTrendBallList,
  basicColumnBackgroundColor,
  shunZhi,
} from '@const/playRule';

import { Row, Col } from 'antd';

const { SSC, D11, KL10F } = constPlayGroup;

const { firstThree, middleThree, lastThree } = shunZhi;

const getTableHeaderData = () => {
  let columns = [
    {
      title: '期数',
      dataIndex: '期数',
      width: 120,
      sorter: true,
    },
    {
      title: '开奖号码',
      dataIndex: '开奖号码',
      width: 120,
      className: 'open-code',
    },
  ];

  return columns;
};

const getTableData = (singleGame, isMobile) => {
  let arrayNumberOpenCode = [];

  singleGame.map((game) => {
    return arrayNumberOpenCode.push(arrayStringToArrayInt(game.openCode));
  });

  let dataSourceArray = [];

  for (let index = 0; index < singleGame.length; index++) {
    const stringIssueNumber = singleGame[index].uniqueIssueNumber.toString();
    const shortenIssueNumber =
      stringIssueNumber.length > 4
        ? stringIssueNumber.slice(stringIssueNumber.length - 4)
        : stringIssueNumber;
    let data = {
      key: index,
      uniqueIssueNumber: isMobile ? shortenIssueNumber : stringIssueNumber,
      openCode: arrayNumberOpenCode[index],
    };
    dataSourceArray.push(data);
  }

  return dataSourceArray;
};

const getTableHeaderAnalysis = (group, ballFilter) => {
  let result = [];
  let header;
  let objPatternHeader;

  for (
    let indexBallFilter = 0;
    indexBallFilter < ballFilter.length;
    indexBallFilter++
  ) {
    header = {
      title: constTrendBallList[ballFilter[indexBallFilter]].text,
      dataIndex: constTrendBallList[ballFilter[indexBallFilter]].text,
    };

    let ball = [];
    for (
      let indexChild = constGameBallStartNumber[group];
      indexChild <= constGameBallNumber[group];
      indexChild++
    ) {
      const ballCol = {
        title: indexChild.toString(),
        dataIndex:
          constTrendBallList[ballFilter[indexBallFilter]].text + indexChild,
      };
      ball.push(ballCol);
    }

    header.children = ball;
    result.push(header);
  }

  switch (group) {
    case D11:
      objPatternHeader = [
        {
          title: '形态',
          children: ['顺子', '半顺', '杂六'],
        },
      ];
      break;
    case SSC:
      objPatternHeader = [
        {
          title: '形态',
          children: ['豹子', '顺子', '对子', '半顺', '杂六'],
        },
        {
          title: '组选类型',
          children: ['组三', '组六', '豹子'],
        },
      ];
      break;
    default:
      break;
  }

  for (let index = 0; index < objPatternHeader.length; index++) {
    header = {
      title: objPatternHeader[index].title,
      dataIndex: objPatternHeader[index].title,
    };

    let pattern = [];
    for (
      let indexChild = 0;
      indexChild < objPatternHeader[index].children.length;
      indexChild++
    ) {
      const patternCol = {
        title: objPatternHeader[index].children[indexChild],
        dataIndex: objPatternHeader[index].children[indexChild],
      };
      pattern.push(patternCol);
    }

    header.children = pattern;
    result.push(header);
  }

  return result;
};

const getTableDataAnalysis = (singleGame, group, ballFilter) => {
  let arrayNumberOpenCode = [];

  singleGame.map((game) => {
    return arrayNumberOpenCode.push(arrayStringToArrayInt(game.openCode));
  });

  const resultDataAnalysis = getDataAnalysis(
    group,
    arrayNumberOpenCode,
    ballFilter,
  );

  const {
    appear,
    average,
    biggest,
    connect,
    openCodeByBall,
    table,
  } = resultDataAnalysis;

  const result = {
    table: [table[ballFilter[0]], table[ballFilter[1]], table[ballFilter[2]]],
    appear: [
      appear[ballFilter[0]],
      appear[ballFilter[1]],
      appear[ballFilter[2]],
    ],
    average: [
      average[ballFilter[0]],
      average[ballFilter[1]],
      average[ballFilter[2]],
    ],
    biggest: [
      biggest[ballFilter[0]],
      biggest[ballFilter[1]],
      biggest[ballFilter[2]],
    ],
    connect: [
      connect[ballFilter[0]],
      connect[ballFilter[1]],
      connect[ballFilter[2]],
    ],
    openCodeByBall: [
      openCodeByBall[ballFilter[0]],
      openCodeByBall[ballFilter[1]],
      openCodeByBall[ballFilter[2]],
    ],
  };

  return result;
};

const getPatternAnalysis = (openCode, group, findSingleGame, ballFilter) => {
  let result;
  let resultTable = [];
  let countAppear;
  let countShunZhi;
  let countNonAppear;
  let maxShunZi;
  switch (group) {
    case SSC:
      countAppear = new Array(8).fill(0);
      countShunZhi = new Array(8).fill(0);
      countNonAppear = new Array(8).fill(true);
      maxShunZi = 8;
      break;
    case D11:
      countAppear = new Array(3).fill(0);
      countShunZhi = new Array(3).fill(0);
      countNonAppear = new Array(3).fill(true);
      maxShunZi = 3;
      break;

    default:
      break;
  }

  for (let index = 0; index < openCode[0].length; index++) {
    let arrayShunZhi = [];
    let shunZhiResult;
    switch (JSON.stringify(ballFilter)) {
      case '[0,1,2]':
        shunZhiResult = findShunZi(
          firstThree,
          [openCode[0][index], openCode[1][index], openCode[2][index]],
          group,
        );
        break;
      case '[1,2,3]':
        shunZhiResult = findShunZi(
          middleThree,
          [0, openCode[0][index], openCode[1][index], openCode[2][index]],
          group,
        );
        break;
      case '[2,3,4]':
        shunZhiResult = findShunZi(
          lastThree,
          [0, 0, openCode[0][index], openCode[1][index], openCode[2][index]],
          group,
        );
        break;
      default:
        break;
    }

    switch (group) {
      case SSC:
        switch (shunZhiResult) {
          case '豹子':
            for (let indexCount = 0; indexCount < maxShunZi; indexCount++) {
              if (indexCount === 0 || indexCount === 7) {
                countAppear[indexCount]++;
                countShunZhi[indexCount] = 0;
                countNonAppear[indexCount] = false;
                arrayShunZhi.push(['豹子', countNonAppear[indexCount]]);
              } else {
                countShunZhi[indexCount]++;
                arrayShunZhi.push([
                  countShunZhi[indexCount],
                  countNonAppear[indexCount],
                ]);
              }
            }
            break;
          case '顺子':
            for (let indexCount = 0; indexCount < maxShunZi; indexCount++) {
              if (indexCount === 1 || indexCount === 6) {
                countAppear[indexCount]++;
                countShunZhi[indexCount] = 0;
                countNonAppear[indexCount] = false;
                if (indexCount === 1) {
                  arrayShunZhi.push(['顺子', countNonAppear[indexCount]]);
                } else {
                  arrayShunZhi.push(['组六', countNonAppear[indexCount]]);
                }
              } else {
                countShunZhi[indexCount]++;
                arrayShunZhi.push([
                  countShunZhi[indexCount],
                  countNonAppear[indexCount],
                ]);
              }
            }
            break;
          case '对子':
            for (let indexCount = 0; indexCount < maxShunZi; indexCount++) {
              if (indexCount === 2 || indexCount === 5) {
                countAppear[indexCount]++;
                countShunZhi[indexCount] = 0;
                countNonAppear[indexCount] = false;
                if (indexCount === 2) {
                  arrayShunZhi.push(['对子', countNonAppear[indexCount]]);
                } else {
                  arrayShunZhi.push(['组三', countNonAppear[indexCount]]);
                }
              } else {
                countShunZhi[indexCount]++;
                arrayShunZhi.push([
                  countShunZhi[indexCount],
                  countNonAppear[indexCount],
                ]);
              }
            }
            break;
          case '半顺':
            for (let indexCount = 0; indexCount < maxShunZi; indexCount++) {
              if (indexCount === 3 || indexCount === 6) {
                countAppear[indexCount]++;
                countShunZhi[indexCount] = 0;
                countNonAppear[indexCount] = false;
                if (indexCount === 3) {
                  arrayShunZhi.push(['半顺', countNonAppear[indexCount]]);
                } else {
                  arrayShunZhi.push(['组六', countNonAppear[indexCount]]);
                }
              } else {
                countShunZhi[indexCount]++;
                arrayShunZhi.push([
                  countShunZhi[indexCount],
                  countNonAppear[indexCount],
                ]);
              }
            }
            break;
          case '杂六':
            for (let indexCount = 0; indexCount < maxShunZi; indexCount++) {
              if (indexCount === 4 || indexCount === 6) {
                countAppear[indexCount]++;
                countShunZhi[indexCount] = 0;
                countNonAppear[indexCount] = false;
                if (indexCount === 4) {
                  arrayShunZhi.push(['杂六', countNonAppear[indexCount]]);
                } else {
                  arrayShunZhi.push(['组六', countNonAppear[indexCount]]);
                }
              } else {
                countShunZhi[indexCount]++;
                arrayShunZhi.push([
                  countShunZhi[indexCount],
                  countNonAppear[indexCount],
                ]);
              }
            }
            break;

          default:
            break;
        }
        break;

      case D11:
        switch (shunZhiResult) {
          case '顺子':
            for (let indexCount = 0; indexCount < maxShunZi; indexCount++) {
              if (indexCount === 0) {
                countAppear[indexCount]++;
                countShunZhi[indexCount] = 0;
                countNonAppear[indexCount] = false;
                arrayShunZhi.push(['顺子', countNonAppear[indexCount]]);
              } else {
                countShunZhi[indexCount]++;
                arrayShunZhi.push([
                  countShunZhi[indexCount],
                  countNonAppear[indexCount],
                ]);
              }
            }
            break;
          case '半顺':
            for (let indexCount = 0; indexCount < maxShunZi; indexCount++) {
              if (indexCount === 1) {
                countAppear[indexCount]++;
                countShunZhi[indexCount] = 0;
                countNonAppear[indexCount] = false;
                arrayShunZhi.push(['半顺', countNonAppear[indexCount]]);
              } else {
                countShunZhi[indexCount]++;
                arrayShunZhi.push([
                  countShunZhi[indexCount],
                  countNonAppear[indexCount],
                ]);
              }
            }
            break;
          case '杂六':
            for (let indexCount = 0; indexCount < maxShunZi; indexCount++) {
              if (indexCount === 2) {
                countAppear[indexCount]++;
                countShunZhi[indexCount] = 0;
                countNonAppear[indexCount] = false;
                arrayShunZhi.push(['杂六', countNonAppear[indexCount]]);
              } else {
                countShunZhi[indexCount]++;
                arrayShunZhi.push([
                  countShunZhi[indexCount],
                  countNonAppear[indexCount],
                ]);
              }
            }
            break;

          default:
            break;
        }
        break;
      default:
        break;
    }

    resultTable.push(arrayShunZhi);
  }

  const calDataAnalysisPattern = getDataAnalysisPattern(resultTable, maxShunZi);

  const { countAverage, countBiggest, countConnect } = calDataAnalysisPattern;

  result = {
    table: resultTable,
    appear: countAppear,
    average: countAverage,
    biggest: countBiggest,
    connect: countConnect,
  };

  return result;
};

function drawCanvas(tableDataAnalysis, context, group, section) {
  let sectionCount = 0;
  const condX = 25;
  const condY = 42;
  for (let indexBall = 0; indexBall < tableDataAnalysis.length; indexBall++) {
    context.beginPath();
    for (
      let indexRow = 0;
      indexRow < tableDataAnalysis[indexBall].length;
      indexRow++
    ) {
      if (section) {
        sectionCount = Math.floor(indexRow / 5);
      }
      for (
        let indexNumber = 0;
        indexNumber < tableDataAnalysis[indexBall][indexRow].length;
        indexNumber++
      ) {
        if (tableDataAnalysis[indexBall][indexRow][indexNumber][1]) {
          const numberPos = indexNumber + 1;
          const ballPos =
            indexBall *
            (constGameBallNumber[group] - constGameBallStartNumber[group] + 1);

          if (indexRow === 0) {
            context.moveTo(
              (numberPos + ballPos) * condX - condX / 2,
              1 * condY - condY / 2 + sectionCount,
            );
          } else {
            context.lineTo(
              (numberPos + ballPos) * condX - condX / 2,
              (indexRow + 1) * condY - condY / 2 + sectionCount,
            );
          }
        }
      }
    }
    context.strokeStyle = basicColumnBackgroundColor[indexBall];
    context.stroke();
  }
}

const ConclusionResultTable = ({ valueBall, group }) => {
  return (
    <div className={'counter ' + group}>
      <span>{valueBall}</span>
    </div>
  );
};

const MainTable = ({ trendsStore, search, isMobile, filter, ballFilter }) => {
  const [sorting, setSorting] = useState(true);

  const canvasRef = useRef(null);
  const mainTable = useRef(null);
  const [context, setContext] = useState(null);
  const [tableWidth, setTableWidth] = useState(0);
  const [tableHeight, setTableHeight] = useState(0);

  const { singleGame, findSingleGame } = trendsStore;

  const { group, language } = search;

  useEffect(() => {
    if (canvasRef.current) {
      const renderCtx = canvasRef.current.getContext('2d');

      if (renderCtx) {
        setContext(renderCtx);
      }
    }

    if (mainTable.current) {
      setTableWidth(mainTable.current.clientWidth);
      setTableHeight(mainTable.current.clientHeight);
    }
  }, [canvasRef, mainTable, context, singleGame]);

  const headerData = getTableHeaderData();
  const headerAnalysis = getTableHeaderAnalysis(group, ballFilter);
  const tableData = singleGame ? getTableData(toJS(singleGame), isMobile) : [];
  const tableDataAnalysis = singleGame
    ? getTableDataAnalysis(toJS(singleGame), group, ballFilter)
    : [];
  const tableDataPattern =
    singleGame && findSingleGame
      ? getPatternAnalysis(
          tableDataAnalysis.openCodeByBall,
          group,
          toJS(findSingleGame),
          ballFilter,
        )
      : '';

  const sortedTableData = tableData.slice();

  const rightTableData = tableDataAnalysis.table.slice();

  const sortedTableDataPattern = tableDataPattern.table.slice();

  if (sorting) {
    sortedTableData.reverse();
    sortedTableDataPattern.reverse();

    for (let index = 0; index < rightTableData.length; index++) {
      const temp = rightTableData[index].slice();
      rightTableData[index] = temp.reverse();
    }
  }

  setTimeout(() => {
    if (
      mainTable.current &&
      context &&
      rightTableData.length &&
      group !== KL10F
    ) {
      context.clearRect(0, 0, tableWidth, tableHeight);
      if (filter.includes('line')) {
        drawCanvas(rightTableData, context, group, filter.includes('section'));
      }
    }
  }, 100);

  return (
    <TrendsResultWrapper>
      <TrendsTableWrapper>
        <div>
          <Row className="table-wrapper">
            <Col className="table-left">
              <Row className={language}>
                {headerData.map(({ title, dataIndex, sorter }) => {
                  const headerClass = sorter
                    ? 'header-left ' + group + ' sorter-col'
                    : 'header-left ' + group;
                  return (
                    <Col key={dataIndex} className={headerClass}>
                      {sorter ? (
                        <button
                          className="sorter"
                          onClick={() => setSorting(!sorting)}>
                          <Row justify="center" gutter={4}>
                            <Col>
                              <b>{transl(title, [], true, false, 'en-US')}</b>
                            </Col>
                            <Col
                              style={{
                                display: 'flex',
                              }}>
                              <Sorter sorting={sorting} />
                            </Col>
                          </Row>
                        </button>
                      ) : (
                        <b>{transl(title, [], true, false, 'en-US')}</b>
                      )}
                    </Col>
                  );
                })}
              </Row>
              {sortedTableData.map(
                ({ key, openCode, uniqueIssueNumber }, index) => {
                  return (
                    <Row
                      key={key}
                      className={
                        index % 5 === 4 && filter.includes('section')
                          ? 'separate'
                          : ''
                      }>
                      <Col className={'data-left ' + group}>
                        {uniqueIssueNumber}
                      </Col>
                      <Col className={'data-left open-code ' + group}>
                        {openCode.map((value, index) => {
                          return (
                            <span
                              key={index}
                              className={
                                ballFilter.includes(index) ? 'red' : ''
                              }>
                              <b>{value}</b>
                            </span>
                          );
                        })}
                      </Col>
                    </Row>
                  );
                },
              )}
              {!isMobile ? (
                <Row>
                  <Col className="header-left span-2">
                    <b>{transl('数据统计', [], true, false, 'en-US')}</b>
                  </Col>
                </Row>
              ) : (
                ''
              )}
              <Row>
                <Col className={'span-2 ' + group}>
                  {transl('出现次数', [], true, false, 'en-US')}
                </Col>
              </Row>
              <Row>
                <Col className={'span-2 ' + group}>
                  {transl('平均遗漏', [], true, false, 'en-US')}
                </Col>
              </Row>
              <Row>
                <Col className={'span-2 ' + group}>
                  {transl('最大遗漏', [], true, false, 'en-US')}
                </Col>
              </Row>
              <Row>
                <Col className={'span-2 ' + group}>
                  {transl('最大连出', [], true, false, 'en-US')}
                </Col>
              </Row>
            </Col>
            <Col className="table-right">
              <Row style={{ width: '100%' }} className={language}>
                {headerAnalysis.map(({ dataIndex, title, children }, index) => {
                  if (isMobile) {
                    if (index > 2) {
                      return (
                        <Col key={dataIndex}>
                          <div className="ball-number">
                            <b>{transl(title, [], true, false, 'en-US')}</b>
                          </div>
                          <Row className="row-number">
                            {children.map(({ dataIndex, title }) => {
                              return (
                                <Col
                                  key={dataIndex}
                                  className={'number ' + group}>
                                  <b>
                                    {transl(title, [], true, false, 'en-US')}
                                  </b>
                                </Col>
                              );
                            })}
                          </Row>
                        </Col>
                      );
                    } else {
                      return '';
                    }
                  } else {
                    return (
                      <Col key={dataIndex}>
                        <div className="ball-number">
                          <b>{transl(title, [], true, false, 'en-US')}</b>
                        </div>
                        <Row className="row-number">
                          {children.map(({ dataIndex, title }) => {
                            return (
                              <Col
                                key={dataIndex}
                                className={'number ' + group}>
                                <b>{transl(title, [], true, false, 'en-US')}</b>
                              </Col>
                            );
                          })}
                        </Row>
                      </Col>
                    );
                  }
                })}
              </Row>
              <div className="result-wrapper" ref={mainTable}>
                {!isMobile ? (
                  <canvas
                    id="canvas"
                    ref={canvasRef}
                    width={tableWidth}
                    height={tableHeight}></canvas>
                ) : (
                  ''
                )}
                <Row>
                  {!isMobile &&
                    rightTableData.map((valueRow, index) => {
                      return (
                        <Col key={index} className="ball-column">
                          {valueRow.map((valueBall, indexRow) => {
                            return (
                              <Row
                                key={indexRow}
                                className={
                                  indexRow % 5 === 4 &&
                                  filter.includes('section')
                                    ? 'separate'
                                    : ''
                                }>
                                {valueBall.map((value, numberIndex) => {
                                  const classStyle =
                                    filter.includes('hightLightCount') &&
                                    value[2]
                                      ? 'non-appear counter ' + group
                                      : 'counter ' + group;
                                  return (
                                    <div
                                      key={indexRow + '' + numberIndex}
                                      className={classStyle}>
                                      <span
                                        className={value[1] ? 'circle' : ''}>
                                        {filter.includes('count') || value[1]
                                          ? value[0]
                                          : ''}
                                      </span>
                                    </div>
                                  );
                                })}
                              </Row>
                            );
                          })}
                        </Col>
                      );
                    })}
                  <Col className="ball-column">
                    {tableDataPattern &&
                      sortedTableDataPattern.map((row, indexRow) => {
                        return (
                          <Row
                            key={indexRow}
                            className={
                              indexRow % 5 === 4 && filter.includes('section')
                                ? 'separate'
                                : ''
                            }>
                            {row.map((value, index) => {
                              let classStyle =
                                typeof value[0] === 'string'
                                  ? 'counter pattern ' + group
                                  : 'counter ' + group;
                              classStyle =
                                filter.includes('hightLightCount') && value[1]
                                  ? classStyle + ' non-appear'
                                  : classStyle;
                              return (
                                <div key={index} className={classStyle}>
                                  <span>
                                    {filter.includes('count') || isNaN(value[0])
                                      ? transl(
                                          value[0],
                                          [],
                                          true,
                                          false,
                                          'en-US',
                                        )
                                      : ''}
                                  </span>
                                </div>
                              );
                            })}
                          </Row>
                        );
                      })}
                  </Col>
                </Row>
              </div>
              <Row style={{ width: '100%' }}>
                {!isMobile &&
                  headerAnalysis.map(
                    ({ dataIndex, title, children }, index) => {
                      return (
                        <Col key={dataIndex}>
                          <div className="ball-number btm-part">
                            <b>{transl(title, [], true, false, 'en-US')}</b>
                          </div>
                          <Row className="row-number">
                            {children.map(({ dataIndex, title }) => {
                              return (
                                <Col
                                  key={dataIndex}
                                  className={'number ' + group}>
                                  <b>
                                    {transl(title, [], true, false, 'en-US')}
                                  </b>
                                </Col>
                              );
                            })}
                          </Row>
                        </Col>
                      );
                    },
                  )}
              </Row>
              <div className="result-wrapper btm-part">
                <Row>
                  {!isMobile &&
                    tableDataAnalysis.appear.map((valueBall, indexBall) => {
                      return valueBall.map((value, index) => {
                        return (
                          <Col key={indexBall + '' + index}>
                            <ConclusionResultTable
                              valueBall={value}
                              group={group}
                            />
                          </Col>
                        );
                      });
                    })}
                  {tableDataPattern &&
                    tableDataPattern.appear.map((valueBall, index) => {
                      return (
                        <Col key={index}>
                          <ConclusionResultTable
                            valueBall={valueBall}
                            group={group}
                          />
                        </Col>
                      );
                    })}
                </Row>
              </div>
              <div className="result-wrapper btm-part">
                <Row>
                  {!isMobile &&
                    tableDataAnalysis.average.map((valueBall, indexBall) => {
                      return valueBall.map((value, index) => {
                        return (
                          <Col key={indexBall + '' + index}>
                            <ConclusionResultTable
                              valueBall={value}
                              group={group}
                            />
                          </Col>
                        );
                      });
                    })}
                  {tableDataPattern &&
                    tableDataPattern.average.map((valueBall, index) => {
                      return (
                        <Col key={index}>
                          <ConclusionResultTable
                            valueBall={valueBall}
                            group={group}
                          />
                        </Col>
                      );
                    })}
                </Row>
              </div>
              <div className="result-wrapper btm-part">
                <Row>
                  {!isMobile &&
                    tableDataAnalysis.biggest.map((valueBall, indexBall) => {
                      return valueBall.map((value, index) => {
                        return (
                          <Col key={indexBall + '' + index}>
                            <ConclusionResultTable
                              valueBall={value}
                              group={group}
                            />
                          </Col>
                        );
                      });
                    })}
                  {tableDataPattern &&
                    tableDataPattern.biggest.map((valueBall, index) => {
                      return (
                        <Col key={index}>
                          <ConclusionResultTable
                            valueBall={valueBall}
                            group={group}
                          />
                        </Col>
                      );
                    })}
                </Row>
              </div>
              <div className="result-wrapper btm-part">
                <Row>
                  {!isMobile &&
                    tableDataAnalysis.connect.map((valueBall, indexBall) => {
                      return valueBall.map((value, index) => {
                        return (
                          <Col key={indexBall + '' + index}>
                            <ConclusionResultTable
                              valueBall={value}
                              group={group}
                            />
                          </Col>
                        );
                      });
                    })}
                  {tableDataPattern &&
                    tableDataPattern.connect.map((valueBall, index) => {
                      return (
                        <Col key={index}>
                          <ConclusionResultTable
                            valueBall={valueBall}
                            group={group}
                          />
                        </Col>
                      );
                    })}
                </Row>
              </div>
            </Col>
          </Row>
        </div>
      </TrendsTableWrapper>
    </TrendsResultWrapper>
  );
};

export default isMobile(
  withLocation(inject('trendsStore')(observer(MainTable))),
);

const TrendsResultWrapper = styled.div`
  background-color: ${color.white};

  .ant-table table {
    th {
      padding: 7px 0;
      font-size: 15px;
      background-color: ${color.white};

      &.number {
        padding: 5px 0;
      }
    }
  }
`;

const TrendsTableWrapper = styled.div`
  position: relative;
  padding: 0 25px 48px;

  @media only screen and (max-width: 576px) {
    padding: 0;
  }

  #canvas {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
  }

  .table-wrapper {
    flex-flow: unset;

    .result-wrapper {
      position: relative;

      .ball-column {
        &:first-child {
          background-color: ${color.opacityYellow};

          .circle {
            background-color: ${color.lightYellow};
          }

          @media only screen and (max-width: 576px) {
            background-color: ${color.white};

            > .ant-row:nth-child(2n) {
              background-color: ${color.backgroundGrey};
            }
          }
        }

        &:nth-child(2) {
          background-color: ${color.opacityRed};

          .circle {
            background-color: ${color.lightRed};
          }
        }

        &:nth-child(3) {
          background-color: ${color.opacityBlue};

          .circle {
            background-color: ${color.lightBlue};
          }
        }

        .separate {
          .counter {
            border-bottom: 2px solid ${color.tableBorderGrey};
            height: 43px;
          }
        }
      }

      .counter {
        display: flex;
        flex: 1 1;
        justify-content: center;
        width: 25px;
        height: 42px;
        color: ${color.lightFontGrey};
        border: 1px solid ${color.tableBorderGrey};
        border-top: 0;
        border-left: 0;

        @media only screen and (max-width: 576px) {
          width: 30px;

          &.D11 {
            width: 60px;
            height: 30px;
          }
        }

        &.non-appear {
          background-color: ${color.darkGrey};
          color: ${color.white};
        }

        &.pattern {
          color: ${color.white};
          background-color: ${color.lightBlue};

          &:nth-child(n + 6) {
            background-color: ${color.lightYellow};
          }

          &.D11 {
            background-color: ${color.lightYellow};

            &:nth-child(2) {
              background-color: ${color.lightRed};
            }

            &:nth-child(3) {
              background-color: ${color.lightBlue};
            }
          }
        }

        span {
          display: block;
          min-width: 20px;
          padding: 0;
          line-height: 20px;
          text-align: center;
          margin: auto;

          @media only screen and (max-width: 576px) {
            font-size: 13px;
            padding: 0 5px;
            line-height: 17px;
          }

          &.circle {
            position: relative;
            border-radius: 100%;
            color: ${color.white};
            z-index: 1;
          }
        }
      }

      @media only screen and (max-width: 576px) {
        &.btm-part {
          background-color: ${color.orange};

          .counter {
            height: auto;

            span {
              line-height: 29px;
            }
          }

          span {
            color: ${color.white};
          }
        }
      }
    }

    .ant-row {
      flex-flow: unset;
    }

    .ant-col {
      max-width: unset;
    }

    .table-left {
      text-align: center;
      flex: 1;

      @media only screen and (max-width: 576px) {
        > .ant-row {
          &:first-child {
            position: sticky;
            top: 88px;
            z-index: 2;

            &.en-US,
            &.vi-VN {
              top: 135px;
            }
          }

          &:nth-child(2n + 1) {
            background-color: ${color.backgroundGrey};
          }
        }
      }

      .header-left {
        display: flex;
        height: 84px;
        font-size: 15px;
        width: 110px;
        border: 1px solid ${color.tableBorderGrey};
        background-color: ${color.backgroundGrey};

        b {
          margin: auto;
        }

        &:first-child {
          border-right: 0;
        }

        &:nth-child(2) {
          width: 80px;
          flex: 1;

          &.D11 {
            width: 130px;
          }
        }

        &.sorter-col {
          .sorter {
            width: 100%;
            height: calc(100% - 2px);
            padding: 0;
            background-color: ${color.backgroundGrey};
            border: none;
            cursor: pointer;

            @media only screen and (max-width: 576px) {
              background-color: ${color.orange};
            }
          }
        }

        &.span-2 {
          display: flex;
          height: 83px;
          padding: 18px 0;
          border: 1px solid ${color.tableBorderGrey};
          border-top: 0;
        }

        @media only screen and (max-width: 576px) {
          font-size: 13px;
          width: 70px;
          height: 81px;
          line-height: 81px;
          padding: 0;
          color: ${color.white};
          background-color: ${color.orange};

          b {
            font-weight: 400;
          }

          &:first-child {
            width: 50px;
            padding: 0;
            flex: 0 1 50px;
          }

          &:nth-child(2) {
            flex: auto;
          }

          &.D11 {
            height: 60px;
            line-height: 60px;
          }
        }
      }

      .data-left {
        width: 110px;
        padding: 9.5px 0px;
        border: 1px solid ${color.tableBorderGrey};
        border-top: 0;

        &:nth-child(2) {
          width: 80px;
        }

        &:first-child {
          border-right: 0;
        }

        &.open-code {
          display: flex;
          flex: 1 1;
          justify-content: center;

          .red {
            color: ${color.red};
          }

          span {
            display: block;
            margin: 0 1px;
          }

          &.D11 {
            span {
              width: 18px;
            }
          }
        }

        @media only screen and (max-width: 576px) {
          font-size: 13px;
          width: 70px;
          height: 42px;
          line-height: 42px;
          padding: 0;
          color: ${color.mobileBlack};

          &.D11 {
            height: 30px;
            line-height: 30px;
          }

          &:first-child {
            width: 50px;
            flex: 0 1 50px;
          }

          &.open-code {
            flex: auto;

            span {
              color: ${color.mobileBlack};
              width: 11%;

              b {
                font-weight: 400;
              }

              .red {
                color: ${color.red};
              }
            }
          }
        }
      }

      .separate {
        .data-left {
          border-bottom: 2px solid ${color.tableBorderGrey};
          height: 43px;
        }
      }

      .span-2 {
        width: 100%;
        height: 30px;
        line-height: 30px;
        border: 1px solid ${color.tableBorderGrey};
        border-top: 0;

        @media only screen and (max-width: 576px) {
          font-size: 13px;
          line-height: 29px;
          color: ${color.white};
          background-color: ${color.orange};
        }
      }
    }

    .table-right {
      overflow-x: auto;

      @media only screen and (max-width: 576px) {
        overflow-x: unset;

        > .ant-row {
          position: sticky;
          top: 88px;
          z-index: 2;

          &.en-US,
          &.vi-VN {
            top: 135px;
          }
        }
      }

      &::-webkit-scrollbar {
        width: 8px;
        height: 8px;
      }

      /* Track */
      &::-webkit-scrollbar-track {
        background: #f1f1f1;
      }

      /* Handle */
      &::-webkit-scrollbar-thumb {
        background: #cdcdcd;
      }

      /* Handle on hover */
      &::-webkit-scrollbar-thumb:hover {
        background: #797979;
      }

      &::-webkit-scrollbar-button {
        width: 0;
        height: 0;
        display: none;
      }

      scrollbar-width: thin;

      .ball-number {
        text-align: center;
        font-size: 15px;
        padding: 4px 0;
        border: 1px solid ${color.tableBorderGrey};
        border-left: 0;
        background-color: ${color.backgroundGrey};

        @media only screen and (max-width: 576px) {
          font-size: 13px;
          background-color: ${color.orange};
          color: ${color.white};

          b {
            font-weight: 400;
          }
        }

        &.btm-part {
          border-top: 0;
        }
      }

      .row-number {
        text-align: center;
        font-size: 15px;
        background-color: ${color.backgroundGrey};

        @media only screen and (max-width: 576px) {
          font-size: 13px;
          background-color: ${color.orange};
          color: ${color.white};
        }

        .number {
          display: flex;
          width: 25px;
          height: 51px;
          border: 1px solid ${color.tableBorderGrey};
          border-left: 0;
          border-top: 0;

          b {
            margin: auto;
          }

          @media only screen and (max-width: 576px) {
            width: 30px;
            padding: 0 5px;

            b {
              font-weight: 400;
            }

            &.D11 {
              height: 30px;
              width: 60px;
            }
          }
        }
      }

      .btm-part {
        .counter {
          height: 30px;
          line-height: 30px;
        }
      }
    }
  }
`;

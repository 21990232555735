import React from 'react';

import DescriptionBox from '@components/common/descriptionBox';

const ruleDescription = [
  '提示说明: 每天推荐计划时间将依据每日彩种每日开彩期数。',
];

const Description = () => {
  return (
    <>
      <DescriptionBox generalDescription={ruleDescription} />
    </>
  );
};

export default Description;

import React, { useState } from 'react';
import { inject, observer } from 'mobx-react';
import Filter from './Filter';

import PlayRuleTitle from '@components/common/playRuleTitle';

import { openCodeToArray, transl } from '@helpers/utils';

import withLocation from '@helpers/hoc/withLocation';
import isMobile from '@helpers/hoc/isMobile';

import MainTable from './MainTable';

const HaoMaTongJi = ({ trendsStore, search, isMobile }) => {
  const { group } = search;

  const { singleGame } = trendsStore;

  const [filter, setFilter] = useState([]);
  const [apply, setApply] = useState(false);
  const [mobileActiveBall, setMobileActiveBall] = useState(0);

  let openCodeArray = [];
  if (singleGame) {
    openCodeArray = openCodeToArray(singleGame);
  }

  return (
    <>
      {!isMobile && <PlayRuleTitle title={transl('号码统计')} />}
      <Filter
        setFilter={setFilter}
        setApply={setApply}
        group={group}
        mobileActiveBall={mobileActiveBall}
        setMobileActiveBall={setMobileActiveBall}
      />
      <MainTable
        openCodeArray={openCodeArray}
        group={group}
        filter={filter}
        apply={apply}
        mobileActiveBall={mobileActiveBall}
      />
    </>
  );
};

export default isMobile(
  withLocation(inject('trendsStore')(observer(HaoMaTongJi))),
);

import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { Row, Col, Drawer } from 'antd';
import { inject, observer } from 'mobx-react';
import { toJS } from 'mobx';

import LcwButton from '@components/common/button';

import withLocation from '@helpers/hoc/withLocation';
import isMobile from '@helpers/hoc/isMobile';
import { color } from '@helpers/const';
import { generateQueryString, parseQueryString, transl } from '@helpers/utils';

import { constPlayGroup } from '@const/game';
import { constPlayRule, constLowFrequencyGame } from '@const/playRule';
import {
  URLS,
  constPlayRuleByGroup,
  constTrendsButtonSSC,
} from '@const/config';

import JiShiKaiJiang from './jiShiKaiJiang';
import LuZhuFenXi from './luZhuFenXi';
import KaiJiangJilu from './kaiJiangJilu';
import ZongHeFenXi from './zongHeFenXi';
import LengReFenXi from './lengReFenXi';
import JiBenZouShi from './jiBenZouShi';
import DingWeiZouShi from './dingWeiZouShi';
import LongHuZouShi from './longHuZouShi';
import XingTaiZouShi from './xingTaiZouShi';
import HaoMaLuzhu from './haoMaLuzhu';
import DaXiaoDanShuangLuzhu from './daXiaoDanShuangLuzhu';
import ZongHeLuZhu from './zongHeLuZhu';
import HaoMaTongJi from './haoMaTongJi';
import LiangMianTongJi from './liangMianTongJi';
import ChangLongTongJi from './changLongTongJi';
import LiShiHaoMa from './liShiHaoMa';
import DaXiaoDanShuangLiShi from './daXiaoDanShuangLiShi';
import PkZhouShi from './pkZhouShi';

const {
  jiShiKaiJiang,
  luZhuFenXi,
  kaiJiangJilu,
  zongHeFenXi,
  lengReFenXi,
  jiBenZouShi,
  dingWeiZouShi,
  longHuZouShi,
  xingTaiZouShi,
  haoMaLuzhu,
  daXiaoDanShuangLuzhu,
  zongHeLuZhu,
  guanYaHeLuZhu,
  haoMaTongJi,
  liangMianTongJi,
  changLongTongJi,
  liShiHaoMa,
  daXiaoDanShuangLiShi,
  pkZhouShi,
} = constPlayRule;

const { SIX } = constPlayGroup;

const PlayRule = ({
  trendsStore,
  search,
  isMobile,
  location,
  ruleName,
  openRule,
  setOpenRule,
}) => {
  const { rule, group, searchGame } = search;

  const { singleGame } = trendsStore;

  const [chartType, setChartType] = useState('');

  useEffect(() => {
    let defaultChartType;

    if (group !== SIX) {
      defaultChartType = jiShiKaiJiang;
    } else {
      defaultChartType = kaiJiangJilu;
    }

    if (rule === undefined) {
      setChartType(defaultChartType);
    } else {
      setChartType(rule);
    }
  }, [rule, group]);

  let playRule;
  let playGroup;
  if (group === 'THREED') {
    playGroup = '3D';
  } else {
    playGroup = group;
  }

  if (singleGame.length && toJS(singleGame)[0].playGroup === playGroup) {
    switch (chartType) {
      case jiShiKaiJiang:
        playRule = <JiShiKaiJiang />;
        break;
      case luZhuFenXi:
        playRule = <LuZhuFenXi />;
        break;
      case kaiJiangJilu:
        playRule = <KaiJiangJilu />;
        break;
      case zongHeFenXi:
        playRule = <ZongHeFenXi />;
        break;
      case lengReFenXi:
        playRule = <LengReFenXi />;
        break;
      case jiBenZouShi:
        playRule = <JiBenZouShi />;
        break;
      case dingWeiZouShi:
        playRule = <DingWeiZouShi />;
        break;
      case longHuZouShi:
        playRule = <LongHuZouShi />;
        break;
      case xingTaiZouShi:
        playRule = <XingTaiZouShi />;
        break;
      case haoMaLuzhu:
        playRule = <HaoMaLuzhu />;
        break;
      case daXiaoDanShuangLuzhu:
        playRule = <DaXiaoDanShuangLuzhu />;
        break;
      case zongHeLuZhu:
      case guanYaHeLuZhu:
        playRule = <ZongHeLuZhu />;
        break;
      case haoMaTongJi:
        playRule = <HaoMaTongJi />;
        break;
      case liangMianTongJi:
        playRule = <LiangMianTongJi />;
        break;
      case changLongTongJi:
        playRule = <ChangLongTongJi />;
        break;
      case liShiHaoMa:
        playRule = <LiShiHaoMa />;
        break;
      case daXiaoDanShuangLiShi:
        playRule = <DaXiaoDanShuangLiShi />;
        break;
      case pkZhouShi:
        playRule = <PkZhouShi />;
        break;
      default:
        break;
    }
  }

  let buttonUrl = URLS.trends;
  if (location.pathname === '/app/iframe') {
    buttonUrl = URLS.iframe;
  }

  let btnStyle = 1;
  if (isMobile && location.pathname !== '/app/trends') {
    btnStyle = 8;
  }

  return (
    <>
      {group !== SIX ? (
        !isMobile || location.pathname === '/app/trends' ? (
          <TrendsLowerWrapper className="trends">
            <PlayRuleButtonRow className="trends">
              {constTrendsButtonSSC.map(({ nameInChinese, code }, index) => {
                if (constPlayRuleByGroup[group].includes(code)) {
                  const comp = (
                    <Col key={code + index}>
                      <LcwButton
                        btnStyle={btnStyle}
                        text={nameInChinese}
                        active={code === chartType ? true : false}
                        url={`${buttonUrl}?${generateQueryString({
                          ...parseQueryString(location.search),
                          rule: code,
                        })}`}
                        activeOnClick={() => {
                          setChartType(code);
                          setOpenRule(false);
                        }}
                      />
                    </Col>
                  );
                  if (
                    !constLowFrequencyGame[searchGame] ||
                    (constLowFrequencyGame[searchGame] &&
                      constLowFrequencyGame[searchGame].includes(code))
                  ) {
                    return comp;
                  } else {
                    return '';
                  }
                } else {
                  return '';
                }
              })}
            </PlayRuleButtonRow>
          </TrendsLowerWrapper>
        ) : (
          <MobilePlayRuleDrawer
            placement="top"
            closable={false}
            onClose={() => setOpenRule(false)}
            visible={openRule}
            height="100%"
            destroyOnClose={true}
            style={{ top: '44px' }}
            zIndex={5}>
            <Title className="mobile">{transl('选择玩法')}</Title>
            <RuleName> {transl(ruleName ? ruleName : '')} </RuleName>
            <PlayRuleButtonRow>
              {constTrendsButtonSSC.map(({ nameInChinese, code }, index) => {
                if (constPlayRuleByGroup[group].includes(code)) {
                  if (
                    !constLowFrequencyGame[searchGame] ||
                    (constLowFrequencyGame[searchGame] &&
                      constLowFrequencyGame[searchGame].includes(code))
                  ) {
                    return (
                      <Col key={code + index}>
                        <LcwButton
                          btnStyle={btnStyle}
                          text={nameInChinese}
                          active={code === chartType ? true : false}
                          url={`${buttonUrl}?${generateQueryString({
                            ...parseQueryString(location.search),
                            rule: code,
                          })}`}
                          activeOnClick={() => {
                            setChartType(code);
                            setOpenRule(false);
                          }}
                        />
                      </Col>
                    );
                  } else {
                    return '';
                  }
                } else {
                  return '';
                }
              })}
            </PlayRuleButtonRow>
          </MobilePlayRuleDrawer>
        )
      ) : (
        ''
      )}
      {playRule ? (
        playRule
      ) : (
        <ResultWrapper>{transl('暂无数据')}</ResultWrapper>
      )}
    </>
  );
};

export default isMobile(
  withLocation(inject('trendsStore')(observer(PlayRule))),
);

const TrendsLowerWrapper = styled.div`
  padding: 19px 25px 17px;
  background-color: ${color.white};

  @media only screen and (max-width: 576px) {
    &.trends {
      position: relative;
      z-index: 4;
      padding: 0;
    }
  }
`;

const MobilePlayRuleDrawer = styled(Drawer)`
  @media only screen and (max-width: 576px) {
    .ant-drawer-content-wrapper {
      max-height: 65vh;

      .ant-drawer-body {
        padding: 0px 10px 25px;
        overflow-x: hidden;
        overflow-y: auto;
      }
    }

    .ant-col {
      flex: 0 0 33.33333333%;
      max-width: 33.33333333%;
      padding: 0 8px;
    }
  }
`;

const ResultWrapper = styled.div`
  padding: 8vh;
  text-align: center;
  background-color: ${color.grey};
`;

const Title = styled.div`
  display: none;

  @media only screen and (max-width: 576px) {
    display: block;
    padding-top: 15px;
    color: ${color.fontGrey};
    text-align: center;
    line-height: 16px;
    margin-bottom: 13px;
  }
`;

const RuleName = styled.div`
  @media only screen and (max-width: 576px) {
    display: block;
    position: relative;
    color: ${color.black};
    text-align: center;
    line-height: 15px;
    width: fit-content;
    margin: auto;
    margin-bottom: 18px;

    &:before,
    &:after {
      content: '';
      position: absolute;
      width: 65px;
      height: 0.5px;
      top: 50%;
      transform: translateY(-50%);
      background-color: ${color.borderGrey};
    }

    &:before {
      left: -80px;
    }

    &:after {
      right: -80px;
    }
  }
`;

const PlayRuleButtonRow = styled(Row)`
  margin: -6px -6px 6px;

  .ant-col {
    padding: 6px;
  }

  @media only screen and (max-width: 576px) {
    &.trends {
      overflow-x: auto;
      flex-flow: unset;
      margin: 0;
      margin-bottom: 11px;

      > .ant-col {
        flex: 1 0 auto;
      }
    }
  }
`;

import React, { useState } from 'react';
import styled from 'styled-components';
import { inject, observer } from 'mobx-react';
import { toJS } from 'mobx';
import { Row, Col } from 'antd';

import {
  sumOfNumber,
  getTotalOddEven,
  getTotalBigSmall,
  findDragonTigerThousandDigit,
  bigSmall,
  oddEven,
  arrayStringToArrayInt,
  findShunZi,
  transl,
} from '@helpers/utils';
import { color } from '@helpers/const';
import { shunZhi, constBigSmallThreshold } from '@const/playRule';
import { constPlayGroup } from '@const/game';
import withLocation from '@helpers/hoc/withLocation';
import isMobile from '@helpers/hoc/isMobile';

import { SummarizeTable } from '@components/common/table';
import { CircleNumber } from '@components/common/number';

import { Images } from '@components/common/images';

const { SSC } = constPlayGroup;

const { firstThree, middleThree, lastThree } = shunZhi;

const styleOddEven = (value) => {
  return (
    <span className={value === '单' ? 'blue-text' : 'red-text'}>
      {transl(value, [], true, false, 'en-US')}
    </span>
  );
};

const styleBigSmall = (value) => {
  return (
    <span className={value === '大' ? 'red-text' : 'blue-text'}>
      {transl(value, [], true, false, 'en-US')}
    </span>
  );
};

// const styledDragonTiger = (value) => {
//     return <span className={value === "龙"? "red-text": value === "虎"? "blue-text": "green-text"}>{value}</span>
// }

const styledShunZhi = (value) => {
  let styleClass = '';
  if (value === '对子' || value === '顺子') {
    styleClass = 'red-text';
  } else if (value === '半顺') {
    styleClass = 'blue-text';
  } else if (value === '豹子') {
    styleClass = 'green-text';
  }
  return (
    <span className={styleClass}>
      {transl(value, [], true, false, 'en-US')}
    </span>
  );
};

const selectedCssStyle = (filter, num, pairStyle, group) => {
  const threshold = constBigSmallThreshold[group];

  if (filter.includes('对子号') && pairStyle) {
    return { opacity: 1 };
  } else if (filter.includes('大') && num > threshold) {
    if (filter.includes('单') || filter.includes('双')) {
      if (filter.includes('单') && num % 2 !== 0) {
        return { opacity: 1 };
      } else if (filter.includes('双') && num % 2 === 0) {
        return { opacity: 1 };
      } else {
        return { opacity: 0.3 };
      }
    } else {
      return { opacity: 1 };
    }
  } else if (filter.includes('小') && num <= threshold) {
    if (filter.includes('单') || filter.includes('双')) {
      if (filter.includes('单') && num % 2 !== 0) {
        return { opacity: 1 };
      } else if (filter.includes('双') && num % 2 === 0) {
        return { opacity: 1 };
      } else {
        return { opacity: 0.3 };
      }
    } else {
      return { opacity: 1 };
    }
  } else if (
    filter.includes('单') &&
    !filter.includes('小') &&
    !filter.includes('大') &&
    num % 2 !== 0
  ) {
    return { opacity: 1 };
  } else if (
    filter.includes('双') &&
    !filter.includes('小') &&
    !filter.includes('大') &&
    num % 2 === 0
  ) {
    return { opacity: 1 };
  } else if (
    !filter.includes('单') &&
    !filter.includes('双') &&
    !filter.includes('大') &&
    !filter.includes('小') &&
    filter.includes(num)
  ) {
    return { opacity: 1 };
  } else if (filter.length === 0) {
    return { opacity: 1 };
  } else {
    return { opacity: 0.3 };
  }
};

const getNumberCode = (value, filter, pairStyle, addClass) => {
  return (
    <Row justify="center">
      {value.map((num, index) => {
        return (
          <Col key={index} className="table-number">
            <CircleNumber
              number={num}
              cssStyle={selectedCssStyle(
                filter,
                num,
                pairStyle[index],
                addClass,
              )}
            />
          </Col>
        );
      })}
    </Row>
  );
};

const bigSmallHeader = (
  dropDownDisplayMode,
  setDropDownDisplayMode,
  setDisplayMode,
) => {
  let column;
  column = {
    title: '1-5球大小',
    className: dropDownDisplayMode ? 'switch open' : 'switch',
    onHeaderCell: (column) => {
      return {
        onClick: () => {
          setDropDownDisplayMode(!dropDownDisplayMode);
        },
      };
    },
    children: [
      {
        title: '1-5球单双',
        dataIndex: '球大小1',
        className: dropDownDisplayMode ? 'option open' : 'option',
        width: 19,
        colSpan: 5,
        onHeaderCell: (column) => {
          return {
            onClick: () => {
              setDisplayMode('oddEven');
              setDropDownDisplayMode(false);
            },
          };
        },
        render: (value) => {
          return styleBigSmall(value);
        },
      },
      {
        title: '球大小',
        dataIndex: '球大小2',
        width: 19,
        colSpan: 0,
        render: (value) => {
          return styleBigSmall(value);
        },
      },
      {
        title: '球大小',
        dataIndex: '球大小3',
        width: 19,
        colSpan: 0,
        render: (value) => {
          return styleBigSmall(value);
        },
      },
      {
        title: '球大小',
        dataIndex: '球大小4',
        width: 19,
        colSpan: 0,
        render: (value) => {
          return styleBigSmall(value);
        },
      },
      {
        title: '球大小',
        dataIndex: '球大小5',
        width: 19,
        colSpan: 0,
        render: (value) => {
          return styleBigSmall(value);
        },
      },
    ],
  };

  return column;
};

const oddEvenHeader = (
  dropDownDisplayMode,
  setDropDownDisplayMode,
  setDisplayMode,
) => {
  let column;
  column = {
    title: '1-5球单双',
    className: dropDownDisplayMode ? 'switch open' : 'switch',
    onHeaderCell: (column) => {
      return {
        onClick: () => {
          setDropDownDisplayMode(!dropDownDisplayMode);
        },
      };
    },
    children: [
      {
        title: '1-5球大小',
        dataIndex: '球单双1',
        className: dropDownDisplayMode ? 'option open' : 'option',
        width: 19,
        colSpan: 5,
        onHeaderCell: (column) => {
          return {
            onClick: () => {
              setDisplayMode('bigSmall');
              setDropDownDisplayMode(false);
            },
          };
        },
        render: (value) => {
          return styleOddEven(value);
        },
      },
      {
        title: '球大小',
        dataIndex: '球单双2',
        width: 19,
        colSpan: 0,
        render: (value) => {
          return styleOddEven(value);
        },
      },
      {
        title: '球大小',
        dataIndex: '球单双3',
        width: 19,
        colSpan: 0,
        render: (value) => {
          return styleOddEven(value);
        },
      },
      {
        title: '球大小',
        dataIndex: '球单双4',
        width: 19,
        colSpan: 0,
        render: (value) => {
          return styleOddEven(value);
        },
      },
      {
        title: '球大小',
        dataIndex: '球单双5',
        width: 19,
        colSpan: 0,
        render: (value) => {
          return styleOddEven(value);
        },
      },
    ],
  };

  return column;
};

const getTableHeader = (
  singleGame,
  group,
  filter,
  isMobile,
  displayMode,
  setDisplayMode,
  dropDownDisplayMode,
  setDropDownDisplayMode,
) => {
  let columns = [];
  let temp = [];

  if (Array.isArray(singleGame) && singleGame.length) {
    switch (group) {
      case SSC:
        if (isMobile) {
          columns = [
            {
              title: '期数',
              dataIndex: '期数',
              width: 40,
              sorter: (a, b) => a.期数 - b.期数,
              defaultSortOrder: 'ascend',
              sortDirections: ['ascend', 'descend', 'ascend'],
            },
            {
              title: '显示号码',
              dataIndex: '显示号码',
              width: 120,
              render: (value, row, index) => {
                let pairStyle = [];

                let nextOpenCode = {};
                if (index < singleGame.length - 1) {
                  nextOpenCode = singleGame[index + 1];
                } else {
                  nextOpenCode = singleGame[index - 1];
                }

                nextOpenCode =
                  nextOpenCode && arrayStringToArrayInt(nextOpenCode.openCode);
                if (nextOpenCode) {
                  for (
                    let indexDigit = 0;
                    indexDigit < nextOpenCode.length;
                    indexDigit++
                  ) {
                    if (value[indexDigit] === nextOpenCode[indexDigit]) {
                      pairStyle.push(true);
                      temp[indexDigit] = nextOpenCode[indexDigit];
                    } else if (value[indexDigit] === temp[indexDigit]) {
                      pairStyle.push(true);
                      temp[indexDigit] = false;
                    } else {
                      pairStyle.push(false);
                      temp[indexDigit] = false;
                    }
                  }
                }

                return getNumberCode(value, filter, pairStyle, group);
              },
            },
            {
              title: '总和',
              dataIndex: '总和',
              colSpan: 3,
              className: 'total',
              width: 22,
            },
            {
              title: '总和OddEven',
              dataIndex: '总和OddEven',
              colSpan: 0,
              width: 22,
              render: (value) => {
                return styleOddEven(value);
              },
            },
            {
              title: '总和BigSmall',
              dataIndex: '总和BigSmall',
              colSpan: 0,
              width: 22,
              render: (value) => {
                return styleBigSmall(value);
              },
            },
            // {
            //     title: '龙',
            //     dataIndex: '龙虎',
            //     width: 22,
            //     render: (value) =>{
            //         return styledDragonTiger(value);
            //     }
            // },
          ];
          if (displayMode === 'bigSmall') {
            columns.push(
              bigSmallHeader(
                dropDownDisplayMode,
                setDropDownDisplayMode,
                setDisplayMode,
              ),
            );
          } else {
            columns.push(
              oddEvenHeader(
                dropDownDisplayMode,
                setDropDownDisplayMode,
                setDisplayMode,
              ),
            );
          }
        } else {
          columns = [
            {
              title: '期数',
              dataIndex: '期数',
              width: 110,
              sorter: (a, b) => a.期数 - b.期数,
              defaultSortOrder: 'ascend',
              sortDirections: ['ascend', 'descend', 'ascend'],
            },
            {
              title: '显示号码',
              dataIndex: '显示号码',
              width: 250,
              render: (value, row, index) => {
                let pairStyle = [];

                let nextOpenCode = {};
                if (index < singleGame.length - 1) {
                  nextOpenCode = singleGame[index + 1];
                } else {
                  nextOpenCode = singleGame[index - 1];
                }

                nextOpenCode =
                  nextOpenCode && arrayStringToArrayInt(nextOpenCode.openCode);
                if (nextOpenCode) {
                  for (
                    let indexDigit = 0;
                    indexDigit < nextOpenCode.length;
                    indexDigit++
                  ) {
                    if (value[indexDigit] === nextOpenCode[indexDigit]) {
                      pairStyle.push(true);
                      temp[indexDigit] = nextOpenCode[indexDigit];
                    } else if (value[indexDigit] === temp[indexDigit]) {
                      pairStyle.push(true);
                      temp[indexDigit] = false;
                    } else {
                      pairStyle.push(false);
                      temp[indexDigit] = false;
                    }
                  }
                }

                return getNumberCode(value, filter, pairStyle, group);
              },
            },
            {
              title: '总和',
              dataIndex: '总和',
              colSpan: 3,
              className: 'total',
              width: 37,
            },
            {
              title: '总和OddEven',
              dataIndex: '总和OddEven',
              colSpan: 0,
              width: 34,
              render: (value) => {
                return styleOddEven(value);
              },
            },
            {
              title: '总和BigSmall',
              dataIndex: '总和BigSmall',
              colSpan: 0,
              width: 34,
              render: (value) => {
                return styleBigSmall(value);
              },
            },
            // {
            //     title: '龙虎',
            //     dataIndex: '龙虎',
            //     width: 34,
            //     render: (value) =>{
            //         return styledDragonTiger(value);
            //     }
            // },
            {
              title: '1-5球大小',
              dataIndex: '球大小1',
              width: 34,
              colSpan: 5,
              render: (value) => {
                return styleBigSmall(value);
              },
            },
            {
              title: '球大小',
              dataIndex: '球大小2',
              width: 34,
              colSpan: 0,
              render: (value) => {
                return styleBigSmall(value);
              },
            },
            {
              title: '球大小',
              dataIndex: '球大小3',
              width: 34,
              colSpan: 0,
              render: (value) => {
                return styleBigSmall(value);
              },
            },
            {
              title: '球大小',
              dataIndex: '球大小4',
              width: 34,
              colSpan: 0,
              render: (value) => {
                return styleBigSmall(value);
              },
            },
            {
              title: '球大小',
              dataIndex: '球大小5',
              width: 34,
              colSpan: 0,
              render: (value) => {
                return styleBigSmall(value);
              },
            },
            {
              title: '1-5球单双',
              dataIndex: '球单双1',
              width: 34,
              colSpan: 5,
              render: (value) => {
                return styleOddEven(value);
              },
            },
            {
              dataIndex: '球单双2',
              width: 34,
              colSpan: 0,
              render: (value) => {
                return styleOddEven(value);
              },
            },
            {
              dataIndex: '球单双3',
              width: 34,
              colSpan: 0,
              render: (value) => {
                return styleOddEven(value);
              },
            },
            {
              dataIndex: '球单双4',
              width: 34,
              colSpan: 0,
              render: (value) => {
                return styleOddEven(value);
              },
            },
            {
              dataIndex: '球单双5',
              width: 34,
              colSpan: 0,
              render: (value) => {
                return styleOddEven(value);
              },
            },
            {
              title: '前三',
              dataIndex: '前三',
              width: 48,
              render: (value) => {
                return styledShunZhi(value);
              },
            },
            {
              title: '中三',
              dataIndex: '中三',
              width: 48,
              render: (value) => {
                return styledShunZhi(value);
              },
            },
            {
              title: '后三',
              dataIndex: '后三',
              width: 48,
              render: (value) => {
                return styledShunZhi(value);
              },
            },
          ];
        }
        break;
      default:
        break;
    }
  }

  return columns;
};

const getTableData = (singleGame, group, isMobile) => {
  let dataSourceArray = [];
  let arrayNumbers = [];

  if (Array.isArray(singleGame) && singleGame.length) {
    switch (group) {
      case SSC:
        if (isMobile) {
          dataSourceArray.push(
            singleGame.map(
              ({ openTime, uniqueIssueNumber, openCode }, index) => {
                arrayNumbers = arrayStringToArrayInt(openCode);
                const stringIssueNumber = uniqueIssueNumber.toString();
                const shortenIssueNumber =
                  stringIssueNumber.length > 4
                    ? stringIssueNumber.slice(stringIssueNumber.length - 4)
                    : stringIssueNumber;
                const obj = {
                  key: index + '' + uniqueIssueNumber,
                  期数: shortenIssueNumber,
                  显示号码: arrayNumbers,
                  总和: sumOfNumber(arrayNumbers),
                  总和OddEven: getTotalOddEven(arrayNumbers),
                  总和BigSmall: getTotalBigSmall(arrayNumbers, group),
                  龙虎: findDragonTigerThousandDigit(
                    arrayNumbers[0],
                    arrayNumbers[4],
                  ),
                  球大小1: bigSmall(
                    arrayNumbers[0],
                    constBigSmallThreshold[group],
                  )
                    ? '大'
                    : '小',
                  球大小2: bigSmall(
                    arrayNumbers[1],
                    constBigSmallThreshold[group],
                  )
                    ? '大'
                    : '小',
                  球大小3: bigSmall(
                    arrayNumbers[2],
                    constBigSmallThreshold[group],
                  )
                    ? '大'
                    : '小',
                  球大小4: bigSmall(
                    arrayNumbers[3],
                    constBigSmallThreshold[group],
                  )
                    ? '大'
                    : '小',
                  球大小5: bigSmall(
                    arrayNumbers[4],
                    constBigSmallThreshold[group],
                  )
                    ? '大'
                    : '小',
                  球单双1: oddEven(arrayNumbers[0]) ? '单' : '双',
                  球单双2: oddEven(arrayNumbers[1]) ? '单' : '双',
                  球单双3: oddEven(arrayNumbers[2]) ? '单' : '双',
                  球单双4: oddEven(arrayNumbers[3]) ? '单' : '双',
                  球单双5: oddEven(arrayNumbers[4]) ? '单' : '双',
                };
                return obj;
              },
            ),
          );
        } else {
          dataSourceArray.push(
            singleGame.map(
              ({ openTime, uniqueIssueNumber, openCode }, index) => {
                arrayNumbers = arrayStringToArrayInt(openCode);
                const obj = {
                  key: index + '' + uniqueIssueNumber,
                  期数: uniqueIssueNumber,
                  显示号码: arrayNumbers,
                  总和: sumOfNumber(arrayNumbers),
                  总和OddEven: getTotalOddEven(arrayNumbers),
                  总和BigSmall: getTotalBigSmall(arrayNumbers, group),
                  龙虎: findDragonTigerThousandDigit(
                    arrayNumbers[0],
                    arrayNumbers[4],
                  ),
                  球大小1: bigSmall(
                    arrayNumbers[0],
                    constBigSmallThreshold[group],
                  )
                    ? '大'
                    : '小',
                  球大小2: bigSmall(
                    arrayNumbers[1],
                    constBigSmallThreshold[group],
                  )
                    ? '大'
                    : '小',
                  球大小3: bigSmall(
                    arrayNumbers[2],
                    constBigSmallThreshold[group],
                  )
                    ? '大'
                    : '小',
                  球大小4: bigSmall(
                    arrayNumbers[3],
                    constBigSmallThreshold[group],
                  )
                    ? '大'
                    : '小',
                  球大小5: bigSmall(
                    arrayNumbers[4],
                    constBigSmallThreshold[group],
                  )
                    ? '大'
                    : '小',
                  球单双1: oddEven(arrayNumbers[0]) ? '单' : '双',
                  球单双2: oddEven(arrayNumbers[1]) ? '单' : '双',
                  球单双3: oddEven(arrayNumbers[2]) ? '单' : '双',
                  球单双4: oddEven(arrayNumbers[3]) ? '单' : '双',
                  球单双5: oddEven(arrayNumbers[4]) ? '单' : '双',
                  前三: findShunZi(firstThree, arrayNumbers, group),
                  中三: findShunZi(middleThree, arrayNumbers, group),
                  后三: findShunZi(lastThree, arrayNumbers, group),
                };
                return obj;
              },
            ),
          );
        }

        dataSourceArray = dataSourceArray.flat();
        break;
      default:
        break;
    }
  }

  return dataSourceArray;
};

const MainTable = ({
  trendsStore,
  search,
  isMobile,
  filterBall,
  filterType,
}) => {
  const { singleGame } = trendsStore;

  const { group } = search;

  const [dropDownDisplayMode, setDropDownDisplayMode] = useState(false);
  const [displayMode, setDisplayMode] = useState('bigSmall');

  let switcher;
  if (isMobile) {
    switcher = document.getElementsByClassName('switch');
    switcher = switcher[0] && switcher[0].clientWidth;
  }

  const columns = getTableHeader(
    toJS(singleGame),
    group,
    filterBall.concat(filterType),
    isMobile,
    displayMode,
    setDisplayMode,
    dropDownDisplayMode,
    setDropDownDisplayMode,
  );
  const dataSourceArray = getTableData(toJS(singleGame), group, isMobile);

  return (
    <TrendsResultWrapper>
      <TrendsTableWrapper
        className={'border-top ' + group}
        switcherWidth={switcher}>
        <SummarizeTable
          tableData={dataSourceArray}
          tableHeader={columns}
          fixHeader={true}
        />
      </TrendsTableWrapper>
    </TrendsResultWrapper>
  );
};

export default isMobile(
  withLocation(inject('trendsStore')(observer(MainTable))),
);

const TrendsResultWrapper = styled.div`
  background-color: ${color.white};

  .ant-table table {
    tr {
      :nth-child(even) {
        background-color: ${color.backgroundGrey};
      }
    }

    th {
      padding: 15px 0;
      font-size: 15px;

      &.ant-table-cell.tab {
        cursor: pointer;
        background-color: ${color.backgroundGrey};

        &.active {
          color: ${color.orange};
          border-color: ${color.orange};
          background-color: ${color.white};

          &:before {
            content: '';
            position: absolute;
            width: 1px;
            height: calc(100% + 2px);
            background-color: ${color.orange};
            left: -1px;
            top: -1px;
          }

          &:after {
            content: '';
            position: absolute;
            width: calc(100% + 2px);
            height: 1px;
            background-color: ${color.orange};
            left: -1px;
            top: 0px;
          }
        }
      }
    }

    td {
      padding: 4px 0;

      @media only screen and (max-width: 576px) {
        padding: 0;

        .table-number {
          div {
            margin: 0 1.5px;
          }
        }
      }
    }
  }
`;

const TrendsTableWrapper = styled.div`
  padding: 15px 25px;

  @media only screen and (max-width: 576px) {
    padding: 0;

    .ant-table table {
      th {
        font-size: 13px;
        height: 30px;
        padding: 0;
        background-color: ${color.orange};

        &.option {
          display: none;
          position: absolute;
          top: 30px;
          right: 0;
          width: ${(props) => props.switcherWidth}px;
          border-top: 1px solid ${color.borderGrey};
          border-right: none !important;
          background-color: ${color.orangeTransparent};
          z-index: 1;

          &.open {
            display: flex;
            justify-content: center;
            align-items: center;
          }
        }

        &.switch {
          &:after {
            content: '';
            background-image: url(${Images.icn_dropdown_white});
            position: absolute;
            width: 11px;
            height: 7px;
            top: 50%;
            left: calc(50% + 37px);
            transform: translate(-50%, -50%);
          }

          &.open {
            &:after {
              background-image: url(${Images.icn_dropdown_up_white});
            }
          }
        }
      }
    }
  }

  &.D11 {
    .ant-table table {
      th {
        padding: 12px 0;
      }
    }
  }
`;

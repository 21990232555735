import React, { useEffect, useState, useCallback } from 'react';
import { toJS } from 'mobx';
import { Row, Col } from 'antd';
import styled from 'styled-components';
import { inject, observer } from 'mobx-react';

import { color } from '@helpers/const';
import { iconLinkData } from '@helpers/utils';
import withLayout from '@helpers/hoc/withLayout';
import withLocation from '@helpers/hoc/withLocation';
import { PaddingContainer } from '@helpers/hoc/withPaddingWidth';

import SEO from '@components/common/seo';
import Timer from '@components/common/timer';
import IconLink from '@components/common/iconLink';
import { Images } from '@components/common/images';
import { NumberRow } from '@components/common/number';
import BannerSlider from '@components/common/bannerSlider';
import { GameGroupNavSet } from '@components/common/navs';

import { constPlayGroup } from '@const/game';

import Filter from './Filter';
import MainTable from './MainTable';
import Content from './Content';

const { SSC, K3 } = constPlayGroup;

const bannerSliderData = [Images.bannerSlider2];

const initialDetail = {
  betNumber: '0,0,0,0,0',
  currentCost: 0,
  totalCost: 0,
  totalProfit: 0,
};

const SuggestPlan = ({ rootStore, trendsStore, location, search }) => {
  const { allGames } = rootStore;

  const { currentTwoGame, histByDate } = trendsStore;

  const { searchGame, searchPlayGroup } = search;

  const currentGame =
    allGames && toJS(allGames).find((item) => item.gameUniqueId === searchGame);

  const [betDetail, setBetDetail] = useState(initialDetail);
  const [filterPlan, setFilterPlan] = useState(0);
  const [rolling, setRolling] = useState(false);
  const [datePicker, setDatePicker] = useState([0, null]);

  useEffect(() => {
    if (datePicker[0] === 0 && searchGame) {
      trendsStore.fetchCurrentTwo(searchGame, searchPlayGroup);
      trendsStore.fetchPrizeSettings();
    } else if (datePicker[1] && searchGame) {
      trendsStore.fetchHistByDate(searchGame, datePicker[1]);
    }

    setBetDetail(initialDetail);

    return () => {
      trendsStore.clearTimeout();
      trendsStore.resetObservable();
    };
  }, [trendsStore, searchGame, datePicker, searchPlayGroup]);

  const onDateClick = useCallback(
    (event) => {
      trendsStore.clearTimeout();
      trendsStore.resetCurrentTwo();
      setBetDetail(initialDetail);
      setDatePicker(event);
    },
    [trendsStore],
  );

  let timerToNextOpen;
  if (typeof toJS(currentTwoGame) === 'object') {
    const { openCode, nextStopOrderTimeEpoch } = currentTwoGame.lastOpen;
    const { currentTimeEpoch } = currentTwoGame.current;

    timerToNextOpen =
      openCode && nextStopOrderTimeEpoch && currentTimeEpoch
        ? nextStopOrderTimeEpoch - currentTimeEpoch
        : 0;
  }

  let lastUniqueIssueNumber = '000000';
  if (histByDate && histByDate.length) {
    lastUniqueIssueNumber = histByDate[0].uniqueIssueNumber;
  }

  return (
    <>
      <SEO title="推荐计划" />
      <BannerSlider banner={bannerSliderData} />
      <PaddingContainer>
        <GameGroupNavSet
          noLowGame={true}
          removeGame={['HF_XYPK']}
          removeGroup={['PCDD']}
        />
        <GameDetailWrapper>
          <Row gutter={80}>
            <Col className="col1" span={8}>
              <Row gutter={40} justify="space-between">
                <Col span={10}>
                  <IconLink
                    data={iconLinkData(
                      searchGame,
                      currentGame && currentGame.gameNameInChinese,
                    )}
                    type={1}
                  />
                </Col>
                <Col span={14}>
                  <div className="title count-down">倒计时</div>
                  <div
                    style={{
                      minHeight: '50px',
                      marginBottom: '16px',
                    }}>
                    <Timer
                      key={lastUniqueIssueNumber}
                      timer={timerToNextOpen}
                      setRolling={setRolling}
                      rolling={rolling}
                      noIcon={true}
                      size="medium"
                    />
                  </div>
                  <div className="suggested-number">
                    已推荐 <span>{histByDate && histByDate.length}</span> 期
                  </div>
                </Col>
              </Row>
            </Col>
            <Col className="col2" span={8}>
              <div className="title">最新计划</div>
              <div
                style={{
                  marginBottom: '23px',
                  minHeight: '40px',
                }}>
                <NumberRow
                  kaiJiangHao={betDetail.betNumber}
                  playGroup={searchPlayGroup === K3 ? SSC : searchPlayGroup}
                  kaiJiangStyle={searchPlayGroup === K3 ? SSC : searchPlayGroup}
                  size="medium"
                  rolling={false}
                />
              </div>
              <Row justify="space-between" style={{ alignItems: 'flex-end' }}>
                <Col className="current-wrapper">
                  <span className="current-period">
                    {lastUniqueIssueNumber + ' '}
                  </span>
                  期
                </Col>
                <Col className="current-bet">
                  本次成本：
                  <span className="orange">{betDetail.currentCost}</span>
                </Col>
              </Row>
            </Col>
            <Col className="col3" span={8}>
              <div className="title summary">数据汇总</div>
              <Row>
                <Col span="12">
                  <div style={{ marginBottom: '12px' }}>累计成本</div>
                  <div className="orange">
                    <span>{betDetail.totalCost}</span>
                  </div>
                </Col>
                <Col span="12">
                  <div style={{ marginBottom: '12px' }}>累计盈亏</div>
                  <div className="orange">
                    <span>{betDetail.totalProfit}</span>
                  </div>
                </Col>
              </Row>
            </Col>
          </Row>
        </GameDetailWrapper>
        <div style={{ marginTop: '10px' }}>
          <div>
            <Filter
              filterPlan={filterPlan}
              setFilterPlan={setFilterPlan}
              onDateClick={onDateClick}
            />
          </div>
          {histByDate.length && histByDate[0].playGroup === searchPlayGroup ? (
            <div>
              <MainTable filterPlan={filterPlan} setBetDetail={setBetDetail} />
            </div>
          ) : (
            // <div
            //     style={{
            //             textAlign: "center",
            //             padding: "20px 0",
            //             backgroundColor: "white",
            //             marginTop: "10px",
            //         }}
            //     >
            //         暂无数据
            //  </div>
            ''
          )}
        </div>
        <div style={{ paddingBottom: '20px' }}>
          <Content
            gameName={currentGame && currentGame.gameNameInChinese}
            group={searchPlayGroup}
          />
        </div>
      </PaddingContainer>
    </>
  );
};

export default withLocation(
  withLayout(inject('rootStore', 'trendsStore')(observer(SuggestPlan))),
);

const GameDetailWrapper = styled.div`
  padding: 20px 30px 16px;
  background-color: ${color.white};

  .col1 {
    font-size: 16px;
    line-height: 17px;
    border-right: 1px solid ${color.borderGrey};
  }

  .col2 {
    font-size: 16px;
    line-height: 17px;
    color: ${color.fontGrey};
    border-right: 1px solid ${color.borderGrey};

    .current-wrapper {
      .current-period {
        font-size: 22px;
        line-height: 17px;
        color: ${color.orange};
      }
    }

    .current-bet {
      text-align: right;

      .orange {
        font-size: 18px;
      }
    }
  }

  .col3 {
    font-size: 16px;
    line-height: 17px;
    color: ${color.fontGrey};

    .orange {
      font-size: 30px;
    }
  }

  .title {
    font-size: 20px;
    line-height: 20px;
    color: ${color.orange};
    margin-bottom: 19px;

    &.count-down {
      margin-bottom: 13px;
    }

    &.summary {
      margin-bottom: 23px;
    }
  }

  .suggested-number {
    color: ${color.fontGrey};

    span {
      font-size: 30px;
      color: ${color.orange};
    }
  }

  .orange {
    color: ${color.orange};
  }
`;

import React from 'react';
import styled from 'styled-components';

import { UnderLineFilter } from '@components/common/filters';

import { color } from '@helpers/const';
import withLocation from '@helpers/hoc/withLocation';

const ModeFilter = ({
  mode,
  setMode,
  modeFilter,
  openFilter,
  setOpenFilter,
}) => {
  return (
    <UnderLineFilterWrapper>
      <UnderLineFilter
        filterList={modeFilter}
        onClickAction={(props) => {
          setMode(props);
          props.filter === mode.filter
            ? setOpenFilter(!openFilter)
            : setOpenFilter(true);
        }}
        activeFilter={mode.filter}
        openFilter={openFilter}
      />
    </UnderLineFilterWrapper>
  );
};

export default withLocation(ModeFilter);

const UnderLineFilterWrapper = styled.div`
  position: relative;
  line-height: 0;
  background-color: ${color.white};
  padding: 0 25px 0 6px;
  overflow: hidden;

  &:after {
    content: '';
    position: absolute;
    left: -10px;
    bottom: 16px;
    width: calc(100% + 20px);
    height: 1px;
    background: ${color.borderGrey};
  }

  @media only screen and (max-width: 576px) {
    position: sticky;
    top: 44px;
    padding: 0;
    z-index: 4;
    border-bottom: 1px solid ${color.borderGrey};

    &:after {
      content: none;
    }

    p {
      font-weight: 400;
    }
  }
`;

import React from 'react';
import styled from 'styled-components';
import { Row, Col } from 'antd';

import { UnderLineFilter } from '@components/common/filters';

import { color } from '@helpers/const';
import withLocation from '@helpers/hoc/withLocation';
import isMobile from '@helpers/hoc/isMobile';

import {
  constLuZhuOptionBall,
  defaultDragonTigerByGroup,
} from '@const/playRule';

import { Images } from '@components/common/images';

import { transl } from '@helpers/utils';

const setDefaultBall = (props, setFilterBall, group) => {
  switch (props.filter) {
    case 'dragonLongMode':
    case 'tigerLongMode':
      setFilterBall([defaultDragonTigerByGroup[group][0].value]);
      break;

    default:
      setFilterBall([
        constLuZhuOptionBall[group][0].value,
        constLuZhuOptionBall[group][0].total,
      ]);
      break;
  }
};

const ModeFilter = ({
  isMobile,
  mode,
  setMode,
  modeFilter,
  setFilterBall,
  group,
  openBallFilter,
  setOpenBallFilter,
  search,
}) => {
  const { language } = search;

  let buttonClass = openBallFilter ? 'bg-grey btn active ' : 'bg-grey btn ';
  buttonClass = buttonClass + language;

  return (
    <UnderLineFilterWrapper>
      <Row>
        {isMobile ? (
          <Col flex="104px" className="btn-wrapper">
            <button
              className={buttonClass}
              onClick={() => {
                setOpenBallFilter(!openBallFilter);
              }}>
              {transl('选择名次')}
            </button>
          </Col>
        ) : (
          ''
        )}
        <Col flex="auto" style={{ overflowX: 'auto' }}>
          <UnderLineFilter
            filterList={modeFilter}
            onClickAction={(props) => {
              setMode(props);
              setDefaultBall(props, setFilterBall, group);
            }}
            activeFilter={mode.filter}
            styleSet={1}
            selection={false}
          />
        </Col>
      </Row>
    </UnderLineFilterWrapper>
  );
};

export default isMobile(withLocation(ModeFilter));

const UnderLineFilterWrapper = styled.div`
  position: relative;
  line-height: 0;
  background-color: ${color.white};
  padding: 0 6px;
  overflow: hidden;

  &:after {
    content: '';
    position: absolute;
    left: -10px;
    bottom: 16px;
    width: calc(100% + 20px);
    height: 1px;
    background: ${color.borderGrey};
  }

  @media only screen and (max-width: 576px) {
    position: sticky;
    top: 44px;
    padding: 0;
    z-index: 4;
    border-bottom: 1px solid ${color.borderGrey};

    > .ant-row {
      flex-flow: row;
    }

    &:after {
      content: none;
    }

    .btn-wrapper {
      background-color: ${color.backgroundGrey};
      border-right: 1px solid ${color.borderGrey};

      .bg-grey {
        line-height: 16px;
        position: relative;
        width: fit-content;
        margin: auto;
        padding-right: 16px;
        color: ${color.mobileBlack};

        &.en-US,
        &.vi-VN {
          font-size: 13px;
          width: min-content;
        }

        &:after {
          content: '';
          position: absolute;
          background-image: url(${Images.icn_dropdown_grey});
          width: 11px;
          height: 7px;
          top: 50%;
          left: unset;
          right: 0;
          transform: translateY(-50%);
          background-color: transparent;
        }

        &.active {
          &:after {
            background-image: url(${Images.icn_dropdown_up_orange});
          }
        }
      }
    }
  }
`;

import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { inject, observer } from 'mobx-react';
import { toJS } from 'mobx';
import { Chart, Line, Point, Axis, Tooltip } from 'bizcharts';
import { Row, Col } from 'antd';

import SEO from '@components/common/seo';
import { FilterButton } from '@components/common/filters';
import { TrendChartCards } from '@components/common/cards';

import withLayout from '@helpers/hoc/withLayout';
import withLocation from '@helpers/hoc/withLocation';
import { PaddingContainer } from '@helpers/hoc/withPaddingWidth';
import { color } from '@helpers/const';
import {
  constPlayGroup,
  constGameUniqueId,
  constTotalCodeDigit,
} from '@const/game';
import { constPlayRuleByGroup } from '@const/config';
import { constTrendBallList } from '@const/playRule';

import { GameGroupNavSet } from '@components/common/navs';

const { HF_XYPK } = constGameUniqueId;

const filterResultByBall = (gameResult, ball) => {
  let trendChartList = [];
  gameResult &&
    gameResult.length &&
    trendChartList.push(
      gameResult.map(({ uniqueIssueNumber, gameUniqueId, openCode }) => {
        let openCodeArray = openCode.split(',');

        if (gameUniqueId === HF_XYPK) {
          for (let index = 0; index < openCodeArray.length; index++) {
            openCodeArray[index] = openCodeArray[index].substring(1);
          }
        }

        const strUniqueIssueNumber = uniqueIssueNumber.toString();
        const trendPoint = {
          period: strUniqueIssueNumber.slice(strUniqueIssueNumber.length - 4),
          number: openCodeArray[ball],
        };

        return trendPoint;
      }),
    );
  return trendChartList[0];
};

const filterBall = (ballList, group) => {
  let newBallList = [];
  const limiter = constTotalCodeDigit[group];

  for (let index = 0; index < limiter; index++) {
    newBallList.push(ballList[index]);
  }

  return newBallList;
};

const TrendChartPage = ({ rootStore, trendChartStore, search, location }) => {
  const { searchGame, ball, group } = search;

  const urlGroup = group;

  const { singleGame } = trendChartStore;

  const { allGames } = rootStore;

  const [listGame, setListGame] = useState([]);

  useEffect(() => {
    trendChartStore.fetchTrendChart(searchGame);
    trendChartStore.setBall(ball);

    return function cleanup() {
      trendChartStore.cleanUp();
    };
  }, [trendChartStore, searchGame, ball, urlGroup, allGames]);

  useEffect(() => {
    if (typeof toJS(allGames) === 'object') {
      let list = [];
      for (let index = 0; index < allGames.length; index++) {
        const playGroup = allGames[index].playGroup;
        const gameUniqueId = allGames[index].gameUniqueId;

        if (
          urlGroup === 'OTHER' &&
          (playGroup === '3D' || playGroup === 'XPK' || playGroup === 'QXC')
        ) {
          list.push(gameUniqueId);
        } else if (urlGroup === playGroup) {
          list.push(gameUniqueId);
        }
      }

      setListGame(list);
    }
  }, [urlGroup, allGames]);

  const resultByBall = filterResultByBall(singleGame, ball);

  let peakNumber;
  let tickCountNumber;
  if (urlGroup === constPlayGroup.SIX) {
    peakNumber = 50;
    tickCountNumber = 5;
  } else if (urlGroup === constPlayGroup.D11) {
    peakNumber = 12;
    tickCountNumber = 4;
  } else if (urlGroup === constPlayGroup.K3) {
    peakNumber = 6;
    tickCountNumber = 4;
  } else if (urlGroup === constPlayGroup.KL10F) {
    peakNumber = 20;
    tickCountNumber = 5;
  } else if (searchGame === HF_XYPK) {
    peakNumber = 12;
    tickCountNumber = 5;
  } else {
    peakNumber = 10;
    tickCountNumber = 3;
  }

  return (
    <>
      <SEO title="Trend Chart" />
      <PaddingContainer>
        <InnerWrapper>
          <GameGroupNavSet />
          <ChartWrapper>
            {searchGame ? (
              <FilterButton
                data={filterBall(constTrendBallList, group)}
                location={location}
                activeBall={search.ball}
              />
            ) : (
              ''
            )}
            <Chart
              padding={[35, 45, 40, 50]}
              autoFit
              height={275}
              data={resultByBall}
              scale={{
                number: {
                  type: 'linear',
                  min: 0,
                  max: peakNumber,
                  tickCount: tickCountNumber,
                },
              }}>
              <Tooltip visible={false} />
              <Line position="period*number" label="number" />
              <Axis
                name="period"
                line={{
                  style: {
                    stroke: color.borderGrey,
                    fill: color.borderGrey,
                    lineWidth: 1,
                  },
                }}
              />
              <Axis
                name="number"
                grid={{
                  line: {
                    type: 'line',
                    style: {
                      stroke: color.borderGrey,
                      lineWidth: 1,
                      lineDash: [2, 4],
                    },
                  },
                }}
                line={{
                  style: {
                    stroke: color.borderGrey,
                    fill: color.borderGrey,
                    lineWidth: 1,
                  },
                }}
              />
              <Point position="period*number" />
            </Chart>
          </ChartWrapper>
          <CardsWrapper gutter={[{ md: 15 }, { md: 15 }]}>
            {typeof allGames === 'object' &&
              toJS(allGames).map(
                ({ gameUniqueId, gameNameInChinese, playGroup }, index) => {
                  if (listGame.includes(gameUniqueId)) {
                    return (
                      <Col key={index} span={24} md={12}>
                        <TrendChartCards
                          gameUniqueId={gameUniqueId}
                          gameNameInChinese={gameNameInChinese}
                          group={playGroup}
                          playRule={constPlayRuleByGroup[playGroup]}
                        />
                      </Col>
                    );
                  } else {
                    return '';
                  }
                },
              )}
          </CardsWrapper>
        </InnerWrapper>
      </PaddingContainer>
    </>
  );
};

export default withLocation(
  withLayout(inject('rootStore', 'trendChartStore')(observer(TrendChartPage))),
);

const ChartWrapper = styled.div`
  background-color: ${color.white};
  margin-bottom: 15px;
  padding: 20px 0 20px 0;

  @media only screen and (max-width: 576px) {
    margin: 0 0 15px;
  }
`;

const CardsWrapper = styled(Row)`
  background-color: ${color.bodyGrey};

  @media (max-width: 825px) {
    > .ant-col {
      margin-bottom: 10px;
    }
  }
`;

const InnerWrapper = styled.div`
  @media (max-width: 825px) {
    padding: 20px 0;
  }
`;

import React from 'react';
// import { Link } from "gatsby";

import SEO from '@components/common/seo';
import withPaddingWidth from '@helpers/hoc/withPaddingWidth';
import withLayout from '@helpers/hoc/withLayout';

const LotteryPage = () => {
  return (
    <>
      <SEO title="Lottery" />
      <div align="center" style={{ padding: 80 }}>
        <p
          style={{
            color: 'cornflowerblue',
            fontSize: 50,
            fontWeight: 'bold',
          }}>
          Halo! This is <b>Lottery</b> page...
        </p>
      </div>
    </>
  );
};

export default withLayout(withPaddingWidth(LotteryPage));

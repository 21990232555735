import React from 'react';
import styled from 'styled-components';
import { color } from '@helpers/const';
import { transl } from '@helpers/utils';
import { Row, Col } from 'antd';

import Sorter from './sorter';

import withLocation from '@helpers/hoc/withLocation';

const LeftTable = ({
  isMobile,
  headerData,
  tableData,
  filter,
  group,
  ball,
  row = 1,
  normal,
  desktopRow = 1,
  sorting,
  setSorting,
  search,
}) => {
  let cusClassName =
    'ball-' + ball + ' row-' + row + ' desktop-row-' + desktopRow;
  if (normal) {
    cusClassName = cusClassName + ' normal';
  }

  const { language } = search;

  return (
    <LeftTableWrapper className={cusClassName}>
      <Row className={row && 'row-' + row + ' ' + language}>
        {headerData.map(({ title, dataIndex, sorter }) => {
          let headerClass = 'header ' + group;
          headerClass = headerClass + (sorter ? ' sorter-col' : '');

          return (
            <Col key={dataIndex} className={headerClass}>
              {sorter ? (
                <button className="sorter" onClick={() => setSorting(!sorting)}>
                  <Row justify="center">
                    <Col>
                      <b>{transl(title, [], true, false, 'en-US')}</b>
                    </Col>
                    <Col>
                      <Sorter sorting={sorting} />
                    </Col>
                  </Row>
                </button>
              ) : (
                <b>{transl(title, [], true, false, 'en-US')}</b>
              )}
            </Col>
          );
        })}
      </Row>
      {tableData.map(({ key, openCode, uniqueIssueNumber }, index) => {
        return (
          <Row
            key={key}
            className={
              index % 5 === 4 && filter.includes('section') ? 'separate' : ''
            }>
            <Col className={'body ' + group}>{uniqueIssueNumber}</Col>
            <Col className={'body open-code ' + group}>
              {openCode.map((value, openCodeIndex) => {
                if (group === 'KL10F') {
                  let valueClass = '';
                  if (ball === 0 && value < 11) {
                    valueClass = 'red';
                  } else if (ball === 1 && value > 10) {
                    valueClass = 'red';
                  }
                  return (
                    <span key={openCodeIndex} className={valueClass}>
                      <b>{value}</b>
                    </span>
                  );
                } else {
                  return (
                    <span
                      key={openCodeIndex}
                      className={
                        openCodeIndex === ball ||
                        (ball.length && ball.includes(openCodeIndex))
                          ? 'red'
                          : ''
                      }>
                      <b>{value}</b>
                    </span>
                  );
                }
              })}
            </Col>
          </Row>
        );
      })}
      {!isMobile ? (
        <Row>
          <Col className="header span-2">
            <b>{transl('数据统计', [], false, false, 'en-US')}</b>
          </Col>
        </Row>
      ) : (
        ''
      )}
      <Row>
        <Col className="span-2">
          {transl('出现次数', [], true, false, 'en-US')}
        </Col>
      </Row>
      <Row>
        <Col className="span-2">
          {transl('平均遗漏', [], true, false, 'en-US')}
        </Col>
      </Row>
      <Row>
        <Col className="span-2">
          {transl('最大遗漏', [], true, false, 'en-US')}
        </Col>
      </Row>
      <Row>
        <Col className="span-2">
          {transl('最大连出', [], true, false, 'en-US')}
        </Col>
      </Row>
    </LeftTableWrapper>
  );
};

export default withLocation(LeftTable);

const LeftTableWrapper = styled.div`
  text-align: center;

  .header {
    font-size: 15px;
    width: 125px;
    padding: 18px 0;
    border: 1px solid ${color.tableBorderGrey};
    background-color: ${color.backgroundGrey};

    &:first-child {
      border-right: 0;

      @media only screen and (max-width: 576px) {
        width: 40px;
      }
    }

    &:nth-child(2) {
      width: auto;
      flex: 1;

      &.PK10,
      &.XY10,
      &.KL10F {
        width: 220px;
      }

      &.KL10F {
        span {
          min-width: 21px;
        }
      }

      @media only screen and (max-width: 576px) {
        &.PK10,
        &.XY10,
        &.KL10F {
          width: auto;
        }
      }
    }

    &.sorter-col {
      padding: 0;

      .sorter {
        width: 100%;
        height: calc(100% - 2px);
        padding: 0;
        background-color: ${color.backgroundGrey};
        border: none;
        cursor: pointer;

        .ant-col {
          height: calc(100% - 2px);
          line-height: 1;
          align-self: center;
        }

        @media only screen and (max-width: 576px) {
          background-color: inherit;
        }
      }
    }

    @media only screen and (max-width: 576px) {
      font-size: 13px;
      font-weight: 400;
      height: 30px;
      padding: 0;
      color: ${color.white};
      display: flex;
      justify-content: center;
      align-items: center;
      line-height: normal;

      b {
        font-weight: 400;
      }
    }
  }

  .body {
    width: 125px;
    padding: 3px 0px;
    border: 1px solid ${color.tableBorderGrey};
    border-top: 0;

    &:first-child {
      border-right: 0;

      @media only screen and (max-width: 576px) {
        width: 40px;

        &.KL10F {
          height: 35px;
          line-height: 35px;
        }
      }
    }

    &.open-code {
      display: flex;
      justify-content: center;
      color: ${color.red};
      width: auto;
      flex: 1;

      span {
        display: block;
        min-width: 17px;
        margin: 0 1px;
      }

      &.PK10,
      &.XY10,
      &.KL10F {
        width: 220px;
      }

      &.KL10F {
        span {
          min-width: 21px;
        }
      }

      @media only screen and (max-width: 576px) {
        color: ${color.mobileBlack};

        span {
          min-width: 15%;

          b {
            font-weight: 400;
          }
        }

        &.PK10,
        &.XY10 {
          width: auto;

          span {
            min-width: 7%;
          }
        }

        &.KL10F {
          flex-flow: wrap;
          height: 35px;
          min-width: 80px;
          width: auto;
          padding: 3px 6%;
          align-items: center;

          span {
            min-width: auto;
            flex: 1 0 25%;
            line-height: 13px;
            margin: 0;
          }
        }
      }
    }

    @media only screen and (max-width: 576px) {
      font-size: 13px;
      height: 29px;
      line-height: 29px;
      padding: 0;
    }
  }

  .span-2 {
    width: 100%;
    padding: 3px 0px;
    border: 1px solid ${color.tableBorderGrey};
    border-top: 0;

    &.header {
      padding: 18px 0;
      border-right: 1px solid ${color.tableBorderGrey};
    }

    &:nth-child(2) {
      &.PK10,
      &.XY10,
      &.KL10F {
        width: 220px;
      }

      &.KL10F {
        span {
          min-width: 21px;
        }
      }
    }

    @media only screen and (max-width: 576px) {
      font-size: 13px;
      line-height: 29px;
      height: 29px;
      padding: 0;
      color: ${color.white};

      &.header {
        flex: 1;
        padding: 0;
        color: ${color.white};
        background-color: ${color.lightYellow};

        b {
          font-weight: 400;
        }
      }
    }
  }

  .separate {
    .body {
      border-bottom: 2px solid ${color.tableBorderGrey};
    }
  }

  &.row-2 {
    .ant-row {
      > .ant-col {
        &.header {
          height: 61px;
          line-height: 61px;
        }
      }
    }
  }

  &.desktop-row-2 {
    .ant-row {
      > .ant-col {
        &.header {
          height: 61px;
          line-height: 61px;

          @media only screen and (max-width: 576px) {
            height: 29px;
            line-height: 29px;
          }
        }
      }
    }
  }

  &.normal {
    .ant-row {
      > .ant-col {
        padding: 0;
        height: 36px;
        line-height: 36px;
        color: unset;
      }

      &.separate {
        > .ant-col {
          padding: 0;
          height: 37px;
          line-height: 37px;
          color: unset;
        }
      }
    }
  }

  @media only screen and (max-width: 576px) {
    .ant-row {
      &:first-child {
        position: sticky;
        top: 88px;
        z-index: 2;

        &.en-US,
        &.vi-VN {
          top: 135px;
        }
      }
    }

    &.ball-0,
    &.ball-5 {
      > .ant-row {
        .header,
        .span-2 {
          background-color: ${color.lightYellow};
        }

        &:nth-child(2n + 1) {
          background-color: ${color.opacityYellow};
        }
      }
    }

    &.ball-1,
    &.ball-6 {
      > .ant-row {
        .header,
        .span-2 {
          background-color: ${color.lightRed};
        }

        &:nth-child(2n + 1) {
          background-color: ${color.opacityRed};
        }
      }
    }

    &.ball-2,
    &.ball-7 {
      > .ant-row {
        .header,
        .span-2 {
          background-color: ${color.lightBlue};
        }

        &:nth-child(2n + 1) {
          background-color: ${color.opacityBlue};
        }
      }
    }

    &.ball-3,
    &.ball-8 {
      > .ant-row {
        .header,
        .span-2 {
          background-color: ${color.lightGreen};
        }

        &:nth-child(2n + 1) {
          background-color: ${color.opacityGreen};
        }
      }
    }

    &.ball-4,
    &.ball-9 {
      > .ant-row {
        .header,
        .span-2 {
          background-color: ${color.lightPurple};
        }

        &:nth-child(2n + 1) {
          background-color: ${color.opacityPurple};
        }
      }
    }

    .separate {
      .body {
        height: 30px;
      }

      &:nth-last-child(5) {
        .body {
          height: 29px;
          border-bottom: 1px solid ${color.tableBorderGrey};

          &.KL10F {
            height: 35px;
          }
        }
      }
    }

    &.row-2 {
      .ant-row {
        &:nth-child(2n + 3) {
          background-color: ${color.backgroundGrey};
        }

        &.row-2 {
          height: 53px;

          .header {
            height: 53px;
            display: flex;
            line-height: 18px;
            align-items: center;
          }
        }

        .span-2,
        .body {
          height: 29px;
          line-height: 29px;
        }
      }
    }

    &.normal {
      .ant-row {
        .header,
        .span-2 {
          background-color: ${color.orange};
          color: ${color.white};
        }
      }
    }
  }
`;

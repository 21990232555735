// import { arrayStringToArrayInt } from "@helpers/utils";

import {
  getNumberCode,
  getNumberBigSmall,
  getNumberOddEven,
  headerClickedHandler,
} from './style';

const max3dHeader = (
  singleGame,
  group,
  activeTab,
  setTab,
  subSubOption,
  isMobile,
  openDisplayType,
  setOpenDisplayType,
) => {
  let columns = [];
  // let temp = [];

  if (isMobile) {
    columns = [
      {
        title: '期数',
        dataIndex: '期数',
        width: 45,
        sorter: (a, b) => a.期数 - b.期数,
        defaultSortOrder: 'ascend',
        sortDirections: ['ascend', 'descend', 'ascend'],
      },
      {
        title: '显示号码',
        dataIndex: '显示号码',
        key: '显示号码',
        className: openDisplayType
          ? 'tab active open tab1 row2 number'
          : 'tab active tab1 row2 number',
        rowSpan: 2,
        width: 149,
        onHeaderCell: (column) => {
          return {
            onClick: () => {
              setOpenDisplayType(!openDisplayType);
            },
          };
        },
        children: [
          {
            className: 'tab hidden',
            children: [
              {
                title: '显示大小',
                dataIndex: '显示大小',
                key: '显示大小',
                className: openDisplayType
                  ? 'tab open tab2 row2'
                  : 'tab tab2 row2',
                width: 149,
                onHeaderCell: (column) => {
                  return {
                    onClick: () => {
                      setTab(column.key);
                      setOpenDisplayType(!openDisplayType);
                    },
                  };
                },
                children: [
                  {
                    title: '显示单双',
                    dataIndex: '显示单双',
                    key: '显示单双',
                    className: openDisplayType
                      ? 'tab open tab3 row2'
                      : 'tab tab3 row2',
                    width: 149,
                    onHeaderCell: (column) => {
                      return {
                        onClick: () => {
                          setTab(column.key);
                          setOpenDisplayType(!openDisplayType);
                        },
                      };
                    },
                    render: (value, row, index) => {
                      let pairStyle = [];

                      const obj = {
                        children:
                          activeTab === '显示号码'
                            ? getNumberCode(
                                value,
                                subSubOption,
                                pairStyle,
                                group,
                              )
                            : activeTab === '显示大小'
                            ? getNumberBigSmall(
                                value,
                                subSubOption,
                                pairStyle,
                                group,
                              )
                            : getNumberOddEven(
                                value,
                                subSubOption,
                                pairStyle,
                                group,
                              ),
                        // props: { colSpan:3 },
                      };

                      return obj;
                    },
                  },
                ],
              },
            ],
          },
        ],
      },
      {
        title: '和值',
        key: '和值',
        className: 'max3d',
        rowSpan: 1,
        children: [
          {
            title: '总和',
            dataIndex: '总和',
            width: 33,
            rowSpan: 1,
          },
          {
            title: '百位',
            dataIndex: '百位',
            width: 33,
            rowSpan: 1,
          },
          {
            title: '十位',
            dataIndex: '十位',
            width: 33,
            rowSpan: 1,
          },
          {
            title: '各位',
            dataIndex: '各位',
            width: 33,
            rowSpan: 1,
          },
        ],
      },
    ];

    switch (activeTab) {
      case '显示单双':
        columns[1].title = '显示单双';
        columns[1].key = '显示单双';
        columns[1].children[0].children[0].title = '显示号码';
        columns[1].children[0].children[0].key = '显示号码';
        columns[1].children[0].children[0].children[0].title = '显示大小';
        columns[1].children[0].children[0].children[0].key = '显示大小';
        columns[1].children[0].children[0].children[0].dataIndex = '显示号码';
        break;
      case '显示大小':
        columns[1].title = '显示大小';
        columns[1].key = '显示大小';
        columns[1].children[0].children[0].title = '显示号码';
        columns[1].children[0].children[0].key = '显示号码';
        columns[1].children[0].children[0].children[0].title = '显示单双';
        columns[1].children[0].children[0].children[0].key = '显示单双';
        columns[1].children[0].children[0].children[0].dataIndex = '显示号码';
        break;
      default:
        break;
    }
  } else {
    columns = [
      {
        title: '时间',
        dataIndex: '时间',
        width: 150,
      },
      {
        title: '期数',
        dataIndex: '期数',
        width: 100,
        sorter: (a, b) => a.期数 - b.期数,
        defaultSortOrder: 'ascend',
        sortDirections: ['ascend', 'descend', 'ascend'],
      },
      {
        title: '显示号码',
        dataIndex: '显示号码',
        key: '显示号码',
        className: activeTab === '显示号码' ? 'tab active' : 'tab',
        width: 150,
        onHeaderCell: (column) => {
          return {
            onClick: () =>
              headerClickedHandler('JiShiKaiJiang', column, setTab),
          };
        },
        render: (value, row, index) => {
          let pairStyle = [];
          const obj = {
            children:
              activeTab === '显示号码'
                ? getNumberCode(value, subSubOption, pairStyle, group)
                : activeTab === '显示大小'
                ? getNumberBigSmall(value, subSubOption, pairStyle, group)
                : getNumberOddEven(value, subSubOption, pairStyle, group),
            props: { colSpan: 3 },
          };

          return obj;
        },
      },
      {
        title: '显示大小',
        dataIndex: '显示大小',
        key: '显示大小',
        className: activeTab === '显示大小' ? 'tab active' : 'tab',
        width: 150,
        onHeaderCell: (column) => {
          return {
            onClick: () =>
              headerClickedHandler('JiShiKaiJiang', column, setTab),
          };
        },
        render: (value, row, index) => {
          const obj = {
            children: value,
            props: { colSpan: 0 },
          };

          return obj;
        },
      },
      {
        title: '显示单双',
        dataIndex: '显示单双',
        key: '显示单双',
        className: activeTab === '显示单双' ? 'tab active' : 'tab',
        width: 150,
        onHeaderCell: (column) => {
          return {
            onClick: () =>
              headerClickedHandler('JiShiKaiJiang', column, setTab),
          };
        },
        render: (value, row, index) => {
          const obj = {
            children: value,
            props: { colSpan: 0 },
          };

          return obj;
        },
      },
      {
        title: '和值',
        key: '和值',
        className: 'max3d',
        children: [
          {
            title: '总和',
            dataIndex: '总和',
            width: 90,
            className: 'max3d',
          },
          {
            title: '百位',
            dataIndex: '百位',
            width: 90,
            className: 'max3d',
          },
          {
            title: '十位',
            dataIndex: '十位',
            width: 90,
            className: 'max3d',
          },
          {
            title: '各位',
            dataIndex: '各位',
            width: 90,
            className: 'max3d',
          },
        ],
      },
    ];
  }

  return columns;
};

export default max3dHeader;

import { arrayStringToArrayInt } from '@helpers/utils';

import {
  styleOddEven,
  styleBigSmall,
  // styleDragonTiger,
  headerClickedHandler,
  getNumberCode,
  getNumberBigSmall,
  getNumberOddEven,
} from './style';

const sscHeader = (
  singleGame,
  group,
  activeTab,
  setTab,
  subSubOption,
  isMobile,
  openDisplayType,
  setOpenDisplayType,
) => {
  let columns = [];
  let temp = [];

  if (isMobile) {
    columns = [
      {
        title: '',
        className: 'no-line',
        children: [
          {
            title: '期数',
            dataIndex: '期数',
            width: 45,
            sorter: (a, b) => a.期数 - b.期数,
            defaultSortOrder: 'ascend',
            sortDirections: ['ascend', 'descend', 'ascend'],
          },
        ],
      },
      {
        title: '',
        className: 'no-line',
        children: [
          {
            title: '显示号码',
            dataIndex: '显示号码',
            key: '显示号码',
            className: openDisplayType
              ? 'tab active open tab1 number'
              : 'tab active tab1 number',
            width: 149,
            onHeaderCell: (column) => {
              return {
                onClick: () => {
                  setOpenDisplayType(!openDisplayType);
                },
              };
            },
            children: [
              {
                title: '显示大小',
                dataIndex: '显示大小',
                className: openDisplayType ? 'tab open tab2 row2' : 'tab tab2',
                width: 149,
                onHeaderCell: (column) => {
                  return {
                    onClick: () => {
                      setTab(column.dataIndex);
                      setOpenDisplayType(!openDisplayType);
                    },
                  };
                },
                children: [
                  {
                    title: '显示单双',
                    dataIndex: '显示单双',
                    className: openDisplayType
                      ? 'tab open tab3 row2'
                      : 'tab tab3',
                    width: 149,
                    onHeaderCell: (column) => {
                      return {
                        onClick: () => {
                          setTab(column.dataIndex);
                          setOpenDisplayType(!openDisplayType);
                        },
                      };
                    },
                    render: (value, row, index) => {
                      let pairStyle = [];

                      let nextOpenCode = {};
                      if (index < singleGame.length - 1) {
                        nextOpenCode = singleGame[index + 1];
                      } else {
                        nextOpenCode = singleGame[index - 1];
                      }

                      nextOpenCode =
                        nextOpenCode &&
                        arrayStringToArrayInt(nextOpenCode.openCode);
                      if (nextOpenCode) {
                        for (
                          let indexDigit = 0;
                          indexDigit < nextOpenCode.length;
                          indexDigit++
                        ) {
                          if (value[indexDigit] === nextOpenCode[indexDigit]) {
                            pairStyle.push(true);
                            temp[indexDigit] = nextOpenCode[indexDigit];
                          } else if (value[indexDigit] === temp[indexDigit]) {
                            pairStyle.push(true);
                            temp[indexDigit] = false;
                          } else {
                            pairStyle.push(false);
                            temp[indexDigit] = false;
                          }
                        }
                      }

                      const obj = {
                        children:
                          activeTab === '显示单双'
                            ? getNumberOddEven(
                                value,
                                subSubOption,
                                pairStyle,
                                group,
                              )
                            : activeTab === '显示大小'
                            ? getNumberBigSmall(
                                value,
                                subSubOption,
                                pairStyle,
                                group,
                              )
                            : getNumberCode(
                                value,
                                subSubOption,
                                pairStyle,
                                group,
                              ),
                        // props: { colSpan:3 },
                      };

                      return obj;
                    },
                  },
                ],
              },
            ],
          },
        ],
      },
      {
        title: '总和',
        children: [
          {
            title: '总数',
            dataIndex: '总和',
            className: 'total',
            width: 30,
          },
          {
            title: '单双',
            dataIndex: '总和OddEven',
            width: 30,
            render: (value) => {
              return styleOddEven(value);
            },
          },
          {
            title: '大小',
            dataIndex: '总和BigSmall',
            width: 30,
            render: (value) => {
              return styleBigSmall(value);
            },
          },
        ],
      },
    ];

    switch (activeTab) {
      case '显示单双':
        columns[1].children[0].title = '显示单双';
        columns[1].children[0].dataIndex = '显示单双';
        columns[1].children[0].children[0].title = '显示号码';
        columns[1].children[0].children[0].dataIndex = '显示号码';
        columns[1].children[0].children[0].children[0].title = '显示大小';
        columns[1].children[0].children[0].children[0].dataIndex = '显示大小';
        break;
      case '显示大小':
        columns[1].children[0].title = '显示大小';
        columns[1].children[0].dataIndex = '显示大小';
        columns[1].children[0].children[0].title = '显示号码';
        columns[1].children[0].children[0].dataIndex = '显示号码';
        columns[1].children[0].children[0].children[0].title = '显示单双';
        columns[1].children[0].children[0].children[0].dataIndex = '显示单双';
        break;
      default:
        break;
    }
  } else {
    columns = [
      {
        title: '时间',
        dataIndex: '时间',
        width: 150,
      },
      {
        title: '期数',
        dataIndex: '期数',
        width: 120,
        sorter: (a, b) => a.期数 - b.期数,
        defaultSortOrder: 'ascend',
        sortDirections: ['ascend', 'descend', 'ascend'],
      },
      {
        title: '显示号码',
        dataIndex: '显示号码',
        key: '显示号码',
        className: activeTab === '显示号码' ? 'tab active' : 'tab',
        width: 100,
        onHeaderCell: (column) => {
          return {
            onClick: () =>
              headerClickedHandler('JiShiKaiJiang', column, setTab),
          };
        },
        render: (value, row, index) => {
          let pairStyle = [];

          let nextOpenCode = {};
          if (index < singleGame.length - 1) {
            nextOpenCode = singleGame[index + 1];
          } else {
            nextOpenCode = singleGame[index - 1];
          }

          nextOpenCode =
            nextOpenCode && arrayStringToArrayInt(nextOpenCode.openCode);
          if (nextOpenCode) {
            for (
              let indexDigit = 0;
              indexDigit < nextOpenCode.length;
              indexDigit++
            ) {
              if (value[indexDigit] === nextOpenCode[indexDigit]) {
                pairStyle.push(true);
                temp[indexDigit] = nextOpenCode[indexDigit];
              } else if (value[indexDigit] === temp[indexDigit]) {
                pairStyle.push(true);
                temp[indexDigit] = false;
              } else {
                pairStyle.push(false);
                temp[indexDigit] = false;
              }
            }
          }

          const obj = {
            children:
              activeTab === '显示号码'
                ? getNumberCode(value, subSubOption, pairStyle, group)
                : activeTab === '显示大小'
                ? getNumberBigSmall(value, subSubOption, pairStyle, group)
                : getNumberOddEven(value, subSubOption, pairStyle, group),
            props: { colSpan: 3 },
          };

          return obj;
        },
      },
      {
        title: '显示大小',
        dataIndex: '显示大小',
        key: '显示大小',
        className: activeTab === '显示大小' ? 'tab active' : 'tab',
        width: 100,
        onHeaderCell: (column) => {
          return {
            onClick: () =>
              headerClickedHandler('JiShiKaiJiang', column, setTab),
          };
        },
        render: (value, row, index) => {
          const obj = {
            children: value,
            props: { colSpan: 0 },
          };

          return obj;
        },
      },
      {
        title: '显示单双',
        dataIndex: '显示单双',
        key: '显示单双',
        className: activeTab === '显示单双' ? 'tab active' : 'tab',
        width: 100,
        onHeaderCell: (column) => {
          return {
            onClick: () =>
              headerClickedHandler('JiShiKaiJiang', column, setTab),
          };
        },
        render: (value, row, index) => {
          const obj = {
            children: value,
            props: { colSpan: 0 },
          };

          return obj;
        },
      },
      {
        title: '总和',
        dataIndex: '总和',
        colSpan: 3,
        className: 'total',
        width: 60,
      },
      {
        title: '总和OddEven',
        dataIndex: '总和OddEven',
        colSpan: 0,
        width: 60,
        render: (value) => {
          return styleOddEven(value);
        },
      },
      {
        title: '总和BigSmall',
        dataIndex: '总和BigSmall',
        colSpan: 0,
        width: 60,
        render: (value) => {
          return styleBigSmall(value);
        },
      },
    ];
  }

  return columns;
};

export default sscHeader;

import sscHeader from './sscHeader';
import pk10Header from './pk10Header';
import d11Header from './d11Header';
import k3Header from './k3Header';
import kl10fHeader from './kl10fHeader';
import pcddHeader from './pcddHeader';
import threeDHeader from './threeDHeader';
import qxcHeader from './qxcHeader';
import max3dHeader from './max3dHeader';

import { constPlayGroup } from '@const/game';

const {
  SSC,
  PK10,
  XY10,
  D11,
  K3,
  KL10F,
  PCDD,
  THREED,
  QXC,
  MAX3D,
} = constPlayGroup;

const GetTableHeader = (singleGame, group, ...props) => {
  let columns = [];

  if (Array.isArray(singleGame) && singleGame.length) {
    switch (group) {
      case SSC:
        columns = sscHeader(singleGame, group, ...props);
        break;
      case PK10:
      case XY10:
        columns = pk10Header(singleGame, group, ...props);
        break;
      case D11:
        columns = d11Header(singleGame, group, ...props);
        break;
      case K3:
        columns = k3Header(singleGame, group, ...props);
        break;
      case KL10F:
        columns = kl10fHeader(singleGame, group, ...props);
        break;
      case PCDD:
        columns = pcddHeader(singleGame, group, ...props);
        break;
      case THREED:
        columns = threeDHeader(singleGame, group, ...props);
        break;
      case QXC:
        columns = qxcHeader(singleGame, group, ...props);
        break;
      case MAX3D:
        columns = max3dHeader(singleGame, group, ...props);
        break;
      default:
        break;
    }
  }

  return columns;
};

export default GetTableHeader;

import React from 'react';
import styled from 'styled-components';
import { Carousel } from 'antd';

import { color } from '@helpers/const';

import IconLink from '@components/common/iconLink';
import { BgText } from '@components/common/text';

const Recommendation = ({ ribbon, title, list, allGames }) => {
  var settings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 8,
    slidesToScroll: 1,
    initialSlide: 0,
    arrows: true,
    responsive: [
      {
        breakpoint: 1280,
        settings: {
          slidesToShow: 6,
        },
      },
      {
        breakpoint: 750,
        settings: {
          slidesToShow: 4,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <BannerWrapper>
      <BgText bg={ribbon} title={title} />
      <Carousel {...settings}>
        {allGames &&
          list &&
          list.map((item, index) => {
            return (
              allGames &&
              allGames.map(({ gameNameInChinese }) => {
                return gameNameInChinese === item.gameNameInChinese ? (
                  <IconLink key={index} data={item} />
                ) : (
                  ''
                );
              })
            );
          })}
      </Carousel>
    </BannerWrapper>
  );
};

export default Recommendation;

const BannerWrapper = styled.div`
  position: relative;
  background-color: ${color.white};
  padding: 41px 24px 15px;
  margin-bottom: 28px;

  .ant-carousel {
    .slick-prev {
      left: -20px;

      &:before {
        content: '\\276E';
        color: ${color.orange};
        font-size: 28px;
      }
    }

    .slick-next {
      right: -20px;

      &:before {
        content: '\\276F';
        color: ${color.orange};
        font-size: 28px;
      }
    }

    .slick-slide {
      .ant-col {
        text-align: center;
      }
    }
  }
`;

import React, { useEffect, useState } from 'react';
import { inject, observer } from 'mobx-react';
import styled from 'styled-components';
import { color } from '@helpers/const';

import withLocation from '@helpers/hoc/withLocation';
import isMobile from '@helpers/hoc/isMobile';

import Header from '@components/views/iframe/header';
import PlayRule from '@components/views/trends/playRule';

import { constPlayRuleByGroup } from '@const/config';
import { constPlayRuleChineseName } from '@const/playRule';

const Iframe = ({ trendsStore, search, isMobile }) => {
  const [openRule, setOpenRule] = useState(false);
  const [ruleName, setRuleName] = useState('');

  const { searchGame, rule, group, brand } = search;

  useEffect(() => {
    if (searchGame) {
      trendsStore.fetchTrends(searchGame, brand);
      trendsStore.fetchCurrentTwo(searchGame, group, brand);
      trendsStore.fetchResultDragonByGame(searchGame, brand);
    }

    if (
      constPlayRuleByGroup[group].includes('changLongTongJi') ||
      constPlayRuleByGroup[group].includes('liShiHaoMa') ||
      constPlayRuleByGroup[group].includes('daXiaoDanShuangLiShi')
    ) {
      trendsStore.fetchHistByDate19Days(searchGame, brand);
    }

    if (group === 'SIX') {
      trendsStore.clearTimeout();
    }

    return () => {
      trendsStore.clearTimeout();
      trendsStore.resetObservable();
    };
  }, [trendsStore, searchGame, group, brand]);

  useEffect(() => {
    if (rule) {
      setRuleName(constPlayRuleChineseName[rule]);
    } else {
      if (group === 'XPK') {
        setRuleName(constPlayRuleChineseName['pkZhouShi']);
      } else if (group === 'SIX') {
        setRuleName(constPlayRuleChineseName['kaiJiangJilu']);
      } else {
        setRuleName(constPlayRuleChineseName['jiShiKaiJiang']);
      }
    }
  }, [rule, group]);

  return (
    <Wrapper>
      <InnerWrapper>
        {isMobile && (
          <Header
            ruleName={ruleName}
            setOpenRule={setOpenRule}
            openRule={openRule}
          />
        )}
        <PlayRuleWrapper>
          <PlayRule
            ruleName={ruleName}
            openRule={openRule}
            setOpenRule={setOpenRule}
          />
        </PlayRuleWrapper>
      </InnerWrapper>
    </Wrapper>
  );
};

export default isMobile(
  withLocation(inject('rootStore', 'trendsStore')(observer(Iframe))),
);

const Wrapper = styled.div`
  background-color: ${color.bodyGrey};
  padding-top: 44px;
`;

const InnerWrapper = styled.div`
  position: relative;
  max-width: 1200px;
  min-height: calc(100vh - 44px);
  margin: auto;
`;

const PlayRuleWrapper = styled.div`
  background-color: ${color.white};
`;

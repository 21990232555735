import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { Row, Col } from 'antd';
import { Link } from 'gatsby';

import { TitleText, DrawNoText } from '@components/common/text';
import LcwButton from '@components/common/button';
import { NumberRow } from './number';
import Timer from '@components/common/timer';
import { Images } from '@components/common/images';

import { constPlayRule, constPlayRuleChineseName } from '@const/playRule';
import { URLS, pageName, constHomeCardPlayRule } from '@const/config';

import { color } from '@helpers/const';
import {
  calcLeftPlanNo,
  getTrendsURL,
  getZouShiTuBiao,
  getChangLongURL,
} from '@helpers/utils';

import CardTable from '@views/home/cardTable';

import { findUniqueIssueNumberCount } from '@services/general';

export const TrendChartCards = ({
  gameUniqueId,
  gameNameInChinese,
  group,
  playRule,
}) => {
  const { changLongTiXing } = constPlayRule;

  return (
    <TrendChartCardsWrapper>
      <Col className="icon-wrapper" xs={24} flex="120px">
        <div className="icon-inner-wrapper">
          <div className="icon">
            <img src={Images[gameUniqueId]} alt={gameNameInChinese} />
          </div>
          <TrendTitle>{gameNameInChinese}</TrendTitle>
        </div>
      </Col>
      <Col xs={24} flex="auto">
        <Row gutter={[12, 12]}>
          {playRule.map((ruleId) => {
            let buttonList;
            switch (ruleId) {
              case changLongTiXing:
                buttonList = (
                  <Col key={ruleId} className="col-btn">
                    <LcwButton
                      text={constPlayRuleChineseName[ruleId]}
                      url={getChangLongURL(gameUniqueId, gameNameInChinese)}
                      btnStyle={1}
                    />
                  </Col>
                );
                break;
              default:
                buttonList = (
                  <Col key={ruleId} className="col-btn">
                    <LcwButton
                      text={constPlayRuleChineseName[ruleId]}
                      url={getTrendsURL(gameUniqueId, group, ruleId)}
                      btnStyle={1}
                    />
                  </Col>
                );
                break;
            }

            return buttonList;
          })}
        </Row>
      </Col>
    </TrendChartCardsWrapper>
  );
};

const TrendChartCardsWrapper = styled(Row)`
  background-color: ${color.white};
  padding: 20px;
  flex-wrap: nowrap;

  .icon-wrapper {
    .icon-inner-wrapper {
      display: inline-block;
      text-align: center;

      .icon {
        width: 80px;
        margin: 0 auto 12px;
      }
    }
  }

  @media only screen and (max-width: 576px) {
    display: block;
    padding: 10px 20px 5px;

    .icon-wrapper {
      margin-right: 0;
      margin-bottom: 10px;

      div {
        font-size: 14px;
      }

      .icon {
        width: 60px;
        margin: 0px auto 5px;
      }
    }

    .col-btn {
      flex: 0 0 33%;
      max-width: 33%;

      a {
        padding: 7px 0px;
      }
    }
  }
`;

const TrendTitle = styled.div`
  font-weight: bold;
  font-size: 16px;
  letter-spacing: 0;
`;

const { jiShiKaiJiang, zongHeFenXi, luZhuFenXi, kaiJiangJilu } = constPlayRule;
const { zouShiTuBiao, changLongTiXing } = pageName;

export const HomeCard = (props) => {
  const [openPlan, setOpenPlan] = useState(null);
  const [currentPlanNo, setCurrentPlanNo] = useState(0);

  const [rolling, setRolling] = useState(false);

  const {
    gameNameInChinese,
    lastPlanNo,
    lastIssueNumber,
    lastOpenCode,
    currentTimeEpoch,
    stopOrderTimeEpoch,
    gameUniqueId,
    playGroup,
  } = props;

  useEffect(() => {
    if (lastPlanNo !== currentPlanNo) {
      async function findIssueNumber(gameUniqueId) {
        let response = await findUniqueIssueNumberCount(gameUniqueId);
        setOpenPlan(response);
      }
      findIssueNumber(gameUniqueId);
      setCurrentPlanNo(lastPlanNo);
    }
  }, [gameUniqueId, lastPlanNo, currentPlanNo]);

  const leftPlan = calcLeftPlanNo(gameUniqueId, openPlan);

  const timerToNextOpen = lastOpenCode
    ? stopOrderTimeEpoch - currentTimeEpoch
    : 0;

  return (
    <Col xs={24} lg={12}>
      <HomeCardHeaderWrapper justify="space-between">
        <Col>
          <TitleText text={gameNameInChinese} />
        </Col>
        {playGroup !== 'SIX' ? (
          <Date>
            {openPlan && leftPlan
              ? '当前' + openPlan + '期，剩余' + leftPlan + '期'
              : '载入中'}
          </Date>
        ) : (
          ''
        )}
      </HomeCardHeaderWrapper>
      <HomeCardWrapper>
        <DetailWrapper>
          <Col flex="80px">
            <Link
              to={
                URLS.trends + `?searchGame=${gameUniqueId}&group=${playGroup}`
              }>
              <img src={Images[gameUniqueId]} alt={gameNameInChinese} />
            </Link>
          </Col>
          <Col offset={1}>
            <DrawNoText no={lastIssueNumber} />
            <Timer
              key={lastOpenCode}
              timer={timerToNextOpen}
              setRolling={setRolling}
              rolling={rolling}
            />
          </Col>
          {/* <DetailWrapperCol3 flex="88px">
                        <LcwButton text={"开奖历史"} url={URLS.trends} btnStyle={2} />
                    </DetailWrapperCol3> */}
        </DetailWrapper>
        <Row style={{ marginBottom: '24px' }}>
          <NumberRow
            kaiJiangHao={lastOpenCode}
            playGroup={playGroup}
            kaiJiangStyle={playGroup}
            rolling={rolling}
          />
        </Row>
        <div style={{ marginBottom: '15px' }}>
          <CardTable group={playGroup} dataSource={lastOpenCode} />
        </div>
        <Row justify="space-between" gutter={[0, { xs: 6, md: 0 }]}>
          {constHomeCardPlayRule[playGroup].map(({ name, id }, index) => {
            return (
              <Col className="button-wrapper" key={index}>
                <LcwButton
                  text={name}
                  url={
                    id === jiShiKaiJiang ||
                    id === zongHeFenXi ||
                    id === luZhuFenXi ||
                    id === kaiJiangJilu
                      ? getTrendsURL(gameUniqueId, playGroup, id)
                      : id === zouShiTuBiao
                      ? getZouShiTuBiao(gameUniqueId, playGroup)
                      : id === changLongTiXing
                      ? getChangLongURL(gameUniqueId, gameNameInChinese)
                      : ''
                  }
                />
              </Col>
            );
          })}
        </Row>
      </HomeCardWrapper>
    </Col>
  );
};

const HomeCardWrapper = styled.div`
  padding: 14px 22px 15px 24px;
  background-color: ${color.white};

  @media only screen and (max-width: 576px) {
    padding: 14px 10px 15px 10px;

    .button-wrapper {
      flex: 0 0 22%;

      a.lcw-button {
        padding: 7px 0;
      }
    }
  }
`;

const HomeCardHeaderWrapper = styled(Row)`
  padding: 9px 22px 10px 24px;
  border-bottom: 1px solid #eeeeee;
  background-color: ${color.white};

  @media only screen and (max-width: 576px) {
    padding: 9px 10px 10px 10px;
  }
`;

const Date = styled(Col)`
  font-size: 14px;
  line-height: 26px;
  color: ${color.fontGrey};
`;

const DetailWrapper = styled(Row)`
  margin-bottom: 24px;
`;

// const DetailWrapperCol3 = styled(Col)`
//   margin-left: auto;
// `;

import React from 'react';
import styled from 'styled-components';
import { Row, Col, Checkbox } from 'antd';

import Description from './Description';

import LcwButton from '@components/common/button';

import { color } from '@helpers/const';

import isMobile from '@helpers/hoc/isMobile';
import filterSticky from '@helpers/hoc/filterSticky';
import { transl } from '@helpers/utils';

import { defaultXingTaiByGroup } from '@const/playRule';

const BallFilter = ({ group, ballFilter, setBallFilter, isMobile }) => {
  let ballButton = [];
  const groupBall = defaultXingTaiByGroup[group];

  for (let index = 0; index < groupBall.length; index++) {
    ballButton.push(
      <Col key={groupBall[index].title} className="button-wrapper">
        <LcwButton
          text={groupBall[index].title}
          active={
            JSON.stringify(ballFilter) === JSON.stringify(groupBall[index].ball)
              ? true
              : false
          }
          activeOnClick={() => setBallFilter(groupBall[index].ball)}
          btnStyle={isMobile ? 10 : 1}
        />
      </Col>,
    );
  }

  return ballButton;
};

const Filter = ({
  isMobile,
  filter,
  setFilter,
  options,
  ballFilter,
  setBallFilter,
  group,
  ball,
  setBall,
}) => {
  return (
    <FilterWrapper>
      {!isMobile ? (
        <Row>
          <Description group={group} />
        </Row>
      ) : (
        ''
      )}
      <Row
        gutter={[{ md: 0 }, { md: 15 }]}
        justify="space-between"
        align="middle"
        className="block">
        <Col>
          <Row gutter={{ md: 20 }} className="block">
            {!isMobile ? (
              <Col style={{ lineHeight: '28px' }}>{transl('选择名次：')}</Col>
            ) : (
              ''
            )}
            <Col>
              <Row
                gutter={{ md: 12 }}
                className="ball-filter-wrapper hide-scrollbar">
                <BallFilter
                  group={group}
                  ballFilter={ballFilter}
                  setBallFilter={setBallFilter}
                  isMobile={isMobile}
                />
              </Row>
            </Col>
          </Row>
        </Col>
        {!isMobile ? (
          <Col>
            <Checkbox.Group
              value={filter}
              onChange={(props) => setFilter(props)}>
              <Row gutter={{ md: 12.5 }}>
                {options.map(({ label, value }) => {
                  return (
                    <Col key={label}>
                      <Checkbox value={value}>{transl(label)}</Checkbox>
                    </Col>
                  );
                })}
              </Row>
            </Checkbox.Group>
          </Col>
        ) : (
          ''
        )}
      </Row>
    </FilterWrapper>
  );
};

export default filterSticky(isMobile(Filter));

const FilterWrapper = styled.div`
  padding: 0 25px 4px;
  background-color: ${color.white};

  @media only screen and (max-width: 576px) {
    padding: 0;

    .ant-row {
      flex-flow: unset;
    }

    .block {
      display: block;
    }

    .ball-filter-wrapper {
      overflow-x: auto;
      justify-content: center;

      .button-wrapper {
        width: 33%;
        flex: 0 0 auto;
      }
    }
  }
`;

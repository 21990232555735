import React from 'react';
import { SummarizeTable } from '@components/common/table';

const RenderTableHeader = () => {
  const columns = [
    {
      title: '彩种',
      dataIndex: '彩种',
      width: '30%',
    },
    {
      title: '位置',
      dataIndex: '位置',
      width: '40%',
    },
    {
      title: '连续开出期数',
      dataIndex: '连续开出期数',
      width: '30%',
    },
    // {
    //     title: '路珠',
    //     dataIndex: '路珠',
    //     width: 134
    // },
    // {
    //     title: '长龙统计',
    //     dataIndex: '长龙统计',
    //     width: 134
    // },
  ];

  return columns;
};

const RenderTableBody = (dataSource, limiter) => {
  let dataSourceArray = [];
  dataSourceArray.push(
    dataSource &&
      dataSource.map(({ gameNameInChinese, dragon }, index) => {
        return dragon.map(({ title, dragon, dragonNum }, subIndex) => {
          let obj;
          if (dragonNum >= limiter) {
            obj = {
              key: index + ' ' + subIndex,
              彩种: gameNameInChinese,
              位置: title + ' : ' + dragon,
              连续开出期数: dragonNum + ' 期',
              // 路珠: '查看',
              // 长龙统计: '查看',
            };
          }

          return obj;
        });
      }),
  );

  dataSourceArray = dataSourceArray.flat(2);

  dataSourceArray = dataSourceArray.filter(function (element) {
    return element !== undefined;
  });

  return dataSourceArray;
};
const ChangLongTable = ({ data, limiter }) => {
  const columns = RenderTableHeader();

  const source = RenderTableBody(data, limiter);

  return (
    <>
      <SummarizeTable tableHeader={columns} tableData={source} />
    </>
  );
};

export default ChangLongTable;

import React from 'react';
import styled from 'styled-components';
import { Row, Col } from 'antd';

import LcwButton from '@components/common/button';

import { color } from '@helpers/const';
import isMobile from '@helpers/hoc/isMobile';
import withLocation from '@helpers/hoc/withLocation';
import { transl } from '@helpers/utils';

import {
  constLuZhuOptionBall,
  defaultDragonTigerByGroup,
} from '@const/playRule';

import Drawer from '@components/common/drawer';

const BallFilter = ({ group, filterBall, setFilterBall, mode, btnStyle }) => {
  let ballButton = [];
  let groupBall;
  let twoColum;
  switch (mode.filter) {
    case 'dragonLongMode':
    case 'tigerLongMode':
      groupBall = defaultDragonTigerByGroup[group];
      twoColum = true;
      break;

    default:
      groupBall = constLuZhuOptionBall[group];
      break;
  }

  for (let index = 0; index < groupBall.length; index++) {
    ballButton.push(
      <Col key={groupBall[index].label} className={twoColum ? 'col2' : ''}>
        <LcwButton
          text={groupBall[index].label}
          active={groupBall[index].value === filterBall[0] ? true : false}
          activeOnClick={() =>
            setFilterBall([groupBall[index].value, groupBall[index].total])
          }
          btnStyle={btnStyle}
        />
      </Col>,
    );
  }

  return ballButton;
};

const watchResetBallDefault = (mode, setFilterBall, group) => {
  let groupBall;
  switch (mode.filter) {
    case 'dragonLongMode':
    case 'tigerLongMode':
      groupBall = defaultDragonTigerByGroup[group];
      break;

    default:
      groupBall = constLuZhuOptionBall[group];
      break;
  }

  setFilterBall([groupBall[0].value, groupBall[0].total]);
};

const Filter = ({
  isMobile,
  filterBall,
  setFilterBall,
  group,
  mode,
  openBallFilter,
  setOpenBallFilter,
  search,
}) => {
  const { language } = search;

  return (
    <FilterWrapper>
      <div className="desc">
        {transl(
          '该页面统计长龙每天出现的条数，例如某日连续开出5期长龙（该形态）共出现10次',
        )}
      </div>
      {!isMobile ? (
        <Row gutter={[20, 15]} align="middle">
          <Col style={{ lineHeight: '28px' }}>{transl('选择名次：')}</Col>
          <Col>
            <Row gutter={[{ md: 12 }, { md: 12 }]}>
              <BallFilter
                group={group}
                filterBall={filterBall}
                setFilterBall={setFilterBall}
                mode={mode}
              />
            </Row>
          </Col>
        </Row>
      ) : (
        <Drawer
          visible={openBallFilter}
          onClose={() => setOpenBallFilter(false)}>
          <MobileFilterOption>
            <div className="title">{transl('选择名次：')}</div>
            <div>
              <Row className={'ball-filter-wrapper ' + language}>
                <BallFilter
                  group={group}
                  filterBall={filterBall}
                  setFilterBall={setFilterBall}
                  mode={mode}
                  btnStyle={9}
                />
              </Row>
              <Row className="action mobile">
                <Col span={12}>
                  <button
                    onClick={() => {
                      setOpenBallFilter(false);
                      watchResetBallDefault(mode, setFilterBall, group);
                    }}>
                    {transl('清空')}
                  </button>
                </Col>
                <Col span={12}>
                  <button
                    className="confirm"
                    onClick={() => {
                      setOpenBallFilter(false);
                    }}>
                    {transl('确定')}
                  </button>
                </Col>
              </Row>
            </div>
          </MobileFilterOption>
        </Drawer>
      )}
    </FilterWrapper>
  );
};

export default withLocation(isMobile(Filter));

const FilterWrapper = styled.div`
    padding: 0 25px 4px;
    background-color: ${color.white};

    .desc {
        margin-bottom: 10px;
    }

    @media only screen and (max-width: 576px) {
        position: relative;
        padding: 0;

        .desc {
            display: none;
            // color: ${color.lightFontGrey};
            // margin-bottom: 0px;
        }
    }
`;

const MobileFilterOption = styled.div`
  .title {
    color: ${color.lightFontGrey};
    padding: 13px 21px 0px 26px;
    margin-bottom: 11px;
  }

  .ball-filter-wrapper {
    padding: 0 21px 0px 26px;

    .ant-col {
      flex: 1 1 33%;
      max-width: 33%;
      margin-bottom: 18px;

      &.col2 {
        flex: 1 1 50%;
        max-width: 50%;
      }
    }

    &.en-US,
    &.vi-VN {
      .ant-col {
        flex: 1 1 50%;
        max-width: 50%;
      }
    }
  }
`;

import React from 'react';

import DescriptionBox from '@components/common/descriptionBox';

const ruleDescription = [
  '遗漏期数：自上期开出到本期间隔的期数；',
  '分隔线：每五期使用分隔线，使横向导航更加清晰；',
  '出现次数：统计周期内，某个号码的累计出现次数；',
  '平均遗漏：统计期数内遗漏的平均值。（计算公式：平均遗漏＝统计期数/出现次数)；',
  '最大遗漏：统计期数内遗漏的最大值；',
  '最大连出值：统计期数内连续开出的最大值；',
];

const descByGroup = {
  SSC: [
    '大小：5～9属大号，0～4属小号；',
    '单双：1、3、5、7、9属单数，0、2、4、6、8属双数；',
    '质合：2、3、5、7属质数，0、1、4、6、8、9属合数;',
  ],
  PK10: [
    '大小：6～10属大号，1～5属小号；',
    '单双：1、3、5、7、9属单数，0、2、4、6、8、10属双数；',
    '质合：2、3、5、7属质数，0、1、4、6、8、9、10属合数;',
  ],
  XY10: [
    '大小：6～10属大号，1～5属小号；',
    '单双：1、3、5、7、9属单数，0、2、4、6、8、10属双数；',
    '质合：2、3、5、7属质数，0、1、4、6、8、9、10属合数;',
  ],
  D11: [
    '大小：6～11属大号，1～5属小号；',
    '单双：1、3、5、7、9、11属单数，0、2、4、6、8、10属双数；',
    '质合：2、3、5、7、11属质数，0、1、4、6、8、9、10属合数;',
  ],
  K3: [
    '大小：4～6属大号，1～3属小号；',
    '单双：1、3、5属单数，0、2、4、6属双数；',
    '质合：2、3、5属质数，0、1、4、6属合数;',
  ],
};

const Description = ({ group }) => {
  return (
    <>
      <DescriptionBox
        descByGroup={descByGroup[group]}
        generalDescription={ruleDescription}
      />
    </>
  );
};

export default Description;

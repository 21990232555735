import React from 'react';
import styled from 'styled-components';

import { color } from '@helpers/const';
import { constPlayGroup } from '@const/game';

const { SIX, K3 } = constPlayGroup;

const Content = ({ gameName, group }) => {
  let playStyle;
  switch (group) {
    case SIX:
      playStyle = '特码生肖';
      break;
    case K3:
      playStyle = '和值';
      break;
    default:
      playStyle = '第一球';
      break;
  }
  return (
    <ContentWrapper>
      <div className="section">
        <div className="title">
          <b>功能介绍</b>
        </div>
        <div className="content">
          {gameName}购彩计划：依据{gameName}
          历史大数据规律为基础，提供N组购彩计划参考，建议使用阶梯倍投方法投注，如果连续10期没有成功则标记为失败。
        </div>
      </div>
      <div className="section">
        <div className="title">
          <b>本推荐玩法</b>
        </div>
        <div className="content">
          {gameName}追踪{playStyle}
          开奖号码，以倍投的形式，第一期以1为基数，以此类推，连续十次不中则更新计划。
        </div>
      </div>
      <div className="section">
        <div className="title">
          <b>使用技巧</b>
        </div>
        <div className="content">
          {gameName}购彩计划，建议使用，阶梯倍投方式追号投注。
        </div>
      </div>
      <div className="section">
        <div className="title">
          <b>推荐计划</b>
        </div>
        <div className="content">
          {gameName}推荐计划，以此类推，连续十次不中则更新计划
          推荐计划：三种方案的推荐计划分别对应不同个数的号码，号码越多中奖率越高，
          但是中奖额度较小，风险
          越大，收益越高。比如：计划1为5个号码，所需要的成本为5，假如赔率是10，则赢5;如果是计划3，
          有3个号码，成本为3,则赢7。
        </div>
      </div>
      <div className="section">
        <div className="title">
          <b>当前成本</b>
        </div>
        <div className="content">
          {gameName}
          当前成本，指当前期购彩号码成本，每个计划的成本以号码多少为基准。
        </div>
      </div>
      <div className="section">
        <div className="title">
          <b>累计成本</b>
        </div>
        <div className="content">
          {gameName}累计成本，指从推荐第一期开始计算所用总成本。
        </div>
      </div>
      <div className="section">
        <div className="title">
          <b>赢亏</b>
        </div>
        <div className="content">
          {gameName}赢亏，根据试玩的赔率计算得来，红为赢，蓝为亏。
        </div>
      </div>
    </ContentWrapper>
  );
};

export default Content;

const ContentWrapper = styled.div`
  background-color: ${color.white};
  padding: 25px 65px;

  .section {
    margin-bottom: 25px;
  }

  .title {
    margin-bottom: 5px;
  }

  .content {
    padding-left: 15px;
  }
`;

import React from 'react';
import { inject, observer } from 'mobx-react';
import { Select, Row, Col } from 'antd';

import { transl } from '@helpers/utils';

const { Option } = Select;

const NumberSelection = ({ trendsStore }) => {
  const { resultDisplay } = trendsStore;

  const handleChange = (value) => {
    trendsStore.changeResultNumber(value);
  };

  return (
    <div>
      <Row align="middle" gutter={15}>
        <Col>{transl('近期设置')}</Col>
        <Col>
          <Select
            defaultValue={resultDisplay}
            dropdownClassName="comp-number-selection"
            onChange={(props) => handleChange(props)}>
            <Option value="30">30</Option>
            <Option value="50">50</Option>
            <Option value="100">100</Option>
          </Select>
        </Col>
      </Row>
    </div>
  );
};

export default inject('trendsStore')(observer(NumberSelection));

import React from 'react';
import styled from 'styled-components';
import { Row, Col } from 'antd';

import { color } from '@helpers/const';
import { transl } from '@helpers/utils';

import NumberSelection from '@components/common/numberSelection';

const PlayRuleTitle = ({ title }) => {
  return (
    <PlayRuleTitleWrapper>
      <TitleWrapper justify="space-between">
        <Col>
          <div>
            <span style={{ fontSize: '18px', fontWeight: '600' }}>
              {transl(title)}
            </span>
          </div>
        </Col>
        <Col>
          <NumberSelection />
        </Col>
      </TitleWrapper>
    </PlayRuleTitleWrapper>
  );
};

export default PlayRuleTitle;

const PlayRuleTitleWrapper = styled.div`
  padding: 0 25px 19px;
  background-color: ${color.white};
`;

const TitleWrapper = styled(Row)`
  position: relative;
  padding-bottom: 15px;

  &:after {
    content: '';
    position: absolute;
    width: calc(100% + 50px);
    height: 1px;
    left: -25px;
    bottom: 0;
    background-color: ${color.borderGrey};
  }
`;

import React from 'react';
import styled from 'styled-components';
import { Checkbox } from 'antd';
import { inject, observer } from 'mobx-react';

import { color } from '@helpers/const';
import withLocation from '@helpers/hoc/withLocation';
import { transl } from '@helpers/utils';

import { constHotWarmCold } from '@const/playRule';

const onChange = (props, setShowNumber) => {
  setShowNumber(props.target.checked);
};

const Filter = ({ trendsStore, setShowNumber }) => {
  const { resultDisplay } = trendsStore;

  const { hot, warm, cold } = constHotWarmCold[resultDisplay];

  return (
    <FilterWrapper>
      <div className="action">
        <Checkbox onChange={(props) => onChange(props, setShowNumber)}>
          {transl('显示热号码出现次数')}
        </Checkbox>
      </div>
      <div style={{ paddingBottom: '14px' }}>
        {transl(
          '近/x/i期内热码：出现频繁、活跃的号码（/x/i 次以上）；冷码：出现频率低甚至没有出现的号码（/x/i-/x/i 次）；温码：介于冷热之间的号码（/x/i-/x/i 次）。',
          [resultDisplay, hot, cold[0], cold[1], warm[0], warm[1]],
        )}
      </div>
    </FilterWrapper>
  );
};

export default withLocation(inject('trendsStore')(observer(Filter)));

const FilterWrapper = styled.div`
  padding: 0 25px 15px;
  background-color: ${color.white};

  .action {
    margin-bottom: 14px;
  }

  @media only screen and (max-width: 576px) {
    padding: 0 12px;

    span {
      font-size: 14px;
      line-height: 44px;
      color: ${color.mobileBlack};
    }

    .action {
      margin-bottom: 0;
    }
  }
`;

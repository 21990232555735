import React, { useState, useEffect } from 'react';
import styled from 'styled-components';

import { Row, Col, InputNumber } from 'antd';

import { color } from '@helpers/const';
import isMobile from '@helpers/hoc/isMobile';
import { transl } from '@helpers/utils';

import LcwButton from '@components/common/button';
import { Images } from '@components/common/images';

import { constTrendBallList } from '@const/playRule';
import { constTotalCodeDigit } from '@const/game';

import Reference from '@components/common/reference';

const onClickAddButton = (
  filterNumber,
  tempFilter,
  setTempFilter,
  setFilter,
) => {
  let newArray = [...tempFilter];
  if (filterNumber === 0) {
    if (tempFilter.length < 2) {
      newArray.push([
        tempFilter[filterNumber][1] + 1,
        tempFilter[filterNumber][1] + 16,
      ]);
    } else {
      newArray.splice(1);
      setFilter(newArray);
    }
  } else if (filterNumber === 1) {
    if (tempFilter.length < 3) {
      newArray.push([
        tempFilter[filterNumber][1] + 1,
        tempFilter[filterNumber][1] + 16,
      ]);
    } else {
      newArray.splice(2);
      setFilter(newArray);
    }
  }

  setTempFilter(newArray);
};

const onChangeInput = (tempFilter, setTempFilter, value, filterNumber, pos) => {
  let newArray = [...tempFilter];
  if (pos === 0) {
    if (filterNumber === 0) {
      if (value < tempFilter[filterNumber][1] && value > 0) {
        newArray[filterNumber][0] = value;
        setTempFilter(newArray);
      }
    } else {
      if (
        value > tempFilter[filterNumber - 1][1] &&
        value < tempFilter[filterNumber][1]
      ) {
        newArray[filterNumber][0] = value;
        setTempFilter(newArray);
      }
    }
  } else if (pos === 1) {
    if (filterNumber === tempFilter.length - 1) {
      if (value > tempFilter[filterNumber][0]) {
        newArray[filterNumber][1] = value;
        setTempFilter(newArray);
      }
    } else {
      if (
        value < tempFilter[filterNumber + 1][0] &&
        value > tempFilter[filterNumber][0]
      ) {
        newArray[filterNumber][1] = value;
        setTempFilter(newArray);
      }
    }
  }
};

const FilterOption = ({
  filterNumber,
  addButton,
  tempFilter,
  setTempFilter,
  setFilter,
}) => {
  return (
    <FilterOptionWrapper gutter={{ md: 8 }} align="middle">
      <Col>
        {transl('参数设置')}
        &nbsp;
        {filterNumber + 1}:
      </Col>
      <Col>{transl('当数值为')}</Col>
      <Col>
        <InputNumber
          value={tempFilter[filterNumber][0]}
          onChange={(props) =>
            onChangeInput(tempFilter, setTempFilter, props, filterNumber, 0)
          }
        />
      </Col>
      <Col>{transl('至')}</Col>
      <Col>
        <InputNumber
          value={tempFilter[filterNumber][1]}
          onChange={(props) =>
            onChangeInput(tempFilter, setTempFilter, props, filterNumber, 1)
          }
        />
      </Col>
      <Col>{transl('时为')}</Col>
      <Col>
        <ColorWrapper className={'hl-' + filterNumber}>
          {transl('色')}
        </ColorWrapper>
      </Col>
      {addButton ? (
        <Col className="add-option-wrapper">
          <button
            className={
              filterNumber < tempFilter.length - 1
                ? 'add-option is-active'
                : 'add-option'
            }
            onClick={() =>
              onClickAddButton(
                filterNumber,
                tempFilter,
                setTempFilter,
                setFilter,
              )
            }>
            {transl('add')}
          </button>
        </Col>
      ) : (
        ''
      )}
    </FilterOptionWrapper>
  );
};

const FilterOptions = ({ tempFilter, setTempFilter, setFilter }) => {
  let result = [];
  for (let index = 0; index < tempFilter.length; index++) {
    result.push(
      <Col className="filter-col" key={index}>
        <FilterOption
          filterNumber={index}
          tempFilter={tempFilter}
          setTempFilter={setTempFilter}
          addButton={index < 2 ? true : false}
          setFilter={setFilter}
        />
      </Col>,
    );
  }

  return result;
};

const onClickApply = (tempFilter, setFilter) => {
  if (tempFilter[0][2] === 0) {
    let newArray = [...tempFilter];
    newArray[0][2] = 1;
    setFilter(newArray);
  } else {
    setFilter(tempFilter);
  }
};

const BallFilter = ({ group, mobileActiveBall, setMobileActiveBall }) => {
  let ballButton = [];
  for (let index = 0; index < constTotalCodeDigit[group]; index++) {
    ballButton.push(
      <Col key={constTrendBallList[index].text} className="button-wrapper">
        <LcwButton
          text={constTrendBallList[index].text}
          active={mobileActiveBall === index ? true : false}
          activeOnClick={() => setMobileActiveBall(index)}
          btnStyle={10}
          translShort={true}
        />
      </Col>,
    );
  }

  return ballButton;
};

const Filter = ({
  isMobile,
  group,
  setFilter,
  setApply,
  mobileActiveBall,
  setMobileActiveBall,
}) => {
  const [tempFilter, setTempFilter] = useState([[15, 30]]);

  useEffect(() => {
    if (isMobile) {
      const defaultFilter = [
        [3, 10],
        [11, 20],
        [21, 40],
      ];
      setTempFilter(defaultFilter);
    }
  }, [isMobile]);

  const content = [
    'No. - Number',
    'Total - Total open',
    'Open Streak - Open streak count',
    'Current N/O - Current no open count',
    'Lgt N/O Streak - Longest no open streak count',
  ];

  return (
    <>
      {!isMobile && <Reference content={content} />}
      <FilterWrapper>
        {isMobile ? (
          <>
            <div className="ball-filter-wrapper hide-scrollbar">
              <Row>
                <BallFilter
                  group={group}
                  mobileActiveBall={mobileActiveBall}
                  setMobileActiveBall={setMobileActiveBall}
                />
              </Row>
            </div>
            <Reference content={content} />
          </>
        ) : (
          ''
        )}
        <Row
          gutter={[{ md: 10 }, { md: 10 }]}
          align="middle"
          className="option-wrapper">
          <FilterOptions
            tempFilter={tempFilter}
            setTempFilter={setTempFilter}
            setFilter={setFilter}
          />
          {!isMobile ? (
            <Col>
              <div>
                <LcwButton
                  text="确定"
                  styleCss={{
                    backgroundColor: color.black,
                    color: color.white,
                    border: 'none',
                    borderRadius: '4px',
                  }}
                  activeOnClick={() => {
                    onClickApply(tempFilter, setFilter);
                    setApply(true);
                  }}
                />
              </div>
            </Col>
          ) : (
            ''
          )}
        </Row>
        {isMobile ? (
          <Row className="action mobile">
            <Col span={12}>
              <button
                onClick={() => {
                  setApply(false);
                }}>
                {transl('清空')}
              </button>
            </Col>
            <Col span={12}>
              <button
                className="confirm"
                onClick={() => {
                  onClickApply(tempFilter, setFilter);
                  setApply(true);
                }}>
                {transl('确定')}
              </button>
            </Col>
          </Row>
        ) : (
          ''
        )}
      </FilterWrapper>
    </>
  );
};

export default isMobile(Filter);

const FilterWrapper = styled.div`
  padding: 0 25px 7px;
  background-color: ${color.white};

  .filter-col {
    overflow: hidden;
    height: 40px;
    max-width: 0;
    opacity: 0;
    animation: expand 0.5s linear;
    animation-fill-mode: forwards;

    @keyframes expand {
      to {
        max-width: 100%;
        opacity: 1;
      }
    }
  }

  @media only screen and (max-width: 576px) {
    padding: 0;

    .ball-filter-wrapper {
      position: relative;
      z-index: 3;
      background-color: ${color.white};
      overflow-x: auto;

      .ant-row {
        flex-flow: unset;

        .button-wrapper {
          min-width: 22%;
          flex: 1 0 auto;
          margin: 0 5px;
        }
      }
    }

    .option-wrapper {
      display: block;

      .filter-col {
        height: auto;
        padding: 10px 5%;
        border-top: 1px solid ${color.borderGrey};
      }
    }

    button {
      color: ${color.mobileBlack};
      background-color: transparent;
      border: none;
      padding: 0;
      display: block;
      width: 100%;
      height: 100%;

      &:focus {
        outline: none;
      }
    }
  }
`;

const FilterOptionWrapper = styled(Row)`
  .ant-input-number {
    width: 46px;
    height: 30px;
    border-radius: 4px;

    @media only screen and (max-width: 576px) {
      width: 50px;
    }

    .ant-input-number-handler-wrap {
      display: none;
    }

    .ant-input-number-input-wrap {
      input {
        text-align: center;
      }
    }
  }

  .add-option {
    position: relative;
    font-size: 0;
    width: 15px;
    height: 15px;
    border: none;
    background-color: transparent;
    vertical-align: middle;
    outline: none;
    cursor: pointer;

    &:after {
      content: '';
      background: url(${Images.icn_circle_plus}) center/cover;
      position: absolute;
      width: 100%;
      height: 100%;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      transition: linear 0.1s;
    }

    &.is-active {
      &:after {
        transform: translate(-50%, -50%) rotate(45deg);
      }
    }
  }

  @media only screen and (max-width: 576px) {
    justify-content: space-between;

    > .ant-col {
      color: ${color.mobileBlack};

      &:first-child {
        font-size: 13px;
        color: ${color.lightFontGrey};
        width: 100%;
        margin-bottom: 4px;
      }

      &.add-option-wrapper {
        display: none;
      }

      input {
        color: ${color.orange};
      }
    }
  }
`;

const ColorWrapper = styled.div`
  position: relative;
  padding-left: 16px;

  @media only screen and (max-width: 576px) {
    padding-left: 21px;

    &:before {
      width: 14px;
      height: 14px;
    }
  }

  &:before {
    content: '';
    position: absolute;
    top: 50%;
    left: 0;
    transform: translateY(-50%);
    width: 11px;
    height: 11px;
    background-color: ${color.red};

    @media only screen and (max-width: 576px) {
      width: 14px;
      height: 14px;
    }
  }

  &.hl-1 {
    &:before {
      background-color: ${color.blue};
    }
  }

  &.hl-2 {
    &:before {
      background-color: ${color.green};
    }
  }
`;

import React, { useState } from 'react';
import { toJS } from 'mobx';
import { inject, observer } from 'mobx-react';
import MainTable from './MainTable';
import Filter from './Filter';

import { color } from '@helpers/const';
import withLocation from '@helpers/hoc/withLocation';
import isMobile from '@helpers/hoc/isMobile';
import { transl } from '@helpers/utils';

import styled from 'styled-components';

import { constLuZhuOptionBall } from '@const/playRule';

const ChangLongTongJi = ({ trendsStore, search, isMobile }) => {
  const { group } = search;

  const { histByDate, histByDateBy19Days } = trendsStore;

  const [filter, setFilter] = useState(constLuZhuOptionBall[group][0].value);

  let openCodeArray = [];
  if (histByDate && histByDateBy19Days) {
    openCodeArray = toJS(histByDateBy19Days);
    openCodeArray.unshift(toJS(histByDate));
  }

  return (
    <>
      {!isMobile ? (
        <TitleWrapper>
          <Title>
            <span style={{ fontSize: '18px', fontWeight: '600' }}>
              {transl('大小单双历史')}
            </span>
          </Title>
        </TitleWrapper>
      ) : (
        <Filter group={group} filter={filter} setFilter={setFilter} />
      )}
      <MainTable openCodeArray={openCodeArray} group={group} filter={filter} />
    </>
  );
};

export default isMobile(
  withLocation(inject('trendsStore')(observer(ChangLongTongJi))),
);

const TitleWrapper = styled.div`
  padding: 0 25px 4px;
  background-color: ${color.white};
`;

const Title = styled.div`
  position: relative;
  padding-bottom: 15px;
  margin-bottom: 19px;

  &:after {
    content: '';
    position: absolute;
    width: calc(100% + 50px);
    height: 1px;
    left: -25px;
    bottom: 0;
    background-color: ${color.borderGrey};
  }
`;

import React, { useState, useRef } from 'react';
import styled from 'styled-components';
import { Drawer, Row, Col } from 'antd';

import { color } from '@helpers/const';
import { transl } from '@helpers/utils';
import { Images } from '@components/common/images';

import DescriptionBox from '@components/common/descriptionBox';

import isMobile from '@helpers/hoc/isMobile';
import withLocation from '@helpers/hoc/withLocation';

export const colorContent = (value, isPC = false) => {
  let result = value;
  let classStyle = '';
  if (
    value.includes('E - Even') ||
    value.includes('B - Big') ||
    value.includes('R - Red') ||
    value.includes('D - Dragon') ||
    value.includes('DZ - Pair') ||
    value.includes('EB - Extreme Big') ||
    value.includes('SZ - Straight')
  ) {
    classStyle = 'red';
  } else if (value.includes('G - Green') || value.includes('BZ - Leopard')) {
    classStyle = 'green';
  } else if (
    value.includes('B - Blue') ||
    value.includes('O - Odd') ||
    value.includes('S - Small') ||
    value.includes('T - Tiger') ||
    value.includes('ES - Extreme Small') ||
    value.includes('BS - Semi-smooth')
  ) {
    classStyle = 'blue';
  }
  if (isPC) {
    result = (
      <>
        <span className={classStyle}>
          {value.substr(0, value.indexOf(' -'))}
        </span>
        <>{value.substr(value.indexOf(' -'))}</>
      </>
    );
  } else {
    result = (
      <span className={classStyle}>{value.substr(0, value.indexOf(' -'))}</span>
    );
  }

  return result;
};

const Reference = ({ isMobile, search, content, sticky = false, top = 88 }) => {
  const { language } = search;

  const [visible, setVisible] = useState(false);
  const [refWrapperH, setRefWrapperH] = useState(129);
  const refReferenceWrapper = useRef(null);

  let comp = '';

  const { current: refWrapper } = refReferenceWrapper;

  if (refWrapper && refWrapper.offsetTop) {
    if (refWrapperH !== refWrapper.offsetTop) {
      setRefWrapperH(refWrapper.offsetTop);
    }
  }

  let arrayContent = [];
  for (let index = 0; index < content.length; index++) {
    arrayContent.push(content[index].split(' - '));
  }

  if (language === 'en-US' || language === 'vi-VN') {
    let referenceClass = '';
    referenceClass = referenceClass.concat(sticky ? 'sticky ' : '');
    referenceClass = referenceClass.concat(visible ? 'open ' : '');

    comp = isMobile ? (
      <ReferenceWrapper
        ref={refReferenceWrapper}
        className={referenceClass}
        top={top}>
        <div className="btn-wrapper">
          <button
            className="btn"
            onClick={() => content.length > 1 && setVisible(!visible)}>
            <Row>
              <Col
                style={{
                  width: language === 'en-US' ? '69px' : '80px',
                  marginRight: '5px',
                }}>
                <span style={{ fontWeight: 500 }}>{transl('参考：')}</span>
              </Col>
              <Col>
                <Row gutter={2}>
                  <Col className="title">
                    1.{' '}
                    {colorContent(
                      arrayContent[0][0] + ' - ' + arrayContent[0][1],
                    )}
                  </Col>
                  <Col>-</Col>
                  <Col>{transl(arrayContent[0][1])}</Col>
                </Row>
              </Col>
            </Row>
          </button>
          {content.length > 1 && (
            <div className={visible ? 'expand-icon open' : 'expand-icon'}></div>
          )}
        </div>
        {arrayContent.length > 1 && (
          <ReferenceDrawer
            style={{
              top: sticky ? 126 + top - 90 + 'px' : refWrapperH + 80 + 'px',
            }}
            height="auto"
            placement="top"
            closable={false}
            visible={visible}
            onClose={() => setVisible(false)}
            destroyOnClose={true}
            zIndex={2}>
            <div className={'content ' + language}>
              {arrayContent.map((value, index) => {
                const display =
                  index !== 0 ? (
                    <Row key={index} gutter={2}>
                      <Col className="title">
                        {index + 1}. {colorContent(value[0] + ' - ' + value[1])}
                      </Col>
                      <Col>-</Col>
                      <Col>{transl(value[1])}</Col>
                    </Row>
                  ) : null;
                return display;
              })}
            </div>
            <button className="btn-close" onClick={() => setVisible(false)}>
              {transl('关闭')}
            </button>
          </ReferenceDrawer>
        )}
      </ReferenceWrapper>
    ) : (
      <DescriptionBoxWrapper>
        <DescriptionBox generalDescription={content} title="Reference:" />
      </DescriptionBoxWrapper>
    );
  }

  return comp;
};

export default isMobile(withLocation(Reference));

const ReferenceWrapper = styled.div`
  font-size: 13px;
  margin-top: -1px;
  padding: 19px 21px 29px 21px;
  border-bottom: 1px solid ${color.orange};

  @media only screen and (max-width: 576px) {
    position: relative;
    padding: 0;
    padding-bottom: 4px;
    border: none;
    background-color: ${color.white};
    z-index: 3;

    .btn-wrapper {
      border-bottom: 1px solid ${color.orange};
    }

    &.sticky {
      position: sticky;
      top: ${(props) => props.top}px;
    }

    &.open {
      padding-bottom: 0;
      margin-bottom: 11px;

      .btn-wrapper {
        border-bottom: none;

        .btn {
          padding-bottom: 4px;
        }
      }
    }
  }

  .btn-wrapper {
    position: relative;
    display: block;
    z-index: 2;
    background-color: ${color.lightOrange};

    .btn {
      background-color: transparent;
      border: none;
      text-align: left;
      padding: 12px 10px 10px;

      .ant-row {
        flex-wrap: nowrap;

        .title {
          flex: none;
        }
      }
    }

    .expand-icon {
      background-image: url(${Images.icn_dropdown_grey});
      width: 11px;
      height: 7px;
      position: absolute;
      right: 10px;
      top: 18px;

      &.open {
        background-image: url(${Images.icn_dropdown_up_orange});
      }
    }
  }
`;

const ReferenceDrawer = styled(Drawer)`
  .ant-drawer-body {
    background-color: ${color.lightOrange};
    padding: 0;

    .content {
      font-size: 13px;
      line-height: 20px;
      max-height: 50vh;
      overflow: auto;
      padding: 0 11px 13px 85px;

      &.vi-VN {
        padding: 0 11px 13px 95px;
      }

      .ant-row {
        flex-wrap: nowrap;
        margin-bottom: 8px;

        .title {
          flex: none;
        }
      }
    }

    .btn-close {
      font-size: 15px;
      height: 44px;
      border-top: 1px solid ${color.orange};
      color: ${color.orange};
    }
  }
`;

const DescriptionBoxWrapper = styled.div`
  padding: 15px 25px 6px;
  margin-bottom: -16px;
  background-color: ${color.white};
`;

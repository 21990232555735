import React from 'react';
import styled from 'styled-components';

import LuzhuTable from '@components/common/luzhuTable';

import { constPlayGroup } from '@const/game';
import { constLuZhuName, constLuZhuOptionBall } from '@const/playRule';

import { color } from '@helpers/const';
import {
  generateLuzhuResult,
  sumOfArrayList,
  sumOfArrayListByPosition,
} from '@helpers/utils';

const { PK10, XY10, D11, KL10F, SSC } = constPlayGroup;

const { bigSmall, oddEven, dragonTiger } = constLuZhuName;

const FilterResult = ({ openCodeArray, filterBall, filterType, group }) => {
  return (
    <TrendsResultWrapper>
      <GenerateLuzhuTable
        filterBall={filterBall}
        filterType={filterType}
        openCodeArray={openCodeArray}
        group={group}
      />
    </TrendsResultWrapper>
  );
};

export default FilterResult;

const GenerateLuzhuTable = ({
  filterBall,
  filterType,
  openCodeArray,
  group,
}) => {
  let table = [];
  let groupDuplicateArray;
  let totalOfOpenCode;
  let dragonTigerTable = true;
  let ballNumber;

  if (filterBall.length && filterType.length && openCodeArray) {
    for (let indexBall = 0; indexBall < filterBall.length; indexBall++) {
      for (let indexType = 0; indexType < filterType.length; indexType++) {
        groupDuplicateArray = '';
        if (group !== PK10 && group !== XY10) {
          if (
            constLuZhuOptionBall[group][filterBall[indexBall]].total &&
            filterType[indexType] !== dragonTiger.value
          ) {
            totalOfOpenCode = sumOfArrayList(openCodeArray);
            groupDuplicateArray = generateLuzhuResult(
              totalOfOpenCode,
              filterBall[indexBall],
              filterType[indexType],
              group,
            );
          } else if (filterType[indexType] !== dragonTiger.value) {
            groupDuplicateArray = generateLuzhuResult(
              openCodeArray,
              filterBall[indexBall],
              filterType[indexType],
              group,
            );
          } else if (
            !constLuZhuOptionBall[group][filterBall[indexBall]].total &&
            filterType[indexType] === dragonTiger.value &&
            dragonTigerTable
          ) {
            ballNumber = filterBall[indexBall];
            if (group === 'SSC') {
              switch (ballNumber) {
                case 0:
                  ballNumber = [
                    [0, 1],
                    [0, 2],
                    [0, 3],
                    [0, 4],
                  ];
                  break;
                case 1:
                  ballNumber = [
                    [1, 2],
                    [1, 3],
                    [1, 4],
                  ];
                  break;
                case 2:
                  ballNumber = [
                    [2, 3],
                    [2, 4],
                  ];
                  break;
                case 3:
                  ballNumber = [[3, 4]];
                  break;
                default:
                  break;
              }
              for (let index = 0; index < ballNumber.length; index++) {
                groupDuplicateArray = generateLuzhuResult(
                  openCodeArray,
                  ballNumber[index],
                  filterType[indexType],
                  group,
                );
                const defaultFilter = ['dragon', 'tiger', 'draw'];
                table.push(
                  <LuzhuTable
                    key={ballNumber[index] + filterType[indexType]}
                    groupDuplicateArray={groupDuplicateArray}
                    defaultFilter={defaultFilter}
                    filterType={filterType[indexType]}
                    filterBall={ballNumber[index]}
                    // luZhuBallNumber={luZhuBallNumber}
                    group={group}
                  />,
                );
              }
            } else {
              dragonTigerTable = false;
              groupDuplicateArray = generateLuzhuResult(
                openCodeArray,
                ballNumber,
                filterType[indexType],
                group,
              );
            }
          }
        } else if (group === PK10 || group === XY10) {
          if (
            constLuZhuOptionBall[group][filterBall[indexBall]].total &&
            filterType[indexType] !== dragonTiger.value
          ) {
            totalOfOpenCode = sumOfArrayListByPosition(openCodeArray, 0, 1);
            groupDuplicateArray = generateLuzhuResult(
              totalOfOpenCode,
              filterBall[indexBall],
              filterType[indexType],
              group,
            );
          } else if (
            filterType[indexType] !== dragonTiger.value ||
            (filterType[indexType] === dragonTiger.value &&
              filterBall[indexBall] < 5)
          ) {
            groupDuplicateArray = generateLuzhuResult(
              openCodeArray,
              filterBall[indexBall],
              filterType[indexType],
              group,
            );
          }
        }

        if (
          (group !== SSC && groupDuplicateArray) ||
          (group === SSC && filterType[indexType] !== dragonTiger.value)
        ) {
          let defaultFilter;
          switch (group) {
            case PK10:
            case XY10:
              defaultFilter =
                filterType[indexType] === bigSmall.value
                  ? ['big', 'small']
                  : filterType[indexType] === oddEven.value
                  ? ['odd', 'even']
                  : ['dragon', 'tiger'];
              break;
            case D11:
            case KL10F:
              if (
                (group === D11 && filterBall[indexBall] === 5) ||
                (group === KL10F && filterBall[indexBall] === 8)
              ) {
                defaultFilter =
                  filterType[indexType] === bigSmall.value
                    ? ['big', 'small', 'draw']
                    : filterType[indexType] === oddEven.value
                    ? ['odd', 'even']
                    : ['dragon', 'tiger'];
              } else {
                defaultFilter =
                  filterType[indexType] === bigSmall.value
                    ? ['big', 'small']
                    : filterType[indexType] === oddEven.value
                    ? ['odd', 'even']
                    : ['dragon', 'tiger'];
              }
              break;
            default:
              defaultFilter =
                filterType[indexType] === bigSmall.value
                  ? ['big', 'small']
                  : filterType[indexType] === oddEven.value
                  ? ['odd', 'even']
                  : ['dragon', 'tiger', 'draw'];
              break;
          }
          table.push(
            <LuzhuTable
              key={filterBall[indexBall] + filterType[indexType]}
              groupDuplicateArray={groupDuplicateArray}
              defaultFilter={defaultFilter}
              filterType={filterType[indexType]}
              filterBall={filterBall[indexBall]}
              // luZhuBallNumber={luZhuBallNumber}
              group={group}
            />,
          );
        }
      }
    }
  }
  return table;
};

const TrendsResultWrapper = styled.div`
  background-color: ${color.white};
`;

import React from 'react';

import { Drawer as AntdDrawer } from 'antd';

import withLocation from '@helpers/hoc/withLocation';

const Drawer = ({ children, location, visible, onClose, style }) => {
  const { pathname } = location;

  const bodyCSS =
    pathname === '/app/trends'
      ? {
          padding: 0,
          maxHeight: '70vh',
        }
      : {
          padding: 0,
        };

  return (
    <AntdDrawer
      style={
        style ? style : { top: pathname === '/app/trends' ? '90px' : '88px' }
      }
      height="auto"
      placement="top"
      closable={false}
      visible={visible}
      onClose={onClose}
      destroyOnClose={true}
      bodyStyle={bodyCSS}
      zIndex={pathname === '/app/trends' ? 5 : 3}>
      {children}
    </AntdDrawer>
  );
};

export default withLocation(Drawer);

import {
  sumOfNumber,
  getTotalOddEven,
  getTotalBigSmall,
  findDragonTigerThousandDigit,
  formatOpenTime,
  bigSmall,
  oddEven,
  arrayStringToArrayInt,
  findShunZi,
  findK3Pattern,
  getNumberSpan,
} from '@helpers/utils';
import { shunZhi } from '@const/playRule';
import { constPlayGroup } from '@const/game';

const {
  SSC,
  PK10,
  XY10,
  D11,
  K3,
  KL10F,
  PCDD,
  THREED,
  QXC,
  MAX3D,
} = constPlayGroup;

const { firstThree, middleThree, lastThree } = shunZhi;

const getTableData = (singleGame, group, isMobile, activeTab) => {
  let dataSourceArray = [];
  let arrayNumbers = [];

  if (Array.isArray(singleGame) && singleGame.length) {
    switch (group) {
      case SSC:
        if (isMobile) {
          dataSourceArray.push(
            singleGame.map(
              ({ openTime, uniqueIssueNumber, openCode }, index) => {
                arrayNumbers = arrayStringToArrayInt(openCode);
                const stringIssueNumber = uniqueIssueNumber.toString();
                const shortenIssueNumber =
                  stringIssueNumber.length > 4
                    ? stringIssueNumber.slice(stringIssueNumber.length - 4)
                    : stringIssueNumber;
                const obj = {
                  key: index + '' + uniqueIssueNumber,
                  期数: shortenIssueNumber,
                  总和: sumOfNumber(arrayNumbers),
                  总和OddEven: getTotalOddEven(arrayNumbers),
                  总和BigSmall: getTotalBigSmall(arrayNumbers, group),
                  龙虎: findDragonTigerThousandDigit(
                    arrayNumbers[0],
                    arrayNumbers[4],
                  ),
                  显示大小: arrayNumbers,
                  显示单双: arrayNumbers,
                  显示号码: arrayNumbers,
                };
                return obj;
              },
            ),
          );
          dataSourceArray = dataSourceArray.flat();
        } else {
          dataSourceArray.push(
            singleGame.map(
              ({ openTime, uniqueIssueNumber, openCode }, index) => {
                arrayNumbers = arrayStringToArrayInt(openCode);
                const obj = {
                  key: index + '' + uniqueIssueNumber,
                  时间: formatOpenTime(openTime),
                  期数: uniqueIssueNumber,
                  显示号码: arrayNumbers,
                  总和: sumOfNumber(arrayNumbers),
                  总和OddEven: getTotalOddEven(arrayNumbers),
                  总和BigSmall: getTotalBigSmall(arrayNumbers, group),
                  龙虎: findDragonTigerThousandDigit(
                    arrayNumbers[0],
                    arrayNumbers[4],
                  ),
                };
                return obj;
              },
            ),
          );
          dataSourceArray = dataSourceArray.flat();
        }

        break;
      case D11:
        dataSourceArray.push(
          singleGame.map(({ openTime, uniqueIssueNumber, openCode }, index) => {
            arrayNumbers = arrayStringToArrayInt(openCode);
            let stringIssueNumber = uniqueIssueNumber.toString();
            if (isMobile) {
              stringIssueNumber =
                stringIssueNumber.length > 4
                  ? stringIssueNumber.slice(stringIssueNumber.length - 4)
                  : stringIssueNumber;
            }
            const obj = {
              key: index + '' + uniqueIssueNumber,
              时间: formatOpenTime(openTime),
              期数: stringIssueNumber,
              显示号码: arrayNumbers,
              总和: sumOfNumber(arrayNumbers),
              总和OddEven: getTotalOddEven(arrayNumbers),
              总和BigSmall: getTotalBigSmall(arrayNumbers, group),
              龙虎: findDragonTigerThousandDigit(
                arrayNumbers[0],
                arrayNumbers[4],
              ),
              前三: findShunZi(firstThree, arrayNumbers, group),
              中三: findShunZi(middleThree, arrayNumbers, group),
              后三: findShunZi(lastThree, arrayNumbers, group),
            };
            return obj;
          }),
        );
        dataSourceArray = dataSourceArray.flat();
        break;
      case PK10:
      case XY10:
        if (isMobile) {
          dataSourceArray.push(
            singleGame.map(
              ({ openTime, uniqueIssueNumber, openCode }, index) => {
                arrayNumbers = arrayStringToArrayInt(openCode);
                const stringIssueNumber = uniqueIssueNumber.toString();
                const shortenIssueNumber =
                  stringIssueNumber.length > 4
                    ? stringIssueNumber.slice(stringIssueNumber.length - 4)
                    : stringIssueNumber;
                const obj = {
                  key: index + '' + uniqueIssueNumber,
                  期数: shortenIssueNumber,
                  冠亚和: sumOfNumber([arrayNumbers[0], arrayNumbers[1]]),
                  冠亚和OddEven: getTotalOddEven([
                    arrayNumbers[0],
                    arrayNumbers[1],
                  ]),
                  冠亚和BigSmall: getTotalBigSmall(arrayNumbers, group),
                  龙虎: findDragonTigerThousandDigit(
                    arrayNumbers[0],
                    arrayNumbers[9],
                  ),
                  龙虎2: findDragonTigerThousandDigit(
                    arrayNumbers[1],
                    arrayNumbers[8],
                  ),
                  龙虎3: findDragonTigerThousandDigit(
                    arrayNumbers[2],
                    arrayNumbers[7],
                  ),
                  龙虎4: findDragonTigerThousandDigit(
                    arrayNumbers[3],
                    arrayNumbers[6],
                  ),
                  龙虎5: findDragonTigerThousandDigit(
                    arrayNumbers[4],
                    arrayNumbers[5],
                  ),
                  显示大小: arrayNumbers,
                  显示单双: arrayNumbers,
                  显示号码: arrayNumbers,
                };
                return obj;
              },
            ),
          );
          dataSourceArray = dataSourceArray.flat();
        } else {
          dataSourceArray.push(
            singleGame.map(
              ({ openTime, uniqueIssueNumber, openCode }, index) => {
                arrayNumbers = arrayStringToArrayInt(openCode);
                const obj = {
                  key: index + '' + uniqueIssueNumber,
                  时间: formatOpenTime(openTime),
                  期数: uniqueIssueNumber,
                  显示号码: arrayNumbers,
                  冠亚和: sumOfNumber([arrayNumbers[0], arrayNumbers[1]]),
                  冠亚和OddEven: getTotalOddEven([
                    arrayNumbers[0],
                    arrayNumbers[1],
                  ]),
                  冠亚和BigSmall: getTotalBigSmall(arrayNumbers, group),
                  龙虎: findDragonTigerThousandDigit(
                    arrayNumbers[0],
                    arrayNumbers[9],
                  ),
                  龙虎2: findDragonTigerThousandDigit(
                    arrayNumbers[1],
                    arrayNumbers[8],
                  ),
                  龙虎3: findDragonTigerThousandDigit(
                    arrayNumbers[2],
                    arrayNumbers[7],
                  ),
                  龙虎4: findDragonTigerThousandDigit(
                    arrayNumbers[3],
                    arrayNumbers[6],
                  ),
                  龙虎5: findDragonTigerThousandDigit(
                    arrayNumbers[4],
                    arrayNumbers[5],
                  ),
                };
                return obj;
              },
            ),
          );
          dataSourceArray = dataSourceArray.flat();
        }
        break;
      case K3:
        dataSourceArray.push(
          singleGame.map(({ openTime, uniqueIssueNumber, openCode }, index) => {
            arrayNumbers = arrayStringToArrayInt(openCode);
            let stringIssueNumber = uniqueIssueNumber.toString();
            if (isMobile) {
              stringIssueNumber =
                stringIssueNumber.length > 4
                  ? stringIssueNumber.slice(stringIssueNumber.length - 4)
                  : stringIssueNumber;
            }
            const obj = {
              key: index + '' + uniqueIssueNumber,
              时间: formatOpenTime(openTime),
              期数: stringIssueNumber,
              显示号码: arrayNumbers,
              总和: sumOfNumber(arrayNumbers),
              总和oddEven: getTotalOddEven(arrayNumbers),
              总和BigSmall: getTotalBigSmall(arrayNumbers, group),
              形态: findK3Pattern(arrayNumbers, group),
              跨: getNumberSpan(arrayNumbers[2], arrayNumbers[0]),
            };
            return obj;
          }),
        );
        dataSourceArray = dataSourceArray.flat();
        break;
      case KL10F:
        dataSourceArray.push(
          singleGame.map(({ openTime, uniqueIssueNumber, openCode }, index) => {
            arrayNumbers = arrayStringToArrayInt(openCode);
            const sortArrayNumbers = arrayNumbers.slice().sort((a, b) => a - b);
            let stringIssueNumber = uniqueIssueNumber.toString();
            if (isMobile) {
              stringIssueNumber =
                stringIssueNumber.length > 4
                  ? stringIssueNumber.slice(stringIssueNumber.length - 4)
                  : stringIssueNumber;
            }
            const obj = {
              key: index + '' + uniqueIssueNumber,
              时间: formatOpenTime(openTime),
              期数: stringIssueNumber,
              显示号码: arrayNumbers,
              总和: sumOfNumber(arrayNumbers),
              总和OddEven: getTotalOddEven(arrayNumbers),
              总和BigSmall: getTotalBigSmall(arrayNumbers, group),
              跨: getNumberSpan(sortArrayNumbers[7], sortArrayNumbers[0]),
            };
            return obj;
          }),
        );
        dataSourceArray = dataSourceArray.flat();
        break;
      case PCDD:
        dataSourceArray.push(
          singleGame.map(({ openTime, uniqueIssueNumber, openCode }, index) => {
            arrayNumbers = arrayStringToArrayInt(openCode);
            let stringIssueNumber = uniqueIssueNumber.toString();
            if (isMobile) {
              stringIssueNumber =
                stringIssueNumber.length > 4
                  ? stringIssueNumber.slice(stringIssueNumber.length - 4)
                  : stringIssueNumber;
            }
            const obj = {
              key: index + '' + uniqueIssueNumber,
              时间: formatOpenTime(openTime),
              期数: stringIssueNumber,
              开奖号码: arrayNumbers,
              总和: sumOfNumber(arrayNumbers),
              大小: getTotalBigSmall(arrayNumbers, group),
              单双: getTotalOddEven(arrayNumbers),
              极值: sumOfNumber(arrayNumbers),
              波色: sumOfNumber(arrayNumbers),
              形态: findShunZi(firstThree, arrayNumbers, group),
            };
            return obj;
          }),
        );
        dataSourceArray = dataSourceArray.flat();
        break;
      case THREED:
        dataSourceArray.push(
          singleGame.map(({ openTime, uniqueIssueNumber, openCode }, index) => {
            arrayNumbers = arrayStringToArrayInt(openCode);
            const lunPanBai = arrayNumbers[0] * 100;
            const lunPanShi = arrayNumbers[1] * 10;
            const lunPan = (lunPanBai + lunPanShi + arrayNumbers[2]) % 37;
            let stringIssueNumber = uniqueIssueNumber.toString();
            if (isMobile) {
              stringIssueNumber =
                stringIssueNumber.length > 4
                  ? stringIssueNumber.slice(stringIssueNumber.length - 4)
                  : stringIssueNumber;
            }
            const obj = {
              key: index + '' + uniqueIssueNumber,
              时间: formatOpenTime(openTime),
              期数: stringIssueNumber,
              显示号码: arrayNumbers,
              佰拾和Total: sumOfNumber([arrayNumbers[0], arrayNumbers[1]]),
              佰拾和OddEven: getTotalOddEven([
                arrayNumbers[0],
                arrayNumbers[1],
              ]),
              拾个和Total: sumOfNumber([arrayNumbers[1], arrayNumbers[2]]),
              拾个和OddEven: getTotalOddEven([
                arrayNumbers[1],
                arrayNumbers[2],
              ]),
              总和Total: sumOfNumber(arrayNumbers),
              总和OddEven: getTotalOddEven(arrayNumbers),
              轮盘: lunPan,
              轮盘BigSmall: bigSmall(lunPan, 18) ? '大' : '小',
              轮盘OddEven: oddEven(lunPan) ? '单' : '双',
            };
            return obj;
          }),
        );
        dataSourceArray = dataSourceArray.flat();
        break;
      case QXC:
        dataSourceArray.push(
          singleGame.map(({ openTime, uniqueIssueNumber, openCode }, index) => {
            arrayNumbers = arrayStringToArrayInt(openCode);
            let stringIssueNumber = uniqueIssueNumber.toString();
            if (isMobile) {
              stringIssueNumber =
                stringIssueNumber.length > 4
                  ? stringIssueNumber.slice(stringIssueNumber.length - 4)
                  : stringIssueNumber;
            }
            const obj = {
              key: index + '' + uniqueIssueNumber,
              时间: formatOpenTime(openTime),
              期数: stringIssueNumber,
              显示号码: arrayNumbers,
              总和: sumOfNumber(arrayNumbers),
              总和OddEven: getTotalOddEven(arrayNumbers),
              总和BigSmall: getTotalBigSmall(arrayNumbers, group),
              前三: sumOfNumber([
                arrayNumbers[0],
                arrayNumbers[1],
                arrayNumbers[2],
              ]),
              前三OddEven: getTotalOddEven([
                arrayNumbers[0],
                arrayNumbers[1],
                arrayNumbers[2],
              ]),
              前三BigSmall: bigSmall(
                sumOfNumber([
                  arrayNumbers[0],
                  arrayNumbers[1],
                  arrayNumbers[2],
                ]),
                13,
              )
                ? '大'
                : '小',
              后三: sumOfNumber([
                arrayNumbers[1],
                arrayNumbers[2],
                arrayNumbers[3],
              ]),
              后三OddEven: getTotalOddEven([
                arrayNumbers[1],
                arrayNumbers[2],
                arrayNumbers[3],
              ]),
              后三BigSmall: bigSmall(
                sumOfNumber([
                  arrayNumbers[1],
                  arrayNumbers[2],
                  arrayNumbers[3],
                ]),
                13,
              )
                ? '大'
                : '小',
            };
            return obj;
          }),
        );
        dataSourceArray = dataSourceArray.flat();
        break;
      case MAX3D:
        if (isMobile) {
          dataSourceArray.push(
            singleGame.map(
              ({ openTime, uniqueIssueNumber, openCode }, index) => {
                arrayNumbers = openCode.split(',');

                let numFunc = (num) => Number(num);

                const firstPrize = Array.from(String(arrayNumbers[0]), numFunc);
                const secondPrize = Array.from(
                  String(arrayNumbers[1]),
                  numFunc,
                );
                const combineFirstSecondPrize = firstPrize.concat(secondPrize);

                const stringIssueNumber = uniqueIssueNumber.toString();
                const shortenIssueNumber =
                  stringIssueNumber.length > 4
                    ? stringIssueNumber.slice(stringIssueNumber.length - 4)
                    : stringIssueNumber;
                const obj = {
                  key: index + '' + uniqueIssueNumber,
                  期数: shortenIssueNumber,
                  总和: sumOfNumber(combineFirstSecondPrize),
                  百位: firstPrize[0] + secondPrize[0],
                  十位: firstPrize[1] + secondPrize[1],
                  各位: firstPrize[2] + secondPrize[2],
                  显示号码: combineFirstSecondPrize,
                  显示大小: combineFirstSecondPrize,
                  显示单双: combineFirstSecondPrize,
                };
                return obj;
              },
            ),
          );
          dataSourceArray = dataSourceArray.flat();
        } else {
          dataSourceArray.push(
            singleGame.map(
              ({ openTime, uniqueIssueNumber, openCode }, index) => {
                arrayNumbers = openCode.split(',');

                let numFunc = (num) => Number(num);

                const firstPrize = Array.from(String(arrayNumbers[0]), numFunc);
                const secondPrize = Array.from(
                  String(arrayNumbers[1]),
                  numFunc,
                );
                const combineFirstSecondPrize = firstPrize.concat(secondPrize);

                const obj = {
                  key: index + '' + uniqueIssueNumber,
                  时间: formatOpenTime(openTime),
                  期数: uniqueIssueNumber,
                  总和: sumOfNumber(combineFirstSecondPrize),
                  百位: firstPrize[0] + secondPrize[0],
                  十位: firstPrize[1] + secondPrize[1],
                  各位: firstPrize[2] + secondPrize[2],
                  显示号码: combineFirstSecondPrize,
                  显示大小: combineFirstSecondPrize,
                  显示单双: combineFirstSecondPrize,
                };
                return obj;
              },
            ),
          );
          dataSourceArray = dataSourceArray.flat();
        }
        break;
      default:
        break;
    }
  }

  return dataSourceArray;
};

export default getTableData;

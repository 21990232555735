import React, { useRef, useState } from 'react';
import styled from 'styled-components';
import { Table } from 'antd';
import { color } from '@helpers/const';
import { transl } from '@helpers/utils';

import isMobile from '@helpers/hoc/isMobile';
import withLocation from '@helpers/hoc/withLocation';

import '@styles/components/table.less';

const paginationSetting = {
  position: ['bottomRight'],
  defaultPageSize: 30,
  pageSizeOptions: ['30', '60', '120', '150'],
};

const TrendsTable = ({
  location,
  isMobile,
  className,
  tableHeader,
  tableData,
  scrollAble,
  fixHeader,
  headerHight,
  fixSubTable = false,
  pagination = false,
  translHeaderFull = false,
  search,
}) => {
  const { pathname } = location;
  const { language } = search;

  const elementRef = useRef(0);
  const [offSetTop, setOffSetTop] = useState(0);

  setTimeout(function () {
    if (pathname !== '/app/trends' && isMobile && elementRef.current) {
      if (elementRef.current.offsetTop !== (headerHight ? headerHight : 31)) {
        const divElement = elementRef.current.offsetTop;
        setOffSetTop(divElement + 44 + (headerHight ? headerHight : 31));
      }
    }
  }, 200);

  if (language === 'en-US' || language === 'vi-VN') {
    for (let index = 0; index < tableHeader.length; index++) {
      const tH = tableHeader[index];
      const tHClass = tH.className
        ? tH.className.includes('fullTransl')
        : false;
      tH.title =
        translHeaderFull || tHClass
          ? transl(tableHeader[index].title, [], false, false, 'en-US')
          : transl(tableHeader[index].title, [], true, false, 'en-US');
      if (tableHeader[index].children) {
        for (
          let innerIndex = 0;
          innerIndex < tableHeader[index].children.length;
          innerIndex++
        ) {
          const c1st = tableHeader[index].children[innerIndex];
          const c1stClass = c1st.className
            ? c1st.className.includes('fullTransl')
            : false;
          c1st.title =
            translHeaderFull || c1stClass
              ? transl(
                  tableHeader[index].children[innerIndex].title,
                  [],
                  false,
                  false,
                  'en-US',
                )
              : transl(
                  tableHeader[index].children[innerIndex].title,
                  [],
                  true,
                  false,
                  'en-US',
                );
          if (tableHeader[index].children[innerIndex].children) {
            for (
              let inner3rdIndex = 0;
              inner3rdIndex <
              tableHeader[index].children[innerIndex].children.length;
              inner3rdIndex++
            ) {
              const c3rd =
                tableHeader[index].children[innerIndex].children[inner3rdIndex];
              const c3rdClass = c3rd.className
                ? c3rd.className.includes('fullTransl')
                : false;
              tableHeader[index].children[innerIndex].children[
                inner3rdIndex
              ].title =
                translHeaderFull || c3rdClass
                  ? transl(
                      tableHeader[index].children[innerIndex].children[
                        inner3rdIndex
                      ].title,
                      [],
                      false,
                      false,
                      'en-US',
                    )
                  : transl(
                      tableHeader[index].children[innerIndex].children[
                        inner3rdIndex
                      ].title,
                      [],
                      true,
                      false,
                      'en-US',
                    );
              if (
                tableHeader[index].children[innerIndex].children[inner3rdIndex]
                  .children
              ) {
                for (
                  let inner4thIndex = 0;
                  inner4thIndex <
                  tableHeader[index].children[innerIndex].children[
                    inner3rdIndex
                  ].children.length;
                  inner4thIndex++
                ) {
                  const c4rd =
                    tableHeader[index].children[innerIndex].children[
                      inner3rdIndex
                    ].children[inner4thIndex];
                  const c4rdClass = c4rd.className
                    ? c4rd.className.includes('fullTransl')
                    : false;
                  c4rd.title =
                    translHeaderFull || c4rdClass
                      ? transl(
                          tableHeader[index].children[innerIndex].children[
                            inner3rdIndex
                          ].children[inner4thIndex].title,
                          [],
                          false,
                          false,
                          'en-US',
                        )
                      : transl(
                          tableHeader[index].children[innerIndex].children[
                            inner3rdIndex
                          ].children[inner4thIndex].title,
                          [],
                          true,
                          false,
                          'en-US',
                        );

                  if (
                    tableHeader[index].children[innerIndex].children[
                      inner3rdIndex
                    ].children[inner4thIndex].children
                  ) {
                    for (
                      let inner5thIndex = 0;
                      inner5thIndex <
                      tableHeader[index].children[innerIndex].children[
                        inner3rdIndex
                      ].children[inner4thIndex].children.length;
                      inner5thIndex++
                    ) {
                      tableHeader[index].children[innerIndex].children[
                        inner3rdIndex
                      ].children[inner4thIndex].children[
                        inner5thIndex
                      ].title = translHeaderFull
                        ? transl(
                            tableHeader[index].children[innerIndex].children[
                              inner3rdIndex
                            ].children[inner4thIndex].children[inner5thIndex]
                              .title,
                            [],
                            false,
                            false,
                            'en-US',
                          )
                        : transl(
                            tableHeader[index].children[innerIndex].children[
                              inner3rdIndex
                            ].children[inner4thIndex].children[inner5thIndex]
                              .title,
                            [],
                            true,
                            false,
                            'en-US',
                          );
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }

  return (
    <div ref={fixHeader ? elementRef : null}>
      <TableWrapper
        dataSource={tableData}
        columns={tableHeader}
        pagination={pagination && paginationSetting}
        className={className}
        bordered={true}
        locale={{ emptyText: transl('暂无数据') }}
        showSorterTooltip={false}
        tableLayout={scrollAble ? 'fixed' : isMobile ? 'auto' : 'fixed'}
        scroll={
          isMobile && offSetTop
            ? { y: 'calc(100vh - ' + offSetTop + 'px)', x: 'auto' }
            : isMobile && fixSubTable
            ? { y: '20vh', x: 'auto' }
            : {}
        }
      />
    </div>
  );
};

export const SummarizeTable = withLocation(isMobile(TrendsTable));

const TableWrapper = styled(Table)`
  width: 100%;
  // overflow-x: auto;
  transition: all 0.2s liner;

  .ant-table-content {
    overflow-x: auto;

    &::-webkit-scrollbar {
      width: 8px;
      height: 8px;
    }

    /* Track */
    &::-webkit-scrollbar-track {
      background: #f1f1f1;
    }

    /* Handle */
    &::-webkit-scrollbar-thumb {
      background: #cdcdcd;
    }

    /* Handle on hover */
    &::-webkit-scrollbar-thumb:hover {
      background: #797979;
    }

    &::-webkit-scrollbar-button {
      width: 0;
      height: 0;
      display: none;
    }

    scrollbar-width: thin;
  }

  table {
    tr {
      &[aria-hidden='true'] {
        visibility: collapse;
      }

      &:nth-child(even) {
        background-color: ${color.backgroundGrey};
      }

      th {
        font-size: 15px;
        font-weight: 600;
        line-height: 20px;
        padding: 7px 0;
        background-color: ${color.backgroundGrey};

        &.ant-table-column-sort {
          background-color: ${color.backgroundGrey};
        }

        &.max3d {
          padding: 5px;
        }

        @media only screen and (max-width: 576px) {
          font-size: 14px;
          font-weight: 400;
          color: ${color.white};
          background-color: ${color.orange};

          b {
            font-weight: 400;
          }

          &.empty {
            display: none;
          }

          &.max3d {
            padding: 0;
          }
        }
      }

      td {
        line-height: 13px;
        padding: 9px 0 10px;
        overflow: hidden;

        &.ant-table-cell-fix-left,
        &.ant-table-cell-fix-right,
        &.ant-table-column-sort {
          background-color: unset;
        }

        @media only screen and (max-width: 576px) {
          color: ${color.mobileBlack};
        }

        .ant-empty {
          margin: 0;
          display: flex;
          justify-content: center;

          .ant-empty-image {
            display: none;
          }

          .ant-empty-description {
            line-height: 1;
            font-size: 13px;
          }
        }

        .table-number {
          div {
            font-size: 18px;
            width: 30px;
            height: 30px;
            line-height: 31px;
            margin: 0 3px;

            @media only screen and (max-width: 576px) {
              font-size: 13px;
              width: 20px;
              height: 20px;
              line-height: 20px;

              &.number-box {
                width: 18px;
                height: 18px;
                margin: 0 1.5px;
              }
            }
          }
        }

        .big,
        .small,
        .odd,
        .even {
          font-size: 16px;
          width: 30px;
          height: 30px;
          line-height: 30px;
          color: ${color.white};
          border-radius: 50%;
          margin: 0 3px;

          @media only screen and (max-width: 576px) {
            font-size: 13px;
            font-weight: 600;
            width: 20px;
            height: 20px;
            line-height: 20px;

            &.PK10,
            &.XY10 {
              width: 18px;
              height: 18px;
              margin: 0 1.5px;
              font-size: 10px;
              line-height: 18px;
            }
          }
        }

        .big,
        .even {
          background-color: ${color.red};
        }

        .small,
        .odd {
          background-color: ${color.blue};
        }

        .blue-text {
          color: ${color.blue};
        }

        .red-text {
          color: ${color.red};
        }

        .green-text {
          color: ${color.green};
        }

        .PK10,
        .XY10 {
          border-radius: 3px;
        }

        .result-row-MAX3D {
          > .ant-col {
            &:nth-child(3) {
              margin-right: 15px;

              @media only screen and (max-width: 576px) {
                margin-right: 5px;
              }
            }
          }
        }
      }

      th,
      td {
        text-align: center;
        border-color: ${color.bodyGrey};

        @media only screen and (max-width: 576px) {
          padding: 0 2px;
          font-size: 13px;
          line-height: 16px;
          height: 29px;
        }
      }

      &:hover {
        td.ant-table-cell {
          background-color: none;
        }
      }

      &.ant-table-placeholder {
        td {
          color: #999999;
        }
      }
    }

    &.haveTabs {
      tr {
        th {
          background-color: ${color.white};

          &.tab {
            cursor: pointer;
            background-color: ${color.backgroundGrey};

            &.active {
              color: ${color.orange};
              border-color: ${color.orange};
              background-color: ${color.white};
            }
          }
        }
      }
    }
  }

  @media only screen and (max-width: 576px) {
    // &.ant-table-wrapper {
    //     height: 100%;

    //     .ant-spin-nested-loading {
    //       height: 100%;

    //       .ant-spin-container {
    //         height: 100%;
    //         display: flex;
    //         flex-flow: column nowrap;

    //         .ant-table {
    //           flex: auto;
    //           overflow: hidden;

    //           .ant-table-container {
    //             height: 100%;
    //             display: flex;
    //             flex-flow: column nowrap;

    //             .ant-table-thead {
    //               flex: none;
    //             }

    //             .ant-table-tbody {
    //               flex: auto;
    //               overflow: scroll;
    //             }
    //           }
    //         }

    //         .ant-table-pagination {
    //           flex: none;
    //         }
    //       }
    //     }
    //   }
  }

  .ant-pagination {
    .ant-pagination-options-size-changer.ant-select {
      margin-right: 0;
    }

    .ant-select-item-option {
      line-height: 30px;
    }

    .anticon {
      vertical-align: 0;
    }
  }

  .ant-table-thead {
    .ant-table-column-has-sorters {
      @media only screen and (max-width: 576px) {
        background-color: ${color.orange};
      }

      .ant-table-column-sorters {
        padding: 0;

        .ant-table-column-sorter-full {
          margin-top: -6px;
        }

        .ant-table-column-sorter {
          margin-top: -4px;
          margin-bottom: unset;
          margin-left: 5px;

          @media only screen and (max-width: 576px) {
            display: flex;
            margin: 0;
          }

          .ant-table-column-sorter-up,
          .ant-table-column-sorter-down {
            &.active {
              color: ${color.orange};

              @media only screen and (max-width: 576px) {
                color: ${color.white};
              }
            }
          }
        }
      }

      @media only screen and (max-width: 576px) {
        &:hover {
          background-color: ${color.orange};
        }
      }
    }
  }
`;

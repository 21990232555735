import React, { useState } from 'react';
import styled from 'styled-components';
import { Link } from 'gatsby';
import { toJS } from 'mobx';
import { inject, observer } from 'mobx-react';
import { Menu, Row, Col, Drawer } from 'antd';

import { color } from '@helpers/const';
import { menu, URLS } from '@const/config';

import { PaddingContainer } from '@helpers/hoc/withPaddingWidth';

import logoImg from '@images/logos/lcw_logo.png';

import { Images } from '@components/common/images';

import withLocation from '@helpers/hoc/withLocation';

import '@styles/components/menu.less';

import { generateQueryString, parseQueryString } from '@helpers/utils';

const { SubMenu } = Menu;

const getGameMenu = (popularGame, allGames, location) => {
  return typeof allGames === 'object'
    ? toJS(allGames).map((game, index) => {
        const { gameUniqueId, gameNameInChinese, playGroup } = game;
        let url;
        if (gameUniqueId.startsWith('HF_') === popularGame) {
          if (playGroup === 'XPK') {
            url = `${URLS.trends}?${generateQueryString({
              ...parseQueryString(location.search),
              searchGame: gameUniqueId,
              group: playGroup,
              rule: 'pkZhouShi',
            })}`;
          } else {
            url = `${URLS.trends}?${generateQueryString({
              ...parseQueryString(location.search),
              searchGame: gameUniqueId,
              group: playGroup,
              rule: '',
            })}`;
          }
          return (
            <Menu.Item key={index}>
              <Link className="menu-item" to={url}>
                {gameNameInChinese}
              </Link>
            </Menu.Item>
          );
        } else {
          return '';
        }
      })
    : '';
};

const Header = ({ rootStore, location }) => {
  const [visible, setVisible] = useState(false);
  const [subMenuArray, setSubMenuArray] = useState([]);

  const showDrawer = () => {
    setVisible(true);
  };

  const onClose = () => {
    setVisible(false);
  };

  const onTriggerSubMenu = (openKeys) => {
    if (subMenuArray.indexOf(openKeys)) {
      setSubMenuArray(openKeys);
    }
  };

  const { allGames } = rootStore;

  return (
    <HeaderWrapper>
      <HeaderInnerWrapper>
        <PaddingContainer className="header">
          <Row align="bottom">
            <Col flex="145px">
              <div style={{ marginBottom: '19.4px' }}>
                <Link className="logo" to={URLS.home}>
                  <img src={logoImg} alt="乐彩网 Logo" />
                </Link>
              </div>
            </Col>
            <Col flex="auto">
              <HeaderNavTabWrap
                selectedKeys={[
                  location.pathname,
                  location.pathname + location.search,
                ]}
                mode="horizontal">
                {menu.map(({ text, baseUrl, url }) => {
                  const menuButton = url ? (
                    <Menu.Item key={baseUrl}>
                      <Link className="menu-item" to={url}>
                        {text}
                      </Link>
                    </Menu.Item>
                  ) : (
                    <SubMenu
                      popupClassName="sub-menu-wrapper"
                      key={text}
                      title={text}>
                      <Menu.ItemGroup title="高频彩">
                        {getGameMenu(true, allGames, location)}
                      </Menu.ItemGroup>
                      <Menu.ItemGroup title="低频彩" className="low-class">
                        {getGameMenu(false, allGames, location)}
                      </Menu.ItemGroup>
                    </SubMenu>
                  );

                  return menuButton;
                })}
              </HeaderNavTabWrap>
              <HeaderMobileNavWrapper>
                <HeaderMobileHamburger
                  onClick={showDrawer}
                  className={visible ? 'open' : ''}>
                  <span className="line line1"></span>
                  <span className="line line2"></span>
                  <span className="line line3"></span>
                </HeaderMobileHamburger>
                <Drawer
                  className="mobile-drawer"
                  placement="right"
                  closable={false}
                  onClose={onClose}
                  visible={visible}
                  width="90%"
                  drawerStyle={{
                    backgroundColor: color.orange,
                  }}
                  bodyStyle={{
                    padding: '20px 0',
                  }}>
                  <Menu
                    mode="inline"
                    selectedKeys={[
                      location.pathname,
                      location.pathname + location.search,
                    ]}
                    openKeys={subMenuArray}
                    onOpenChange={onTriggerSubMenu}
                    onClick={onClose}>
                    {menu.map(({ text, baseUrl, url }) => {
                      const menuButton = url ? (
                        <Menu.Item key={baseUrl}>
                          <Link className="menu-item" to={url}>
                            {text}
                          </Link>
                        </Menu.Item>
                      ) : (
                        <SubMenu
                          popupClassName="sub-menu-wrapper"
                          key={text}
                          title={text}>
                          <Menu.ItemGroup title="高频彩">
                            {getGameMenu(true, allGames, location)}
                          </Menu.ItemGroup>
                          <Menu.ItemGroup title="低频彩" className="low-class">
                            {getGameMenu(false, allGames, location)}
                          </Menu.ItemGroup>
                        </SubMenu>
                      );
                      return menuButton;
                    })}
                  </Menu>
                </Drawer>
              </HeaderMobileNavWrapper>
            </Col>
            <Col>
              {/* <HeaderLinkWrap>
                                <span>设为首页</span> | <span>收藏本站</span>
                            </HeaderLinkWrap> */}
            </Col>
          </Row>
        </PaddingContainer>
      </HeaderInnerWrapper>
    </HeaderWrapper>
  );
};

export default withLocation(inject('rootStore', 'homeStore')(observer(Header)));

const HeaderWrapper = styled.header`
  padding-bottom: 90px;
`;

const HeaderInnerWrapper = styled.div`
  position: fixed;
  width: 100%;
  height: 90px;
  background-color: ${color.orange};
  z-index: 99;

  @media only screen and (max-width: 825px) {
    .header {
      padding: 0 20px;
    }
  }
`;

const HeaderNavTabWrap = styled(Menu)`
    margin-left: 86px;
    margin-top: 48px;
    vertical-align: bottom;
    line-height: unset;
    border-bottom: none;
    background-color: ${color.orange};

    @media only screen and (max-width: 825px) {
        display:none;
    }

    &.ant-menu {
        >li.ant-menu-item,
        >li.ant-menu-submenu {
            font-size: 18px;
            border-bottom: none;
            margin-top: 0;
            margin-bottom: 14px;
            margin-left: 0;
            margin-right: 71px;
            top: 0;

            >a.menu-item,
            >div {
                color: ${color.white};

                &:hover {
                    color: ${color.yellow};
                }
            }
    
            &.ant-menu-item-selected {
                &:before {
                    content: '';
                    position absolute;
                    bottom: -14px;
                    left: 50%;
                    transform: translateX(-50%);
                    height: 5px;
                    width: 36px;
                    background: ${color.yellow};
                }

                >a.menu-item {
                    color: ${color.yellow};
                }
            }

            &:hover {
                border-bottom: none;
            }
        }

        >li.ant-menu-submenu {
            padding-left: 20px;
            padding-right: 35px;
            padding-bottom: 14px;
            margin-right: 36px;
            margin-bottom: 0;
            margin-left: -20px;

            &:before {
                content: "";
                background-color: ${color.white};
                width: 100%;
                height: calc(100% + 15px);
                position: absolute;
                top: -15px;
                left: 0;
                opacity: 0;
                // max-height: 0;
                // transition: all 0.2s linear;
            }

            .ant-menu-submenu-title {
                &:after {
                    content: "";
                    width: 14px;
                    height: 9px;
                    position: absolute;
                    top: 11px;
                    right: -21px;
                    background: url(${Images.arrowDownWhite}) center/cover;
                    transition: all 0.2s linear;
                }
            }

            &.ant-menu-submenu-active {
              &:before {
                // max-height: 100px;
                opacity: 1;
              }

              .ant-menu-submenu-title {
                color: ${color.orange};

                &:after {
                    top: 11px;
                    right: -21px;
                    transform: rotate(180deg);
                    background: url(${Images.arrowDownOrange}) center/cover;
                  }
              }
            }
        }
    }
`;

const HeaderMobileNavWrapper = styled.div`
  display: none;
  width: fit-content;
  margin-bottom: 19.4px;
  margin-top: 49px;
  margin-left: auto;

  @media only screen and (max-width: 825px) {
    display: block;
  }
`;

const HeaderMobileHamburger = styled.div`
  position: relative;
  padding-bottom: 22px;
  width: 35px;

  .line {
    position: absolute;
    left: 0;
    width: 100%;
    height: 4px;
    background-color: ${color.white};
    border-radius: 5px;
    transition: all 0.2s linear;

    &.line1 {
      top: 0;
    }

    &.line2 {
      top: 9px;
    }

    &.line3 {
      bottom: 0;
    }
  }

  &.open {
    opacity: 0.3;
  }
`;

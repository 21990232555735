import React from 'react';
import styled from 'styled-components';
import { color } from '@helpers/const';
import { Carousel } from 'antd';

const BannerSlider = ({ banner }) => {
  const settings = {
    customPaging: function (i) {
      return <Dot></Dot>;
    },
    draggable: true,
    autoplay: true,
    pauseOnHover: false,
    autoplaySpeed: 5000,
  };

  return (
    <BannerWrapper>
      <Carousel {...settings}>
        {banner.map((data, index) => {
          return (
            <Banner key={index}>
              <img src={data} alt="HomeBanner" />
            </Banner>
          );
        })}
      </Carousel>
    </BannerWrapper>
  );
};

export default BannerSlider;

const BannerWrapper = styled.div`
    margin-top: -1px;
    margin-bottom: 20px;

    div {
        line-height: 0;
    }

    .slick-list,
    .slick-slide {
        cursor: default;
    }

    .ant-carousel .slick-dots {
        bottom: 15px;
        margin: 0;
        cursor: default;

        li {
            position: relative;
            width: auto;
            height: auto;
            margin-left: 0;
            margin-right: 15px;
            cursor: pointer;
    
            &.slick-active {
                div {
                    background-color: ${color.orange};
                }
    
                &:after {
                    content: '';
                    position: absolute;
                    width: 20px;
                    height: 20px;
                    top: 50%;
                    left: 50%;
                    transform: translate(-50%, -50%);
                    border: 1px solid ${color.orange};
                    border-radius: 50%;
                }
            }
        }
    }

        &:last-child {
            margin-right: 0;
        }
    }
`;

const Banner = styled.div`
  position: relative;
  // padding-bottom: 316px;
  width: auto;

  img {
    margin: auto;
    // position: absolute;
    // top: 50%;
    // left: 50%;
    // width: 100%;
    // height: 100%;
    // object-fit: cover;
    // transform: translate(-50%, -50%);
  }
`;

const Dot = styled.div`
  width: 12px;
  height: 12px;
  border-radius: 50%;
  background-color: ${color.white};
`;

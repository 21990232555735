import React from 'react';
import styled from 'styled-components';
import { Row, Col, Checkbox, Typography, Space } from 'antd';
import { inject, observer } from 'mobx-react';
import { toJS } from 'mobx';

import LcwButton from '@components/common/button';
import { TitleText } from '@components/common/text';
import { Images, MarkSixImage } from '@components/common/images';

import { color } from '@helpers/const';
import isMobile from '@helpers/hoc/isMobile';
import withLocation from '@helpers/hoc/withLocation';
import { transl } from '@helpers/utils';

import { constSixBall, arraySixAttribute, arrayElement } from '@const/playRule';

import Drawer from '@components/common/drawer';

const { Text } = Typography;

const FilterNumber = ({ trendsStore, sixBall, setOpenFilter, language }) => {
  return (
    <>
      <Row className="filter">
        <Col className="filter-title">{transl('选择号码：')}</Col>
        <Col>
          <Checkbox.Group
            value={sixBall}
            onChange={(props) => {
              trendsStore.setSixBall(props);
            }}>
            <Row>
              {constSixBall.map((label, index) => {
                return (
                  <Col key={label} className={'filter-checkbox ' + language}>
                    <Checkbox value={index}>{transl(label)}</Checkbox>
                  </Col>
                );
              })}
            </Row>
          </Checkbox.Group>
        </Col>
      </Row>
      <Row className="action mobile">
        <Col span={12}>
          <button
            onClick={() => {
              setOpenFilter([false, false, false]);
              trendsStore.setSixBall([]);
            }}>
            {transl('清空')}
          </button>
        </Col>
        <Col span={12}>
          <button
            className="confirm"
            onClick={() => setOpenFilter([false, false, false])}
            onKeyDown={() => setOpenFilter([false, false, false])}>
            {transl('确定')}
          </button>
        </Col>
      </Row>
    </>
  );
};

const AttributeOption = ({ trendsStore, sixAttribute }) => {
  return (
    <Row align="middle">
      <Col className="filter-title">{transl('选择属性:')}</Col>
      <Col>
        <Row gutter={[20]}>
          {arraySixAttribute.map((label, index) => {
            return (
              <Col key={label}>
                <LcwButton
                  text={transl(label)}
                  active={sixAttribute === label ? true : false}
                  activeOnClick={() => trendsStore.setSixAttribute(label)}
                />
              </Col>
            );
          })}
        </Row>
      </Col>
    </Row>
  );
};

const FilterAttribute = ({
  trendsStore,
  sixCriteria,
  sixAttribute,
  setOpenFilter,
  zodiacAnimal,
  isMobile,
}) => {
  let criteriaArray = [];
  if (sixAttribute === '生肖') {
    criteriaArray = zodiacAnimal;
  } else if (sixAttribute === '五行') {
    criteriaArray = arrayElement;
  }

  return (
    <>
      <Row className="filter">
        <Col className="filter-title">{transl('选择条件:')}</Col>
        <Col>
          <Checkbox.Group
            value={sixCriteria}
            onChange={(props) => {
              trendsStore.setSixCriteria(props);
            }}>
            <Row>
              {criteriaArray &&
                criteriaArray.map((label, index) => {
                  return (
                    <Col key={label} className="filter-checkbox">
                      <Checkbox value={label}>
                        {/* {transl(label)} */}
                        <div className="icon-wrapper">
                          <MarkSixImage name={label} border={true} />
                        </div>
                      </Checkbox>
                    </Col>
                  );
                })}
              {!isMobile && (
                <Col className="desktop">
                  <LcwButton
                    text={transl('清空')}
                    btnStyle={3}
                    activeOnClick={() => {
                      trendsStore.setSixCriteria([]);
                      trendsStore.setSixBall([]);
                    }}
                  />
                </Col>
              )}
            </Row>
          </Checkbox.Group>
        </Col>
      </Row>
      <Row className="action mobile">
        <Col span={12}>
          <button
            onClick={() => {
              setOpenFilter([false, false, false]);
              trendsStore.setSixCriteria([]);
            }}>
            {transl('清空')}
          </button>
        </Col>
        <Col span={12}>
          <button
            className="confirm"
            onClick={() => setOpenFilter([false, false, false])}>
            {transl('确定')}
          </button>
        </Col>
      </Row>
    </>
  );
};

const Filter = ({
  trendsStore,
  rootStore,
  isMobile,
  filterMode,
  setFilterMode,
  openFilter,
  setOpenFilter,
  isTrendsPage,
  search,
}) => {
  const { sixBall, sixAttribute, sixCriteria } = trendsStore;
  const { zodiacAnimal } = rootStore;
  const { language } = search;

  let mobileNumberFilterClass = isTrendsPage ? 'not-iframe' : '';
  if (language === 'en-US' || language === 'vi-VN')
    mobileNumberFilterClass = mobileNumberFilterClass + ' col2';
  return (
    <FilterWrapper className={isTrendsPage ? 'not-iframe' : ''}>
      <TitleWrapper>
        <TitleText text={transl('最新开奖记录')} />
      </TitleWrapper>
      <BodyWrapper>
        <div className="mobile">
          <Row>
            <Col flex={64}>
              <button
                className={
                  sixBall.length > 0 || sixCriteria.length > 0
                    ? 'bg-grey btn reset active'
                    : 'bg-grey btn reset'
                }
                onClick={() => {
                  trendsStore.setSixCriteria([]);
                  trendsStore.setSixBall([]);
                }}>
                {transl('还原')}
              </button>
            </Col>
            <Col flex={104}>
              <button
                className={
                  openFilter[0] ? 'bg-grey btn dropdown' : 'bg-grey btn'
                }
                onClick={() => setOpenFilter([!openFilter[0], false, false])}>
                <div className="btn-text">{transl('号码分布', [], true)}</div>
                <div className="expand-icon"></div>
              </button>
            </Col>
            <Col flex={103.5}>
              <button
                className={
                  sixAttribute === '生肖' && openFilter[1]
                    ? 'btn active dropdown'
                    : sixAttribute === '生肖'
                    ? 'btn active'
                    : 'btn'
                }
                onClick={(e) => {
                  sixAttribute !== '生肖'
                    ? trendsStore.setSixAttribute('生肖')
                    : e.preventDefault();
                  setOpenFilter([false, !openFilter[1], false]);
                }}>
                <div className="btn-text">{transl('生肖', [], true)}</div>
                <div className="expand-icon"></div>
              </button>
            </Col>
            <Col flex={103.5}>
              <button
                className={
                  sixAttribute === '五行' && openFilter[2]
                    ? 'btn active dropdown'
                    : sixAttribute === '五行'
                    ? 'btn active'
                    : 'btn'
                }
                onClick={(e) => {
                  sixAttribute !== '五行'
                    ? trendsStore.setSixAttribute('五行')
                    : e.preventDefault();
                  setOpenFilter([false, false, !openFilter[2]]);
                }}>
                <div className="btn-text">{transl('五行', [], true)}</div>
                <div className="expand-icon"></div>
              </button>
            </Col>
          </Row>
          {
            <Drawer
              visible={openFilter.includes(true)}
              onClose={() => setOpenFilter([false, false, false])}>
              {openFilter[0] ? (
                <FilterPopup className={mobileNumberFilterClass}>
                  <FilterNumber
                    trendsStore={trendsStore}
                    sixBall={toJS(sixBall)}
                    setOpenFilter={setOpenFilter}
                    language={language}
                  />
                </FilterPopup>
              ) : openFilter[1] || openFilter[2] ? (
                <FilterPopup className={isTrendsPage ? 'not-iframe' : ''}>
                  <FilterAttribute
                    trendsStore={trendsStore}
                    sixCriteria={toJS(sixCriteria)}
                    sixAttribute={toJS(sixAttribute)}
                    setOpenFilter={setOpenFilter}
                    isMobile={isMobile}
                    zodiacAnimal={zodiacAnimal}
                  />
                </FilterPopup>
              ) : (
                ''
              )}
            </Drawer>
          }
        </div>
        <div className="desktop">
          <div style={{ marginBottom: '15px' }}>
            <TitleText text={transl('号码分布')} fontSize="16" />
          </div>
          <Row>
            <Col>
              <FilterNumber trendsStore={trendsStore} sixBall={toJS(sixBall)} />
            </Col>
          </Row>
          <Row>
            <Col>
              <AttributeOption
                trendsStore={trendsStore}
                sixAttribute={toJS(sixAttribute)}
              />
            </Col>
          </Row>
          <Row>
            <Col>
              <FilterAttribute
                trendsStore={trendsStore}
                sixCriteria={toJS(sixCriteria)}
                sixAttribute={toJS(sixAttribute)}
                zodiacAnimal={toJS(zodiacAnimal)}
                isMobile={isMobile}
              />
            </Col>
          </Row>
          <Row className="desc">
            <Space direction="vertical">
              <Text strong>{transl('(1)总肖')}</Text>
              <Text>
                {transl(
                  '从2肖～7肖中选1个组成1注，所选投注组合与开奖号码对应的生肖总数相同，视为中奖。',
                )}
              </Text>
            </Space>
          </Row>
          <Row className="desc">
            <Space direction="vertical">
              <Text strong>{transl('(2)总肖单双')}</Text>
              <Text>
                {transl(
                  '当期开奖号码对应的生肖总数为单则为总肖单，生肖总数为双则为总肖双。',
                )}
              </Text>
            </Space>
          </Row>
        </div>
      </BodyWrapper>
    </FilterWrapper>
  );
};

export default isMobile(
  withLocation(inject('trendsStore', 'rootStore')(observer(Filter))),
);

const FilterWrapper = styled.div`
  @media only screen and (max-width: 576px) {
    width: 100%;
    background-color: white;

    &.not-iframe {
      position: relative;
      top: 0;
      z-index: 0;
    }
  }
`;

const TitleWrapper = styled.div`
  border-bottom: 1px solid ${color.borderGrey};
  text-align: center;
  padding: 11px 0;
  background-color: ${color.lightOrange};

  @media only screen and (max-width: 576px) {
    display: none;
  }
`;

const BodyWrapper = styled.div`
    padding: 19px 21px 10px 21px;

    button {
        color: ${color.mobileBlack};
        background-color: transparent;
        border: none;
        padding: 0;
        display: block;
        width: 100%;
        height: 100%;

        &:focus {
            outline: none;
        }
    }

    .filter {
        align-items: center;
    }

    .filter-title {
        margin-right 15px;
        color: ${color.lightFontGrey};

        @media only screen and (max-width: 576px) {
            margin-bottom: 13px;
        }
    }

    .mobile {
        display: none;
    }

    .desktop {
        .filter-title {
            color: ${color.lightFontGrey};
        }

        .filter-checkbox {
            margin-right 15px;

            label {
                display: flex;
                align-items: center;

                .icon-wrapper {
                    width: 36px;
                }

                .ant-checkbox + span {
                    padding-right: 6px;
                    padding-left: 6px;
                }
            }
        }

        >.ant-row {
            margin-bottom: 20px;

            &:last-child {
                margin-bottom: 0;
            }
        }
    }

    @media only screen and (max-width: 576px) {
        padding: 0;
        border-bottom: 1px solid ${color.borderGrey};

        .desktop {
            display: none;
        }

        .mobile {
            position: relative;
            display: block;
            font-size: 13px;
            background-color: ${color.white};
            border-bottom: 1px solid ${color.borderGrey};
            z-index: 4;

            .ant-row{
                display: flex;
            }

            .ant-col {
                &:first-child {
                    color: ${color.lightFontGrey};
                }

                &:not(:first-child) {
                    .btn {
                        display: flex;
                        justify-content: center;
                        position: relative;

                        .btn-text {
                            display: flex;
                            align-self: center;
                            margin-right: 5px;
                        }

                        .expand-icon {
                            background-image: url(${Images.icn_dropdown_grey});
                            width: 11px;
                            height: 7px;
                            display: flex;
                            align-self: center;
                        }

                        &.dropdown {
                            .expand-icon {
                                background-image: url(${Images.icn_dropdown_up_orange});
                            }
                        }
                    }
                }

                &:nth-child(3),
                &:nth-child(4) {
                    .btn {
                        border-right: none;

                        &:after{
                            left: 70%;
                        }
                    }
                }
            }

            .btn {
                white-space: break-spaces;
                color: ${color.mobileBlack}
                text-align: center;
                // padding: 10.5px 0;
                height: 44.5px;
                border-right: 1px solid ${color.borderGrey};

                &.active {
                    color: ${color.orange};
                }
            }

            .bg-grey {
                background-color: ${color.backgroundGrey};

                &.reset {
                    color: ${color.lightFontGrey};
                }

                &.active {
                    color: ${color.mobileBlack}
                }
            }
        }
    }
`;

const FilterPopup = styled.div`
    // position: absolute;
    // width: 100%;
    // top: 44px;
    // left: 0;
    // background-color: ${color.white};
    z-index: 2;

    &.not-iframe {
        position: relative;
        top:0;
    }

    .filter {
        padding: 13px 10px 0 25px;
        
        .filter-title {
            margin-bottom: 15px;
        }

        >.ant-col {
            width: 100%;

            .ant-checkbox-group {
                width: 100%;
                
                .filter-checkbox {
                    flex: 0 0 33.33333333%;
                    max-width: 33.33333333%;
                    margin-bottom: 18px;

                    &.en-US, 
                    &.vi-VN {
                        flex: 0 0 50%;
                        max-width: 50%;
                    }

                    span {
                        font-size: 14px;
                        color: ${color.mobileBlack};
                    }

                    label {
                        display: flex;
                        align-items: center;

                        .icon-wrapper {
                            width: 43px;
                        }
                    }
                }    
            }
        }
    }

    .action {
        .ant-col {
            text-align: center;
            border-top: 1px solid ${color.borderGrey};
            border-right: 1px solid ${color.borderGrey};

            &:nth-child(2) {
                border-right: none;
                color: ${color.orange};
            }
        }

        .confirm {
            color: ${color.orange};
        }
    }
`;

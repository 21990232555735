import React, { useState } from 'react';
import Filter from './Filter';
import MainTable from './MainTable';
import withLocation from '@helpers/hoc/withLocation';
import isMobile from '@helpers/hoc/isMobile';

import PlayRuleTitle from '@components/common/playRuleTitle';

import Reference from '@components/common/reference';

const PKZhouShi = ({ isMobile }) => {
  const [filter, setFilter] = useState(['count', 'line']);
  const [ball, setBall] = useState(0);
  const [openFilter, setOpenFilter] = useState(false);

  let options = [
    { label: '遗漏', value: 'count' },
    { label: '遗漏分成', value: 'hightLightCount' },
    { label: '分隔线', value: 'section' },
  ];

  const content = [
    'Dr. - Draw Number',
    'Winner No. - Winner Number',
    'L - Loose',
    'SZ - Straight',
    'F - Flush',
    'SF - Straight flush',
    'DZ - Pair',
    'BZ - Leopard',
    'Appear Times - Number of appear times',
    'Avg N/O Streak - Average no open streak count',
    'Lgt N/O Streak - Longest no open streak count',
    'Lgt Streak - Longest open streak',
  ];

  return (
    <>
      {!isMobile && <PlayRuleTitle title="扑克走势" />}
      <Filter
        filter={filter}
        setFilter={setFilter}
        options={options}
        ball={ball}
        setBall={setBall}
        openFilter={openFilter}
        setOpenFilter={setOpenFilter}
      />
      <Reference content={content} sticky={true} />
      <MainTable filter={filter} isMobile={isMobile} ball={ball} />
    </>
  );
};

export default isMobile(withLocation(PKZhouShi));

import React from 'react';
import styled from 'styled-components';

import { SummarizeTable } from '@components/common/table';

import { color } from '@helpers/const';
import isMobile from '@helpers/hoc/isMobile';
import {
  oddEven,
  bigSmall,
  findDragonTigerThousandDigit,
  openCodeToArray,
} from '@helpers/utils';

import { constBigSmallThreshold } from '@const/playRule';
import {
  constPlayGroup,
  constGameBallStartNumber,
  constGameBallNumber,
  constTotalCodeDigit,
} from '@const/game';

const { K3, D11, KL10F, SSC } = constPlayGroup;

const getTableHeader = (group, isMobile, filter) => {
  let columns = [
    {
      title: '日期',
      dataIndex: '日期',
      width: 113,
    },
  ];

  for (
    let index = constGameBallStartNumber[group];
    index <= constGameBallNumber[group];
    index++
  ) {
    if (isMobile) {
      if (filter.includes(index)) {
        columns.push({
          title: index.toString(),
          dataIndex: index,
        });
      }
    } else {
      columns.push({
        title: index.toString(),
        dataIndex: index,
      });
    }
  }

  switch (group) {
    case K3:
      if (!isMobile || filter.includes('big')) {
        columns.push(
          {
            title: '大',
            dataIndex: '大',
          },
          {
            title: '小',
            dataIndex: '小',
          },
        );
      }
      break;
    default:
      if (isMobile) {
        for (let index = 0; index < filter.length; index++) {
          switch (filter[index]) {
            case 'odd':
              columns.push({
                title: '单',
                dataIndex: '单',
              });
              break;
            case 'even':
              columns.push({
                title: '双',
                dataIndex: '双',
              });
              break;
            case 'big':
              columns.push({
                title: '大',
                dataIndex: '大',
              });
              break;
            case 'small':
              columns.push({
                title: '小',
                dataIndex: '小',
              });
              break;
            case 'dragon':
              columns.push({
                title: '龙',
                dataIndex: '龙',
              });
              break;
            case 'tiger':
              columns.push({
                title: '虎',
                dataIndex: '虎',
              });
              break;
            case 'equal':
              columns.push({
                title: '和',
                dataIndex: '和',
              });
              break;

            default:
              break;
          }
        }
      } else {
        columns.push(
          {
            title: '单',
            dataIndex: '单',
          },
          {
            title: '双',
            dataIndex: '双',
          },
          {
            title: '大',
            dataIndex: '大',
          },
          {
            title: '小',
            dataIndex: '小',
          },
          // {
          //     title: '龙',
          //     dataIndex: '龙',
          // },
          // {
          //     title: '虎',
          //     dataIndex: '虎',
          // },
        );
        if (group !== SSC && group !== KL10F) {
          columns.push(
            {
              title: '龙',
              dataIndex: '龙',
            },
            {
              title: '虎',
              dataIndex: '虎',
            },
          );
        }
        if (group !== D11 && group !== KL10F && group !== SSC) {
          columns.push({
            title: '和',
            dataIndex: '和',
          });
        }
      }
      break;
  }
  return columns;
};

const getTableData = (openCodeArray, group) => {
  let dataSourceArray = [];

  for (let indexDate = 0; indexDate < openCodeArray.length; indexDate++) {
    let countOpenCode;
    switch (group) {
      case K3:
        countOpenCode = {
          大: 0,
          小: 0,
          日期: '',
        };
        break;

      default:
        countOpenCode = {
          单: 0,
          双: 0,
          大: 0,
          小: 0,
          龙: 0,
          虎: 0,
          和: 0,
          日期: '',
        };
        break;
    }

    const openCode = openCodeToArray(openCodeArray[indexDate]);
    countOpenCode.日期 =
      openCodeArray[indexDate].length > 1 &&
      openCodeArray[indexDate][0].stopOrderTime.slice(0, 10);
    for (
      let indexRow = 0;
      indexRow < openCodeArray[indexDate].length;
      indexRow++
    ) {
      for (
        let indexNumber = 0;
        indexNumber < constTotalCodeDigit[group];
        indexNumber++
      ) {
        const number = openCode[indexRow][indexNumber];

        countOpenCode['key'] = indexDate + '' + indexRow;
        countOpenCode[number] = countOpenCode[number]
          ? countOpenCode[number] + 1
          : 1;

        if (oddEven(number) && group !== K3) {
          countOpenCode.单++;
        } else {
          countOpenCode.双++;
        }
        if (bigSmall(number, constBigSmallThreshold[group])) {
          countOpenCode.大++;
        } else {
          countOpenCode.小++;
        }

        if (indexNumber === 0 && group !== K3) {
          const lastIndex = openCode[indexRow].length - 1;
          const dragonTiger = findDragonTigerThousandDigit(
            number,
            openCode[indexRow][lastIndex],
          );

          if (dragonTiger === '龙') {
            countOpenCode.龙++;
          } else if (dragonTiger === '虎') {
            countOpenCode.虎++;
          } else if (dragonTiger === '和') {
            countOpenCode.和++;
          }
        }
      }
    }

    dataSourceArray.push(countOpenCode);
  }

  return dataSourceArray;
};

const MainTable = ({ isMobile, openCodeArray, group, filter }) => {
  const columns = getTableHeader(group, isMobile, filter);
  let dataSourceArray = [];
  dataSourceArray =
    openCodeArray.length > 1 && getTableData(openCodeArray, group);

  return (
    <TrendsResultWrapper>
      <TrendsTableWrapper>
        <SummarizeTable
          tableData={dataSourceArray}
          tableHeader={columns}
          fixHeader={true}
          translHeaderFull={true}
        />
      </TrendsTableWrapper>
    </TrendsResultWrapper>
  );
};

export default isMobile(MainTable);

const TrendsResultWrapper = styled.div`
  background-color: ${color.white};

  .ant-table-wrapper {
    .ant-table {
      .ant-table-container {
        > .ant-table-content {
          > table {
            > thead {
              th {
                background-color: ${color.backgroundGrey};

                @media only screen and (max-width: 576px) {
                  background-color: ${color.orange};
                }
              }
            }
          }
        }
      }

      td {
        color: ${color.black};

        @media only screen and (max-width: 576px) {
          color: ${color.mobileBlack};
        }
      }
    }
  }
`;

const TrendsTableWrapper = styled.div`
  padding: 0 25px 48px;

  @media only screen and (max-width: 576px) {
    padding: 0;
  }
`;

import React from 'react';
import { Row, Col } from 'antd';
import styled from 'styled-components';
import { Link } from 'gatsby';

import { color } from '@helpers/const';
import { URLS } from '@const/config';

import logoImg from '@images/logos/lcw_logo_orange.png';

import footerHeadLine from '@images/icons/footer_headline.svg';
import footerHeadLineMobile from '@images/icons/footer_headline_mobile.svg';

const Footer = () => {
  var d = new Date();
  var year = d.getFullYear();

  return (
    <Wrapper>
      <Row justify="space-around" align="middle">
        <Col span={24} style={{ lineHeight: 0, marginBottom: '34px' }}>
          <NavLogoWrapper>
            <img src={logoImg} alt="乐彩网 Orange Logo" />
          </NavLogoWrapper>
        </Col>
        <Col span={24} style={{ color: color.lightGrey, marginBottom: '50px' }}>
          {/* <TitleWrapper>世界网最专业彩票开奖号码神器数据分析平台</TitleWrapper> */}
          <FooterTagLine>
            <img
              className="desktop"
              src={footerHeadLine}
              alt="世界网最专业彩票开奖号码神器数据分析平台"
            />
            <img
              className="mobile"
              src={footerHeadLineMobile}
              alt="世界网最专业彩票开奖号码神器数据分析平台"
            />
          </FooterTagLine>
        </Col>
        <Col span={24}>
          <FooterNav>
            <Row
              justify="space-around"
              align="middle"
              style={{
                maxWidth: '384px',
                margin: 'auto',
                flexWrap: 'nowrap',
              }}>
              <Col
                span={6}
                style={{
                  color: color.softWhite,
                  fontSize: '20px',
                }}>
                关于我们
              </Col>
              <Col span={6} className="addLine">
                <Link style={{ color: color.lightGrey }} to={URLS.aboutUs}>
                  关于我们
                </Link>
              </Col>
              <Col span={6} className="addLine">
                <Link
                  style={{ color: color.lightGrey }}
                  to={URLS.aboutUs + '?tab=2'}>
                  免责声明
                </Link>
              </Col>
              <Col span={6}>
                <Link
                  style={{ color: color.lightGrey }}
                  to={URLS.aboutUs + '?tab=3'}>
                  商务合作
                </Link>
              </Col>
            </Row>
          </FooterNav>
        </Col>
        {/* <Col span={24}>
            <FooterNav>
                <Row justify="space-around" align="middle">
                <Col
                    span={6}
                    style={{ color: color.softWhite, fontSize: "18px" }}
                >
                    中奖神器
                </Col>
                <Col span={6}>开奖号码</Col>
                <Col span={6}>走势图表</Col>
                <Col span={6}>长龙提醒</Col>
                </Row>
            </FooterNav>
            </Col> */}
        <Col span={24} style={{ marginTop: '56px' }}>
          <CopyrightTitleWrapper>
            温馨提醒：彩票有风险，投注需谨慎！我们积极推行负责任博彩,
            本站所有数据均由团队收集整理，数据仅供参考之用。
            <br />
            {year}©乐彩网 [www.lcw.cc] 版权所有
          </CopyrightTitleWrapper>
        </Col>
      </Row>
    </Wrapper>
  );
};

export default Footer;

const Wrapper = styled.div`
  margin: auto;
  background-color: ${color.charcoalGrey};
  color: ${color.white};
  text-align: center;
  padding: 60px 0 15px;
`;

export const NavLogoWrapper = styled.div`
  width: 145px;
  height: auto;
  margin: auto;
  padding: 0;
  vertical-align: unset;
`;

const FooterTagLine = styled.div`
  width: 600px;
  margin: auto;

  .mobile {
    display: none;
  }

  .desktop {
    display: block;
  }

  @media only screen and (max-width: 580px) {
    width: 300px;

    .mobile {
      display: block;
    }

    .desktop {
      display: none;
    }
  }
`;

const FooterNav = styled.div`
  font-size: 14px;
  color: ${color.lightGrey};
  letter-spacing: 0;
  max-width: 360px;
  margin: auto;
  text-align: center;

  .addLine {
    line-height: 20px;
    border-right: 1px solid ${color.separator};
  }
`;

// const TitleWrapper = styled.div`
//     font-weight: bold;
//     font-size: 30px;
//     color: ${color.lightGrey};
//     letter-spacing: 0;
//     text-align: center;
//     line-height: 47px;
// `;

const CopyrightTitleWrapper = styled.div`
  font-size: 12px;
  color: ${color.fontGrey};
  letter-spacing: 0;
  text-align: center;
`;

import React, { useState } from 'react';
import styled from 'styled-components';
import { Row, Col, InputNumber, Select } from 'antd';

import LcwButton from '@components/common/button';
import { Images } from '@components/common/images';

import { color } from '@helpers/const';
import { calcDuplicateCode, transl } from '@helpers/utils';

import isMobile from '@helpers/hoc/isMobile';

import { constPlayGroup } from '@const/game';
import {
  constFilterRankSubOptions,
  constLuZhuOptionBall,
  constFilterInChinese,
} from '@const/playRule';

import withLocation from '@helpers/hoc/withLocation';

const { PK10, XY10 } = constPlayGroup;

const { Option } = Select;

const GetLuZhuCol = ({
  groupDuplicateArray,
  filterType,
  appliedContinuously,
  group,
  language,
}) => {
  let cols = [];
  const limit =
    groupDuplicateArray.length > 46 ? groupDuplicateArray.length : 46;

  let generateClass;
  for (let index = 0; index < limit; index++) {
    if (Array.isArray(groupDuplicateArray[index])) {
      if (groupDuplicateArray[index].length >= appliedContinuously) {
        if (filterType === 'bigSmall') {
          generateClass =
            groupDuplicateArray[index][0] === '大'
              ? 'red big'
              : groupDuplicateArray[index][0] === '小'
              ? 'blue small'
              : 'draw';
        } else if (filterType === 'oddEven') {
          generateClass =
            groupDuplicateArray[index][0] === '双' ? 'red even' : 'blue odd';
        } else if (filterType === 'dragonTiger') {
          generateClass =
            groupDuplicateArray[index][0] === '龙'
              ? 'red dragon'
              : groupDuplicateArray[index][0] === '虎'
              ? 'blue tiger'
              : 'draw';
        } else if (filterType === 'haoMa') {
          if (group === PK10 || group === XY10) {
            generateClass =
              groupDuplicateArray[index][0] === '前' ? 'red front' : 'back';
          } else {
            generateClass =
              groupDuplicateArray[index][0] === '总来'
                ? 'hl coming'
                : 'hl not-coming';
          }
        }
      } else {
        if (filterType === 'bigSmall') {
          generateClass = groupDuplicateArray[index][0] === '大' ? 'red' : '';
        } else if (filterType === 'oddEven') {
          generateClass = groupDuplicateArray[index][0] === '双' ? 'red' : '';
        } else if (filterType === 'dragonTiger') {
          generateClass = groupDuplicateArray[index][0] === '龙' ? 'red' : '';
        } else if (filterType === 'haoMa') {
          if (group === PK10 || group === XY10) {
            generateClass = groupDuplicateArray[index][0] === '前' ? 'red' : '';
          } else {
            generateClass =
              groupDuplicateArray[index][0] === '总来'
                ? 'coming'
                : 'not-coming';
          }
        }
      }
    } else {
      if (appliedContinuously === 1 && groupDuplicateArray[index]) {
        if (filterType === 'bigSmall') {
          generateClass =
            groupDuplicateArray[index] === '大'
              ? 'red big'
              : groupDuplicateArray[index] === '小'
              ? 'blue small'
              : 'draw';
        } else if (filterType === 'oddEven') {
          generateClass =
            groupDuplicateArray[index] === '双' ? 'red even' : 'blue odd';
        } else if (filterType === 'dragonTiger') {
          generateClass =
            groupDuplicateArray[index] === '龙'
              ? 'red dragon'
              : groupDuplicateArray[index] === '虎'
              ? 'blue tiger'
              : 'draw';
        } else if (filterType === 'haoMa') {
          if (group === PK10 || group === XY10) {
            generateClass =
              groupDuplicateArray[index] === '前' ? 'red front' : 'back';
          } else {
            generateClass =
              groupDuplicateArray[index] === '总来'
                ? 'hl coming'
                : 'hl not-coming';
          }
        }
      } else {
        if (groupDuplicateArray[index]) {
          if (filterType === 'bigSmall') {
            generateClass = groupDuplicateArray[index] === '大' ? 'red' : '';
          } else if (filterType === 'oddEven') {
            generateClass = groupDuplicateArray[index] === '双' ? 'red' : '';
          } else if (filterType === 'dragonTiger') {
            generateClass = groupDuplicateArray[index] === '龙' ? 'red' : '';
          } else if (filterType === 'haoMa') {
            if (group === PK10 || group === XY10) {
              generateClass = groupDuplicateArray[index] === '前' ? 'red' : '';
            } else {
              generateClass =
                groupDuplicateArray[index] === '总来' ? 'coming' : 'not-coming';
            }
          }
        }
      }
    }

    let textResult = [];
    if (
      filterType === 'haoMa' &&
      group !== PK10 &&
      filterType === 'haoMa' &&
      group !== XY10
    ) {
      if (index === 0) {
        // if (typeof groupDuplicateArray[index] === "object") {
        //     const arrayLen = groupDuplicateArray[index].length;
        //     textResult.push(
        //         groupDuplicateArray[index].map((value, valueIndex) => {
        //             if (arrayLen === valueIndex + 1) {
        //                 return (
        //                     <div
        //                         key={index + "" + valueIndex}
        //                         className="tick blink"
        //                     ></div>
        //                 );
        //             } else {
        //                 return (
        //                     <div
        //                         key={index + "" + valueIndex}
        //                         className="tick"
        //                     ></div>
        //                 );
        //             }
        //         })
        //     );
        // } else {
        //     textResult.push(
        //         <div key={index} className="tick blink"></div>
        //     );
        // }
      }
      if (typeof groupDuplicateArray[index] === 'object') {
        const arrayLen = groupDuplicateArray[index].length;
        textResult.push(
          groupDuplicateArray[index].map((value, valueIndex) => {
            if (arrayLen === valueIndex + 1) {
              return <div key={index + '' + valueIndex} className="tick"></div>;
            } else {
              return <div key={index + '' + valueIndex} className="tick"></div>;
            }
          }),
        );
      } else {
        textResult.push(<div key={index} className="tick"></div>);
      }
    } else {
      if (index === 0) {
        if (typeof groupDuplicateArray[index] === 'object') {
          const arrayLen = groupDuplicateArray[index].length;
          textResult.push(
            groupDuplicateArray[index].map((value, valueIndex) => {
              if (arrayLen === valueIndex + 1) {
                return (
                  <div key={index + '' + valueIndex} className="blink">
                    {transl(value, [], true, false, 'en-US')}
                  </div>
                );
              } else {
                let icon = '';
                if (
                  value === '虎' &&
                  valueIndex === 0 &&
                  (language === 'en-US' || language === 'vi-VN')
                ) {
                  icon = 'icon tiger';
                } else if (
                  value === '龙' &&
                  valueIndex === 0 &&
                  (language === 'en-US' || language === 'vi-VN')
                ) {
                  icon = 'icon dragon';
                } else if (
                  value === '和' &&
                  valueIndex === 0 &&
                  (language === 'en-US' || language === 'vi-VN')
                ) {
                  icon = 'icon tie';
                }

                if (icon) {
                  return (
                    <>
                      <div className={icon}></div>
                      <div>{transl(value, [], true, false, 'en-US')}</div>
                    </>
                  );
                }

                return (
                  <div key={index + '' + valueIndex}>
                    {transl(value, [], true, false, 'en-US')}
                  </div>
                );
              }
            }),
          );
        } else {
          let icon = '';
          if (
            groupDuplicateArray[index] === '虎' &&
            (language === 'en-US' || language === 'vi-VN')
          ) {
            icon = 'icon tiger';
          } else if (
            groupDuplicateArray[index] === '龙' &&
            (language === 'en-US' || language === 'vi-VN')
          ) {
            icon = 'icon dragon';
          } else if (
            groupDuplicateArray[index] === '和' &&
            (language === 'en-US' || language === 'vi-VN')
          ) {
            icon = 'icon tie';
          }

          if (icon) {
            textResult.push(<div className={icon}></div>);
          }

          textResult.push(
            <div key={index} className="blink">
              {transl(groupDuplicateArray[index], [], true, false, 'en-US')}
            </div>,
          );
        }
      } else {
        textResult = groupDuplicateArray[index];
      }
    }

    let icon = '';
    if (
      (textResult === '虎' ||
        (Array.isArray(textResult) && textResult.includes('虎'))) &&
      (language === 'en-US' || language === 'vi-VN')
    ) {
      icon = 'icon tiger';
    } else if (
      (textResult === '龙' ||
        (Array.isArray(textResult) && textResult.includes('龙'))) &&
      (language === 'en-US' || language === 'vi-VN')
    ) {
      icon = 'icon dragon';
    } else if (
      (textResult === '和' ||
        (Array.isArray(textResult) && textResult.includes('和'))) &&
      (language === 'en-US' || language === 'vi-VN')
    ) {
      icon = 'icon tie';
    }

    cols.push(
      <Col className={generateClass} key={index}>
        <div className={icon}></div>
        {Array.isArray(textResult)
          ? textResult.map((text, index) => (
              <div key={index}>{transl(text, [], true, false, 'en-US')}</div>
            ))
          : transl(textResult, [], true, false, 'en-US')}
      </Col>,
    );

    generateClass = '';
  }

  return cols;
};

const TableTitle = ({
  filterType,
  filterBall,
  optionBallName,
  group,
  isMobile,
  openFilter,
  setOpenFilter,
  appliedTypeFilter,
  setTypeFilter,
  setAppliedTypeFilter,
  defaultFilter,
}) => {
  let ballName;
  if (filterType === 'dragonTiger' && group !== 'PK10' && group !== 'XY10') {
    ballName = '';
    if (group === 'SSC') {
      ballName =
        constLuZhuOptionBall[group][filterBall[0]].label +
        'vs' +
        constLuZhuOptionBall[group][filterBall[1]].label;
    }
  } else if (constLuZhuOptionBall[group][filterBall]) {
    ballName = constLuZhuOptionBall[group][filterBall].label;
  }

  return (
    <TableTitleWrapper className="red">
      <Row gutter={{ xs: 0, md: 12 }}>
        <Col flex="auto">
          {transl(
            optionBallName ? optionBallName : ballName,
            [],
            true,
            false,
            'en-US',
          ) + ' '}
          {transl(
            constFilterRankSubOptions[filterType].shortText
              ? constFilterRankSubOptions[filterType].shortText
              : '',
            [],
            false,
            false,
            'en-US',
          )}
        </Col>
        <Col>
          {transl('最新', [], true, false, 'en-US')}{' '}
          <RedArrowDown></RedArrowDown>
        </Col>
        {isMobile ? (
          <Col
            flex="124px"
            className={openFilter ? 'filter active' : 'filter'}
            onClick={() => {
              setOpenFilter(!openFilter);
            }}>
            <div className="displayFilter">
              {transl(
                appliedTypeFilter
                  ? constFilterInChinese[appliedTypeFilter]
                  : '连续出现',
                [],
                false,
                false,
                'en-US',
              )}
            </div>
            <FilterAppear className={openFilter ? 'active' : ''}>
              {constFilterRankSubOptions[filterType].option.map(
                ({ label, name }, index) => {
                  if (
                    filterType === 'haoMa' &&
                    (group === PK10 || group === XY10)
                  ) {
                    if (index > 1) {
                      return (
                        <div key={name}>
                          <button
                            onClick={() => {
                              setTypeFilter(name);
                              setAppliedTypeFilter(name);
                            }}>
                            {transl(label, [], false, false, 'en-US')}
                          </button>
                        </div>
                      );
                    } else {
                      return '';
                    }
                  } else {
                    if (index < defaultFilter.length) {
                      return (
                        <div key={name}>
                          <button
                            onClick={() => {
                              setTypeFilter(name);
                              setAppliedTypeFilter(name);
                            }}>
                            {transl(label, [], false, false, 'en-US')}
                          </button>
                        </div>
                      );
                    } else {
                      return '';
                    }
                  }
                },
              )}
            </FilterAppear>
          </Col>
        ) : (
          ''
        )}
      </Row>
    </TableTitleWrapper>
  );
};

const calLuZhuColFilter = (
  groupDuplicateArray,
  appliedContinuously,
  typeFilter,
) => {
  let countFilter = 0;
  const limit =
    groupDuplicateArray.length > 46 ? groupDuplicateArray.length : 46;

  for (let index = 0; index < limit; index++) {
    if (Array.isArray(groupDuplicateArray[index])) {
      if (groupDuplicateArray[index].length >= appliedContinuously) {
        switch (groupDuplicateArray[index][0]) {
          case '大':
            if (typeFilter === 'big') {
              countFilter++;
            }
            break;
          case '小':
            if (typeFilter === 'small') {
              countFilter++;
            }
            break;
          case '单':
            if (typeFilter === 'odd') {
              countFilter++;
            }
            break;
          case '双':
            if (typeFilter === 'even') {
              countFilter++;
            }
            break;
          case '虎':
            if (typeFilter === 'tiger') {
              countFilter++;
            }
            break;
          case '龙':
            if (typeFilter === 'dragon') {
              countFilter++;
            }
            break;
          case '和':
            if (typeFilter === 'draw') {
              countFilter++;
            }
            break;
          case '总来':
            if (typeFilter === 'coming') {
              countFilter++;
            }
            break;
          case '没来':
            if (typeFilter === 'not-coming') {
              countFilter++;
            }
            break;
          case '前':
            if (typeFilter === 'front') {
              countFilter++;
            }
            break;
          case '后':
            if (typeFilter === 'back') {
              countFilter++;
            }
            break;
          default:
            break;
        }
      }
    } else {
      if (appliedContinuously === 1 && groupDuplicateArray[index]) {
        switch (groupDuplicateArray[index]) {
          case '大':
            if (typeFilter === 'big') {
              countFilter++;
            }
            break;
          case '小':
            if (typeFilter === 'small') {
              countFilter++;
            }
            break;
          case '单':
            if (typeFilter === 'odd') {
              countFilter++;
            }
            break;
          case '双':
            if (typeFilter === 'even') {
              countFilter++;
            }
            break;
          case '虎':
            if (typeFilter === 'tiger') {
              countFilter++;
            }
            break;
          case '龙':
            if (typeFilter === 'dragon') {
              countFilter++;
            }
            break;
          case '和':
            if (typeFilter === 'draw') {
              countFilter++;
            }
            break;
          case '总来':
            if (typeFilter === 'coming') {
              countFilter++;
            }
            break;
          case '没来':
            if (typeFilter === 'not-coming') {
              countFilter++;
            }
            break;
          case '前':
            if (typeFilter === 'front') {
              countFilter++;
            }
            break;
          case '后':
            if (typeFilter === 'back') {
              countFilter++;
            }
            break;
          default:
            break;
        }
      }
    }
  }

  return countFilter;
};

const LuzhuTable = ({
  isMobile,
  groupDuplicateArray,
  defaultFilter,
  filterType,
  filterBall,
  optionBallName,
  group,
  search,
}) => {
  const [typeFilter, setTypeFilter] = useState(defaultFilter[0]);
  const [appliedTypeFilter, setAppliedTypeFilter] = useState('');

  const [continuously, setContinuously] = useState(1);
  const [appliedContinuously, setAppliedContinuously] = useState(1);

  const [filterCount, setFilterCount] = useState(0);

  const [openFilter, setOpenFilter] = useState(false);

  const { language } = search;

  let calTodayTotal1Text;
  let calTodayTotal2Text;
  let calTodayTotal3Text;
  let numberOfTotal1;
  let numberOfTotal2;
  let numberOfTotal3;

  switch (filterType) {
    case 'bigSmall':
      numberOfTotal1 = calcDuplicateCode(groupDuplicateArray).大
        ? calcDuplicateCode(groupDuplicateArray).大
        : 0;
      numberOfTotal2 = calcDuplicateCode(groupDuplicateArray).小
        ? calcDuplicateCode(groupDuplicateArray).小
        : 0;

      if (defaultFilter.length === 3) {
        numberOfTotal3 = calcDuplicateCode(groupDuplicateArray).和
          ? calcDuplicateCode(groupDuplicateArray).和
          : 0;
        calTodayTotal3Text = `${transl(
          '和',
          [],
          false,
          false,
          'en-US',
        )} (${numberOfTotal3})`;
      }

      calTodayTotal1Text = `${transl(
        '大',
        [],
        false,
        false,
        'en-US',
      )} (${numberOfTotal1})`;
      calTodayTotal2Text = `${transl(
        '小',
        [],
        false,
        false,
        'en-US',
      )} (${numberOfTotal2})`;
      break;
    case 'oddEven':
      numberOfTotal1 = calcDuplicateCode(groupDuplicateArray).单
        ? calcDuplicateCode(groupDuplicateArray).单
        : 0;
      numberOfTotal2 = calcDuplicateCode(groupDuplicateArray).双
        ? calcDuplicateCode(groupDuplicateArray).双
        : 0;

      calTodayTotal1Text = `${transl(
        '单',
        [],
        false,
        false,
        'en-US',
      )} (${numberOfTotal1})`;
      calTodayTotal2Text = `${transl(
        '双',
        [],
        false,
        false,
        'en-US',
      )} (${numberOfTotal2})`;
      break;
    case 'dragonTiger':
      numberOfTotal1 = calcDuplicateCode(groupDuplicateArray).龙
        ? calcDuplicateCode(groupDuplicateArray).龙
        : 0;
      numberOfTotal2 = calcDuplicateCode(groupDuplicateArray).虎
        ? calcDuplicateCode(groupDuplicateArray).虎
        : 0;

      if (defaultFilter.length === 3) {
        numberOfTotal3 = calcDuplicateCode(groupDuplicateArray).和
          ? calcDuplicateCode(groupDuplicateArray).和
          : 0;
        calTodayTotal3Text = `${transl(
          '和',
          [],
          false,
          false,
          'en-US',
        )} (${numberOfTotal3})`;
      }

      calTodayTotal1Text = `${transl(
        '龙',
        [],
        false,
        false,
        'en-US',
      )} (${numberOfTotal1})`;
      calTodayTotal2Text = `${transl(
        '虎',
        [],
        false,
        false,
        'en-US',
      )} (${numberOfTotal2})`;
      break;
    case 'haoMa':
      if (group === PK10 || group === XY10) {
        numberOfTotal1 = calcDuplicateCode(groupDuplicateArray).前
          ? calcDuplicateCode(groupDuplicateArray).前
          : 0;
        numberOfTotal2 = calcDuplicateCode(groupDuplicateArray).后
          ? calcDuplicateCode(groupDuplicateArray).后
          : 0;
        calTodayTotal1Text = `${transl(
          '前',
          [],
          false,
          false,
          'en-US',
        )} (${numberOfTotal1})`;
        calTodayTotal2Text = `${transl(
          '后',
          [],
          false,
          false,
          'en-US',
        )} (${numberOfTotal2})`;
      } else {
        numberOfTotal1 = calcDuplicateCode(groupDuplicateArray).总来
          ? calcDuplicateCode(groupDuplicateArray).总来
          : 0;
        numberOfTotal2 = calcDuplicateCode(groupDuplicateArray).没来
          ? calcDuplicateCode(groupDuplicateArray).没来
          : 0;
        calTodayTotal1Text = `${transl(
          '总来',
          [],
          false,
          false,
          'en-US',
        )} (${numberOfTotal1})`;
        calTodayTotal2Text = `${transl(
          '没来',
          [],
          false,
          false,
          'en-US',
        )} (${numberOfTotal2})`;
      }

      break;

    default:
      break;
  }

  let defaultSelectOptionFilter;
  if (filterType === 'haoMa' && (group === PK10 || group === XY10)) {
    defaultSelectOptionFilter =
      constFilterRankSubOptions[filterType].option[2].name;
  }

  return (
    <TrendsTableWrapper className={appliedTypeFilter}>
      <Row className="luzhu-title-wrapper" justify="space-between">
        {!isMobile ? (
          <Col>
            <Row gutter={15} align="middle">
              <Col>
                <Row gutter={10} align="middle">
                  <Col>{transl('连续出现', [], false, false, 'en-US')}</Col>
                  <Col>
                    <InputNumber
                      className="input-number"
                      min={1}
                      defaultValue={continuously}
                      onChange={(props) => {
                        setContinuously(props);
                      }}
                    />
                  </Col>
                </Row>
              </Col>
              <Col>
                <Row gutter={10} align="middle">
                  <Col>{transl('连续出现', [], false, false, 'en-US')}</Col>
                  <Col>
                    <Select
                      className="input-select"
                      defaultValue={
                        defaultSelectOptionFilter
                          ? defaultSelectOptionFilter
                          : constFilterRankSubOptions[filterType].option[0].name
                      }
                      dropdownClassName="ballType"
                      onSelect={(props) => setTypeFilter(props)}>
                      {constFilterRankSubOptions[filterType].option.map(
                        (value, index) => {
                          if (
                            filterType === 'haoMa' &&
                            (group === PK10 || group === XY10)
                          ) {
                            if (index > 1) {
                              return (
                                <Option key={value.name} value={value.name}>
                                  {transl(
                                    value.label,
                                    [],
                                    false,
                                    false,
                                    'en-US',
                                  )}
                                </Option>
                              );
                            } else {
                              return '';
                            }
                          } else {
                            if (index < defaultFilter.length) {
                              return (
                                <Option key={value.name} value={value.name}>
                                  {transl(
                                    value.label,
                                    [],
                                    false,
                                    false,
                                    'en-US',
                                  )}
                                </Option>
                              );
                            } else {
                              return '';
                            }
                          }
                        },
                      )}
                    </Select>
                  </Col>
                </Row>
              </Col>
              <Col>
                <div>
                  <LcwButton
                    text="确定"
                    styleCss={{
                      backgroundColor: color.black,
                      color: color.white,
                      border: 'none',
                      borderRadius: '4px',
                    }}
                    // url={location.search}
                    activeOnClick={() => {
                      setAppliedTypeFilter(typeFilter);
                      setAppliedContinuously(continuously);
                      setFilterCount(
                        calLuZhuColFilter(
                          groupDuplicateArray,
                          continuously,
                          typeFilter,
                        ),
                      );
                    }}
                  />
                </div>
              </Col>
              <Col>
                <div>
                  {transl('出现的次数为：', [], false, false, 'en-US')}
                  {filterCount}
                </div>
              </Col>
            </Row>
          </Col>
        ) : (
          ''
        )}
        <Col>
          <Row gutter={12}>
            <Col>
              <Row gutter={{ xs: 5, md: 12 }}>
                <Col>{transl('今天', [], false, false, 'en-US')}</Col>
                <Col>{transl('累计:', [], false, false, 'en-US')}</Col>
                <Col>{calTodayTotal1Text}</Col>
                <Col>{calTodayTotal2Text}</Col>
                {calTodayTotal3Text ? <Col>{calTodayTotal3Text}</Col> : ''}
              </Row>
            </Col>
            {!isMobile ? (
              <TableTitle
                filterType={filterType}
                filterBall={filterBall}
                optionBallName={optionBallName}
                group={group}
                isMobile={isMobile}
                openFilter={openFilter}
                setOpenFilter={setOpenFilter}
              />
            ) : (
              ''
            )}
          </Row>
        </Col>
      </Row>
      {isMobile ? (
        <MobileTableTitle>
          <TableTitle
            filterType={filterType}
            filterBall={filterBall}
            optionBallName={optionBallName}
            group={group}
            isMobile={isMobile}
            openFilter={openFilter}
            setOpenFilter={setOpenFilter}
            setTypeFilter={setTypeFilter}
            setAppliedTypeFilter={setAppliedTypeFilter}
            appliedTypeFilter={appliedTypeFilter}
            defaultFilter={defaultFilter}
          />
        </MobileTableTitle>
      ) : (
        ''
      )}
      <Row className="luzhu-result-wrapper">
        <GetLuZhuCol
          groupDuplicateArray={groupDuplicateArray}
          filterType={filterType}
          appliedContinuously={appliedContinuously}
          typeFilter={typeFilter}
          group={group}
          language={language}
        />
      </Row>
    </TrendsTableWrapper>
  );
};

export default isMobile(withLocation(LuzhuTable));

const TrendsTableWrapper = styled.div`
    padding: 0 25px 15px;

    @media only screen and (max-width: 576px) {
        padding: 0;
    }

    .input-number {
        border-radius: 4px;
        width: 54px;

        .ant-input-number-handler-wrap {
            width: 15px;

            .anticon {
                right: 1px;

                &.ant-input-number-handler-up-inner {
                    margin-top: -5px;
                }
            }
        }

        .ant-input-number-input-wrap {
            input {
                text-align: center;
            }
        }
    }

    .input-select {
        width: auto;

        .ant-select-selector {
            border-radius: 4px;
        }

        .ant-select-arrow {
            width: 9px;
            height: 4.9px;
            top: 53%;
            transform: translateY(-50%);
            margin: 0;
            transition: all 0.2s linear;

            svg {
                display: none;
            }

            &:after {
                content: '';
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                background: url(${Images.arrowDownBlack}) center/cover;
            }
        }

        &.ant-select-open {
            .ant-select-arrow {
                transform: translateY(-50%) rotate(180deg);
            }
        }
    }

    .red {
        color: ${color.red}
    }

    .green {
        color: ${color.green}
    }

    .blue {
        color: ${color.blue}
    }

    .luzhu-title-wrapper {
        line-height: 31px;
        padding: 7px 15px;
        border: 1px solid ${color.borderGrey};
        background-color: ${color.backgroundGrey};

        @media only screen and (max-width: 576px) {
            color: ${color.lightFontGrey};
            line-height: 16px;
            padding: 11px 0 3px 8px;
            border: none;
            border-top: 1px solid ${color.borderGrey};
        }
    }

    .luzhu-result-wrapper {
        overflow-x: scroll;
        flex-wrap: nowrap;
        flex-direction: row-reverse;
        -webkit-overflow-scrolling: touch;

        &::-webkit-scrollbar {
            z-index: 1;
            width: 8px;
            height: 8px;
        }
        
        /* Track */
        &::-webkit-scrollbar-track {
            background: #f1f1f1;
        }
        
        /* Handle */
        &::-webkit-scrollbar-thumb {
            background: #cdcdcd;
        }
        
        /* Handle on hover */
        &::-webkit-scrollbar-thumb:hover {
            background: #797979;
        }
    
        &::-webkit-scrollbar-button {
            width: 0;
            height: 0;
            display: none;
        }
    
        scrollbar-width: thin;
        
        .ant-col {
            min-height: 180px;
            border: 1px solid ${color.borderGrey};
            border-top: none;
            border-right: none;
            padding: 2px 0;
            min-width: 28px;
            text-align: center;

            @media only screen and (max-width: 576px) {
                min-height: 160px;
                font-size: 13px;
                line-height: 16px;
                color: ${color.mobileBlack};

                &.red {
                    color: ${color.red};
                }

                &.green {
                    color: ${color.green};
                }

                &.blue {
                    color: ${color.blue};
                }
            }

            &:first-child {
                border-right 1px solid ${color.borderGrey};
                padding-right: 4px;
            }

            &:nth-child(even) {
                background-color: ${color.backgroundGrey};
            }

            &.coming {
                .tick {
                    position: relative;
                    width: 10px;
                    padding-bottom: 10px;
                    margin: 9px auto;

                    &:after{
                        content:"";
                        background: url(${Images.tick_mark_red}) no-repeat center/cover;
                        position: absolute;
                        width: 100%;
                        height: 100%;
                        left: 50%;
                        top: 50%;
                        transform: translate(-50%, -50%);
                    }

                    &.blink {
                        background: url(${Images.tick_mark_red_bold}) no-repeat center/cover;
                    }
                }
            }
            &.not-coming {
                .tick {
                    position: relative;
                    width: 10px;
                    padding-bottom: 10px;
                    margin: 9px auto;

                    &:after{
                        content:"";
                        background: url(${Images.tick_x_black}) no-repeat center/cover;
                        position: absolute;
                        width: 100%;
                        height: 100%;
                        left: 50%;
                        top: 50%;
                        transform: translate(-50%, -50%);
                    }

                    &.blink {
                        background: url(${Images.tick_x_black_bold}) no-repeat center/cover;
                    }
                }
            }

            .blink {
                font-weight: bold;
            }

            .icon {
                width: 20px;
                height: 20px;
                margin: 3px auto;

                &.dragon {
                    background: url(${Images.icn_red_dragon}) no-repeat center/cover;
                }

                &.tiger {
                    background: url(${Images.icn_blue_tiger}) no-repeat center/cover;
                }

                &.tie {
                    &:after {
                        content: "Tie";
                    }
                }
            }
        }
    }

    &.big,
    &.even,
    &.dragon,
    &.coming,
    &.front {
        .luzhu-result-wrapper {
            .big,
            .even,
            .dragon,
            .front {
                color: ${color.white};
                background-color: ${color.orange};
            }

            .icon.dragon {
                background: url(${Images.icn_white_dragon}) no-repeat center/cover;
            }

            .hl.coming {
                color: ${color.white};
                background-color: ${color.orange};
            }

            .ant-col.coming {
                .tick {
                    &:after {
                        background: url(${Images.icn_tick_white}) no-repeat center/cover;
                    }
                }
            }
        }
    }

    &.small,
    &.odd,
    &.tiger,
    &.not-coming,
    &.back {
        .luzhu-result-wrapper {
            .small,
            .odd,
            .tiger,
            .back {
                color: ${color.white};
                background-color: ${color.orange};
            }

            .icon.tiger {
                background: url(${Images.icn_white_tiger}) no-repeat center/cover;
            }

            .hl.not-coming {
                color: ${color.white};
                background-color: ${color.orange};
            }
            
            .ant-col.not-coming {
                .tick {
                    &:after {
                        background: url(${Images.icn_X_white}) no-repeat center/cover;
                    }
                }
            }
        }
    }

    &.draw {
        .luzhu-result-wrapper {
            .draw {
                color: ${color.white};
                background-color: ${color.green};
            }
        }
    }
`;

const RedArrowDown = styled.div`
  display: inline-block;
  width: 7px;
  height: 13px;
  margin-right: -6px;
  margin-left: 1px;
  background: url(${Images.arrowDownRed}) center/cover;
`;

const MobileTableTitle = styled.div``;

const TableTitleWrapper = styled(Col)`
  @media only screen and (max-width: 576px) {
    .ant-row {
      text-align: center;
      line-height: 16px;
      color: ${color.white};
      background-color: ${color.orange};

      .ant-col {
        padding: 0;
        line-height: 29px;
        border-bottom: 1px solid ${color.borderGrey};

        &:first-child {
          border-right: 1px solid ${color.borderGrey};
        }

        &:nth-child(2) {
          display: none;
        }

        &.filter {
          .displayFilter {
            position: relative;
            width: fit-content;
            margin: auto;

            &:after {
              content: '';
              background-image: url(${Images.icn_dropdown_white});
              position: absolute;
              width: 11px;
              height: 7px;
              top: 50%;
              right: -15px;
              transform: translateY(-50%);
            }
          }

          &.active {
            .displayFilter {
              &:after {
                background-image: url(${Images.icn_dropdown_up_white});
              }
            }
          }
        }
      }
    }
  }
`;

const FilterAppear = styled.div`
  @media only screen and (max-width: 576px) {
    display: none;
    position: absolute;
    top: 29px;
    left: 0;
    width: 100%;
    z-index: 1;
    background-color: ${color.orangeTransparent};

    &.active {
      display: block;
    }

    div {
      border-top: 1px solid ${color.borderGrey};

      button {
        width: 100%;
        border: none;
        background-color: transparent;
      }
    }
  }
`;
